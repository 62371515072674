import React, { Component } from 'react';
import './ResetPassword.scss';
import { Button, FormGroup, Label, Form, Input } from 'reactstrap';
import combinedServices from "../../shared/services/user-service";
import { Redirect } from "react-router-dom";
import Header from '../../component/Header/Header';
import { TopLinks } from '../../component/TopLinks/TopLinks';
import removeLocalData from "../../shared/localStorage/removeData";
import history from "../../shared/history";
import { Footer } from '../../component/Footer/Footer';
export class ResetPassword extends Component {
    constructor(props) {
    super(props);
        this.state = {
            email : '',
            password : '',
            confirmPassword : '',
            errors :{},
            successMessage : "",
            errorMessage : "",
            toHome:false
           

        }
       
    }

    componentDidMount =()=>{

    const yourParamName = history.location.pathname ;
    const youradminUrl  = yourParamName.split('/') ;
   
    this.setState({ token:youradminUrl[2] })
    }



    handleChange = e =>{
        
        this.setState({ [e.target.name]: e.target.value })

    }



     handleSubmit = async (e) =>{
        e.preventDefault();
        const form = e.target;

        if (this.validateForm() === false) {
        return;
        } else {
            
            const data = {
            token: this.state.token,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword
            
            };
            

            let reponsedata = await combinedServices.resetPassword(data);
            let response = reponsedata.data;
            let result = response.result1;

            if(response.responseCode === 200){
               this.setState({
                    errorMessage: false,
                    successMessage: response.responseMessage,
                    toHome :true
                });

             
            }else{
                

                this.setState({
                    successMessage: false,
                    errorMessage: response.responseMessage
                })

            }

        }

        
    }



    

     validateForm=()=>{
        
        let password = this.state.password;
        let confirmPassword = this.state.confirmPassword;
        let errors = {};
        let formIsValid = true;
       

         if (!password) {
            formIsValid = false;
            errors["password"] = "Please enter your password.";
        }
        if (!confirmPassword) {
            formIsValid = false;
            errors["confirmPassword"] = "Please enter your confirm password.";
        }
        if(password || confirmPassword ){
            if(password!=confirmPassword){
                formIsValid = false;
                errors["confirmPassword"] = "Password and confirm password did not match ";

            }
        }
        this.setState({
            errors: errors
        });
        return formIsValid
    }






    render() {

        if (this.state.toHome) {
           return <Redirect to='/' />
        }
        return (
            <div>
            <Header/>
           
            <Form onSubmit={this.handleSubmit}>
            {this.state.successMessage ? <div className="alert alert-success">{this.state.successMessage}</div>: ''}
            {this.state.errorMessage ? <div className="alert alert-danger">{this.state.errorMessage}</div>: ''}
                <div className="inner fpBox">
                    <FormGroup>
                       
                         <Label>Password</Label>
                        <Input type="password" name="password" value={this.state.password} onChange={this.handleChange} className="loginInput passwordIn" />
                        {this.state.errors.password && (
                        <div className="errorMsg">{this.state.errors.password}</div> 
                        )}
                    </FormGroup>
                    <FormGroup>
                         <Label>Confirm Password</Label>
                        <Input type="password" name="confirmPassword" value={this.state.confirmPassword} onChange={this.handleChange} className="loginInput passwordIn" />
                        {this.state.errors.confirmPassword && (
                        <div className="errorMsg">{this.state.errors.confirmPassword}</div> 
                        )}
                    </FormGroup>
                    <div className="loginBtn text-right"><Button>Reset My Password</Button></div>
                </div>
                
                
            </Form>
            <Footer/>
            </div>
        )
    }
}
export default ResetPassword;