import React,{Component} from 'react';
import {NavLink, Link} from "react-router-dom";
import './Footer.scss';
import logo from '../../assets/images/logo.png';
import getLocalData from "../../shared/localStorage/getData";
import removeLocalData from "../../shared/localStorage/removeData";
import { Redirect } from "react-router-dom";

export class Footer extends Component {

    constructor() {
        super();
        this.state = {
          user_logged_in: false,
          admin_logged_in: false,
          toHome: false
        };
    }


    componentDidMount() {
     

        if(getLocalData('user_info','isLogin')){
          this.setState({
            user_logged_in: true
          })
        }

        if(getLocalData('admin_info','isLogin')){
          this.setState({
            admin_logged_in: true
          })
        }
   
    }


    logoutUser = () => {

            removeLocalData("user_info");
            removeLocalData("admin_info");
            window.location.href='/';

      };

    render(){
      var headerLogo ='';
        if (this.state.toHome) {
           return <Redirect to='/' />
        }
        if(this.state.admin_logged_in){
          headerLogo =  <div className="logo"><NavLink to="/admin/manual-links"><img alt="" src={logo} /></NavLink></div>
          }else{
    
            headerLogo =  <div className="logo"><NavLink to="/"><img alt="" src={logo} /></NavLink></div>
          }
        return(
            <footer className="footer">
                 <div className="container d-flex flex-wrap align-items-center justify-content-between">
                        {headerLogo}
                        {this.state.user_logged_in && (
                        <ul className="footerLink d-flex  flex-wrap">
                            <li><NavLink to="/user-profile">Profile</NavLink></li>
                            <li><NavLink to="/resources">Client Support</NavLink></li>

                            {/* <li><NavLink to="/user-profile">Client Support</NavLink></li> */}
                            <li><NavLink to="/terms-and-conditions">Terms &amp; Conditions</NavLink></li>
                            <li>Copyright {(new Date().getFullYear())}</li>
                        </ul>
                        )}


                        {this.state.admin_logged_in && (
                        <ul className="footerLink d-flex  flex-wrap">
                            <li onClick={this.logoutUser} >Logout</li>
                            <li><NavLink to="/terms-and-conditions">Terms &amp; Conditions</NavLink></li>
                            <li>Copyright {(new Date().getFullYear())}</li>
                        </ul>
                        )}



                        {!this.state.user_logged_in && !this.state.admin_logged_in && (
                           <ul className="footerLink d-flex  flex-wrap">
                        
                            <li><NavLink to="/terms-and-conditions">Terms &amp; Conditions</NavLink></li>
                            <li>Copyright {(new Date().getFullYear())}</li>
                           </ul>
                        )}


                    </div>
            </footer>
        )
    }
}
export default Footer;