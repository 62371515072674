import React, { Component, useState } from 'react';
import './ManualLinks.scss';
import { AdminTopLinks } from '../../component/TopLinks/AdminTopLinks';
import { Button, FormGroup, Label, Form, Input } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from '../../component/headerWithoutLoginPopup/Header';
import { Footer } from '../../component/Footer/Footer';
import combinedServices from "../../shared/services/user-service";
import dateFormat from 'dateformat';
import Swal from "sweetalert2";
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

export class ManualLinks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            OCIOParagraph: '',
            tAndC: '',
            trialText: '',
            whiteLabelInsightsParagraph: '',
            successMessage: false,
            errorMessage: false,
            ratedate1: new Date(),
            allDates: {},
            clientSupportData: [],
            // getclientResourceDataList: {},
            // clientResourcesDataStatus: false,
            getCurrentRatingDataList: {},
            currentRatingDataStatus: false,
            getModelPortfiloDataList: {},
            getLrtReportDataList: {},
            lrtReportStatus: false,
            ModelPortfiloDataStatus: false,
            getThematicAndTacticalDataList: {},
            thematicAndTacticalDataSatus: false,
            OCIOResourceDataList: {},
            OCIOResourceDataStatus: false,
            OCIODocumentDataList: {},
            OCIODocumentDataStatus: false,
            OCIOCoreDataList: {},
            OCIOCoreDataStatus: false,
            whiteResourcesDataList: {},
            whiteResourcesDataStatus: false,
            whiteLevelMonthlyDataList: {},
            whiteLevelMonthlyDataStatus: false,
            whiteLevelChartDataList: {},
            whiteLevelChartDataStatus: false,
            whiteLevelQuartlyLetterDataList: {},
            whiteLevelQuartlyLetterDataStatus: false,
            whiteLevelOffShelfDataList: {},
            whiteLevelOffShelfDataStatus: false,
            whiteLevelQuartlyMarketDataList: {},
            whiteLevelQuartlyMarketDataStatus: false,
            trialDaysDataStatus: false,
            trialDaysDataList: {},
            whiteLabelMarketTalkingPointDataList: {},
            whiteLabelMarketTalkingPointStatus: false


        }

    }

    handleChangeTermsCondition = (value) => {
        this.setState({
            tAndC: value
        });
    }

    componentDidMount = async () => {
        let allDates = this.state.allDates;


        let clientSupportResponse = await combinedServices.getClientSupportData();

        if (clientSupportResponse.status) {

            var clientSupportData = clientSupportResponse.data


            this.setState({

                supportButtonText1: clientSupportData.supportButtonText1,
                supportButtonText2: clientSupportData.supportButtonText2,
                supportButtonText3: clientSupportData.supportButtonText3,
                supportButtonText4: clientSupportData.supportButtonText4,
                supportButtonText5: clientSupportData.supportButtonText5,
                supportButtonText6: clientSupportData.supportButtonText6,
                supportButtonText7: clientSupportData.supportButtonText7,
                supportButtonText8: clientSupportData.supportButtonText8,
                supportButtonText9: clientSupportData.supportButtonText9,
                supportButtonText10: clientSupportData.supportButtonText10,
                supportButtonText11: clientSupportData.supportButtonText11,
                supportButtonText12: clientSupportData.supportButtonText12,
                supportButtonText13: clientSupportData.supportButtonText13,
                supportButtonText14: clientSupportData.supportButtonText14,
                supportButtonText15: clientSupportData.supportButtonText15,
                supportButtonText16: clientSupportData.supportButtonText16,
                supportButtonText17: clientSupportData.supportButtonText17,
                supportButtonText18: clientSupportData.supportButtonText18,
                supportButtonText19: clientSupportData.supportButtonText19,
                supportButtonText20: clientSupportData.supportButtonText20,
                supportButtonURL1: clientSupportData.supportButtonURL1,
                supportButtonURL2: clientSupportData.supportButtonURL2,
                supportButtonURL3: clientSupportData.supportButtonURL3,
                supportButtonURL4: clientSupportData.supportButtonURL4,
                supportButtonURL5: clientSupportData.supportButtonURL5,
                supportButtonURL6: clientSupportData.supportButtonURL6,
                supportButtonURL7: clientSupportData.supportButtonURL7,
                supportButtonURL8: clientSupportData.supportButtonURL8,
                supportButtonURL9: clientSupportData.supportButtonURL9,
                supportButtonURL10: clientSupportData.supportButtonURL10,
                supportButtonURL11: clientSupportData.supportButtonURL11,
                supportButtonURL12: clientSupportData.supportButtonURL12,
                supportButtonURL13: clientSupportData.supportButtonURL13,
                supportButtonURL14: clientSupportData.supportButtonURL14,
                supportButtonURL15: clientSupportData.supportButtonURL15,
                supportButtonURL16: clientSupportData.supportButtonURL16,
                supportButtonURL17: clientSupportData.supportButtonURL17,
                supportButtonURL18: clientSupportData.supportButtonURL18,
                supportButtonURL19: clientSupportData.supportButtonURL19,
                supportButtonURL20: clientSupportData.supportButtonURL20,
                supportType1: clientSupportData.supportType1,
                supportType2: clientSupportData.supportType2,
                supportType3: clientSupportData.supportType3,
                supportType4: clientSupportData.supportType4,
                supportType5: clientSupportData.supportType5,
                supportType6: clientSupportData.supportType6,
                supportType7: clientSupportData.supportType7,
                supportType8: clientSupportData.supportType8,
                supportType9: clientSupportData.supportType9,
                supportType10: clientSupportData.supportType10,
                supportType11: clientSupportData.supportType11,
                supportType12: clientSupportData.supportType12,
                supportType13: clientSupportData.supportType13,
                supportType14: clientSupportData.supportType14,
                supportType15: clientSupportData.supportType15,
                supportType16: clientSupportData.supportType16,
                supportType17: clientSupportData.supportType17,
                supportType18: clientSupportData.supportType18,
                supportType19: clientSupportData.supportType19,
                supportType20: clientSupportData.supportType20,
                supportId: clientSupportData._id
            })

        }

        // +++++++++++++++++++++++++Added by chandra

        let lrtReportResponse = await combinedServices.getLrtReportData();
        console.log("154", lrtReportResponse)
        if (lrtReportResponse.status) {
            var getLrtReportDataList = lrtReportResponse.data;

            // allDates['lrtDate1'] = getLrtReportDataList.lrtDate1 ? new Date(getLrtReportDataList.lrtDate1) : new Date();
            allDates['lrtDate1'] = getLrtReportDataList.lrtDate1 ? new Date(getLrtReportDataList.lrtDate1) : null;

            allDates['lrtDate2'] = getLrtReportDataList.lrtDate2 ? new Date(getLrtReportDataList.lrtDate2) : null;
            allDates['lrtDate3'] = getLrtReportDataList.lrtDate3 ? new Date(getLrtReportDataList.lrtDate3) : null;
            allDates['lrtDate4'] = getLrtReportDataList.lrtDate4 ? new Date(getLrtReportDataList.lrtDate4) : null;
            allDates['lrtDate5'] = getLrtReportDataList.lrtDate5 ? new Date(getLrtReportDataList.lrtDate5) :null;

            allDates['lrtDate6'] = getLrtReportDataList.lrtDate6 ? new Date(getLrtReportDataList.lrtDate6) : null;
            allDates['lrtDate7'] = getLrtReportDataList.lrtDate7 ? new Date(getLrtReportDataList.lrtDate7) : null;
            allDates['lrtDate8'] = getLrtReportDataList.lrtDate8 ? new Date(getLrtReportDataList.lrtDate8) : null;
            allDates['lrtDate9'] = getLrtReportDataList.lrtDate9 ? new Date(getLrtReportDataList.lrtDate9) :null;
            allDates['lrtDate10'] = getLrtReportDataList.lrtDate10 ? new Date(getLrtReportDataList.lrtDate10) : null;
            
            allDates['lrtDate11'] = getLrtReportDataList.lrtDate11 ? new Date(getLrtReportDataList.lrtDate11) : null;
            allDates['lrtDate12'] = getLrtReportDataList.lrtDate12 ? new Date(getLrtReportDataList.lrtDate12) : null;
            allDates['lrtDate13'] = getLrtReportDataList.lrtDate13 ? new Date(getLrtReportDataList.lrtDate13) : null;
            allDates['lrtDate14'] = getLrtReportDataList.lrtDate14 ? new Date(getLrtReportDataList.lrtDate14) : null;
            allDates['lrtDate15'] = getLrtReportDataList.lrtDate15 ? new Date(getLrtReportDataList.lrtDate15) : null;
            
            allDates['lrtDate16'] = getLrtReportDataList.lrtDate16 ? new Date(getLrtReportDataList.lrtDate16) : null;
            allDates['lrtDate17'] = getLrtReportDataList.lrtDate17 ? new Date(getLrtReportDataList.lrtDate17) : null;
            allDates['lrtDate18'] = getLrtReportDataList.lrtDate18 ? new Date(getLrtReportDataList.lrtDate18) : null;
            allDates['lrtDate19'] = getLrtReportDataList.lrtDate19 ? new Date(getLrtReportDataList.lrtDate19) : null;
            allDates['lrtDate20'] = getLrtReportDataList.lrtDate20 ? new Date(getLrtReportDataList.lrtDate20) :null;
            
            allDates['lrtDate21'] = getLrtReportDataList.lrtDate21 ? new Date(getLrtReportDataList.lrtDate21) : null;
            allDates['lrtDate22'] = getLrtReportDataList.lrtDate22 ? new Date(getLrtReportDataList.lrtDate22) : null;
            allDates['lrtDate23'] = getLrtReportDataList.lrtDate23 ? new Date(getLrtReportDataList.lrtDate23) : null;
            allDates['lrtDate24'] = getLrtReportDataList.lrtDate24 ? new Date(getLrtReportDataList.lrtDate24) : null;
            allDates['lrtDate25'] = getLrtReportDataList.lrtDate25 ? new Date(getLrtReportDataList.lrtDate25) :null;





            // if (getLrtReportDataList.modDate6) {
            //     allDates['modDate6'] = new Date(getLrtReportDataList.modDate6);

            // } else {
            //     allDates['modDate6'] = new Date();
            // }
            // if (getLrtReportDataList.modDate7) {
            //     allDates['modDate7'] = new Date(getLrtReportDataList.modDate7);

            // } else {
            //     allDates['modDate7'] = new Date();
            // }
            // if (getLrtReportDataList.modDate8) {
            //     allDates['modDate8'] = new Date(getLrtReportDataList.modDate8);

            // } else {
            //     allDates['modDate8'] = new Date();
            // }
            // if (getLrtReportDataList.modDate9) {
            //     allDates['modDate9'] = new Date(getLrtReportDataList.modDate9);

            // } else {
            //     allDates['modDate9'] = new Date();
            // }
            // if (getLrtReportDataList.modDate10) {
            //     allDates['modDate10'] = new Date(getLrtReportDataList.modDate10);

            // } else {
            //     allDates['modDate10'] = new Date();
            // }


            this.setState({
                allDates,
                lrtTitle1: getLrtReportDataList.lrtTitle1,
                lrtTitle2: getLrtReportDataList.lrtTitle2,
                lrtTitle3: getLrtReportDataList.lrtTitle3,
                lrtTitle4: getLrtReportDataList.lrtTitle4,
                lrtTitle5: getLrtReportDataList.lrtTitle5,

                lrtName1: getLrtReportDataList.lrtName1,
                lrtName2: getLrtReportDataList.lrtName2,
                lrtName3: getLrtReportDataList.lrtName3,
                lrtName4: getLrtReportDataList.lrtName4,
                lrtName5: getLrtReportDataList.lrtName5,
                lrtName6: getLrtReportDataList.lrtName6,
                lrtName7: getLrtReportDataList.lrtName7,
                lrtName8: getLrtReportDataList.lrtName8,
                lrtName9: getLrtReportDataList.lrtName9,
                lrtName10: getLrtReportDataList.lrtName10,
                lrtName11: getLrtReportDataList.lrtName11,
                lrtName12: getLrtReportDataList.lrtName12,
                lrtName13: getLrtReportDataList.lrtName13,
                lrtName14: getLrtReportDataList.lrtName14,
                lrtName15: getLrtReportDataList.lrtName15,
                lrtName16: getLrtReportDataList.lrtName16,
                lrtName17: getLrtReportDataList.lrtName17,
                lrtName18: getLrtReportDataList.lrtName18,
                lrtName19: getLrtReportDataList.lrtName19,
                lrtName20: getLrtReportDataList.lrtName20,
                lrtName21: getLrtReportDataList.lrtName21,
                lrtName22: getLrtReportDataList.lrtName22,
                lrtName23: getLrtReportDataList.lrtName23,
                lrtName24: getLrtReportDataList.lrtName24,
                lrtName25: getLrtReportDataList.lrtName25,

                lrtImage1: getLrtReportDataList.lrtImage1,
                lrtImage2: getLrtReportDataList.lrtImage2,
                lrtImage3: getLrtReportDataList.lrtImage3,
                lrtImage4: getLrtReportDataList.lrtImage4,
                lrtImage5: getLrtReportDataList.lrtImage5,
                lrtImage6: getLrtReportDataList.lrtImage6,
                lrtImage7: getLrtReportDataList.lrtImage7,
                lrtImage8: getLrtReportDataList.lrtImage8,
                lrtImage9: getLrtReportDataList.lrtImage9,
                lrtImage10: getLrtReportDataList.lrtImage10,
                lrtImage11: getLrtReportDataList.lrtImage11,
                lrtImage12: getLrtReportDataList.lrtImage12,
                lrtImage13: getLrtReportDataList.lrtImage13,
                lrtImage14: getLrtReportDataList.lrtImage14,
                lrtImage15: getLrtReportDataList.lrtImage15,
                lrtImage16: getLrtReportDataList.lrtImage16,
                lrtImage17: getLrtReportDataList.lrtImage17,
                lrtImage18: getLrtReportDataList.lrtImage18,
                lrtImage19: getLrtReportDataList.lrtImage19,
                lrtImage20: getLrtReportDataList.lrtImage20,
                lrtImage21: getLrtReportDataList.lrtImage21,
                lrtImage22: getLrtReportDataList.lrtImage22,
                lrtImage23: getLrtReportDataList.lrtImage23,
                lrtImage24: getLrtReportDataList.lrtImage24,
                lrtImage25: getLrtReportDataList.lrtImage25,
               


                lrtUrl1: getLrtReportDataList.lrtUrl1,
                lrtUrl2: getLrtReportDataList.lrtUrl2,
                lrtUrl3: getLrtReportDataList.lrtUrl3,
                lrtUrl4: getLrtReportDataList.lrtUrl4,
                lrtUrl5: getLrtReportDataList.lrtUrl5,
                lrtUrl6: getLrtReportDataList.lrtUrl6,
                lrtUrl7: getLrtReportDataList.lrtUrl7,
                lrtUrl8: getLrtReportDataList.lrtUrl8,
                lrtUrl9: getLrtReportDataList.lrtUrl9,
                lrtUrl10: getLrtReportDataList.lrtUrl10,
                lrtUrl11: getLrtReportDataList.lrtUrl11,
                lrtUrl12: getLrtReportDataList.lrtUrl12,
                lrtUrl13: getLrtReportDataList.lrtUrl13,
                lrtUrl14: getLrtReportDataList.lrtUrl14,
                lrtUrl15: getLrtReportDataList.lrtUrl15,
                lrtUrl16: getLrtReportDataList.lrtUrl16,
                lrtUrl17: getLrtReportDataList.lrtUrl17,
                lrtUrl18: getLrtReportDataList.lrtUrl18,
                lrtUrl19: getLrtReportDataList.lrtUrl19,
                lrtUrl20: getLrtReportDataList.lrtUrl20,
                lrtUrl21: getLrtReportDataList.lrtUrl21,
                lrtUrl22: getLrtReportDataList.lrtUrl22,
                lrtUrl23: getLrtReportDataList.lrtUrl23,
                lrtUrl24: getLrtReportDataList.lrtUrl24,
                lrtUrl25: getLrtReportDataList.lrtUrl25,

                lrtId: getLrtReportDataList._id,


            })

        }












        // ======================End

        // ======================Commented by chandra
        // let clientResourceResponse = await combinedServices.getclientResourceData();
        // if (clientResourceResponse.status) {
        //     var getclientResourceDataList = clientResourceResponse.data[0];

        //     this.setState({
        //         clientTitle1: getclientResourceDataList.resource1.clientTitle1,
        //         clientURL1: getclientResourceDataList.resource1.clientURL1,
        //         clientTitle2: getclientResourceDataList.resource2.clientTitle2,
        //         clientURL2: getclientResourceDataList.resource2.clientURL2,
        //         clientTitle3: getclientResourceDataList.resource3.clientTitle3,
        //         clientURL3: getclientResourceDataList.resource3.clientURL3,
        //         clientTitle4: getclientResourceDataList.resource4.clientTitle4,
        //         clientURL4: getclientResourceDataList.resource4.clientURL4,
        //         clientTitle5: getclientResourceDataList.resource5.clientTitle5,
        //         clientURL5: getclientResourceDataList.resource5.clientURL5,
        //         clientId: getclientResourceDataList._id

        //     });





        // }

        let OCIOResource = await combinedServices.getOCIOResourceData();
        if (OCIOResource.status) {
            var OCIOResourceDataList = OCIOResource.data;

            this.setState({
                hTitle1: OCIOResourceDataList.hTitle1,
                hTitle2: OCIOResourceDataList.hTitle2,
                hTitle3: OCIOResourceDataList.hTitle3,
                hURL1: OCIOResourceDataList.hURL1,
                hURL2: OCIOResourceDataList.hURL2,
                hURL3: OCIOResourceDataList.hURL3,
                hId: OCIOResourceDataList._id

            });



        }
        //===================== commented by chandra

        // let OCIODocumentResponse = await combinedServices.getOCIODocumentData();
        // if (OCIODocumentResponse.status) {
        //     var OCIODocumentDataList = OCIODocumentResponse.data;

        //     this.setState({
        //         clientTalkingPointsURL: OCIODocumentDataList.clientTalkingPointsURL,
        //         quantPacksURL: OCIODocumentDataList.quantPacksURL,
        //         ocioDocumentId: OCIODocumentDataList._id

        //     });

        // }

        let OCIOCoreDataResponse = await combinedServices.getOCIOCoreData();
        if (OCIOCoreDataResponse.status) {
            var OCIOCoreDataList = OCIOCoreDataResponse.data;
            allDates['AsofDate3'] = OCIOCoreDataList.AsofDate3 ? new Date(OCIOCoreDataList.AsofDate3) : new Date();
            if (OCIOCoreDataList.aaDate1) {
                allDates['coreDate1'] = new Date(OCIOCoreDataList.aaDate1);
            } else {
                allDates['coreDate1'] = new Date();
            }
            if (OCIOCoreDataList.aaDate2) {
                allDates['coreDate2'] = new Date(OCIOCoreDataList.aaDate2);
            } else {
                allDates['coreDate2'] = new Date();
            }
            if (OCIOCoreDataList.aaDate3) {
                allDates['coreDate3'] = new Date(OCIOCoreDataList.aaDate3);
            } else {
                allDates['coreDate3'] = new Date();
            }

            if (OCIOCoreDataList.aaDate4) {
                allDates['coreDate4'] = new Date(OCIOCoreDataList.aaDate4);
            } else {
                allDates['coreDate4'] = new Date();
            }

            if (OCIOCoreDataList.aaDate5) {
                allDates['coreDate5'] = new Date(OCIOCoreDataList.aaDate5);
            } else {
                allDates['coreDate5'] = new Date();
            }
            if (OCIOCoreDataList.aaDate6) {
                allDates['coreDate6'] = new Date(OCIOCoreDataList.aaDate6);
            } else {
                allDates['coreDate6'] = new Date();
            }
            if (OCIOCoreDataList.aaDate7) {
                allDates['coreDate7'] = new Date(OCIOCoreDataList.aaDate7);
            } else {
                allDates['coreDate7'] = new Date();
            }
            if (OCIOCoreDataList.aaDate8) {
                allDates['coreDate8'] = new Date(OCIOCoreDataList.aaDate8);
            } else {
                allDates['coreDate8'] = new Date();
            }
            if (OCIOCoreDataList.aaDate9) {
                allDates['coreDate9'] = new Date(OCIOCoreDataList.aaDate9);
            } else {
                allDates['coreDate9'] = new Date();
            }
            if (OCIOCoreDataList.aaDate10) {
                allDates['coreDate10'] = new Date(OCIOCoreDataList.aaDate10);
            } else {
                allDates['coreDate10'] = new Date();
            }


            // allDates['coreDate1'] = new Date(OCIOCoreDataList.aaDate1);
            // allDates['coreDate2'] = new Date(OCIOCoreDataList.aaDate2);
            // allDates['coreDate3'] = new Date(OCIOCoreDataList.aaDate3);
            // allDates['coreDate4'] = new Date(OCIOCoreDataList.aaDate4);
            //allDates['coreDate5'] = new Date(OCIOCoreDataList.aaDate5);
            this.setState({
                allDates,
                aaTitle1: OCIOCoreDataList.aaTitle1,
                aaTitle2: OCIOCoreDataList.aaTitle2,
                aaTitle3: OCIOCoreDataList.aaTitle3,
                aaTitle4: OCIOCoreDataList.aaTitle4,
                aaTitle5: OCIOCoreDataList.aaTitle5,
                aaTitle6: OCIOCoreDataList.aaTitle6,
                aaTitle7: OCIOCoreDataList.aaTitle7,
                aaTitle8: OCIOCoreDataList.aaTitle8,
                aaTitle9: OCIOCoreDataList.aaTitle9,
                aaTitle10: OCIOCoreDataList.aaTitle10,
                aaURL1: OCIOCoreDataList.aaURL1,
                aaURL2: OCIOCoreDataList.aaURL2,
                aaURL3: OCIOCoreDataList.aaURL3,
                aaURL4: OCIOCoreDataList.aaURL4,
                aaURL5: OCIOCoreDataList.aaURL5,
                aaURL6: OCIOCoreDataList.aaURL6,
                aaURL7: OCIOCoreDataList.aaURL7,
                aaURL8: OCIOCoreDataList.aaURL8,
                aaURL9: OCIOCoreDataList.aaURL9,
                aaURL10: OCIOCoreDataList.aaURL10,
                aaYTD1: OCIOCoreDataList.aaYTD1,
                aaYTD2: OCIOCoreDataList.aaYTD2,
                aaYTD3: OCIOCoreDataList.aaYTD3,
                aaYTD4: OCIOCoreDataList.aaYTD4,
                aaYTD5: OCIOCoreDataList.aaYTD5,
                aaYTD6: OCIOCoreDataList.aaYTD6,
                aaYTD7: OCIOCoreDataList.aaYTD7,
                aaYTD8: OCIOCoreDataList.aaYTD8,
                aaYTD9: OCIOCoreDataList.aaYTD9,
                aaYTD10: OCIOCoreDataList.aaYTD10,
                aaYear1: OCIOCoreDataList.aaYear1,
                aaYear2: OCIOCoreDataList.aaYear2,
                aaYear3: OCIOCoreDataList.aaYear3,
                aaYear4: OCIOCoreDataList.aaYear4,
                aaYear5: OCIOCoreDataList.aaYear5,
                aaYear6: OCIOCoreDataList.aaYear6,
                aaYear7: OCIOCoreDataList.aaYear7,
                aaYear8: OCIOCoreDataList.aaYear8,
                aaYear9: OCIOCoreDataList.aaYear9,
                aaYear10: OCIOCoreDataList.aaYear10,
                OCIOParagraph: OCIOCoreDataList.OCIOParagraph,

                aaId: OCIOCoreDataList._id

            })

        }
        // ====================commented by chandra
        // let OCIOTacticalsResponse = await combinedServices.getOCIOTacticalsData();


        // if (OCIOTacticalsResponse.status) {
        //     var OCIOTacticalDataList = OCIOTacticalsResponse.data;

        //     allDates['AsofDate4'] = new Date(OCIOTacticalDataList.AsofDate3);

        //     allDates['CoreTacDate1'] = new Date(OCIOTacticalDataList.aaDate1);
        //     allDates['CoreTacDate2'] = new Date(OCIOTacticalDataList.aaDate2);
        //     allDates['CoreTacDate3'] = new Date(OCIOTacticalDataList.aaDate3);
        //     allDates['CoreTacDate4'] = new Date(OCIOTacticalDataList.aaDate4);
        //     if (OCIOTacticalDataList.aaDate5) {
        //         allDates['CoreTacDate5'] = new Date(OCIOTacticalDataList.aaDate5);
        //     } else {
        //         allDates['CoreTacDate5'] = new Date();
        //     }




        //     this.setState({
        //         allDates,
        //         CoreTacTitle1: OCIOTacticalDataList.aaTitle1,
        //         CoreTacTitle2: OCIOTacticalDataList.aaTitle2,
        //         CoreTacTitle3: OCIOTacticalDataList.aaTitle3,
        //         CoreTacTitle4: OCIOTacticalDataList.aaTitle4,
        //         CoreTacTitle5: OCIOTacticalDataList.aaTitle5,
        //         CoreTacURL1: OCIOTacticalDataList.aaURL1,
        //         CoreTacURL2: OCIOTacticalDataList.aaURL2,
        //         CoreTacURL3: OCIOTacticalDataList.aaURL3,
        //         CoreTacURL4: OCIOTacticalDataList.aaURL4,
        //         CoreTacURL5: OCIOTacticalDataList.aaURL5,
        //         CoreTacYTD1: OCIOTacticalDataList.aaYTD1,
        //         CoreTacYTD2: OCIOTacticalDataList.aaYTD2,
        //         CoreTacYTD3: OCIOTacticalDataList.aaYTD3,
        //         CoreTacYTD4: OCIOTacticalDataList.aaYTD4,
        //         CoreTacYTD5: OCIOTacticalDataList.aaYTD5,
        //         CoreTacYear1: OCIOTacticalDataList.aaYear1,
        //         CoreTacYear2: OCIOTacticalDataList.aaYear2,
        //         CoreTacYear3: OCIOTacticalDataList.aaYear3,
        //         CoreTacYear4: OCIOTacticalDataList.aaYear4,
        //         CoreTacYear5: OCIOTacticalDataList.aaYear5,
        //         CoreTacId: OCIOTacticalDataList._id
        //     })

        // }

        let whiteResourcesResponse = await combinedServices.getwhiteResourcesData();


        if (whiteResourcesResponse.status) {
            var whiteResourcesDataList = whiteResourcesResponse.data;

            this.setState({
                whiteLabelResourceTitle1: whiteResourcesDataList.hTitle1,
                whiteLabelResourceTitle2: whiteResourcesDataList.hTitle2,
                whiteLabelResourceTitle3: whiteResourcesDataList.hTitle3,
                whiteLabelresourceUrl1: whiteResourcesDataList.hURL1,
                whiteLabelresourceUrl2: whiteResourcesDataList.hURL2,
                whiteLabelresourceUrl3: whiteResourcesDataList.hURL3,
                whiteLabelresourceId: whiteResourcesDataList._id


            });
        }

        let whiteLevelMonthlyResponse = await combinedServices.getwhiteMonthlyData();



        if (whiteLevelMonthlyResponse.status) {
            var whiteLevelMonthlyDataList = whiteLevelMonthlyResponse.data;
            allDates['monthlyLetterDate1'] = new Date(whiteLevelMonthlyDataList.mlDate1);
            allDates['monthlyLetterDate2'] = new Date(whiteLevelMonthlyDataList.mlDate2);
            allDates['monthlyLetterDate3'] = new Date(whiteLevelMonthlyDataList.mlDate3);



            this.setState({
                allDates,
                mlTitle1: whiteLevelMonthlyDataList.mlTitle1,
                mlTitle2: whiteLevelMonthlyDataList.mlTitle2,
                mlTitle3: whiteLevelMonthlyDataList.mlTitle3,
                mlDoc1: whiteLevelMonthlyDataList.mlDoc1,
                mlDoc2: whiteLevelMonthlyDataList.mlDoc2,
                mlDoc3: whiteLevelMonthlyDataList.mlDoc3,
                mlSupport1: whiteLevelMonthlyDataList.mlSupport1,
                mlSupport2: whiteLevelMonthlyDataList.mlSupport2,
                mlSupport3: whiteLevelMonthlyDataList.mlSupport3,
                mlChart1: whiteLevelMonthlyDataList.mlChart1,
                mlChart2: whiteLevelMonthlyDataList.mlChart2,
                mlChart3: whiteLevelMonthlyDataList.mlChart3,
                mlId: whiteLevelMonthlyDataList._id,
            })
        }

        let whiteLevelChartResponse = await combinedServices.getwhiteChartData();



        if (whiteLevelChartResponse.status) {
            var whiteLevelChartDataList = whiteLevelChartResponse.data;


            allDates['chartDate1'] = new Date(whiteLevelChartDataList.cotmDate1);
            allDates['chartDate2'] = new Date(whiteLevelChartDataList.cotmDate2);
            allDates['chartDate3'] = new Date(whiteLevelChartDataList.cotmDate3);
            allDates['chartDate4'] = new Date(whiteLevelChartDataList.cotmDate4);
            allDates['chartDate5'] = new Date(whiteLevelChartDataList.cotmDate5);
            allDates['chartDate6'] = new Date(whiteLevelChartDataList.cotmDate6);

            this.setState({
                allDates,
                cotmTitle1: whiteLevelChartDataList.cotmTitle1,
                cotmTitle2: whiteLevelChartDataList.cotmTitle2,
                cotmTitle3: whiteLevelChartDataList.cotmTitle3,
                cotmTitle4: whiteLevelChartDataList.cotmTitle4,
                cotmTitle5: whiteLevelChartDataList.cotmTitle5,
                cotmTitle6: whiteLevelChartDataList.cotmTitle6,
                cotmDoc1: whiteLevelChartDataList.cotmDoc1,
                cotmDoc2: whiteLevelChartDataList.cotmDoc2,
                cotmDoc3: whiteLevelChartDataList.cotmDoc3,
                cotmDoc4: whiteLevelChartDataList.cotmDoc4,
                cotmDoc5: whiteLevelChartDataList.cotmDoc5,
                cotmDoc6: whiteLevelChartDataList.cotmDoc6,
                whiteLabelInsightsParagraph: whiteLevelChartDataList.whiteLabelInsightsParagraph,
                cotmId: whiteLevelChartDataList._id
            })


        }


        let whiteLevelQuartlyLetterResponse = await combinedServices.getwhiteQuaterlyLetterData();

        if (whiteLevelQuartlyLetterResponse.status) {
            var whiteLevelQuartlyLetterDataList = whiteLevelQuartlyLetterResponse.data;

            allDates['quarterlyLetterDate1'] = new Date(whiteLevelQuartlyLetterDataList.qlDate1);
            allDates['quarterlyLetterDate2'] = new Date(whiteLevelQuartlyLetterDataList.qlDate2);
            allDates['quarterlyLetterDate3'] = new Date(whiteLevelQuartlyLetterDataList.qlDate3);

            this.setState({
                allDates,
                qlTitle1: whiteLevelQuartlyLetterDataList.qlTitle1,
                qlTitle2: whiteLevelQuartlyLetterDataList.qlTitle2,
                qlTitle3: whiteLevelQuartlyLetterDataList.qlTitle3,
                qlDoc1: whiteLevelQuartlyLetterDataList.qlDoc1,
                qlDoc2: whiteLevelQuartlyLetterDataList.qlDoc2,
                qlDoc3: whiteLevelQuartlyLetterDataList.qlDoc3,
                qlSupport1: whiteLevelQuartlyLetterDataList.qlSupport1,
                qlSupport2: whiteLevelQuartlyLetterDataList.qlSupport2,
                qlSupport3: whiteLevelQuartlyLetterDataList.qlSupport3,
                qlChart1: whiteLevelQuartlyLetterDataList.qlChart1,
                qlChart2: whiteLevelQuartlyLetterDataList.qlChart2,
                qlChart3: whiteLevelQuartlyLetterDataList.qlChart3,
                qlId: whiteLevelQuartlyLetterDataList._id,

            })
        }



        let whiteLevelOffShefResponse = await combinedServices.getwhiteoffShelfData();

        if (whiteLevelOffShefResponse.status) {
            var whiteLevelOffShelfDataList = whiteLevelOffShefResponse.data;

            allDates['shelfdate1'] = new Date(whiteLevelOffShelfDataList.ospDate1);

            if (whiteLevelOffShelfDataList.ospDate2) {

                allDates['shelfdate2'] = new Date(whiteLevelOffShelfDataList.ospDate2);
            } else {

                allDates['shelfdate2'] = new Date();
            }

            allDates['shelfdate3'] = new Date(whiteLevelOffShelfDataList.ospDate3);
            allDates['shelfdate4'] = new Date(whiteLevelOffShelfDataList.ospDate4);

            this.setState({
                allDates,
                ospTitle1: whiteLevelOffShelfDataList.ospTitle1,
                ospTitle2: whiteLevelOffShelfDataList.ospTitle2,
                ospTitle3: whiteLevelOffShelfDataList.ospTitle3,
                ospTitle4: whiteLevelOffShelfDataList.ospTitle4,
                ospDoc1: whiteLevelOffShelfDataList.ospDoc1,
                ospDoc2: whiteLevelOffShelfDataList.ospDoc2,
                ospDoc3: whiteLevelOffShelfDataList.ospDoc3,
                ospDoc4: whiteLevelOffShelfDataList.ospDoc4,
                ospId: whiteLevelOffShelfDataList._id,
            })
        }



        let trialDaysResponse = await combinedServices.gettrialDayLeftData();

        if (trialDaysResponse.status) {
            var trialDaysDataList = trialDaysResponse.data;


            this.setState({
                trialTitle1: trialDaysDataList.trialTitle1,
                trialTitle2: trialDaysDataList.trialTitle2,
                trialTitle3: trialDaysDataList.trialTitle3,
                trialURL1: trialDaysDataList.trialURL1,
                trialURL2: trialDaysDataList.trialURL2,
                trialURL3: trialDaysDataList.trialURL3,
                trialText: trialDaysDataList.trialText,
                trialId: trialDaysDataList._id,


            });
        }



        let whiteLevelQuartlyMarketResponse = await combinedServices.getwhiteQuaterlyMarketData();

        if (whiteLevelQuartlyMarketResponse.status) {
            var whiteLevelQuartlyMarketDataList = whiteLevelQuartlyMarketResponse.data;

            allDates['perspectiveDate1'] = new Date(whiteLevelQuartlyMarketDataList.qmpDate1);
            allDates['perspectiveDate2'] = new Date(whiteLevelQuartlyMarketDataList.qmpDate2);
            allDates['perspectiveDate3'] = new Date(whiteLevelQuartlyMarketDataList.qmpDate3);



            this.setState({
                allDates,
                qmpTitle1: whiteLevelQuartlyMarketDataList.qmpTitle1,
                qmpTitle2: whiteLevelQuartlyMarketDataList.qmpTitle2,
                qmpTitle3: whiteLevelQuartlyMarketDataList.qmpTitle3,
                qmpPpt1: whiteLevelQuartlyMarketDataList.qmpPpt1,
                qmpPpt2: whiteLevelQuartlyMarketDataList.qmpPpt2,
                qmpPpt3: whiteLevelQuartlyMarketDataList.qmpPpt3,
                qmpId: whiteLevelQuartlyMarketDataList._id,

            })
        }
        // ================add by chandra for whitel Label market ttpoint

        let whiteLevelMarketTalkingPointResponse = await combinedServices.getwhiteMarketTalkingPointData();

        if (whiteLevelMarketTalkingPointResponse.status) {
            var whiteLabelMarketTalkingPointDataList = whiteLevelMarketTalkingPointResponse.data;

            allDates['talkingDate1'] = new Date(whiteLabelMarketTalkingPointDataList.mtpDate1);
            allDates['talkingDate2'] = new Date(whiteLabelMarketTalkingPointDataList.mtpDate2);
            allDates['talkingDate3'] = new Date(whiteLabelMarketTalkingPointDataList.mtpDate3);

            this.setState({
                allDates,
                mtpTitle1: whiteLabelMarketTalkingPointDataList.mtpTitle1,
                mtpTitle2: whiteLabelMarketTalkingPointDataList.mtpTitle2,
                mtpTitle3: whiteLabelMarketTalkingPointDataList.mtpTitle3,
                mtpUrl1: whiteLabelMarketTalkingPointDataList.mtpUrl1,
                mtpUrl2: whiteLabelMarketTalkingPointDataList.mtpUrl2,
                mtpUrl3: whiteLabelMarketTalkingPointDataList.mtpUrl3,
                mtpId: whiteLabelMarketTalkingPointDataList._id,


            })
        }




        // ============================end


        //  ==================Commented by chandra
        // let currentRatingResponse = await combinedServices.getcurrentRatingsData();
        // if (currentRatingResponse.status) {
        //     var getCurrentRatingDataList = currentRatingResponse.data[0];

        //     allDates['ratDate1'] = new Date(getCurrentRatingDataList.rat1.ratDate1);
        //     allDates['ratDate2'] = new Date(getCurrentRatingDataList.rat2.ratDate2);
        //     allDates['ratDate3'] = new Date(getCurrentRatingDataList.rat3.ratDate3);
        //     allDates['ratDate4'] = new Date(getCurrentRatingDataList.rat4.ratDate4);
        //     allDates['ratDate5'] = new Date(getCurrentRatingDataList.rat5.ratDate5);
        //     allDates['ratDate6'] = new Date(getCurrentRatingDataList.rat6.ratDate6);
        //     allDates['ratDate7'] = new Date(getCurrentRatingDataList.rat7.ratDate7);
        //     this.setState({
        //         allDates,
        //         ratTitle1: getCurrentRatingDataList.rat1.ratTitle1,
        //         ratTitle2: getCurrentRatingDataList.rat2.ratTitle2,
        //         ratTitle3: getCurrentRatingDataList.rat3.ratTitle3,
        //         ratTitle4: getCurrentRatingDataList.rat4.ratTitle4,
        //         ratTitle5: getCurrentRatingDataList.rat5.ratTitle5,
        //         ratTitle6: getCurrentRatingDataList.rat6.ratTitle6,
        //         ratTitle7: getCurrentRatingDataList.rat7.ratTitle7,
        //         ratURL1: getCurrentRatingDataList.rat1.ratURL1,
        //         ratURL2: getCurrentRatingDataList.rat2.ratURL2,
        //         ratURL3: getCurrentRatingDataList.rat3.ratURL3,
        //         ratURL4: getCurrentRatingDataList.rat4.ratURL4,
        //         ratURL5: getCurrentRatingDataList.rat5.ratURL5,
        //         ratURL6: getCurrentRatingDataList.rat6.ratURL6,
        //         ratURL7: getCurrentRatingDataList.rat7.ratURL7,
        //         ratId: getCurrentRatingDataList._id,

        //     })



        // }

        let modelPortfolioResponse = await combinedServices.getmodelPortfolioData();
        if (modelPortfolioResponse.status) {
            var getModelPortfiloDataList = modelPortfolioResponse.data;

            allDates['AsofDate1'] = getModelPortfiloDataList.AsofDate1 ? new Date(getModelPortfiloDataList.AsofDate1) : new Date();
            allDates['modDate1'] = getModelPortfiloDataList.modDate1 ? new Date(getModelPortfiloDataList.modDate1) : new Date();
            allDates['modDate2'] = getModelPortfiloDataList.modDate2 ? new Date(getModelPortfiloDataList.modDate2) : new Date();
            allDates['modDate3'] = getModelPortfiloDataList.modDate3 ? new Date(getModelPortfiloDataList.modDate3) : new Date();
            allDates['modDate4'] = getModelPortfiloDataList.modDate4 ? new Date(getModelPortfiloDataList.modDate4) : new Date();
            allDates['modDate5'] = getModelPortfiloDataList.modDate5 ? new Date(getModelPortfiloDataList.modDate5) : new Date();

            if (getModelPortfiloDataList.modDate6) {
                allDates['modDate6'] = new Date(getModelPortfiloDataList.modDate6);

            } else {
                allDates['modDate6'] = new Date();
            }
            if (getModelPortfiloDataList.modDate7) {
                allDates['modDate7'] = new Date(getModelPortfiloDataList.modDate7);

            } else {
                allDates['modDate7'] = new Date();
            }
            if (getModelPortfiloDataList.modDate8) {
                allDates['modDate8'] = new Date(getModelPortfiloDataList.modDate8);

            } else {
                allDates['modDate8'] = new Date();
            }
            if (getModelPortfiloDataList.modDate9) {
                allDates['modDate9'] = new Date(getModelPortfiloDataList.modDate9);

            } else {
                allDates['modDate9'] = new Date();
            }
            if (getModelPortfiloDataList.modDate10) {
                allDates['modDate10'] = new Date(getModelPortfiloDataList.modDate10);

            } else {
                allDates['modDate10'] = new Date();
            }


            this.setState({
                allDates,
                modTitle1: getModelPortfiloDataList.modTitle1,
                modTitle2: getModelPortfiloDataList.modTitle2,
                modTitle3: getModelPortfiloDataList.modTitle3,
                modTitle4: getModelPortfiloDataList.modTitle4,
                modTitle5: getModelPortfiloDataList.modTitle5,
                modTitle6: getModelPortfiloDataList.modTitle6,
                modTitle7: getModelPortfiloDataList.modTitle7,
                modTitle8: getModelPortfiloDataList.modTitle8,
                modTitle9: getModelPortfiloDataList.modTitle9,
                modTitle10: getModelPortfiloDataList.modTitle10,

                modURL1: getModelPortfiloDataList.modURL1,
                modURL2: getModelPortfiloDataList.modURL2,
                modURL3: getModelPortfiloDataList.modURL3,
                modURL4: getModelPortfiloDataList.modURL4,
                modURL5: getModelPortfiloDataList.modURL5,
                modURL6: getModelPortfiloDataList.modURL6,
                modURL7: getModelPortfiloDataList.modURL7,
                modURL8: getModelPortfiloDataList.modURL8,
                modURL9: getModelPortfiloDataList.modURL9,
                modURL10: getModelPortfiloDataList.modURL10,

                modYTD1: getModelPortfiloDataList.modYTD1,
                modYTD2: getModelPortfiloDataList.modYTD2,
                modYTD3: getModelPortfiloDataList.modYTD3,
                modYTD4: getModelPortfiloDataList.modYTD4,
                modYTD5: getModelPortfiloDataList.modYTD5,
                modYTD6: getModelPortfiloDataList.modYTD6,
                modYTD7: getModelPortfiloDataList.modYTD7,
                modYTD8: getModelPortfiloDataList.modYTD8,
                modYTD9: getModelPortfiloDataList.modYTD9,
                modYTD10: getModelPortfiloDataList.modYTD10,

                modYear1: getModelPortfiloDataList.modYear1,
                modYear2: getModelPortfiloDataList.modYear2,
                modYear3: getModelPortfiloDataList.modYear3,
                modYear4: getModelPortfiloDataList.modYear4,
                modYear5: getModelPortfiloDataList.modYear5,
                modYear6: getModelPortfiloDataList.modYear6,
                modYear7: getModelPortfiloDataList.modYear7,
                modYear8: getModelPortfiloDataList.modYear8,
                modYear9: getModelPortfiloDataList.modYear9,
                modYear10: getModelPortfiloDataList.modYear10,
                modId: getModelPortfiloDataList._id,


            })

        }
        // ==============================commented by chandra

        // let thematicAndTacticalResponse = await combinedServices.getthematicAndTacticalData();
        // if (thematicAndTacticalResponse.status) {
        //     var getThematicAndTacticalDataList = thematicAndTacticalResponse.data;

        //     allDates['AsofDate2'] = getThematicAndTacticalDataList.AsofDate2 ? new Date(getThematicAndTacticalDataList.AsofDate2) : new Date();
        //     allDates['tacDate1'] = getThematicAndTacticalDataList.tacDate1 ? new Date(getThematicAndTacticalDataList.tacDate1) : new Date();
        //     allDates['tacDate2'] = getThematicAndTacticalDataList.tacDate2 ? new Date(getThematicAndTacticalDataList.tacDate2) : new Date();
        //     allDates['tacDate3'] = getThematicAndTacticalDataList.tacDate3 ? new Date(getThematicAndTacticalDataList.tacDate3) : new Date();
        //     allDates['tacDate4'] = getThematicAndTacticalDataList.tacDate4 ? new Date(getThematicAndTacticalDataList.tacDate4) : new Date();
        //     allDates['tacDate5'] = getThematicAndTacticalDataList.tacDate5 ? new Date(getThematicAndTacticalDataList.tacDate5) : new Date();


        //     this.setState({
        //         allDates,
        //         tacTitle1: getThematicAndTacticalDataList.tacTitle1,
        //         tacTitle2: getThematicAndTacticalDataList.tacTitle2,
        //         tacTitle3: getThematicAndTacticalDataList.tacTitle3,
        //         tacTitle4: getThematicAndTacticalDataList.tacTitle4,
        //         tacTitle5: getThematicAndTacticalDataList.tacTitle5,
        //         tacURL1: getThematicAndTacticalDataList.tacURL1,
        //         tacURL2: getThematicAndTacticalDataList.tacURL2,
        //         tacURL3: getThematicAndTacticalDataList.tacURL3,
        //         tacURL4: getThematicAndTacticalDataList.tacURL4,
        //         tacURL5: getThematicAndTacticalDataList.tacURL5,
        //         tacWeek1: getThematicAndTacticalDataList.tacWeek1,
        //         tacWeek2: getThematicAndTacticalDataList.tacWeek2,
        //         tacWeek3: getThematicAndTacticalDataList.tacWeek3,
        //         tacWeek4: getThematicAndTacticalDataList.tacWeek4,
        //         tacWeek5: getThematicAndTacticalDataList.tacWeek5,
        //         tacIncep1: getThematicAndTacticalDataList.tacIncep1,
        //         tacIncep2: getThematicAndTacticalDataList.tacIncep2,
        //         tacIncep3: getThematicAndTacticalDataList.tacIncep3,
        //         tacIncep4: getThematicAndTacticalDataList.tacIncep4,
        //         tacIncep5: getThematicAndTacticalDataList.tacIncep5,
        //         tacId: getThematicAndTacticalDataList._id

        //     })

        // }
        let getTermsAndConditionsResponse = await combinedServices.getTermsAndConditions();

        if (getTermsAndConditionsResponse.status) {

            var getTermsAndConditionsDetails = getTermsAndConditionsResponse.data;
            this.setState({

                tAndC_id: getTermsAndConditionsDetails._id,
                tAndC: getTermsAndConditionsDetails.termsAndConditions,
            })


        }

    }
    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })

    }


    handleChangeDate(date1, name) {

        let allDates = this.state.allDates;

        allDates[name] = date1;

        this.setState({
            allDates
        });


        console.log(allDates)
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        if (this.validateForm() === false) {
            return;
        } else {


            const clientSupport = {

                SupportButtonText1: this.state.supportButtonText1,
                SupportButtonText2: this.state.supportButtonText2,
                SupportButtonText3: this.state.supportButtonText3,
                SupportButtonText4: this.state.supportButtonText4,
                SupportButtonText5: this.state.supportButtonText5,
                SupportButtonText6: this.state.supportButtonText6,
                SupportButtonText7: this.state.supportButtonText7,
                SupportButtonText8: this.state.supportButtonText8,
                SupportButtonText9: this.state.supportButtonText9,
                SupportButtonText10: this.state.supportButtonText10,
                SupportButtonText11: this.state.supportButtonText11,
                SupportButtonText12: this.state.supportButtonText12,
                SupportButtonText13: this.state.supportButtonText13,
                SupportButtonText14: this.state.supportButtonText14,
                SupportButtonText15: this.state.supportButtonText15,
                SupportButtonText16: this.state.supportButtonText16,
                SupportButtonText17: this.state.supportButtonText17,
                SupportButtonText18: this.state.supportButtonText18,
                SupportButtonText19: this.state.supportButtonText19,
                SupportButtonText20: this.state.supportButtonText20,
                SupportButtonUrl1: this.state.supportButtonURL1,
                SupportButtonUrl2: this.state.supportButtonURL2,
                SupportButtonUrl3: this.state.supportButtonURL3,
                SupportButtonUrl4: this.state.supportButtonURL4,
                SupportButtonUrl5: this.state.supportButtonURL5,
                SupportButtonUrl6: this.state.supportButtonURL6,
                SupportButtonUrl7: this.state.supportButtonURL7,
                SupportButtonUrl8: this.state.supportButtonURL8,
                SupportButtonUrl9: this.state.supportButtonURL9,
                SupportButtonUrl10: this.state.supportButtonURL10,
                SupportButtonUrl11: this.state.supportButtonURL11,
                SupportButtonUrl12: this.state.supportButtonURL12,
                SupportButtonUrl13: this.state.supportButtonURL13,
                SupportButtonUrl14: this.state.supportButtonURL14,
                SupportButtonUrl15: this.state.supportButtonURL15,
                SupportButtonUrl16: this.state.supportButtonURL16,
                SupportButtonUrl17: this.state.supportButtonURL17,
                SupportButtonUrl18: this.state.supportButtonURL18,
                SupportButtonUrl19: this.state.supportButtonURL19,
                SupportButtonUrl20: this.state.supportButtonURL20,
                SupportType1: this.state.supportType1,
                SupportType2: this.state.supportType2,
                SupportType3: this.state.supportType3,
                SupportType4: this.state.supportType4,
                SupportType5: this.state.supportType5,
                SupportType6: this.state.supportType6,
                SupportType7: this.state.supportType7,
                SupportType8: this.state.supportType8,
                SupportType9: this.state.supportType9,
                SupportType10: this.state.supportType10,
                SupportType11: this.state.supportType11,
                SupportType12: this.state.supportType12,
                SupportType13: this.state.supportType13,
                SupportType14: this.state.supportType14,
                SupportType15: this.state.supportType15,
                SupportType16: this.state.supportType16,
                SupportType17: this.state.supportType17,
                SupportType18: this.state.supportType18,
                SupportType19: this.state.supportType19,
                SupportType20: this.state.supportType20,

                _id: this.state.supportId


            }


            // const clientResource = {

            //     clientTitle1: this.state.clientTitle1,
            //     clientUrl1: this.state.clientURL1,
            //     clientTitle2: this.state.clientTitle2,
            //     clientUrl2: this.state.clientURL2,
            //     clientTitle3: this.state.clientTitle3,
            //     clientUrl3: this.state.clientURL3,
            //     clientTitle4: this.state.clientTitle4,
            //     clientUrl4: this.state.clientURL4,
            //     clientTitle5: this.state.clientTitle5,
            //     clientUrl5: this.state.clientURL5,
            //     _id: this.state.clientId
            // }


            // const currentRatings = {

            //     ratDate1: this.state.allDates.ratDate1,
            //     ratDate2: this.state.allDates.ratDate2,
            //     ratDate3: this.state.allDates.ratDate3,
            //     ratDate4: this.state.allDates.ratDate4,
            //     ratDate5: this.state.allDates.ratDate5,
            //     ratDate6: this.state.allDates.ratDate6,
            //     ratDate7: this.state.allDates.ratDate7,
            //     ratTitle1: this.state.ratTitle1,
            //     ratTitle2: this.state.ratTitle2,
            //     ratTitle3: this.state.ratTitle3,
            //     ratTitle4: this.state.ratTitle4,
            //     ratTitle5: this.state.ratTitle5,
            //     ratTitle6: this.state.ratTitle6,
            //     ratTitle7: this.state.ratTitle7,
            //     ratUrl1: this.state.ratURL1,
            //     ratUrl2: this.state.ratURL2,
            //     ratUrl3: this.state.ratURL3,
            //     ratUrl4: this.state.ratURL4,
            //     ratUrl5: this.state.ratURL5,
            //     ratUrl6: this.state.ratURL6,
            //     ratUrl7: this.state.ratURL7,
            //     _id: this.state.ratId,

            // }
            // ======added by chandra 

            const lrtReport = {
                lrtTitle1: this.state.lrtTitle1,
                lrtTitle2: this.state.lrtTitle2,
                lrtTitle3: this.state.lrtTitle3,
                lrtTitle4: this.state.lrtTitle4,
                lrtTitle5: this.state.lrtTitle5,

                lrtDate1: this.state.allDates.lrtDate1,
                lrtDate2: this.state.allDates.lrtDate2,
                lrtDate3: this.state.allDates.lrtDate3,
                lrtDate4: this.state.allDates.lrtDate4,
                lrtDate5: this.state.allDates.lrtDate5,
                lrtDate6: this.state.allDates.lrtDate6,
                lrtDate7: this.state.allDates.lrtDate7,
                lrtDate8: this.state.allDates.lrtDate8,
                lrtDate9: this.state.allDates.lrtDate9,
                lrtDate10: this.state.allDates.lrtDate10,
                lrtDate11: this.state.allDates.lrtDate11,
                lrtDate12: this.state.allDates.lrtDate12,
                lrtDate13: this.state.allDates.lrtDate13,
                lrtDate14: this.state.allDates.lrtDate14,
                lrtDate15: this.state.allDates.lrtDate15,
                lrtDate16: this.state.allDates.lrtDate16,
                lrtDate17: this.state.allDates.lrtDate17,
                lrtDate18: this.state.allDates.lrtDate18,
                lrtDate19: this.state.allDates.lrtDate19,
                lrtDate20: this.state.allDates.lrtDate20,
                lrtDate21: this.state.allDates.lrtDate21,
                lrtDate22: this.state.allDates.lrtDate22,
                lrtDate23: this.state.allDates.lrtDate23,
                lrtDate24: this.state.allDates.lrtDate24,
                lrtDate25: this.state.allDates.lrtDate25,

                lrtName1: this.state.lrtName1,
                lrtName2: this.state.lrtName2,
                lrtName3: this.state.lrtName3,
                lrtName4: this.state.lrtName4,
                lrtName5: this.state.lrtName5,
                lrtName6: this.state.lrtName6,
                lrtName7: this.state.lrtName7,
                lrtName8: this.state.lrtName8,
                lrtName9: this.state.lrtName9,
                lrtName10: this.state.lrtName10,
                lrtName11: this.state.lrtName11,
                lrtName12: this.state.lrtName12,
                lrtName13: this.state.lrtName13,
                lrtName14: this.state.lrtName14,
                lrtName15: this.state.lrtName15,
                lrtName16: this.state.lrtName16,
                lrtName17: this.state.lrtName17,
                lrtName18: this.state.lrtName18,
                lrtName19: this.state.lrtName19,
                lrtName20: this.state.lrtName20,
                lrtName21: this.state.lrtName21,
                lrtName22: this.state.lrtName22,
                lrtName23: this.state.lrtName23,
                lrtName24: this.state.lrtName24,
                lrtName25: this.state.lrtName25,
                lrtImage1: this.state.lrtImage1,
                lrtImage2: this.state.lrtImage2,
                lrtImage3: this.state.lrtImage3,
                lrtImage4: this.state.lrtImage4,
                lrtImage5: this.state.lrtImage5,
                lrtImage6: this.state.lrtImage6,
                lrtImage7: this.state.lrtImage7,
                lrtImage8: this.state.lrtImage8,
                lrtImage9: this.state.lrtImage9,
                lrtImage10: this.state.lrtImage10,
                lrtImage11: this.state.lrtImage11,
                lrtImage12: this.state.lrtImage12,
                lrtImage13: this.state.lrtImage13,
                lrtImage14: this.state.lrtImage14,
                lrtImage15: this.state.lrtImage15,
                lrtImage16: this.state.lrtImage16,
                lrtImage17: this.state.lrtImage17,
                lrtImage18: this.state.lrtImage18,
                lrtImage19: this.state.lrtImage19,
                lrtImage20: this.state.lrtImage20,
                lrtImage21: this.state.lrtImage21,
                lrtImage22: this.state.lrtImage22,
                lrtImage23: this.state.lrtImage23,
                lrtImage24: this.state.lrtImage24,
                lrtImage25: this.state.lrtImage25,
                lrtUrl1: this.state.lrtUrl1,
                lrtUrl2: this.state.lrtUrl2,
                lrtUrl3: this.state.lrtUrl3,
                lrtUrl4: this.state.lrtUrl4,
                lrtUrl5: this.state.lrtUrl5,
                lrtUrl6: this.state.lrtUrl6,
                lrtUrl7: this.state.lrtUrl7,
                lrtUrl8: this.state.lrtUrl8,
                lrtUrl9: this.state.lrtUrl9,
                lrtUrl10: this.state.lrtUrl10,
                lrtUrl11: this.state.lrtUrl11,
                lrtUrl12: this.state.lrtUrl12,
                lrtUrl13: this.state.lrtUrl13,
                lrtUrl14: this.state.lrtUrl14,
                lrtUrl15: this.state.lrtUrl15,
                lrtUrl16: this.state.lrtUrl16,
                lrtUrl17: this.state.lrtUrl17,
                lrtUrl18: this.state.lrtUrl18,
                lrtUrl19: this.state.lrtUrl19,
                lrtUrl20: this.state.lrtUrl20,
                lrtUrl21: this.state.lrtUrl21,
                lrtUrl22: this.state.lrtUrl22,
                lrtUrl23: this.state.lrtUrl23,
                lrtUrl24: this.state.lrtUrl24,
                lrtUrl25: this.state.lrtUrl25,
            }


            // =========end


            const modelPortfolio = {

                AsofDate1: this.state.allDates.AsofDate1,
                modDate1: this.state.allDates.modDate1,
                modDate2: this.state.allDates.modDate2,
                modDate3: this.state.allDates.modDate3,
                modDate4: this.state.allDates.modDate4,
                modDate5: this.state.allDates.modDate5,
                modDate6: this.state.allDates.modDate6,
                modDate7: this.state.allDates.modDate7,
                modDate8: this.state.allDates.modDate8,
                modDate9: this.state.allDates.modDate9,
                modDate10: this.state.allDates.modDate10,



                modTitle1: this.state.modTitle1,
                modTitle2: this.state.modTitle2,
                modTitle3: this.state.modTitle3,
                modTitle4: this.state.modTitle4,
                modTitle5: this.state.modTitle5,
                modTitle6: this.state.modTitle6,
                modTitle7: this.state.modTitle7,
                modTitle8: this.state.modTitle8,
                modTitle9: this.state.modTitle9,
                modTitle10: this.state.modTitle10,

                modURL1: this.state.modURL1,
                modURL2: this.state.modURL2,
                modURL3: this.state.modURL3,
                modURL4: this.state.modURL4,
                modURL5: this.state.modURL5,
                modURL6: this.state.modURL6,
                modURL7: this.state.modURL7,
                modURL8: this.state.modURL8,
                modURL9: this.state.modURL9,
                modURL10: this.state.modURL10,

                modYTD1: this.state.modYTD1,
                modYTD2: this.state.modYTD2,
                modYTD3: this.state.modYTD3,
                modYTD4: this.state.modYTD4,
                modYTD5: this.state.modYTD5,
                modYTD6: this.state.modYTD6,
                modYTD7: this.state.modYTD7,
                modYTD8: this.state.modYTD8,
                modYTD9: this.state.modYTD9,
                modYTD10: this.state.modYTD10,

                modYear1: this.state.modYear1,
                modYear2: this.state.modYear2,
                modYear3: this.state.modYear3,
                modYear4: this.state.modYear4,
                modYear5: this.state.modYear5,
                modYear6: this.state.modYear6,
                modYear7: this.state.modYear7,
                modYear8: this.state.modYear8,
                modYear9: this.state.modYear9,
                modYear10: this.state.modYear10,
                _id: this.state.modId,




            }


            // const thematicAndTactical = {

            //     AsofDate2: this.state.allDates.AsofDate2,
            //     tacDate1: this.state.allDates.tacDate1,
            //     tacDate2: this.state.allDates.tacDate2,
            //     tacDate3: this.state.allDates.tacDate3,
            //     tacDate4: this.state.allDates.tacDate4,
            //     tacDate5: this.state.allDates.tacDate5,
            //     tacTitle1: this.state.tacTitle1,
            //     tacTitle2: this.state.tacTitle2,
            //     tacTitle3: this.state.tacTitle3,
            //     tacTitle4: this.state.tacTitle4,
            //     tacTitle5: this.state.tacTitle5,
            //     tacUrl1: this.state.tacURL1,
            //     tacUrl2: this.state.tacURL2,
            //     tacUrl3: this.state.tacURL3,
            //     tacUrl4: this.state.tacURL4,
            //     tacUrl5: this.state.tacURL5,
            //     tacWeek1: this.state.tacWeek1,
            //     tacWeek2: this.state.tacWeek2,
            //     tacWeek3: this.state.tacWeek3,
            //     tacWeek4: this.state.tacWeek4,
            //     tacWeek5: this.state.tacWeek5,
            //     tacIncep1: this.state.tacIncep1,
            //     tacIncep2: this.state.tacIncep2,
            //     tacIncep3: this.state.tacIncep3,
            //     tacIncep4: this.state.tacIncep4,
            //     tacIncep5: this.state.tacIncep5,
            //     _id: this.state.tacId

            // }

            const trialDayLeft = {

                trialTitle1: this.state.trialTitle1,
                trialTitle2: this.state.trialTitle2,
                trialTitle3: this.state.trialTitle3,
                trialURL1: this.state.trialURL1,
                trialURL2: this.state.trialURL2,
                trialURL3: this.state.trialURL3,
                trialText: this.state.trialText,
                _id: this.state.trialId,

            }


            const whiteResource = {

                hTitle1: this.state.whiteLabelResourceTitle1,
                hTitle2: this.state.whiteLabelResourceTitle2,
                hTitle3: this.state.whiteLabelResourceTitle3,
                hURL1: this.state.whiteLabelresourceUrl1,
                hURL2: this.state.whiteLabelresourceUrl2,
                hURL3: this.state.whiteLabelresourceUrl3,
                _id: this.state.whiteLabelresourceId
            }


            const ocioResource = {

                hTitle1: this.state.hTitle1,
                hTitle2: this.state.hTitle2,
                hTitle3: this.state.hTitle3,
                hURL1: this.state.hURL1,
                hURL2: this.state.hURL2,
                hURL3: this.state.hURL3,
                _id: this.state.hId
            }

            // const ocioDocument = {
            //     clientTalkingPointsURL: this.state.clientTalkingPointsURL,
            //     quantPacksURL: this.state.quantPacksURL,
            //     _id: this.state.ocioDocumentId
            // }


            const ocioCore = {

                AsofDate3: this.state.allDates.AsofDate3,
                aaDate1: this.state.allDates.coreDate1,
                aaDate2: this.state.allDates.coreDate2,
                aaDate3: this.state.allDates.coreDate3,
                aaDate4: this.state.allDates.coreDate4,
                aaDate5: this.state.allDates.coreDate5,
                aaDate6: this.state.allDates.coreDate6,
                aaDate7: this.state.allDates.coreDate7,
                aaDate8: this.state.allDates.coreDate8,
                aaDate9: this.state.allDates.coreDate9,
                aaDate10: this.state.allDates.coreDate10,
                aaTitle1: this.state.aaTitle1,
                aaTitle2: this.state.aaTitle2,
                aaTitle3: this.state.aaTitle3,
                aaTitle4: this.state.aaTitle4,
                aaTitle5: this.state.aaTitle5,
                aaTitle6: this.state.aaTitle6,
                aaTitle7: this.state.aaTitle7,
                aaTitle8: this.state.aaTitle8,
                aaTitle9: this.state.aaTitle9,
                aaTitle10: this.state.aaTitle10,
                aaURL1: this.state.aaURL1,
                aaURL2: this.state.aaURL2,
                aaURL3: this.state.aaURL3,
                aaURL4: this.state.aaURL4,
                aaURL5: this.state.aaURL5,
                aaURL6: this.state.aaURL6,
                aaURL7: this.state.aaURL7,
                aaURL8: this.state.aaURL8,
                aaURL9: this.state.aaURL9,
                aaURL10: this.state.aaURL10,
                aaYTD1: this.state.aaYTD1,
                aaYTD2: this.state.aaYTD2,
                aaYTD3: this.state.aaYTD3,
                aaYTD4: this.state.aaYTD4,
                aaYTD5: this.state.aaYTD5,
                aaYTD6: this.state.aaYTD6,
                aaYTD7: this.state.aaYTD7,
                aaYTD8: this.state.aaYTD8,
                aaYTD9: this.state.aaYTD9,
                aaYTD10: this.state.aaYTD10,
                aaYear1: this.state.aaYear1,
                aaYear2: this.state.aaYear2,
                aaYear3: this.state.aaYear3,
                aaYear4: this.state.aaYear4,
                aaYear5: this.state.aaYear5,
                aaYear6: this.state.aaYear6,
                aaYear7: this.state.aaYear7,
                aaYear8: this.state.aaYear8,
                aaYear9: this.state.aaYear9,
                aaYear10: this.state.aaYear10,
                OCIOParagraph: this.state.OCIOParagraph,
                _id: this.state.aaId
            }

            // const ocioTactical = {


            //     AsofDate3: this.state.allDates.AsofDate4,
            //     aaDate1: this.state.allDates.CoreTacDate1,
            //     aaDate2: this.state.allDates.CoreTacDate2,
            //     aaDate3: this.state.allDates.CoreTacDate3,
            //     aaDate4: this.state.allDates.CoreTacDate4,
            //     aaDate5: this.state.allDates.CoreTacDate5,
            //     aaTitle1: this.state.CoreTacTitle1,
            //     aaTitle2: this.state.CoreTacTitle2,
            //     aaTitle3: this.state.CoreTacTitle3,
            //     aaTitle4: this.state.CoreTacTitle4,
            //     aaTitle5: this.state.CoreTacTitle5,
            //     aaURL1: this.state.CoreTacURL1,
            //     aaURL2: this.state.CoreTacURL2,
            //     aaURL3: this.state.CoreTacURL3,
            //     aaURL4: this.state.CoreTacURL4,
            //     aaURL5: this.state.CoreTacURL5,
            //     aaYTD1: this.state.CoreTacYTD1,
            //     aaYTD2: this.state.CoreTacYTD2,
            //     aaYTD3: this.state.CoreTacYTD3,
            //     aaYTD4: this.state.CoreTacYTD4,
            //     aaYTD5: this.state.CoreTacYTD5,
            //     aaYear1: this.state.CoreTacYear1,
            //     aaYear2: this.state.CoreTacYear2,
            //     aaYear3: this.state.CoreTacYear3,
            //     aaYear4: this.state.CoreTacYear4,
            //     aaYear5: this.state.CoreTacYear5,
            //     _id: this.state.CoreTacId

            // }


            const whiteMonthly = {
                _id: this.state.mlId,

                mlDate1: this.state.allDates.monthlyLetterDate1,
                mlDate2: this.state.allDates.monthlyLetterDate2,
                mlDate3: this.state.allDates.monthlyLetterDate3,
                mlTitle1: this.state.mlTitle1,
                mlTitle2: this.state.mlTitle2,
                mlTitle3: this.state.mlTitle3,
                mlDoc1: this.state.mlDoc1,
                mlDoc2: this.state.mlDoc2,
                mlDoc3: this.state.mlDoc3,
                mlChart1: this.state.mlChart1,
                mlChart2: this.state.mlChart2,
                mlChart3: this.state.mlChart3,
                mlSupport1: this.state.mlSupport1,
                mlSupport2: this.state.mlSupport2,
                mlSupport3: this.state.mlSupport3



            }

            const whiteQuaterlyLetter = {


                qlDate1: this.state.allDates.quarterlyLetterDate1,
                qlDate2: this.state.allDates.quarterlyLetterDate2,
                qlDate3: this.state.allDates.quarterlyLetterDate3,

                qlTitle1: this.state.qlTitle1,
                qlTitle2: this.state.qlTitle2,
                qlTitle3: this.state.qlTitle3,

                qlDoc1: this.state.qlDoc1,
                qlDoc2: this.state.qlDoc2,
                qlDoc3: this.state.qlDoc3,

                qlChart1: this.state.qlChart1,
                qlChart2: this.state.qlChart2,
                qlChart3: this.state.qlChart3,
                qlSupport1: this.state.qlSupport1,
                qlSupport2: this.state.qlSupport2,
                qlSupport3: this.state.qlSupport3,
                _id: this.state.qlId,

            }

            const whiteQuaterlyMarket = {

                _id: this.state.qmpId,
                qmpDate1: this.state.allDates.perspectiveDate1,
                qmpDate2: this.state.allDates.perspectiveDate2,
                qmpDate3: this.state.allDates.perspectiveDate3,
                qmpTitle1: this.state.qmpTitle1,
                qmpTitle2: this.state.qmpTitle2,
                qmpTitle3: this.state.qmpTitle3,
                qmpPpt1: this.state.qmpPpt1,
                qmpPpt2: this.state.qmpPpt2,
                qmpPpt3: this.state.qmpPpt3,

            }
            // ====== added by chandra
            const whiteMarketTalkingPoint = {

                _id: this.state.mtpId,
                mtpDate1: this.state.allDates.talkingDate1,
                mtpDate2: this.state.allDates.talkingDate2,
                mtpDate3: this.state.allDates.talkingDate3,
                mtpTitle1: this.state.mtpTitle1,
                mtpTitle2: this.state.mtpTitle2,
                mtpTitle3: this.state.mtpTitle3,
                mtpUrl1: this.state.mtpUrl1,
                mtpUrl2: this.state.mtpUrl2,
                mtpUrl3: this.state.mtpUrl3,

            }
            // ==================end


            const whiteChartMonth = {

                cotmDate1: this.state.allDates.chartDate1,
                cotmDate2: this.state.allDates.chartDate2,
                cotmDate3: this.state.allDates.chartDate3,
                cotmDate4: this.state.allDates.chartDate4,
                cotmDate5: this.state.allDates.chartDate5,
                cotmDate6: this.state.allDates.chartDate6,

                cotmTitle1: this.state.cotmTitle1,
                cotmTitle2: this.state.cotmTitle2,
                cotmTitle3: this.state.cotmTitle3,
                cotmTitle4: this.state.cotmTitle4,
                cotmTitle5: this.state.cotmTitle5,
                cotmTitle6: this.state.cotmTitle6,

                cotmDoc1: this.state.cotmDoc1,
                cotmDoc2: this.state.cotmDoc2,
                cotmDoc3: this.state.cotmDoc3,
                cotmDoc4: this.state.cotmDoc4,
                cotmDoc5: this.state.cotmDoc5,
                cotmDoc6: this.state.cotmDoc6,
                whiteLabelInsightsParagraph: this.state.whiteLabelInsightsParagraph,

                _id: this.state.cotmId,



            }

            const whiteOffShelf = {

                ospDate1: this.state.allDates.shelfdate1,
                ospDate2: this.state.allDates.shelfdate2,
                ospDate3: this.state.allDates.shelfdate3,
                ospDate4: this.state.allDates.shelfdate4,

                ospTitle1: this.state.ospTitle1,
                ospTitle2: this.state.ospTitle2,
                ospTitle3: this.state.ospTitle3,
                ospTitle4: this.state.ospTitle4,

                ospDoc1: this.state.ospDoc1,
                ospDoc2: this.state.ospDoc2,
                ospDoc3: this.state.ospDoc3,
                ospDoc4: this.state.ospDoc4,
                _id: this.state.ospId,


            }

            const termsAndConditions = {
                _id: this.state.tAndC_id,
                tAndC: this.state.tAndC,


            }




            const manualData = {

                clientSupport,
                lrtReport,
                // clientResource,
                // currentRatings,
                modelPortfolio,
                // thematicAndTactical,
                trialDayLeft,
                ocioCore,
                // ocioDocument,
                ocioResource,
                // ocioTactical,
                whiteChartMonth,
                whiteOffShelf,
                whiteMonthly,
                whiteQuaterlyLetter,
                whiteQuaterlyMarket,
                whiteResource,
                termsAndConditions,
                whiteMarketTalkingPoint



            }
            console.log("1148", manualData)


            let responseData = await combinedServices.manualLink(manualData);
            let response = responseData.data;
            if (responseData.status) {

                this.setState({
                    errorMessage: false,
                    successMessage: responseData.message
                })
                this.sucessMsgAlert();

            } else {
                this.setState({
                    successMessage: false,
                    errorMessage: responseData.message
                })
                this.errorMsgAlert();

            }





        }

    }


    validateForm = () => {


        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;



        if (!this.state.supportButtonText1 || !this.state.supportButtonURL1 || !this.state.supportType1 ||
            !this.state.supportButtonText2 || !this.state.supportButtonURL2 || !this.state.supportType2 ||
            !this.state.supportButtonText3 || !this.state.supportButtonURL3 || !this.state.supportType3 ||
            !this.state.supportButtonText4 || !this.state.supportButtonURL4 || !this.state.supportType4 ||
            !this.state.supportButtonText5 || !this.state.supportButtonURL5 || !this.state.supportType5 ||
            !this.state.supportButtonText6 || !this.state.supportButtonURL6 || !this.state.supportType6 ||
            !this.state.supportButtonText7 || !this.state.supportButtonURL7 || !this.state.supportType7 ||
            !this.state.supportButtonText8 || !this.state.supportButtonURL8 || !this.state.supportType8 ||
            !this.state.supportButtonText9 || !this.state.supportButtonURL9 || !this.state.supportType9 ||
            !this.state.supportButtonText10 || !this.state.supportButtonURL10 || !this.state.supportType10){
            // !this.state.supportButtonText11 || !this.state.supportButtonURL11 || !this.state.supportType11 ||
            // !this.state.supportButtonText12 || !this.state.supportButtonURL12 || !this.state.supportType12 ||
            // !this.state.supportButtonText13 || !this.state.supportButtonURL13 || !this.state.supportType13 ||
            // !this.state.supportButtonText14 || !this.state.supportButtonURL14 || !this.state.supportType14 ||
            // !this.state.supportButtonText15 || !this.state.supportButtonURL15 || !this.state.supportType15 ||
            // !this.state.supportButtonText16 || !this.state.supportButtonURL16 || !this.state.supportType16 ||
            // !this.state.supportButtonText17 || !this.state.supportButtonURL17 || !this.state.supportType17 ||
            // !this.state.supportButtonText18 || !this.state.supportButtonURL18 || !this.state.supportType18 ||
            // !this.state.supportButtonText19 || !this.state.supportButtonURL19 || !this.state.supportType19 ||
            // !this.state.supportButtonText20 || !this.state.supportButtonURL20 || !this.state.supportType20) {

            formIsValid = false;
            errors["clientSupportError"] = "Missing data in client support link";
        }

        // ======================Commented by chandra
        // if (!this.state.clientTitle1 || !this.state.clientURL1 ||
        //     !this.state.clientTitle2 || !this.state.clientURL2 ||
        //     !this.state.clientTitle3 || !this.state.clientURL3 ||
        //     !this.state.clientTitle4 || !this.state.clientURL4 ||
        //     !this.state.clientTitle5 || !this.state.clientURL5) {
        //     formIsValid = false;
        //     errors["clientResourceError"] = "Missing data in client Resources.";
        // }



        // if (!this.state.allDates.ratDate1 || !this.state.ratTitle1 || !this.state.ratURL1 ||
        //     !this.state.allDates.ratDate2 || !this.state.ratTitle2 || !this.state.ratURL2 ||
        //     !this.state.allDates.ratDate3 || !this.state.ratTitle3 || !this.state.ratURL3 ||
        //     !this.state.allDates.ratDate4 || !this.state.ratTitle4 || !this.state.ratURL4 ||
        //     !this.state.allDates.ratDate5 || !this.state.ratTitle5 || !this.state.ratURL5 ||
        //     !this.state.allDates.ratDate6 || !this.state.ratTitle6 || !this.state.ratURL6 ||
        //     !this.state.allDates.ratDate7 || !this.state.ratTitle7 || !this.state.ratURL7
        // ) {
        //     formIsValid = false;
        //     errors["currentRatingError"] = "Missing data in current Ratings & Views Table.";
        // }


        if (!this.state.allDates.AsofDate1 ||
            !this.state.allDates.modDate1 || !this.state.modTitle1 || !this.state.modURL1 || !this.state.modYTD1 || !this.state.modYear1 ||
            !this.state.allDates.modDate2 || !this.state.modTitle2 || !this.state.modURL2 || !this.state.modYTD2 || !this.state.modYear2 ||
            !this.state.allDates.modDate3 || !this.state.modTitle3 || !this.state.modURL3 || !this.state.modYTD3 || !this.state.modYear3 ||
            !this.state.allDates.modDate4 || !this.state.modTitle4 || !this.state.modURL4 || !this.state.modYTD4 || !this.state.modYear4 ||
            !this.state.allDates.modDate5 || !this.state.modTitle5 || !this.state.modURL1 || !this.state.modYTD5 || !this.state.modYear5

        ) {
            formIsValid = false;
            errors["modelPortfolioError"] = "Missing data in model portfolio performance table";
        }

        // =====================Added by chandra

        // if (!this.state.lrtTitle1 ||
        //     !this.state.allDates.lrtDate1 || !this.state.lrtName1 || !this.state.lrtImage1 || !this.state.lrtUrl1 ||
        //     !this.state.allDates.lrtDate2 || !this.state.lrtName2 || !this.state.lrtImage2 || !this.state.lrtUrl2 ||
        //     !this.state.allDates.lrtDate3 || !this.state.lrtName3 || !this.state.lrtImage3 || !this.state.lrtUrl3 ||
        //     !this.state.allDates.lrtDate4 || !this.state.lrtName4 || !this.state.lrtImage4 || !this.state.lrtUrl4 ||
        //     !this.state.allDates.lrtDate5 || !this.state.lrtName5 || !this.state.lrtImage5 || !this.state.lrtUrl5 ||
        //     !this.state.lrtTitle2 ||
        //     !this.state.allDates.lrtDate6 || !this.state.lrtName6 || !this.state.lrtImage6 || !this.state.lrtUrl6 ||
        //     !this.state.allDates.lrtDate7 || !this.state.lrtName7 || !this.state.lrtImage7 || !this.state.lrtUrl7 ||
        //     !this.state.allDates.lrtDate8 || !this.state.lrtName8 || !this.state.lrtImage8 || !this.state.lrtUrl8 ||
        //     !this.state.allDates.lrtDate9 || !this.state.lrtName9 || !this.state.lrtImage9 || !this.state.lrtUrl9 ||
        //     !this.state.allDates.lrtDate10 || !this.state.lrtName10 || !this.state.lrtImage10 || !this.state.lrtUrl10 ||
        //     !this.state.lrtTitle3 ||
        //     !this.state.allDates.lrtDate11 || !this.state.lrtName11 || !this.state.lrtImage11 || !this.state.lrtUrl11 ||
        //     !this.state.allDates.lrtDate12 || !this.state.lrtName12 || !this.state.lrtImage12 || !this.state.lrtUrl12 ||
        //     !this.state.allDates.lrtDate13 || !this.state.lrtName13 || !this.state.lrtImage13 || !this.state.lrtUrl13 ||
        //     !this.state.allDates.lrtDate14 || !this.state.lrtName14 || !this.state.lrtImage14 || !this.state.lrtUrl14 ||
        //     !this.state.allDates.lrtDate15 || !this.state.lrtName15 || !this.state.lrtImage15 ||  !this.state.lrtUrl15 ||
        //     !this.state.lrtTitle4 ||
        //     !this.state.allDates.lrtDate16 || !this.state.lrtName16 || !this.state.lrtImage16 || !this.state.lrtUrl16 ||
        //     !this.state.allDates.lrtDate17 || !this.state.lrtName17 || !this.state.lrtImage17 || !this.state.lrtUrl17 ||
        //     !this.state.allDates.lrtDate18 || !this.state.lrtName18 || !this.state.lrtImage18 || !this.state.lrtUrl18 ||
        //     !this.state.allDates.lrtDate19 || !this.state.lrtName19 || !this.state.lrtImage19 || !this.state.lrtUrl19 ||
        //     !this.state.allDates.lrtDate20 || !this.state.lrtName20 || !this.state.lrtImage20 ||  !this.state.lrtUrl20 ||
        //     !this.state.lrtTitle5 ||
        //     !this.state.allDates.lrtDate21 || !this.state.lrtName21 || !this.state.lrtImage21 || !this.state.lrtUrl21 ||
        //     !this.state.allDates.lrtDate22 || !this.state.lrtName22 || !this.state.lrtImage22 || !this.state.lrtUrl22 ||
        //     !this.state.allDates.lrtDate23 || !this.state.lrtName23 || !this.state.lrtImage23 || !this.state.lrtUrl23 ||
        //     !this.state.allDates.lrtDate24 || !this.state.lrtName24 || !this.state.lrtImage24 || !this.state.lrtUrl24 ||
        //     !this.state.allDates.lrtDate25 || !this.state.lrtName25 || !this.state.lrtImage25 ||  !this.state.lrtUrl25





        // ) {
        //     formIsValid = false;
        //     errors["lrtReportError"] = "Missing data in LRT Report Table";
        // }
        // =====================end



        // if (!this.state.allDates.AsofDate2 ||
        //     !this.state.allDates.tacDate1 || !this.state.tacTitle1 || !this.state.tacURL1 || !this.state.tacWeek1 || !this.state.tacIncep1 ||
        //     !this.state.allDates.tacDate2 || !this.state.tacTitle2 || !this.state.tacURL2 || !this.state.tacWeek2 || !this.state.tacIncep2 ||
        //     !this.state.allDates.tacDate3 || !this.state.tacTitle3 || !this.state.tacURL3 || !this.state.tacWeek3 || !this.state.tacIncep3 ||
        //     !this.state.allDates.tacDate4 || !this.state.tacTitle4 || !this.state.tacURL4 || !this.state.tacWeek4 || !this.state.tacIncep4 ||
        //     !this.state.allDates.tacDate5 || !this.state.tacTitle5 || !this.state.tacURL5 || !this.state.tacWeek5 || !this.state.tacIncep5
        // ) {
        //     formIsValid = false;
        //     errors["thematicPerformanceError1"] = "Missing data in thematic & tactical ideas performance table.";
        // }


        if (!this.state.trialTitle1 || !this.state.trialURL1 ||
            !this.state.trialTitle2 || !this.state.trialURL2 ||
            !this.state.trialTitle3 || !this.state.trialURL3 ||
            !this.state.trialText
        ) {
            formIsValid = false;
            errors["trialDaysError"] = "Missing data in trial days left page.";
        }

        if (!this.state.hTitle1 || !this.state.hURL1 ||
            !this.state.hTitle2 || !this.state.hURL2 ||
            !this.state.hTitle3 || !this.state.hURL3

        ) {
            formIsValid = false;
            errors["ocioResourcesError"] = "Missing data in OCIO resources.";
        }
        // ===============commented by chandra
        // if (!this.state.clientTalkingPointsURL || !this.state.quantPacksURL
        // ) {
        //     formIsValid = false;
        //     errors["ocioDocumentLinkError"] = "Missing data in OCIO document links.";
        // }

        if (!this.state.allDates.AsofDate3 ||
            !this.state.allDates.coreDate1 || !this.state.aaTitle1 || !this.state.aaURL1 || !this.state.aaYTD1 || !this.state.aaYear1 ||
            !this.state.allDates.coreDate2 || !this.state.aaTitle2 || !this.state.aaURL2 || !this.state.aaYTD2 || !this.state.aaYear2 ||
            !this.state.allDates.coreDate3 || !this.state.aaTitle3 || !this.state.aaURL3 || !this.state.aaYTD3 || !this.state.aaYear3 ||
            !this.state.allDates.coreDate4 || !this.state.aaTitle4 || !this.state.aaURL4 || !this.state.aaYTD4 || !this.state.aaYear4 
            // !this.state.allDates.coreDate5 || !this.state.aaTitle5 || !this.state.aaURL5 || !this.state.aaYTD5 || !this.state.aaYear5 ||
            // !this.state.allDates.coreDate6 || !this.state.aaTitle6 || !this.state.aaURL6 || !this.state.aaYTD6 || !this.state.aaYear6 ||
            // !this.state.allDates.coreDate7 || !this.state.aaTitle7 || !this.state.aaURL7 || !this.state.aaYTD5 || !this.state.aaYear7 ||
            // !this.state.allDates.coreDate8 || !this.state.aaTitle8 || !this.state.aaURL8 || !this.state.aaYTD8 || !this.state.aaYear8 ||
            // !this.state.allDates.coreDate9 || !this.state.aaTitle9 || !this.state.aaURL9 || !this.state.aaYTD9 || !this.state.aaYear9 ||
            // !this.state.allDates.coreDate10 || !this.state.aaTitle10 || !this.state.aaURL10 || !this.state.aaYTD10 || !this.state.aaYear10






        ) {
            formIsValid = false;
            errors["ocioCoreModelError"] = "Missing data in OCIO core models.";
        }




        // if (!this.state.allDates.AsofDate4 ||
        //     !this.state.allDates.CoreTacDate1 || !this.state.CoreTacTitle1 || !this.state.CoreTacURL1 || !this.state.CoreTacURL1 || !this.state.CoreTacYear1 ||
        //     !this.state.allDates.CoreTacDate2 || !this.state.CoreTacTitle2 || !this.state.CoreTacURL2 || !this.state.CoreTacURL2 || !this.state.CoreTacYear2 ||
        //     !this.state.allDates.CoreTacDate3 || !this.state.CoreTacTitle3 || !this.state.CoreTacURL3 || !this.state.CoreTacURL3 || !this.state.CoreTacYear2 ||
        //     !this.state.allDates.CoreTacDate4 || !this.state.CoreTacTitle4 || !this.state.CoreTacURL4 || !this.state.CoreTacURL4 || !this.state.CoreTacYear4 ||
        //     !this.state.allDates.CoreTacDate5 || !this.state.CoreTacTitle5 || !this.state.CoreTacURL5 || !this.state.CoreTacURL5 || !this.state.CoreTacYear5


        // ) {
        //     formIsValid = false;
        //     errors["ocioTacticalModelError"] = "Missing data in OCIO core models.";
        // }

        if (!this.state.whiteLabelResourceTitle1 || !this.state.whiteLabelresourceUrl1 ||
            !this.state.whiteLabelResourceTitle2 || !this.state.whiteLabelresourceUrl2 ||
            !this.state.whiteLabelResourceTitle3 || !this.state.whiteLabelresourceUrl3

        ) {
            formIsValid = false;
            errors["whiteLabelResourcesError"] = "Missing data in white label resources.";
        }



        if (!this.state.allDates.monthlyLetterDate1 || !this.state.mlTitle1 || !this.state.mlDoc1 || !this.state.mlChart1 || !this.state.mlSupport1 ||
            !this.state.allDates.monthlyLetterDate2 || !this.state.mlTitle2 || !this.state.mlDoc2 || !this.state.mlChart2 || !this.state.mlSupport2 ||
            !this.state.allDates.monthlyLetterDate3 || !this.state.mlTitle3 || !this.state.mlDoc3 || !this.state.mlChart3 || !this.state.mlSupport3


        ) {
            formIsValid = false;
            errors["whiteLabelMonthlyLetterError"] = "Missing data in white label month letter.";
        }



        if (!this.state.allDates.quarterlyLetterDate1 ||
            !this.state.allDates.quarterlyLetterDate2 ||
            !this.state.allDates.quarterlyLetterDate3 ||
            !this.state.qlDoc1 ||
            !this.state.qlDoc2 ||
            !this.state.qlDoc3 ||
            !this.state.qlChart1 ||
            !this.state.qlChart2 ||
            !this.state.qlChart3 ||
            !this.state.qlSupport1 ||
            !this.state.qlSupport2 ||
            !this.state.qlSupport3

        ) {
            formIsValid = false;
            errors["whiteLabelQuarterlyLetterError"] = "Missing data in white label quarterly letter.";
        }


        if (!this.state.allDates.perspectiveDate1 || !this.state.qmpTitle1 || !this.state.qmpPpt1 ||
            !this.state.allDates.perspectiveDate2 || !this.state.qmpTitle2 || !this.state.qmpPpt2 ||
            !this.state.allDates.perspectiveDate3 || !this.state.qmpTitle3 || !this.state.qmpPpt3

        ) {
            formIsValid = false;
            errors["whiteLabelPerspectiveError"] = "Missing data in white label quarterly perpspective letter.";
        }
        // ====added by chandra for market Talking point

        if (!this.state.allDates.talkingDate1 || !this.state.mtpTitle1 || !this.state.mtpUrl1 ||
            !this.state.allDates.talkingDate2 || !this.state.mtpTitle2 || !this.state.mtpUrl2 ||
            !this.state.allDates.talkingDate3 || !this.state.mtpTitle3 || !this.state.mtpUrl3

        ) {
            formIsValid = false;
            errors["whiteLabelTalkingError"] = "Missing data in white label market talking point.";
        }


        // ===========================end





        if (!this.state.allDates.chartDate1 || !this.state.cotmTitle1 || !this.state.cotmDoc1 ||
            !this.state.allDates.chartDate2 || !this.state.cotmTitle2 || !this.state.cotmDoc2 ||
            !this.state.allDates.chartDate3 || !this.state.cotmTitle3 || !this.state.cotmDoc3 ||
            !this.state.allDates.chartDate4 || !this.state.cotmTitle4 || !this.state.cotmDoc4 ||
            !this.state.allDates.chartDate5 || !this.state.cotmTitle5 || !this.state.cotmDoc5 ||
            !this.state.allDates.chartDate6 || !this.state.cotmTitle6 || !this.state.cotmDoc6


        ) {
            formIsValid = false;
            errors["whiteLabelChartsError"] = "Missing data in white label chart of the month.";
        }


        if (!this.state.allDates.shelfdate1 || !this.state.ospTitle1 || !this.state.ospDoc1 ||
            !this.state.allDates.shelfdate2 || !this.state.ospTitle2 || !this.state.ospDoc2 ||
            !this.state.allDates.shelfdate3 || !this.state.ospTitle3 || !this.state.ospDoc3 ||
            !this.state.allDates.shelfdate4 || !this.state.ospTitle4 || !this.state.ospDoc4




        ) {
            formIsValid = false;
            errors["whiteLabelShelfPresentationsError"] = "Missing data in white label off the shelf presentations.";
        }


        this.setState({
            errors: errors
        });
        return formIsValid
    }



    sucessMsgAlert = () => {

        return (
            Swal.fire({
                title: 'Success',
                type: 'Success',
                text: this.state.successMessage,
            })
        )
    }
    errorMsgAlert = () => {

        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: this.state.errorMessage,
        });
    }

    handleChangeTrialPeragraph = (value) => {
        this.setState({
            trialText: value
        });

    }


    render() {
        return (

            <div>
                <Header />

                <AdminTopLinks />
                <div className="container miidlePart">
                    <Form onSubmit={this.handleSubmit} >

                        <div className="row">

                            <div className="col-12 pt-4 pb-4">
                                <div className="mainHd">Client Support Links</div>

                                <div className="mb-3">

                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText1</Label>
                                                <Input type="text" name="supportButtonText1" value={this.state.supportButtonText1} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL1</Label>
                                                <Input type="text" name="supportButtonURL1" value={this.state.supportButtonURL1} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType1</Label>
                                                <Input type="text" name="supportType1" value={this.state.supportType1} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText2</Label>
                                                <Input type="text" name="supportButtonText2" value={this.state.supportButtonText2} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL2</Label>
                                                <Input type="text" name="supportButtonURL2" value={this.state.supportButtonURL2} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType2</Label>
                                                <Input type="text" name="supportType2" value={this.state.supportType2} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText3</Label>
                                                <Input type="text" name="supportButtonText3" value={this.state.supportButtonText3} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL3</Label>
                                                <Input type="text" name="supportButtonURL3" value={this.state.supportButtonURL3} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType3</Label>
                                                <Input type="text" name="supportType3" value={this.state.supportType3} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText4</Label>
                                                <Input type="text" name="supportButtonText4" value={this.state.supportButtonText4} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL4</Label>
                                                <Input type="text" name="supportButtonURL4" value={this.state.supportButtonURL4} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType4</Label>
                                                <Input type="text" name="supportType4" value={this.state.supportType4} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText5</Label>
                                                <Input type="text" name="supportButtonText5" value={this.state.supportButtonText5} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL5</Label>
                                                <Input type="text" name="supportButtonURL5" value={this.state.supportButtonURL5} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType5</Label>
                                                <Input type="text" name="supportType5" value={this.state.supportType5} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText6</Label>
                                                <Input type="text" name="supportButtonText6" value={this.state.supportButtonText6} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL6</Label>
                                                <Input type="text" name="supportButtonURL6" value={this.state.supportButtonURL6} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType6</Label>
                                                <Input type="text" name="supportType6" value={this.state.supportType6} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText7</Label>
                                                <Input type="text" name="supportButtonText7" value={this.state.supportButtonText7} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL7</Label>
                                                <Input type="text" name="supportButtonURL7" value={this.state.supportButtonURL7} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType7</Label>
                                                <Input type="text" name="supportType7" value={this.state.supportType7} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText8</Label>
                                                <Input type="text" name="supportButtonText8" value={this.state.supportButtonText8} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL8</Label>
                                                <Input type="text" name="supportButtonURL8" value={this.state.supportButtonURL8} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType8</Label>
                                                <Input type="text" name="supportType8" value={this.state.supportType8} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText9</Label>
                                                <Input type="text" name="supportButtonText9" value={this.state.supportButtonText9} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL9</Label>
                                                <Input type="text" name="supportButtonURL9" value={this.state.supportButtonURL9} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType9</Label>
                                                <Input type="text" name="supportType9" value={this.state.supportType9} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText10</Label>
                                                <Input type="text" name="supportButtonText10" value={this.state.supportButtonText10} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL10</Label>
                                                <Input type="text" name="supportButtonURL10" value={this.state.supportButtonURL10} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType10</Label>
                                                <Input type="text" name="supportType10" value={this.state.supportType10} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText11</Label>
                                                <Input type="text" name="supportButtonText11" value={this.state.supportButtonText11} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL11</Label>
                                                <Input type="text" name="supportButtonURL11" value={this.state.supportButtonURL11} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType11</Label>
                                                <Input type="text" name="supportType11" value={this.state.supportType11} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText12</Label>
                                                <Input type="text" name="supportButtonText12" value={this.state.supportButtonText12} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL12</Label>
                                                <Input type="text" name="supportButtonURL12" value={this.state.supportButtonURL12} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType12</Label>
                                                <Input type="text" name="supportType12" value={this.state.supportType12} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText13</Label>
                                                <Input type="text" name="supportButtonText13" value={this.state.supportButtonText13} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL13</Label>
                                                <Input type="text" name="supportButtonURL13" value={this.state.supportButtonURL13} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType13</Label>
                                                <Input type="text" name="supportType13" value={this.state.supportType13} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText14</Label>
                                                <Input type="text" name="supportButtonText14" value={this.state.supportButtonText14} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL14</Label>
                                                <Input type="text" name="supportButtonURL14" value={this.state.supportButtonURL14} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType14</Label>
                                                <Input type="text" name="supportType14" value={this.state.supportType14} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText15</Label>
                                                <Input type="text" name="supportButtonText15" value={this.state.supportButtonText15} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL15</Label>
                                                <Input type="text" name="supportButtonURL15" value={this.state.supportButtonURL15} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType15</Label>
                                                <Input type="text" name="supportType15" value={this.state.supportType15} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText16</Label>
                                                <Input type="text" name="supportButtonText16" value={this.state.supportButtonText16} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL16</Label>
                                                <Input type="text" name="supportButtonURL16" value={this.state.supportButtonURL16} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType16</Label>
                                                <Input type="text" name="supportType16" value={this.state.supportType16} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText17</Label>
                                                <Input type="text" name="supportButtonText17" value={this.state.supportButtonText17} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL17</Label>
                                                <Input type="text" name="supportButtonURL17" value={this.state.supportButtonURL17} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType17</Label>
                                                <Input type="text" name="supportType17" value={this.state.supportType17} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText18</Label>
                                                <Input type="text" name="supportButtonText18" value={this.state.supportButtonText18} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL18</Label>
                                                <Input type="text" name="supportButtonURL18" value={this.state.supportButtonURL18} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType18</Label>
                                                <Input type="text" name="supportType18" value={this.state.supportType18} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText19</Label>
                                                <Input type="text" name="supportButtonText19" value={this.state.supportButtonText19} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL19</Label>
                                                <Input type="text" name="supportButtonURL19" value={this.state.supportButtonURL19} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType19</Label>
                                                <Input type="text" name="supportType19" value={this.state.supportType19} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonText20</Label>
                                                <Input type="text" name="supportButtonText20" value={this.state.supportButtonText20} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportButtonURL20</Label>
                                                <Input type="text" name="supportButtonURL20" value={this.state.supportButtonURL20} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />

                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>SupportType20</Label>
                                                <Input type="text" name="supportType20" value={this.state.supportType20} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />

                                            </FormGroup>
                                        </li>


                                    </ul>


                                    {this.state.errors.clientSupportError && (
                                        <div className="errorMsg">{this.state.errors.clientSupportError}</div>
                                    )}


                                </div>
                                <div className="mainHd">Latest Report Table</div>


                                <div className="mb-3">
                                    <ul className="manualForm d-flex flex-wrap ">
                                        <li style={{ width: '27.33' }}>
                                            <FormGroup>
                                                <Label>LRT-Title1</Label>
                                                <Input type="text" name="lrtTitle1" value={this.state.lrtTitle1} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date1</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate1} onChange={(date) => this.handleChangeDate(date, "lrtDate1")} name="lrtDate1" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name1</Label>
                                                <Input type="text" name="lrtName1" value={this.state.lrtName1} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image1</Label>
                                                <Input type="text" name="lrtImage1" onChange={this.handleChange} value={this.state.lrtImage1} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL1</Label>
                                                <Input type="text" name="lrtUrl1" onChange={this.handleChange} value={this.state.lrtUrl1} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date2</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate2} onChange={(date) => this.handleChangeDate(date, "lrtDate2")} name="lrtDate2" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name2</Label>
                                                <Input type="text" name="lrtName2" value={this.state.lrtName2} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image2</Label>
                                                <Input type="text" name="lrtImage2" onChange={this.handleChange} value={this.state.lrtImage2} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL2</Label>
                                                <Input type="text" name="lrtUrl2" onChange={this.handleChange} value={this.state.lrtUrl2} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date3</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate3} onChange={(date) => this.handleChangeDate(date, "lrtDate3")} name="lrtDate3" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name3</Label>
                                                <Input type="text" name="lrtName3" value={this.state.lrtName3} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image3</Label>
                                                <Input type="text" name="lrtImage3" onChange={this.handleChange} value={this.state.lrtImage3} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL3</Label>
                                                <Input type="text" name="lrtUrl3" onChange={this.handleChange} value={this.state.lrtUrl3} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date4</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate4} onChange={(date) => this.handleChangeDate(date, "lrtDate4")} name="lrtDate4" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name4</Label>
                                                <Input type="text" name="lrtName4" value={this.state.lrtName4} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image4</Label>
                                                <Input type="text" name="lrtImage4" onChange={this.handleChange} value={this.state.lrtImage4} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL4</Label>
                                                <Input type="text" name="lrtUrl4" onChange={this.handleChange} value={this.state.lrtUrl4} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date5</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate5} onChange={(date) => this.handleChangeDate(date, "lrtDate5")} name="lrtDate5" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name5</Label>
                                                <Input type="text" name="lrtName5" value={this.state.lrtName5} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image5</Label>
                                                <Input type="text" name="lrtImage5" onChange={this.handleChange} value={this.state.lrtImage5} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL5</Label>
                                                <Input type="text" name="lrtUrl5" onChange={this.handleChange} value={this.state.lrtUrl5} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap ">
                                        <li style={{ width: '27.33' }}>
                                            <FormGroup>
                                                <Label>LRT-Title2</Label>
                                                <Input type="text" name="lrtTitle2" value={this.state.lrtTitle2} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date6</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate6} onChange={(date) => this.handleChangeDate(date, "lrtDate6")} name="lrtDate6" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name6</Label>
                                                <Input type="text" name="lrtName6" value={this.state.lrtName6} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image6</Label>
                                                <Input type="text" name="lrtImage6" onChange={this.handleChange} value={this.state.lrtImage6} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL6</Label>
                                                <Input type="text" name="lrtUrl6" onChange={this.handleChange} value={this.state.lrtUrl6} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date7</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate7} onChange={(date) => this.handleChangeDate(date, "lrtDate7")} name="lrtDate7" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name7</Label>
                                                <Input type="text" name="lrtName7" value={this.state.lrtName7} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image7</Label>
                                                <Input type="text" name="lrtImage7" onChange={this.handleChange} value={this.state.lrtImage7} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL7</Label>
                                                <Input type="text" name="lrtUrl7" onChange={this.handleChange} value={this.state.lrtUrl7} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date8</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate8} onChange={(date) => this.handleChangeDate(date, "lrtDate8")} name="lrtDate8" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name8</Label>
                                                <Input type="text" name="lrtName8" value={this.state.lrtName8} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image8</Label>
                                                <Input type="text" name="lrtImage8" onChange={this.handleChange} value={this.state.lrtImage8} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL8</Label>
                                                <Input type="text" name="lrtUrl8" onChange={this.handleChange} value={this.state.lrtUrl8} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date9</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate9} onChange={(date) => this.handleChangeDate(date, "lrtDate9")} name="lrtDate9" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name9</Label>
                                                <Input type="text" name="lrtName9" value={this.state.lrtName9} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image9</Label>
                                                <Input type="text" name="lrtImage9" onChange={this.handleChange} value={this.state.lrtImage9} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL9</Label>
                                                <Input type="text" name="lrtUrl9" onChange={this.handleChange} value={this.state.lrtUrl9} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date10</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate10} onChange={(date) => this.handleChangeDate(date, "lrtDate10")} name="lrtDate10" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name10</Label>
                                                <Input type="text" name="lrtName10" value={this.state.lrtName10} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image10</Label>
                                                <Input type="text" name="lrtImage10" onChange={this.handleChange} value={this.state.lrtImage10} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL10</Label>
                                                <Input type="text" name="lrtUrl10" onChange={this.handleChange} value={this.state.lrtUrl10} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap ">
                                        <li style={{ width: '27.33' }}>
                                            <FormGroup>
                                                <Label>LRT-Title3</Label>
                                                <Input type="text" name="lrtTitle3" value={this.state.lrtTitle3} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date11</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate11} onChange={(date) => this.handleChangeDate(date, "lrtDate11")} name="lrtDate11" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name11</Label>
                                                <Input type="text" name="lrtName11" value={this.state.lrtName11} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image11</Label>
                                                <Input type="text" name="lrtImage11" onChange={this.handleChange} value={this.state.lrtImage11} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL11</Label>
                                                <Input type="text" name="lrtUrl11" onChange={this.handleChange} value={this.state.lrtUrl11} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date12</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate12} onChange={(date) => this.handleChangeDate(date, "lrtDate12")} name="lrtDate12" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name12</Label>
                                                <Input type="text" name="lrtName12" value={this.state.lrtName12} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image12</Label>
                                                <Input type="text" name="lrtImage12" onChange={this.handleChange} value={this.state.lrtImage12} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL12</Label>
                                                <Input type="text" name="lrtUrl12" onChange={this.handleChange} value={this.state.lrtUrl12} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date13</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate13} onChange={(date) => this.handleChangeDate(date, "lrtDate13")} name="lrtDate13" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name13</Label>
                                                <Input type="text" name="lrtName13" value={this.state.lrtName13} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image13</Label>
                                                <Input type="text" name="lrtImage13" onChange={this.handleChange} value={this.state.lrtImage13} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL13</Label>
                                                <Input type="text" name="lrtUrl13" onChange={this.handleChange} value={this.state.lrtUrl13} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date14</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate14} onChange={(date) => this.handleChangeDate(date, "lrtDate14")} name="lrtDate14" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name14</Label>
                                                <Input type="text" name="lrtName14" value={this.state.lrtName14} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image14</Label>
                                                <Input type="text" name="lrtImage14" onChange={this.handleChange} value={this.state.lrtImage14} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL14</Label>
                                                <Input type="text" name="lrtUrl14" onChange={this.handleChange} value={this.state.lrtUrl14} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date15</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate15} onChange={(date) => this.handleChangeDate(date, "lrtDate15")} name="lrtDate15" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name15</Label>
                                                <Input type="text" name="lrtName15" value={this.state.lrtName15} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image15</Label>
                                                <Input type="text" name="lrtImage15" onChange={this.handleChange} value={this.state.lrtImage15} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL15</Label>
                                                <Input type="text" name="lrtUrl15" onChange={this.handleChange} value={this.state.lrtUrl15} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap ">
                                        <li style={{ width: '27.33' }}>
                                            <FormGroup>
                                                <Label>LRT-Title4</Label>
                                                <Input type="text" name="lrtTitle4" value={this.state.lrtTitle4} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date16</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate16} onChange={(date) => this.handleChangeDate(date, "lrtDate16")} name="lrtDate16" placeholderText="Select Date" className="formInput form-control" />

                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name16</Label>
                                                <Input type="text" name="lrtName16" value={this.state.lrtName16} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image16</Label>
                                                <Input type="text" name="lrtImage16" onChange={this.handleChange} value={this.state.lrtImage16} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL16</Label>
                                                <Input type="text" name="lrtUrl16" onChange={this.handleChange} value={this.state.lrtUrl16} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date17</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate17} onChange={(date) => this.handleChangeDate(date, "lrtDate17")} name="lrtDate17" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name17</Label>
                                                <Input type="text" name="lrtName17" value={this.state.lrtName17} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image17</Label>
                                                <Input type="text" name="lrtImage17" onChange={this.handleChange} value={this.state.lrtImage17} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL17</Label>
                                                <Input type="text" name="lrtUrl17" onChange={this.handleChange} value={this.state.lrtUrl17} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date18</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate18} onChange={(date) => this.handleChangeDate(date, "lrtDate18")} name="lrtDate18" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name18</Label>
                                                <Input type="text" name="lrtName18" value={this.state.lrtName18} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image18</Label>
                                                <Input type="text" name="lrtImage18" onChange={this.handleChange} value={this.state.lrtImage18} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL18</Label>
                                                <Input type="text" name="lrtUrl18" onChange={this.handleChange} value={this.state.lrtUrl18} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date19</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate19} onChange={(date) => this.handleChangeDate(date, "lrtDate19")} name="lrtDate19" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name19</Label>
                                                <Input type="text" name="lrtName19" value={this.state.lrtName19} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image19</Label>
                                                <Input type="text" name="lrtImage19" onChange={this.handleChange} value={this.state.lrtImage19} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL19</Label>
                                                <Input type="text" name="lrtUrl19" onChange={this.handleChange} value={this.state.lrtUrl19} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date20</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate20} onChange={(date) => this.handleChangeDate(date, "lrtDate20")} name="lrtDate20" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name20</Label>
                                                <Input type="text" name="lrtName20" value={this.state.lrtName20} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image20</Label>
                                                <Input type="text" name="lrtImage20" onChange={this.handleChange} value={this.state.lrtImage20} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL20</Label>
                                                <Input type="text" name="lrtUrl20" onChange={this.handleChange} value={this.state.lrtUrl20} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap ">
                                        <li style={{ width: '27.33' }}>
                                            <FormGroup>
                                                <Label>LRT-Title5</Label>
                                                <Input type="text" name="lrtTitle5" value={this.state.lrtTitle5} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date21</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate21} onChange={(date) => this.handleChangeDate(date, "lrtDate21")} name="lrtDate21" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name21</Label>
                                                <Input type="text" name="lrtName21" value={this.state.lrtName21} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image21</Label>
                                                <Input type="text" name="lrtImage21" onChange={this.handleChange} value={this.state.lrtImage21} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL21</Label>
                                                <Input type="text" name="lrtUrl21" onChange={this.handleChange} value={this.state.lrtUrl21} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date22</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate22} onChange={(date) => this.handleChangeDate(date, "lrtDate22")} name="lrtDate22" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name22</Label>
                                                <Input type="text" name="lrtName22" value={this.state.lrtName22} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image22</Label>
                                                <Input type="text" name="lrtImage22" onChange={this.handleChange} value={this.state.lrtImage22} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL22</Label>
                                                <Input type="text" name="lrtUrl22" onChange={this.handleChange} value={this.state.lrtUrl22} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date23</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate23} onChange={(date) => this.handleChangeDate(date, "lrtDate23")} name="lrtDate23" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name23</Label>
                                                <Input type="text" name="lrtName23" value={this.state.lrtName23} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image23</Label>
                                                <Input type="text" name="lrtImage23" onChange={this.handleChange} value={this.state.lrtImage23} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL23</Label>
                                                <Input type="text" name="lrtUrl23" onChange={this.handleChange} value={this.state.lrtUrl23} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date24</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate24} onChange={(date) => this.handleChangeDate(date, "lrtDate24")} name="lrtDate24" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name24</Label>
                                                <Input type="text" name="lrtName24" value={this.state.lrtName24} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image24</Label>
                                                <Input type="text" name="lrtImage24" onChange={this.handleChange} value={this.state.lrtImage24} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL24</Label>
                                                <Input type="text" name="lrtUrl24" onChange={this.handleChange} value={this.state.lrtUrl24} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap latestReportForm">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>LRT-Date25</Label>
                                                <DatePicker selected={this.state.allDates.lrtDate25} onChange={(date) => this.handleChangeDate(date, "lrtDate25")} name="lrtDate25" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Name25</Label>
                                                <Input type="text" name="lrtName25" value={this.state.lrtName25} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-Image25</Label>
                                                <Input type="text" name="lrtImage25" onChange={this.handleChange} value={this.state.lrtImage25} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li style={{ width: '27.33%' }}>
                                            <FormGroup>
                                                <Label>LRT-URL25</Label>
                                                <Input type="text" name="lrtUrl25" onChange={this.handleChange} value={this.state.lrtUrl25} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    {this.state.errors.lrtReportError && (
                                        <div className="errorMsg">{this.state.errors.lrtReportError}</div>
                                    )}





                                </div>

                                {/* <div className="mainHd">Client Resources Table</div>

                                <div className="mb-3">

                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>ClientTitle1</Label>
                                                <Input type="text" name="clientTitle1" value={this.state.clientTitle1} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>ClientURL1</Label>

                                                <Input type="text" name="clientURL1" value={this.state.clientURL1} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>


                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>ClientTitle2</Label>
                                                <Input type="text" name="clientTitle2" value={this.state.clientTitle2} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>ClientURL2</Label>

                                                <Input type="text" name="clientURL2" value={this.state.clientURL2} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>ClientTitle3</Label>
                                                <Input type="text" name="clientTitle3" value={this.state.clientTitle3} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>ClientURL3</Label>

                                                <Input type="text" name="clientURL3" value={this.state.clientURL3} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>ClientTitle4</Label>
                                                <Input type="text" name="clientTitle4" value={this.state.clientTitle4} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>ClientURL4</Label>

                                                <Input type="text" name="clientURL4" value={this.state.clientURL4} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>ClientTitle5</Label>
                                                <Input type="text" name="clientTitle5" value={this.state.clientTitle5} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>ClientURL5</Label>

                                                <Input type="text" name="clientURL5" value={this.state.clientURL5} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>



                                    {this.state.errors.clientResourceError && (
                                        <div className="errorMsg">{this.state.errors.clientResourceError}</div>
                                    )}
                                </div> */}


                                {/* <div className="mainHd">Current Ratings &amp; Views Table</div>


                                <div className="mb-3">



                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>RatDate1</Label>
                                                <DatePicker selected={this.state.allDates.ratDate1} onChange={(date) => this.handleChangeDate(date, "ratDate1")} name="ratDate1" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>RatTitle1</Label>
                                                <Input type="text" name="ratTitle1" value={this.state.ratTitle1} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>RatURL1</Label>
                                                <Input type="text" name="ratURL1" onChange={this.handleChange} value={this.state.ratURL1} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>RatDate2</Label>
                                                <DatePicker selected={this.state.allDates.ratDate2} onChange={(date) => this.handleChangeDate(date, "ratDate2")} name="ratDate2" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>RatTitle2</Label>
                                                <Input type="text" name="ratTitle2" value={this.state.ratTitle2} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>RatURL2</Label>
                                                <Input type="text" name="ratURL2" value={this.state.ratURL2} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>


                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>RatDate3</Label>
                                                <DatePicker selected={this.state.allDates.ratDate3} onChange={(date) => this.handleChangeDate(date, "ratDate3")} name="ratDate3" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>RatTitle3</Label>
                                                <Input type="text" name="ratTitle3" value={this.state.ratTitle3} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>RatURL3</Label>
                                                <Input type="text" name="ratURL3" value={this.state.ratURL3} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>



                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>RatDate4</Label>
                                                <DatePicker selected={this.state.allDates.ratDate4} onChange={(date) => this.handleChangeDate(date, "ratDate4")} name="ratDate4" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>RatTitle4</Label>
                                                <Input type="text" name="ratTitle4" value={this.state.ratTitle4} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>RatURL4</Label>
                                                <Input type="text" name="ratURL4" value={this.state.ratURL4} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>


                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>RatDate5</Label>
                                                <DatePicker selected={this.state.allDates.ratDate5} onChange={(date) => this.handleChangeDate(date, "ratDate5")} name="ratDate5" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>RatTitle5</Label>
                                                <Input type="text" value={this.state.ratTitle5} name="ratTitle5" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>RatURL5</Label>
                                                <Input type="text" name="ratURL5" value={this.state.ratURL5} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>


                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>RatDate6</Label>
                                                <DatePicker selected={this.state.allDates.ratDate6} onChange={(date) => this.handleChangeDate(date, "ratDate6")} name="ratDate6" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>RatTitle6</Label>
                                                <Input type="text" name="ratTitle6" value={this.state.ratTitle6} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>RatURL6</Label>
                                                <Input type="text" name="ratURL6" value={this.state.ratURL6} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>



                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>RatDate7</Label>

                                                <DatePicker selected={this.state.allDates.ratDate7} onChange={(date) => this.handleChangeDate(date, "ratDate7")} name="ratDate7" placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>RatTitle7</Label>
                                                <Input type="text" value={this.state.ratTitle7} name="ratTitle7" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>RatURL7</Label>
                                                <Input type="text" name="ratURL7" value={this.state.ratURL7} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>

                                    {this.state.errors.currentRatingError && (
                                        <div className="errorMsg">{this.state.errors.currentRatingError}</div>
                                    )}

                                </div> */}

                                {/* <div className="mainHd">OCIO-Equity & ETF Models</div> */}
                                <div className="mainHd">Equity Models</div>
                                <div className="mb-3">
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AsofDate1</Label>
                                                <DatePicker selected={this.state.allDates.AsofDate1} onChange={(date) => this.handleChangeDate(date, "AsofDate1")} name="AsofDate1" placeholderText="Select Date" className="formInput form-control" />

                                            </FormGroup>
                                        </li>
                                    </ul>


                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModDate1</Label>
                                                <DatePicker selected={this.state.allDates.modDate1} name="modDate1" onChange={(date) => this.handleChangeDate(date, "modDate1")} placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModTitle1</Label>
                                                <Input type="text" name="modTitle1" value={this.state.modTitle1} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModURL1</Label>
                                                <Input type="text" name="modURL1" value={this.state.modURL1} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYTD1</Label>
                                                <Input type="text" name="modYTD1" value={this.state.modYTD1} onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYear1</Label>
                                                <Input type="text" name="modYear1" value={this.state.modYear1} onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                    </ul>


                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModDate1</Label>
                                                <DatePicker selected={this.state.allDates.modDate2} name="modDate2" onChange={(date) => this.handleChangeDate(date, "modDate2")} placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModTitle2</Label>
                                                <Input type="text" name="modTitle2" value={this.state.modTitle2} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModURL2</Label>
                                                <Input type="text" name="modURL2" value={this.state.modURL2} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYTD2</Label>
                                                <Input type="text" name="modYTD2" value={this.state.modYTD2} onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYear2</Label>
                                                <Input type="text" name="modYear2" value={this.state.modYear2} onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                    </ul>


                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModDate3</Label>
                                                <DatePicker selected={this.state.allDates.modDate3} name="modDate3" onChange={(date) => this.handleChangeDate(date, "modDate3")} placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModTitle3</Label>
                                                <Input type="text" name="modTitle3" value={this.state.modTitle3} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModURL3</Label>
                                                <Input type="text" name="modURL3" value={this.state.modURL3} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYTD3</Label>
                                                <Input type="text" name="modYTD3" value={this.state.modYTD3} onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYear3</Label>
                                                <Input type="text" name="modYear3" value={this.state.modYear3} onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                    </ul>


                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModDate4</Label>
                                                <DatePicker selected={this.state.allDates.modDate4} name="modDate4" onChange={(date) => this.handleChangeDate(date, "modDate4")} placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModTitle4</Label>
                                                <Input type="text" value={this.state.modTitle4} name="modTitle4" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModURL4</Label>
                                                <Input type="text" value={this.state.modURL4} name="modURL4" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYTD4</Label>
                                                <Input type="text" value={this.state.modYTD4} name="modYTD4" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYear4</Label>
                                                <Input type="text" name="modYear4" value={this.state.modYear4} onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                    </ul>


                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModDate5</Label>
                                                <DatePicker selected={this.state.allDates.modDate5} name="modDate5" onChange={(date) => this.handleChangeDate(date, "modDate5")} placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModTitle5</Label>
                                                <Input type="text" name="modTitle5" value={this.state.modTitle5} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModURL5</Label>
                                                <Input type="text" name="modURL5" value={this.state.modURL5} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYTD5</Label>
                                                <Input type="text" name="modYTD5" value={this.state.modYTD5} onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYear5</Label>
                                                <Input type="text" name="modYear5" value={this.state.modYear5} onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                    </ul>



                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModDate6</Label>
                                                <DatePicker selected={this.state.allDates.modDate6} name="modDate6" onChange={(date) => this.handleChangeDate(date, "modDate6")} placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModTitle6</Label>
                                                <Input type="text" name="modTitle6" value={this.state.modTitle6} onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModURL6</Label>
                                                <Input type="text" name="modURL6" value={this.state.modURL6} onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYTD6</Label>
                                                <Input type="text" name="modYTD6" value={this.state.modYTD6} onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYear6</Label>
                                                <Input type="text" name="modYear6" value={this.state.modYear6} onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                    </ul>


                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModDate7</Label>
                                                <DatePicker selected={this.state.allDates.modDate7} name="modDate7" onChange={(date) => this.handleChangeDate(date, "modDate7")} placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModTitle7</Label>
                                                <Input type="text" value={this.state.modTitle7} name="modTitle7" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModURL7</Label>
                                                <Input type="text" value={this.state.modURL7} name="modURL7" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYTD7</Label>
                                                <Input type="text" value={this.state.modYTD7} name="modYTD7" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYear7</Label>
                                                <Input type="text" value={this.state.modYear7} name="modYear7" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                    </ul>



                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModDate8</Label>
                                                <DatePicker selected={this.state.allDates.modDate8} name="modDate8" onChange={(date) => this.handleChangeDate(date, "modDate8")} placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModTitle8</Label>
                                                <Input type="text" value={this.state.modTitle8} name="modTitle8" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModURL8</Label>
                                                <Input type="text" value={this.state.modURL8} name="modURL8" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYTD8</Label>
                                                <Input type="text" value={this.state.modYTD8} name="modYTD8" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYear8</Label>
                                                <Input type="text" value={this.state.modYear8} name="modYear8" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModDate9</Label>
                                                <DatePicker selected={this.state.allDates.modDate9} name="modDate9" onChange={(date) => this.handleChangeDate(date, "modDate9")} placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModTitle9</Label>
                                                <Input type="text" value={this.state.modTitle9} name="modTitle9" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModURL9</Label>
                                                <Input type="text" value={this.state.modURL9} name="modURL9" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYTD9</Label>
                                                <Input type="text" value={this.state.modYTD9} name="modYTD9" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYear9</Label>
                                                <Input type="text" name="modYear9" value={this.state.modYear9} onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                    </ul>


                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModDate10</Label>
                                                <DatePicker selected={this.state.allDates.modDate10} name="modDate10" onChange={(date) => this.handleChangeDate(date, "modDate10")} placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModTitle10</Label>
                                                <Input type="text" value={this.state.modTitle10} name="modTitle10" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>ModURL10</Label>
                                                <Input type="text" value={this.state.modURL10} name="modURL10" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYTD10</Label>
                                                <Input type="text" value={this.state.modYTD10} name="modYTD10" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>ModYear10</Label>
                                                <Input type="text" value={this.state.modYear10} name="modYear10" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                    </ul>


                                    {this.state.errors.modelPortfolioError && (
                                        <div className="errorMsg">{this.state.errors.modelPortfolioError}</div>
                                    )}


                                </div>
                                {/* <div className="mainHd">Thematic &amp; Tactical Ideas Performance Table</div>


                                <div className="mb-3">
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AsofDate2</Label>
                                                <DatePicker selected={this.state.allDates.AsofDate2} name="AsofDate2" onChange={(date) => this.handleChangeDate(date, "AsofDate2")} placeholderText="Select Date" className="formInput form-control" />

                                            </FormGroup>
                                        </li>
                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>TacDate1</Label>
                                                <DatePicker selected={this.state.allDates.tacDate1} name="tacDate1" onChange={(date) => this.handleChangeDate(date, "tacDate1")} placeholderText="Select Date" className="formInput form-control" />

                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>TacTitle1</Label>
                                                <Input type="text" value={this.state.tacTitle1} name="tacTitle1" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>TacURL1</Label>
                                                <Input type="text" value={this.state.tacURL1} name="tacURL1" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>TacWeek1</Label>
                                                <Input type="text" value={this.state.tacWeek1} name="tacWeek1" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>TacIncep1</Label>
                                                <Input type="text" value={this.state.tacIncep1} name="tacIncep1" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                    </ul>


                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>TacDate2</Label>
                                                <DatePicker selected={this.state.allDates.tacDate2} name="tacDate2" onChange={(date) => this.handleChangeDate(date, "tacDate2")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>TacTitle2</Label>
                                                <Input type="text" value={this.state.tacTitle2} name="tacTitle2" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>TacURL2</Label>
                                                <Input type="text" value={this.state.tacURL2} name="tacURL2" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>TacWeek2</Label>
                                                <Input type="text" value={this.state.tacWeek2} name="tacWeek2" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>TacIncep2</Label>
                                                <Input type="text" value={this.state.tacIncep2} name="tacIncep2" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                    </ul>




                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>TacDate3</Label>
                                                <DatePicker selected={this.state.allDates.tacDate3} name="tacDate3" onChange={(date) => this.handleChangeDate(date, "tacDate3")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>TacTitle3</Label>
                                                <Input type="text" value={this.state.tacTitle3} name="tacTitle3" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>TacURL3</Label>
                                                <Input type="text" value={this.state.tacURL3} name="tacURL3" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>TacWeek3</Label>
                                                <Input type="text" value={this.state.tacWeek3} name="tacWeek3" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>TacIncep3</Label>
                                                <Input type="text" value={this.state.tacIncep3} name="tacIncep3" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                    </ul>



                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>TacDate4</Label>
                                                <DatePicker selected={this.state.allDates.tacDate4} name="tacDate4" onChange={(date) => this.handleChangeDate(date, "tacDate4")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>TacTitle4</Label>
                                                <Input type="text" value={this.state.tacTitle4} name="tacTitle4" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>TacURL4</Label>
                                                <Input type="text" value={this.state.tacURL4} name="tacURL4" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>TacWeek4</Label>
                                                <Input type="text" value={this.state.tacWeek4} name="tacWeek4" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>TacIncep4</Label>
                                                <Input type="text" value={this.state.tacIncep4} name="tacIncep4" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                    </ul>


                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>TacDate5</Label>
                                                <DatePicker selected={this.state.allDates.tacDate5} name="tacDate5" onChange={(date) => this.handleChangeDate(date, "tacDate5")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>TacTitle5</Label>
                                                <Input type="text" value={this.state.tacTitle5} name="tacTitle5" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>TacURL5</Label>
                                                <Input type="text" value={this.state.tacURL5} name="tacURL5" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>TacWeek5</Label>
                                                <Input type="text" value={this.state.tacWeek5} name="tacWeek5" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>TacIncep5</Label>
                                                <Input type="text" value={this.state.tacIncep5} name="tacIncep5" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                    </ul>

                                    {this.state.errors.thematicPerformanceError1 && (
                                        <div className="errorMsg">{this.state.errors.thematicPerformanceError1}</div>
                                    )}

                                </div> */}


                                {/* <div className="mainHd">OCIO - Resources</div> */}
                                <div className="mainHd">Model Resources</div>

                                <div className="mb-3">
                                    <Input type="hidden" value={this.state.OCIOResourceDataList._id} name="coreResourceId" />
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label> Htitle1</Label>
                                                <Input type="text" value={this.state.hTitle1} onChange={this.handleChange} name="hTitle1" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label> HURL1</Label>
                                                <Input type="text" value={this.state.hURL1} onChange={this.handleChange} name="hURL1" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label> Htitle2</Label>
                                                <Input type="text" value={this.state.hTitle2} onChange={this.handleChange} name="hTitle2" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label> HURL2</Label>
                                                <Input type="text" value={this.state.hURL2} onChange={this.handleChange} name="hURL2" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label> Htitle3</Label>
                                                <Input type="text" value={this.state.hTitle3} onChange={this.handleChange} name="hTitle3" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label> HURL3</Label>
                                                <Input type="text" value={this.state.hURL3} onChange={this.handleChange} name="hURL3" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>



                                    {this.state.errors.ocioResourcesError && (
                                        <div className="errorMsg">{this.state.errors.ocioResourcesError}</div>
                                    )}

                                </div>


                                {/* <div className="mainHd">OCIO-Document Links</div>
                                <div className="mb-3">


                                    <ul className="manualForm d-flex flex-wrap">

                                        <li>
                                            <FormGroup>
                                                <Label>ClinetTalkingPointURL</Label>
                                                <Input type="text" value={this.state.clientTalkingPointsURL} onChange={this.handleChange} name="clientTalkingPointsURL" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                        <li>
                                            <FormGroup>
                                                <Label>OCIOQuantPackURL</Label>
                                                <Input type="text" value={this.state.quantPacksURL} onChange={this.handleChange} name="quantPacksURL" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>




                                    {this.state.errors.ocioDocumentLinkError && (
                                        <div className="errorMsg">{this.state.errors.ocioDocumentLinkError}</div>
                                    )}

                                </div> */}


                                {/* <div className="mainHd">OCIO - Core Models</div> */}
                                <div className="mainHd">ETF Models</div>
                                <div className="mb-3">

                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AsofDate3</Label>
                                                <DatePicker name="AsofDate3" selected={this.state.allDates.AsofDate3} onChange={(date) => this.handleChangeDate(date, "AsofDate3")} placeholderText="Select Date" className="formInput form-control" />

                                            </FormGroup>
                                        </li>
                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAdate1</Label>
                                                <DatePicker selected={this.state.allDates.coreDate1} name="coreDate1" onChange={(date) => this.handleChangeDate(date, "coreDate1")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAtitle1</Label>
                                                <Input type="text" value={this.state.aaTitle1} name="aaTitle1" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAURL1</Label>
                                                <Input type="text" value={this.state.aaURL1} name="aaURL1" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYTD1</Label>
                                                <Input type="text" value={this.state.aaYTD1} name="aaYTD1" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYear1</Label>
                                                <Input type="text" value={this.state.aaYear1} name="aaYear1" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>

                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAdate2</Label>
                                                <DatePicker selected={this.state.allDates.coreDate2} name="coreDate2" onChange={(date) => this.handleChangeDate(date, "coreDate2")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAtitle2</Label>
                                                <Input type="text" value={this.state.aaTitle2} name="aaTitle2" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAURL2</Label>
                                                <Input type="text" value={this.state.aaURL2} name="aaURL2" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYTD2</Label>
                                                <Input type="text" value={this.state.aaYTD2} name="aaYTD2" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYear2</Label>
                                                <Input type="text" value={this.state.aaYear2} name="aaYear2" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>

                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAdate3</Label>
                                                <DatePicker selected={this.state.allDates.coreDate3} name="coreDate3" onChange={(date) => this.handleChangeDate(date, "coreDate3")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAtitle3</Label>
                                                <Input type="text" value={this.state.aaTitle3} name="aaTitle3" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAURL3</Label>
                                                <Input type="text" value={this.state.aaURL3} name="aaURL3" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYTD3</Label>
                                                <Input type="text" value={this.state.aaYTD3} name="aaYTD3" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYear3</Label>
                                                <Input type="text" value={this.state.aaYear3} name="aaYear3" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>

                                    </ul>


                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAdate4</Label>
                                                <DatePicker selected={this.state.allDates.coreDate4} name="coreDate4" onChange={(date) => this.handleChangeDate(date, "coreDate4")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAtitle4</Label>
                                                <Input type="text" value={this.state.aaTitle4} name="aaTitle4" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAURL4</Label>
                                                <Input type="text" value={this.state.aaURL4} name="aaURL4" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYTD4</Label>
                                                <Input type="text" value={this.state.aaYTD4} name="aaYTD4" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYear4</Label>
                                                <Input type="text" value={this.state.aaYear4} name="aaYear4" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>

                                    </ul>


                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAdate5</Label>
                                                <DatePicker selected={this.state.allDates.coreDate5} name="coreDate5" onChange={(date) => this.handleChangeDate(date, "coreDate5")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAtitle5</Label>
                                                <Input type="text" value={this.state.aaTitle5} name="aaTitle5" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAURL5</Label>
                                                <Input type="text" value={this.state.aaURL5} name="aaURL5" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYTD5</Label>
                                                <Input type="text" value={this.state.aaYTD5} name="aaYTD5" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYear5</Label>
                                                <Input type="text" value={this.state.aaYear5} name="aaYear5" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAdate6</Label>
                                                <DatePicker selected={this.state.allDates.coreDate6} name="coreDate5" onChange={(date) => this.handleChangeDate(date, "coreDate6")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAtitle6</Label>
                                                <Input type="text" value={this.state.aaTitle6} name="aaTitle6" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAURL6</Label>
                                                <Input type="text" value={this.state.aaURL6} name="aaURL6" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYTD6</Label>
                                                <Input type="text" value={this.state.aaYTD6} name="aaYTD6" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYear6</Label>
                                                <Input type="text" value={this.state.aaYear6} name="aaYear6" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAdate7</Label>
                                                <DatePicker selected={this.state.allDates.coreDate7} name="coreDate7" onChange={(date) => this.handleChangeDate(date, "coreDate7")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAtitle7</Label>
                                                <Input type="text" value={this.state.aaTitle7} name="aaTitle7" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAURL7</Label>
                                                <Input type="text" value={this.state.aaURL7} name="aaURL7" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYTD7</Label>
                                                <Input type="text" value={this.state.aaYTD7} name="aaYTD7" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYear7</Label>
                                                <Input type="text" value={this.state.aaYear7} name="aaYear7" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAdate8</Label>
                                                <DatePicker selected={this.state.allDates.coreDate8} name="coreDate8" onChange={(date) => this.handleChangeDate(date, "coreDate8")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAtitle8</Label>
                                                <Input type="text" value={this.state.aaTitle8} name="aaTitle8" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAURL8</Label>
                                                <Input type="text" value={this.state.aaURL8} name="aaURL8" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYTD8</Label>
                                                <Input type="text" value={this.state.aaYTD8} name="aaYTD8" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYear8</Label>
                                                <Input type="text" value={this.state.aaYear8} name="aaYear8" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAdate9</Label>
                                                <DatePicker selected={this.state.allDates.coreDate9} name="coreDate9" onChange={(date) => this.handleChangeDate(date, "coreDate9")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAtitle9</Label>
                                                <Input type="text" value={this.state.aaTitle9} name="aaTitle9" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAURL9</Label>
                                                <Input type="text" value={this.state.aaURL9} name="aaURL9" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYTD9</Label>
                                                <Input type="text" value={this.state.aaYTD9} name="aaYTD9" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYear9</Label>
                                                <Input type="text" value={this.state.aaYear9} name="aaYear9" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAdate10</Label>
                                                <DatePicker selected={this.state.allDates.coreDate10} name="coreDate10" onChange={(date) => this.handleChangeDate(date, "coreDate10")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAtitle10</Label>
                                                <Input type="text" value={this.state.aaTitle10} name="aaTitle10" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAURL10</Label>
                                                <Input type="text" value={this.state.aaURL10} name="aaURL10" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYTD10</Label>
                                                <Input type="text" value={this.state.aaYTD10} name="aaYTD10" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYear10</Label>
                                                <Input type="text" value={this.state.aaYear10} name="aaYear10" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>

                                    </ul>

                                    <Input type="hidden" value={this.state.OCIOCoreDataList._id} name="coreModelId" />

                                    {this.state.errors.ocioCoreModelError && (
                                        <div className="errorMsg">{this.state.errors.ocioCoreModelError}</div>
                                    )}

                                </div>


                                {/* <div className="mainHd">OCIO-Tactical Models</div>

                                <div className="mb-3">

                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AsofDate3</Label>
                                                <DatePicker selected={this.state.allDates.AsofDate4} name="AsofDate4" onChange={(date) => this.handleChangeDate(date, "AsofDate4")} placeholderText="Select Date" className="formInput form-control" />

                                            </FormGroup>
                                        </li>
                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">


                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAdate1</Label>
                                                <DatePicker selected={this.state.allDates.CoreTacDate1} name="CoreTacDate1" onChange={(date) => this.handleChangeDate(date, "CoreTacDate1")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAtitle1</Label>
                                                <Input type="text" value={this.state.CoreTacTitle1} name="CoreTacTitle1" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAURL1</Label>
                                                <Input type="text" value={this.state.CoreTacURL1} name="CoreTacURL1" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYTD1</Label>
                                                <Input type="text" value={this.state.CoreTacYTD1} name="CoreTacYTD1" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYear1</Label>
                                                <Input type="text" value={this.state.CoreTacYear1} name="CoreTacYear1" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>

                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">


                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAdate2</Label>
                                                <DatePicker name="CoreTacDate2" selected={this.state.allDates.CoreTacDate2} onChange={(date) => this.handleChangeDate(date, "CoreTacDate2")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAtitle2</Label>
                                                <Input type="text" value={this.state.CoreTacTitle2} name="CoreTacTitle2" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAURL2</Label>
                                                <Input type="text" value={this.state.CoreTacURL2} name="CoreTacURL2" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYTD2</Label>
                                                <Input type="text" value={this.state.CoreTacYTD2} name="CoreTacYTD2" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYear2</Label>
                                                <Input type="text" value={this.state.CoreTacYear2} name="CoreTacYear2" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>

                                    </ul>


                                    <ul className="manualForm d-flex flex-wrap">


                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAdate3</Label>
                                                <DatePicker name="CoreTacDate3" selected={this.state.allDates.CoreTacDate3} onChange={(date) => this.handleChangeDate(date, "CoreTacDate3")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAtitle3</Label>
                                                <Input type="text" value={this.state.CoreTacTitle3} name="CoreTacTitle3" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAURL3</Label>
                                                <Input type="text" value={this.state.CoreTacURL3} name="CoreTacURL3" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYTD3</Label>
                                                <Input type="text" value={this.state.CoreTacYTD3} name="CoreTacYTD3" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYear3</Label>
                                                <Input type="text" value={this.state.CoreTacYear3} name="CoreTacYear3" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>

                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">


                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAdate4</Label>
                                                <DatePicker selected={this.state.allDates.CoreTacDate4} name="CoreTacDate4" onChange={(date) => this.handleChangeDate(date, "CoreTacDate4")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAtitle4</Label>
                                                <Input type="text" value={this.state.CoreTacTitle4} name="CoreTacTitle4" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAURL4</Label>
                                                <Input type="text" value={this.state.CoreTacURL4} name="CoreTacURL4" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYTD4</Label>
                                                <Input type="text" value={this.state.CoreTacYTD4} name="CoreTacYTD4" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYear4</Label>
                                                <Input type="text" value={this.state.CoreTacYear4} name="CoreTacYear4" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>

                                    </ul>


                                    <ul className="manualForm d-flex flex-wrap">


                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAdate5</Label>
                                                <DatePicker selected={this.state.allDates.CoreTacDate5} name="CoreTacDate5" onChange={(date) => this.handleChangeDate(date, "CoreTacDate5")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAtitle5</Label>
                                                <Input type="text" value={this.state.CoreTacTitle5} name="CoreTacTitle5" onChange={this.handleChange} className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn23">
                                            <FormGroup>
                                                <Label>AAURL5</Label>
                                                <Input type="text" value={this.state.CoreTacURL5} name="CoreTacURL5" onChange={this.handleChange} className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYTD5</Label>
                                                <Input type="text" value={this.state.CoreTacYTD5} name="CoreTacYTD5" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>AAYear5</Label>
                                                <Input type="text" value={this.state.CoreTacYear5} name="CoreTacYear5" onChange={this.handleChange} className="formInput" placeholder="Enter Number" />
                                            </FormGroup>
                                        </li>

                                    </ul>



                                    {this.state.errors.ocioTacticalModelError && (
                                        <div className="errorMsg">{this.state.errors.ocioTacticalModelError}</div>
                                    )}


                                </div> */}



                                <div className="mainHd">White Label - Resources</div>

                                <div className="mb-3">

                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label> Htitle1</Label>
                                                <Input type="text" value={this.state.whiteLabelResourceTitle1} onChange={this.handleChange} name="whiteLabelResourceTitle1" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label> HURL1</Label>
                                                <Input type="text" value={this.state.whiteLabelresourceUrl1} onChange={this.handleChange} name="whiteLabelresourceUrl1" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label> Htitle2</Label>
                                                <Input type="text" value={this.state.whiteLabelResourceTitle2} onChange={this.handleChange} name="whiteLabelResourceTitle2" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label> HURL2</Label>
                                                <Input type="text" value={this.state.whiteLabelresourceUrl2} onChange={this.handleChange} name="whiteLabelresourceUrl2" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label> Htitle3</Label>
                                                <Input type="text" value={this.state.whiteLabelResourceTitle3} onChange={this.handleChange} name="whiteLabelResourceTitle3" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label> HURL3</Label>
                                                <Input type="text" value={this.state.whiteLabelresourceUrl3} onChange={this.handleChange} name="whiteLabelresourceUrl3" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>




                                    {this.state.errors.whiteLabelResourcesError && (
                                        <div className="errorMsg">{this.state.errors.whiteLabelResourcesError}</div>
                                    )}

                                </div>


                                <div className="mainHd">White Label - Monthly Letter</div>

                                <div className="mb-3">

                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> MLdate1</Label>
                                                <DatePicker selected={this.state.allDates.monthlyLetterDate1} name="monthlyLetterDate1" onChange={(date) => this.handleChangeDate(date, "monthlyLetterDate1")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> MLtitle1</Label>
                                                <Input type="text" value={this.state.mlTitle1} onChange={this.handleChange} name="mlTitle1" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> MLdoc1</Label>
                                                <Input type="text" value={this.state.mlDoc1} onChange={this.handleChange} name="mlDoc1" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> MLChart1</Label>
                                                <Input type="text" value={this.state.mlChart1} onChange={this.handleChange} name="mlChart1" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li >
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> MLsupport1</Label>
                                                <Input type="text" value={this.state.mlSupport1} onChange={this.handleChange} name="mlSupport1" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> MLdate2</Label>
                                                <DatePicker selected={this.state.allDates.monthlyLetterDate2} name="monthlyLetterDate2" onChange={(date) => this.handleChangeDate(date, "monthlyLetterDate2")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> MLtitle2</Label>
                                                <Input type="text" value={this.state.mlTitle2} onChange={this.handleChange} name="mlTitle2" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> MLdoc2</Label>
                                                <Input type="text" value={this.state.mlDoc2} onChange={this.handleChange} name="mlDoc2" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> MLchart2</Label>
                                                <Input type="text" value={this.state.mlChart2} onChange={this.handleChange} name="mlChart2" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li >
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> MLsupport2 </Label>
                                                <Input type="text" value={this.state.mlSupport2} onChange={this.handleChange} name="mlSupport2" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> MLdate3</Label>
                                                <DatePicker selected={this.state.allDates.monthlyLetterDate3} name="monthlyLetterDate3" onChange={(date) => this.handleChangeDate(date, "monthlyLetterDate3")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> MLtitle3</Label>
                                                <Input type="text" value={this.state.mlTitle3} onChange={this.handleChange} name="mlTitle3" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>MLdoc3</Label>
                                                <Input type="text" value={this.state.mlDoc3} onChange={this.handleChange} name="mlDoc3" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> MLChart3</Label>
                                                <Input type="text" value={this.state.mlChart3} onChange={this.handleChange} name="mlChart3" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li >
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> MLsupport3</Label>
                                                <Input type="text" value={this.state.mlSupport3} onChange={this.handleChange} name="mlSupport3" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>

                                    {this.state.errors.whiteLabelMonthlyLetterError && (
                                        <div className="errorMsg">{this.state.errors.whiteLabelMonthlyLetterError}</div>
                                    )}

                                </div>

                                <div className="mainHd">White Label - Quarterly Letter</div>


                                <div className="mb-3">



                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>QLdate1</Label>
                                                <DatePicker selected={this.state.allDates.quarterlyLetterDate1} name="quarterlyLetterDate1" onChange={(date) => this.handleChangeDate(date, "quarterlyLetterDate1")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>QLtitle1</Label>
                                                <Input type="text" value={this.state.qlTitle1} onChange={this.handleChange} name="qlTitle1" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> QLdoc1</Label>
                                                <Input type="text" value={this.state.qlDoc1} onChange={this.handleChange} name="qlDoc1" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> QLchart1</Label>
                                                <Input type="text" value={this.state.qlChart1} onChange={this.handleChange} name="qlChart1" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> QLsupport1</Label>
                                                <Input type="text" value={this.state.qlSupport1} onChange={this.handleChange} name="qlSupport1" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>



                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>QLdate2</Label>
                                                <DatePicker selected={this.state.allDates.quarterlyLetterDate2} name="quarterlyLetterDate2" onChange={(date) => this.handleChangeDate(date, "quarterlyLetterDate2")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>QLtitle2</Label>
                                                <Input type="text" value={this.state.qlTitle2} onChange={this.handleChange} name="qlTitle2" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> QLdoc2</Label>
                                                <Input type="text" value={this.state.qlDoc2} onChange={this.handleChange} name="qlDoc2" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> QLchart2</Label>
                                                <Input type="text" value={this.state.qlChart2} onChange={this.handleChange} name="qlChart2" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> QLsupport2</Label>
                                                <Input type="text" value={this.state.qlSupport2} onChange={this.handleChange} name="qlSupport2" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>QLdate3</Label>
                                                <DatePicker name="quarterlyLetterDate3" selected={this.state.allDates.quarterlyLetterDate3} onChange={(date) => this.handleChangeDate(date, "quarterlyLetterDate3")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> QLtitle3</Label>
                                                <Input type="text" value={this.state.qlTitle3} onChange={this.handleChange} name="qlTitle3" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> QLdoc3</Label>
                                                <Input type="text" value={this.state.qlDoc3} onChange={this.handleChange} name="qlDoc3" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> QLchart3</Label>
                                                <Input type="text" value={this.state.qlChart3} onChange={this.handleChange} name="qlChart3" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> QLsupport3</Label>
                                                <Input type="text" value={this.state.qlSupport3} onChange={this.handleChange} name="qlSupport3" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>



                                    {this.state.errors.whiteLabelQuarterlyLetterError && (
                                        <div className="errorMsg">{this.state.errors.whiteLabelQuarterlyLetterError}</div>
                                    )}



                                </div>
                                <div className="mainHd">White Label - Market Talking Points</div>



                                <div className="mb-3">
                                    <Input type="hidden" value={this.state.whiteLabelMarketTalkingPointDataList._id} name="quartlyMarketLetterId" />


                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup className="coumn18">
                                                <Label> MTPdate1</Label>
                                                <DatePicker selected={this.state.allDates.talkingDate1} name="talkingDate1" onChange={(date) => this.handleChangeDate(date, "talkingDate1")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>MTPtitle1</Label>
                                                <Input type="text" value={this.state.mtpTitle1} onChange={this.handleChange} name="mtpTitle1" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>MTPurl1</Label>
                                                <Input type="text" value={this.state.mtpUrl1} onChange={this.handleChange} name="mtpUrl1" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>MTPdate2</Label>
                                                <DatePicker selected={this.state.allDates.talkingDate2} name="talkingDate2" onChange={(date) => this.handleChangeDate(date, "talkingDate2")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>MTPtitle2</Label>
                                                <Input type="text" value={this.state.mtpTitle2} onChange={this.handleChange} name="mtpTitle2" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>MTPurl2</Label>
                                                <Input type="text" value={this.state.mtpUrl2} onChange={this.handleChange} name="mtpUrl2" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>MTPdate3</Label>
                                                <DatePicker selected={this.state.allDates.talkingDate3} name="talkingDate3" onChange={(date) => this.handleChangeDate(date, "talkingDate3")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>MTPtitle3</Label>
                                                <Input type="text" value={this.state.mtpTitle3} onChange={this.handleChange} name="mtpTitle3" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>MTPurl3</Label>
                                                <Input type="text" value={this.state.mtpUrl3} onChange={this.handleChange} name="mtpUrl3" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    {this.state.errors.whiteLabelTalkingError && (
                                        <div className="errorMsg">{this.state.errors.whiteLabelTalkingError}</div>
                                    )}
                                </div>

                                <div className="mainHd">White Label - Quarterly Market Perspective </div>

                                <div className="mb-3">
                                    <Input type="hidden" value={this.state.whiteLevelQuartlyMarketDataList._id} name="quartlyMarketLetterId" />
                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> QMPdate1</Label>
                                                <DatePicker selected={this.state.allDates.perspectiveDate1} name="perspectiveDate1" onChange={(date) => this.handleChangeDate(date, "perspectiveDate1")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>

                                        <li>
                                            <FormGroup>
                                                <Label>QMPtitle1</Label>
                                                <Input type="text" value={this.state.qmpTitle1} onChange={this.handleChange} name="qmpTitle1" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label> QMPppt1</Label>
                                                <Input type="text" value={this.state.qmpPpt1} onChange={this.handleChange} name="qmpPpt1" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> QMPdate2</Label>
                                                <DatePicker selected={this.state.allDates.perspectiveDate2} name="perspectiveDate2" onChange={(date) => this.handleChangeDate(date, "perspectiveDate2")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>

                                        <li>
                                            <FormGroup>
                                                <Label>QMPtitle2</Label>
                                                <Input type="text" value={this.state.qmpTitle2} onChange={this.handleChange} name="qmpTitle2" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label> QMPppt2</Label>
                                                <Input type="text" value={this.state.qmpPpt2} onChange={this.handleChange} name="qmpPpt2" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> QMPdate3</Label>
                                                <DatePicker selected={this.state.allDates.perspectiveDate3} name="perspectiveDate3" onChange={(date) => this.handleChangeDate(date, "perspectiveDate3")} placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>

                                        <li>
                                            <FormGroup>
                                                <Label> QMPtitle3</Label>
                                                <Input type="text" value={this.state.qmpTitle3} onChange={this.handleChange} name="qmpTitle3" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label> QMPppt3</Label>
                                                <Input type="text" value={this.state.qmpPpt3} onChange={this.handleChange} name="qmpPpt3" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>


                                    {this.state.errors.whiteLabelPerspectiveError && (
                                        <div className="errorMsg">{this.state.errors.whiteLabelPerspectiveError}</div>
                                    )}
                                </div>


                                <div className="mainHd">White Label -Chart Of The Month </div>


                                <div className="mb-3">


                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup className="coumn18">
                                                <Label> COTMdate1</Label>
                                                <DatePicker selected={this.state.allDates.chartDate1} name="chartDate1" onChange={(date) => this.handleChangeDate(date, "chartDate1")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>COTMtitle1</Label>
                                                <Input type="text" value={this.state.cotmTitle1} onChange={this.handleChange} name="cotmTitle1" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label> COTMdoc1</Label>
                                                <Input type="text" value={this.state.cotmDoc1} onChange={this.handleChange} name="cotmDoc1" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> COTMdate2</Label>
                                                <DatePicker selected={this.state.allDates.chartDate2} name="chartDate2" onChange={(date) => this.handleChangeDate(date, "chartDate2")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>COTMtitle2</Label>
                                                <Input type="text" value={this.state.cotmTitle2} onChange={this.handleChange} name="cotmTitle2" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label> COTMdoc2</Label>
                                                <Input type="text" value={this.state.cotmDoc2} onChange={this.handleChange} name="cotmDoc2" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> COTMdate3</Label>
                                                <DatePicker selected={this.state.allDates.chartDate3} name="chartDate3" onChange={(date) => this.handleChangeDate(date, "chartDate3")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label> COTMtitle3</Label>
                                                <Input type="text" value={this.state.cotmTitle3} onChange={this.handleChange} name="cotmTitle3" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label> COTMdoc3</Label>
                                                <Input type="text" value={this.state.cotmDoc3} onChange={this.handleChange} name="cotmDoc3" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> COTMdate4</Label>
                                                <DatePicker selected={this.state.allDates.chartDate4} name="chartDate4" onChange={(date) => this.handleChangeDate(date, "chartDate4")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>COTMtitle4</Label>
                                                <Input type="text" value={this.state.cotmTitle4} onChange={this.handleChange} name="cotmTitle4" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label> COTMdoc4</Label>
                                                <Input type="text" value={this.state.cotmDoc4} onChange={this.handleChange} name="cotmDoc4" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> COTMdate5</Label>
                                                <DatePicker selected={this.state.allDates.chartDate5} name="chartDate5" onChange={(date) => this.handleChangeDate(date, "chartDate5")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>COTMtitle5</Label>
                                                <Input type="text" value={this.state.cotmTitle5} onChange={this.handleChange} name="cotmTitle5" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>COTMdoc5</Label>
                                                <Input type="text" value={this.state.cotmDoc5} onChange={this.handleChange} name="cotmDoc5" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup >
                                                <Label> COTMdate6</Label>
                                                <DatePicker selected={this.state.allDates.chartDate6} name="chartDate6" onChange={(date) => this.handleChangeDate(date, "chartDate6")} placeholderText="Select Date" className="formInput form-control" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label> COTMtitle6</Label>
                                                <Input type="text" value={this.state.cotmTitle6} onChange={this.handleChange} name="cotmTitle6" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>  COTMdoc6</Label>
                                                <Input type="text" value={this.state.cotmDoc6} onChange={this.handleChange} name="cotmDoc6" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>

                                    {this.state.errors.whiteLabelChartsError && (
                                        <div className="errorMsg">{this.state.errors.whiteLabelChartsError}</div>
                                    )}

                                </div>



                                <div className="mainHd">White Label - Off The Shelf Presentations </div>


                                <div className="mb-3">
                                    <Input type="hidden" value={this.state.whiteLevelOffShelfDataList._id} name="offshelfId" />
                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>  OSPdate1</Label>
                                                <DatePicker selected={this.state.allDates.shelfdate1} name="shelfdate1" onChange={(date) => this.handleChangeDate(date, "shelfdate1")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label> OSPtitle1</Label>
                                                <Input type="text" value={this.state.ospTitle1} onChange={this.handleChange} name="ospTitle1" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>  OSPdoc1</Label>
                                                <Input type="text" value={this.state.ospDoc1} onChange={this.handleChange} name="ospDoc1" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>  OSPdate2</Label>
                                                <DatePicker selected={this.state.allDates.shelfdate2} name="shelfdate2" onChange={(date) => this.handleChangeDate(date, "shelfdate2")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label> OSPtitle2</Label>
                                                <Input type="text" value={this.state.ospTitle2} onChange={this.handleChange} name="ospTitle2" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>  OSPdoc2</Label>
                                                <Input type="text" value={this.state.ospDoc2} onChange={this.handleChange} name="ospDoc2" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label>  OSPdate3</Label>
                                                <DatePicker selected={this.state.allDates.shelfdate3} name="shelfdate3" onChange={(date) => this.handleChangeDate(date, "shelfdate3")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label> OSPtitle3</Label>
                                                <Input type="text" value={this.state.ospTitle3} onChange={this.handleChange} name="ospTitle3" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>  OSPdoc3</Label>
                                                <Input type="text" value={this.state.ospDoc3} onChange={this.handleChange} name="ospDoc3" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>

                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">

                                        <li className="coumn18">
                                            <FormGroup>
                                                <Label> OSPdate4</Label>
                                                <DatePicker selected={this.state.allDates.shelfdate4} name="shelfdate4" onChange={(date) => this.handleChangeDate(date, "shelfdate4")} placeholderText="Select Date" className="formInput form-control" />


                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label> OSPtitle4</Label>
                                                <Input type="text" value={this.state.ospTitle4} onChange={this.handleChange} name="ospTitle4" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>  OSPdoc4</Label>
                                                <Input type="text" value={this.state.ospDoc4} onChange={this.handleChange} name="ospDoc4" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>



                                    </ul>

                                    {this.state.errors.whiteLabelShelfPresentationsError && (
                                        <div className="errorMsg">{this.state.errors.whiteLabelShelfPresentationsError}</div>
                                    )}




                                </div>


                                <div className="mainHd">Trial Days Left Page</div>

                                <div className="mb-3">


                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>TrialTitle1</Label>
                                                <Input type="text" value={this.state.trialTitle1} onChange={this.handleChange} name="trialTitle1" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>TrialURL1</Label>
                                                <Input type="text" value={this.state.trialURL1} onChange={this.handleChange} name="trialURL1" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>TrialTitle2</Label>
                                                <Input type="text" value={this.state.trialTitle2} onChange={this.handleChange} name="trialTitle2" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>TrialURL2</Label>
                                                <Input type="text" value={this.state.trialURL2} onChange={this.handleChange} name="trialURL2" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>
                                    <ul className="manualForm d-flex flex-wrap">
                                        <li>
                                            <FormGroup>
                                                <Label>TrialTitle3</Label>
                                                <Input type="text" value={this.state.trialTitle3} onChange={this.handleChange} name="trialTitle3" className="formInput" placeholder="Enter Text" />
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <FormGroup>
                                                <Label>TrialURL3</Label>
                                                <Input type="text" value={this.state.trialURL3} onChange={this.handleChange} name="trialURL3" className="formInput" placeholder="Paste URL" />
                                            </FormGroup>
                                        </li>
                                    </ul>


                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="fullWidth">
                                            <FormGroup>
                                                <Label>TrialPara</Label>
                                                <ReactQuill value={this.state.trialText}
                                                    onChange={this.handleChangeTrialPeragraph}
                                                    name="trialText"
                                                    className="formInput textarea"
                                                />


                                            </FormGroup>
                                        </li>
                                    </ul>

                                    {this.state.errors.trialDaysError && (
                                        <div className="errorMsg">{this.state.errors.trialDaysError}</div>
                                    )}
                                </div>

                                <div className="mb-3">

                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="fullWidth">
                                            <FormGroup>
                                                {/* <Label>OCIO Paragraph</Label> */}
                                                <Label>Model Paragraph</Label>
                                                <Input type="textarea" value={this.state.OCIOParagraph} onChange={this.handleChange} name="OCIOParagraph" className="textarea" />
                                            </FormGroup>
                                        </li>
                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="fullWidth">
                                            <FormGroup>
                                                <Label>White Label Insights Paragraph</Label>
                                                <Input type="textarea" value={this.state.whiteLabelInsightsParagraph} onChange={this.handleChange} name="whiteLabelInsightsParagraph" className="textarea" />
                                            </FormGroup>
                                        </li>
                                    </ul>

                                    <ul className="manualForm d-flex flex-wrap">
                                        <li className="fullWidth">
                                            <FormGroup>
                                                <Label>Terms & Conditions</Label>
                                                <ReactQuill value={this.state.tAndC}
                                                    onChange={this.handleChangeTermsCondition}
                                                    name="termAndConditionPeragraph"
                                                    className="formInput textarea"
                                                />
                                            </FormGroup>
                                        </li>
                                    </ul>
                                </div>



                                <div><Button className="formbtn">Submit</Button></div>
                            </div>

                        </div>

                    </Form>

                </div>
                <Footer />
            </div>
        );
    }
}
export default ManualLinks;