import React, { Component } from 'react';
import './TrialDaysLeft.scss';
import { TopLinks } from '../../component/TopLinks/TopLinks';
import Header from '../../component/headerWithoutLoginPopup/Header';
import { Footer } from '../../component/Footer/Footer';
import combinedServices from "../../shared/services/user-service";
import getLocalData from "../../shared/localStorage/getData";


export class TrialDaysLeft extends Component {


    constructor(props) {
    super(props);
        this.state = {

            trialDayStatus:false,
            trialDayResponseData :'',
            trialDays:0
            

        }
       
    }

    componentDidMount=async()=> { 

        let user_info = getLocalData("user_info", "all");
        const data = {
        userId:user_info.userData.userId
        };

        let trialDayResponse =  await combinedServices.gettrialDayLeftData();    
        if(trialDayResponse.responseCode = 200 ){

                this.setState({
                    trialDayResponseData :trialDayResponse.data,
                    trialDayStatus : true
                })  
       }else{

                this.setState({
               
                trialDayStatus : false
                });



        }

    }



    render() {

        return (

            <div>
            <Header />
                <TopLinks />
                <div className="container miidlePart">

                 {this.state.trialDayStatus && (
                    <div className="row">
                        <div className="col-12 col-lg-8 pt-4 pb-0 pb-lg-4">
                        <div className="mainHd">Your MarketDesk Trial</div>
                            <div className="contentBox">
                            <p dangerouslySetInnerHTML= {{__html:this.state.trialDayResponseData.trialText}}/>
                                
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 pt-4 pb-4">
                            <div>
                                <div className="blueBgHd blueBgHd">Trial Resources</div>
                                <div className="infoBox">
                                    <ul className="resourcesLinks ocioRes">
                                        <li><a target="_blank" href={this.state.trialDayResponseData.trialURL1}>{this.state.trialDayResponseData.trialTitle1}</a></li>
                                        <li><a target="_blank" href={this.state.trialDayResponseData.trialURL2}>{this.state.trialDayResponseData.trialTitle2}</a></li>
                                        <li><a target="_blank" href={this.state.trialDayResponseData.trialURL3}>{this.state.trialDayResponseData.trialTitle3}</a></li>
                                       
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
                <Footer/>
            </div>
        );
    }
}
export default TrialDaysLeft;