import React, { Component } from 'react';
import './ReportPage.scss';
import { TopLinks } from '../../component/TopLinks/TopLinks';
import pdfImg from '../../assets/images/pdf_img.jpg';
import notificationRound from '../../assets/images/notificatio_round.png';
import { Spinner } from 'reactstrap';
import notification_ro from '../../assets/images/notification_ro.png';
import filterRound from '../../assets/images/filter_round.png';
import filterRo from '../../assets/images/filter_round_new.png';
import bookmarkRo from '../../assets/images/filter_ro.png';
import emailRound from '../../assets/images/email_round.png';
import printRound from '../../assets/images/print_round.png';
import view from '../../assets/images/view.png';
import filter from '../../assets/images/filter.png';
import Header from '../../component/headerWithoutLoginPopup/Header';
import { Footer } from '../../component/Footer/Footer';
import history from "../../shared/history";
import moment from 'moment';
import view_over from '../../assets/images/view_over.png';
import combinedServices from "../../shared/services/user-service";
import getLocalData from "../../shared/localStorage/getData";

export class ReportPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reportListData: [],
            reportDetail: [],
            reportStatus: false,
            researchCategory: [],
            assetClass: [],
            reportType: [],
            geography: [],
            frequency: [],
            userId: '',
            userStatus: '',
            bookMarkCountStatus: true,
            isLoading: true,


        }



    }

    getReportDetails = async () => {

        const yourParamName = history.location.pathname;
        const youradminUrl = yourParamName.split('/');
        const userIsLogin = getLocalData("user_info", "isLogin");
        if (youradminUrl[2] != '') {


            if (userIsLogin) {
                var userData = {};
                var user_info = getLocalData("user_info", "all");
                this.setState({ userId: user_info.userData.userId, reportId: youradminUrl[2] });

                userData.email = user_info.userData.userEmail;
                userData.userId = user_info.userData.userId;

                let userProfileResponse = await combinedServices.getUserProfile(userData);
                console.log("userProfileResponse", userProfileResponse)
                var userProfileDetailsData = userProfileResponse.data;
                var userDetails = userProfileDetailsData.user;

                if (userDetails) {
                    this.setState({ userStatus: userDetails.status });
                }

                var data = {
                    reportId: youradminUrl[2],
                    userId: user_info.userData.userId
                }


                let reportDetailById = await combinedServices.getUserReportListById(data);


                this.setState({
                    reportDetail: reportDetailById.data,
                    reportStatus: reportDetailById.status,
                    isLoading: false
                });

            }

        }

        this.handlefilterWithCategory();

    }
    componentDidMount = () => {
        this.getReportDetails();
    }



    handlefilterWithCategory = async () => {
        var data = {};
        var data1 = {};

        if (this.state.reportDetail.researchCategory.length != 0) {

            data.researchCat = this.state.reportDetail.researchCategory;
        }

        if (this.state.reportDetail.assestClass.length != 0) {


            data.assetCls = this.state.reportDetail.assetClass;



        }

        if (this.state.reportDetail.reportType.length != 0) {


            data.reportType = this.state.reportDetail.reportType;




        }

        if (this.state.reportDetail.geography.length != 0) {


            data.geography = this.state.reportDetail.geography;



        }

        if (this.state.reportDetail.frequency.length != 0) {


            data.frequency = this.state.reportDetail.frequency;



        }

        data.page = 1;
        data.userId = this.state.userId;
        data1.reportId = this.state.reportId;
        data1.userId = this.state.userId;

        let bookMarkNotification = await combinedServices.getBookmarkNotification(data);

        if (bookMarkNotification.status) {
            this.setState({
                countBookmarks: bookMarkNotification.data,
                bookMarkCountStatus: bookMarkNotification.status

            });


        } else {
            this.setState({
                countBookmarks: 0,
                bookMarkCountStatus: bookMarkNotification.status

            });


        }



        let reportList = await combinedServices.getCategoryReportForUser(data);

        if (reportList.status) {

            this.setState({
                reportListData: reportList.data,
                totalReports: reportList.totalReport,
                status: reportList.status,

            });



        } else {

            this.setState({
                status: reportList.status,
                errorMessage: reportList.msg


            });



        }


    }

    bookmarkReadOnClick = async (event) => {


        let userIsLogin = getLocalData("user_info", "isLogin");

        if (userIsLogin) {


            let reportId = event.currentTarget.dataset.report_id;
            let report_bookmarks = event.currentTarget.dataset.report_bookmarks;
            let report_bookmarknotify = event.currentTarget.dataset.report_bookmarknotify;

            let user_info = getLocalData("user_info", "all");
            let userId = user_info.userData.userId;
            var bookmarkData = {
                userId: userId,
                reportId: reportId,
                bookmark: report_bookmarks,
                isBookmarkNotify: report_bookmarknotify

            }

            let bookmarkResponse = await combinedServices.saveUserReportBookmarked(bookmarkData);
            this.setState({ bookMarkCountStatus: !this.state.bookMarkCountStatus })
            this.handlefilterWithCategory();
            this.getReportDetails();


        }


    }


    watchlistReadOnClick = async (event) => {


        let userIsLogin = getLocalData("user_info", "isLogin");

        if (userIsLogin) {


            let reportId = event.currentTarget.dataset.report_id;
            let watchlist = event.currentTarget.dataset.report_watchlist;

            let user_info = getLocalData("user_info", "all");
            let userId = user_info.userData.userId;

            var watchlistData = {
                userId: userId,
                reportId: reportId,
                watchlist: watchlist

            }


            let watchlistResponse = await combinedServices.saveUserReporWatchlist(watchlistData);

            this.handlefilterWithCategory();
            this.getReportDetails();

        }


    }




    handlePrint = (event) => {
        let pdfUrl = this.state.reportDetail.reportPDFURL;
        event.preventDefault();
        window.open(pdfUrl, "_blank", "height=400,width=600");
    };



    reportButtonHtml = () => {
        if (this.state.userStatus == "QUANT PACK" && this.state.reportDetail && Array.isArray(this.state.reportDetail.reportType)) {
            var indexOfQuantitative = this.state.reportDetail.reportType.indexOf("Quantitative");
            var hasQuantitative = indexOfQuantitative !== -1;
            var quantitativeValue = hasQuantitative ? this.state.reportDetail.reportType[indexOfQuantitative] : null;
            if (quantitativeValue) {
                return (

                    <ul className="reportBtns d-flex flex-wrap">
                        <li><a target="_blank" href={this.state.reportDetail.buttonURL1}>{this.state.reportDetail.buttonText1}</a></li>
                        {this.state.reportDetail.buttonText2 ? (
                            <li><a target="_blank" href={this.state.reportDetail.buttonURL2}>{this.state.reportDetail.buttonText2}</a></li>
                        ) : ''}
                        {this.state.reportDetail.buttonText3 ? (
                            <li><a target="_blank" href={this.state.reportDetail.buttonURL3}>{this.state.reportDetail.buttonText3}</a></li>
                        ) : ''}
                    </ul>

                )
            }

        }
        var reportResearchCategory = [];
        reportResearchCategory = new Set(this.state.reportDetail.researchCategory);

        if (reportResearchCategory.has("OCIO")) {
            if (this.state.userStatus == "QUANT PACK" && this.state.reportDetail && Array.isArray(this.state.reportDetail.reportType)) {
                var indexOfQuantitative = this.state.reportDetail.reportType.indexOf("Quantitative");
                var hasQuantitative = indexOfQuantitative !== -1;
                var quantitativeValue = hasQuantitative ? this.state.reportDetail.reportType[indexOfQuantitative] : null;
                if (quantitativeValue) {
                    return (
    
                        <ul className="reportBtns d-flex flex-wrap">
                            <li><a target="_blank" href={this.state.reportDetail.buttonURL1}>{this.state.reportDetail.buttonText1}</a></li>
                            {this.state.reportDetail.buttonText2 ? (
                                <li><a target="_blank" href={this.state.reportDetail.buttonURL2}>{this.state.reportDetail.buttonText2}</a></li>
                            ) : ''}
                            {this.state.reportDetail.buttonText3 ? (
                                <li><a target="_blank" href={this.state.reportDetail.buttonURL3}>{this.state.reportDetail.buttonText3}</a></li>
                            ) : ''}
                        </ul>
    
                    )
                }
    
            }

            if (this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" ) {

                return (

                    <ul className="reportBtns d-flex flex-wrap">
                        <li><a target="_blank" href={this.state.reportDetail.buttonURL1}>{this.state.reportDetail.buttonText1}</a></li>
                        {this.state.reportDetail.buttonText2 ? (
                            <li><a target="_blank" href={this.state.reportDetail.buttonURL2}>{this.state.reportDetail.buttonText2}</a></li>
                        ) : ''}
                        {this.state.reportDetail.buttonText3 ? (
                            <li><a target="_blank" href={this.state.reportDetail.buttonURL3}>{this.state.reportDetail.buttonText3}</a></li>
                        ) : ''}
                    </ul>

                )



            }

        } else {

            if (this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" || this.state.userStatus == "RESEARCH" || (this.state.userStatus == "QUANT PACK" && this.state.reportDetail.reportType == "Quantitative")) {

                return (

                    <ul className="reportBtns d-flex flex-wrap">
                        <li><a target="_blank" href={this.state.reportDetail.buttonURL1}>{this.state.reportDetail.buttonText1}</a></li>
                        {this.state.reportDetail.buttonText2 ? (
                            <li><a target="_blank" href={this.state.reportDetail.buttonURL2}>{this.state.reportDetail.buttonText2}</a></li>
                        ) : ''}
                        {this.state.reportDetail.buttonText3 ? (
                            <li><a target="_blank" href={this.state.reportDetail.buttonURL3}>{this.state.reportDetail.buttonText3}</a></li>
                        ) : ''}
                    </ul>

                )



            }



        }


    }





    // reportSummaryHtml = () => {

    //     var reportResearchCategory = [];
    //     reportResearchCategory = new Set(this.state.reportDetail.researchCategory);

    //     if (reportResearchCategory.has("OCIO")) {

    //         if (this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO") {
    //             var reportPera = this.state.reportDetail.reportParagraph;

    //             return (
    //                 <>
    //                     <div className="reportHd">Report Summary</div>
    //                     <div className="contentBox pb-2 rp_li_style">

    //                         <p dangerouslySetInnerHTML={{ __html: this.state.reportDetail.reportParagraph }} />
    //                     </div>
    //                 </>


    //             )
    //         } else if (this.state.userStatus == "WHITE LABEL" || this.state.userStatus == "RESEARCH" || this.state.userStatus == "QUANT PACK" || this.state.userStatus == "WL PLUS") {
    //             return (

    //                 <>
    //                     <div className="reportHd">Report Summary</div>
    //                     <div className="contentBox pb-2 rp_li_style">
    //                         <p>Your subscription level does not provide access to this report. Please email support@marketdeskresearch.com.</p>
    //                     </div>
    //                 </>


    //             )

    //         }

    //     } else {


    //         if (this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" || this.state.userStatus == "RESEARCH") {
    //             var reportPera = this.state.reportDetail.reportParagraph;


    //             return (
    //                 <>
    //                     <div className="reportHd">Report Summary</div>
    //                     <div className="contentBox pb-2 rp_li_style" >

    //                         <p dangerouslySetInnerHTML={{ __html: this.state.reportDetail.reportParagraph }} />
    //                     </div>
    //                 </>

    //             )



    //         } else if (this.state.userStatus === "WHITE LABEL") {
    //             return (
    //                 <>
    //                     <div className="reportHd">Report Summary</div>
    //                     <div className="contentBox pb-2 rp_li_style">
    //                         <p>Your subscription level does not provide access to this report. Please email support@marketdeskresearch.com.</p>
    //                     </div>
    //                 </>
    //             )

    //         }



    //     }


    // }
    reportSummaryHtml = () => {
        if (this.state.userStatus == "QUANT PACK" && this.state.reportDetail && Array.isArray(this.state.reportDetail.reportType)) {
            var indexOfQuantitative = this.state.reportDetail.reportType.indexOf("Quantitative");
            var hasQuantitative = indexOfQuantitative !== -1;
            var quantitativeValue = hasQuantitative ? this.state.reportDetail.reportType[indexOfQuantitative] : null;
            console.log("Quantitative Value 438:", quantitativeValue);
            if (quantitativeValue) {
                return (
                    <>
                        <div className="reportHd">Report Summary</div>
                        <div className="contentBox pb-2 rp_li_style">
                            <p dangerouslySetInnerHTML={{ __html: this.state.reportDetail.reportParagraph }} />
                        </div>
                    </>
                );
            }

        }
        
        var reportResearchCategory = new Set(this.state.reportDetail.researchCategory);


        if (reportResearchCategory.has("OCIO")) {
            if (this.state.userStatus === "TRIAL USER" || this.state.userStatus === "OCIO") {
                return (
                    <>
                        <div className="reportHd">Report Summary</div>
                        <div className="contentBox pb-2 rp_li_style">
                            <p dangerouslySetInnerHTML={{ __html: this.state.reportDetail.reportParagraph }} />
                        </div>
                    </>
                );
            } else if (
                this.state.userStatus === "WHITE LABEL" ||
                this.state.userStatus === "RESEARCH" ||
                this.state.userStatus === "QUANT PACK" ||
                this.state.userStatus === "WL PLUS"
            ) {
                console.log("469", this.state.userStatus,)
                return (
                    <>
                        <div className="reportHd">Report Summary</div>
                        <div className="contentBox pb-2 rp_li_style">
                            <p>Your subscription level does  not provide access to this report. Please email support@marketdeskresearch.com.</p>
                        </div>
                    </>
                );
            }
        } else {
            if (
                this.state.userStatus === "TRIAL USER" ||
                this.state.userStatus === "OCIO" ||
                this.state.userStatus === "RESEARCH"
            ) {
                console.log("449", this.state.userStatus,)
                return (
                    <>
                        <div className="reportHd">Report Summary</div>
                        <div className="contentBox pb-2 rp_li_style">
                            <p dangerouslySetInnerHTML={{ __html: this.state.reportDetail.reportParagraph }} />
                        </div>
                    </>
                );
            } else if (this.state.userStatus === "WHITE LABEL" || this.state.userStatus === "QUANT PACK" || this.state.userStatus === "WL PLUS") {
                console.log("498", this.state.userStatus,)
                return (
                    <>
                        <div className="reportHd">Report Summary</div>
                        <div className="contentBox pb-2 rp_li_style">
                            <p>Your subscription level does not provide access to this report. Please email support@marketdeskresearch.com.</p>
                        </div>
                    </>
                );
            }
        }
    };


    watchlistUpdatesHtml = () => {
        if (this.state.userStatus == "QUANT PACK" && this.state.reportDetail && Array.isArray(this.state.reportDetail.reportType)) {
            var indexOfQuantitative = this.state.reportDetail.reportType.indexOf("Quantitative");
            var hasQuantitative = indexOfQuantitative !== -1;
            var quantitativeValue = hasQuantitative ? this.state.reportDetail.reportType[indexOfQuantitative] : null;
            if (quantitativeValue) {
                return (
                    <>
                        <div className="reportHd">Watchlist Updates</div>

                        <div className="contentBox pb-2 rp_li_style">

                            <p dangerouslySetInnerHTML={{ __html: this.state.reportDetail.watchlistUpdates }} />
                        </div>
                    </>


                )
            }

        }

        var reportResearchCategory = [];
        reportResearchCategory = new Set(this.state.reportDetail.researchCategory);
        if (reportResearchCategory.has("OCIO")) {
            if (this.state.userStatus == "QUANT PACK" && this.state.reportDetail && Array.isArray(this.state.reportDetail.reportType)) {
                var indexOfQuantitative = this.state.reportDetail.reportType.indexOf("Quantitative");
                var hasQuantitative = indexOfQuantitative !== -1;
                var quantitativeValue = hasQuantitative ? this.state.reportDetail.reportType[indexOfQuantitative] : null;
                if (quantitativeValue) {
                    return (
                        <>
                            <div className="reportHd">Watchlist Updates</div>
    
                            <div className="contentBox pb-2 rp_li_style">
    
                                <p dangerouslySetInnerHTML={{ __html: this.state.reportDetail.watchlistUpdates }} />
                            </div>
                        </>
    
    
                    )
                }
    
            }

            if (this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO") {
                return (
                    <>
                        <div className="reportHd">Watchlist Updates</div>

                        <div className="contentBox pb-2 rp_li_style">

                            <p dangerouslySetInnerHTML={{ __html: this.state.reportDetail.watchlistUpdates }} />
                        </div>
                    </>


                )
            } else if (this.state.userStatus == "WHITE LABEL" || this.state.userStatus == "RESEARCH" || this.state.userStatus == "QUANT PACK") {
                return (

                    <>
                        <div className="reportHd">Watchlist Updates</div>
                        <div className="contentBox pb-2">
                            <p>No updates at that time.</p>
                        </div>
                    </>


                )

            }

        } else {


            if (this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" || this.state.userStatus == "RESEARCH") {

                return (
                    <>
                        <div className="reportHd">Watchlist Updates</div>

                        <div className="contentBox pb-2">
                            <p dangerouslySetInnerHTML={{ __html: this.state.reportDetail.watchlistUpdates }} />
                        </div>
                    </>

                )



            } else if (this.state.userStatus === "WHITE LABEL") {
                return (
                    <>
                        <div className="reportHd">Watchlist Updates</div>
                        <div className="contentBox pb-2">
                            <p>No updates at that time.</p>
                        </div>
                    </>
                )

            }



        }


    }

    emailLinkHtml = () => {
        if (this.state.userStatus == "QUANT PACK" && this.state.reportDetail && Array.isArray(this.state.reportDetail.reportType)) {
            var indexOfQuantitative = this.state.reportDetail.reportType.indexOf("Quantitative");
            var hasQuantitative = indexOfQuantitative !== -1;
            var quantitativeValue = hasQuantitative ? this.state.reportDetail.reportType[indexOfQuantitative] : null;
            if (quantitativeValue) {
                return (
                    <>
                        <li><a taget="_blank" href={link}><img alt="" src={emailRound} /></a></li>
                    </>


                )
            }

        }

        var reportResearchCategory = [];
        reportResearchCategory = new Set(this.state.reportDetail.researchCategory);
        var link = "mailto:"
            + "?subject=MarketDesk: " + this.state.reportDetail.reportTitle
            + "&body=" + this.state.reportDetail.reportPDFURL;
        if (reportResearchCategory.has("OCIO")) {
            
            if (this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO") {
                return (

                    <>
                        <li><a taget="_blank" href={link}><img alt="" src={emailRound} /></a></li>
                    </>


                )
            }

        } else {


            if (this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" || this.state.userStatus == "RESEARCH") {

                return (
                    <>
                        <li><a taget="_blank" href={link} ><img alt="" src={emailRound} /></a></li>
                    </>

                )



            }



        }


    }
    printLinkHtml = () => {

        if (this.state.userStatus == "QUANT PACK" && this.state.reportDetail && Array.isArray(this.state.reportDetail.reportType)) {
            var indexOfQuantitative = this.state.reportDetail.reportType.indexOf("Quantitative");
            var hasQuantitative = indexOfQuantitative !== -1;
            var quantitativeValue = hasQuantitative ? this.state.reportDetail.reportType[indexOfQuantitative] : null;
            if (quantitativeValue) {
                return (
                    <>
                        <li><a target='_blank' href={this.state.reportDetail.reportPDFURL} ><img alt="" src={printRound} /></a></li>
                    </>


                )
            }

        }
        var reportResearchCategory = [];
        reportResearchCategory = new Set(this.state.reportDetail.researchCategory);
        if (reportResearchCategory.has("OCIO")) {

            

            if (this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO") {
                return (
                    <>
                        <li><a target='_blank' href={this.state.reportDetail.reportPDFURL} ><img alt="" src={printRound} /></a></li>
                    </>


                )
            }

        } else {


            if (this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" || this.state.userStatus == "RESEARCH") {

                return (
                    <>
                        <li><a target='_blank' href={this.state.reportDetail.reportPDFURL} ><img alt="" src={printRound} /></a></li>
                    </>

                )



            }



        }


    }

    showReportImageHtml = () => {
        if (this.state.userStatus == "QUANT PACK" && this.state.reportDetail && Array.isArray(this.state.reportDetail.reportType)) {
            var indexOfQuantitative = this.state.reportDetail.reportType.indexOf("Quantitative");
            var hasQuantitative = indexOfQuantitative !== -1;
            var quantitativeValue = hasQuantitative ? this.state.reportDetail.reportType[indexOfQuantitative] : null;
            if (quantitativeValue) {
                return (
                    <div>
                        <div className="blueBgHd">Report PDF</div>
                        <div className="pdfImg"><a target="_blank" href={this.state.reportDetail.reportPDFURL}><img src={this.state.reportDetail.reportPDFImgURL} /></a></div>

                    </div>
                )
            }

        }


        var reportResearchCategory = [];
        reportResearchCategory = new Set(this.state.reportDetail.researchCategory);
        if (reportResearchCategory.has("OCIO")) {


            if (this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO") {
                return (
                    <div>
                        <div className="blueBgHd">Report PDF</div>
                        <div className="pdfImg"><a target="_blank" href={this.state.reportDetail.reportPDFURL}><img src={this.state.reportDetail.reportPDFImgURL} /></a></div>

                    </div>
                )
            }



        } else {


            if (this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" || this.state.userStatus == "RESEARCH") {

                return (
                    <div>
                        <div>
                            <div className="blueBgHd">Report PDF</div>
                            <div className="pdfImg"><a target="_blank" href={this.state.reportDetail.reportPDFURL}><img src={this.state.reportDetail.reportPDFImgURL} /></a></div>

                        </div>
                    </div>

                )


            }



        }





    }





    render() {

        var watchlist = '';
        var bookmark = '';


        if (this.state.reportDetail.watchlist) {
            watchlist = <li><a data-report_id={this.state.reportDetail._id} data-report_watchlist={false} onClick={this.watchlistReadOnClick}><img alt="" src={notification_ro} /></a></li>
        } else {

            watchlist = <li><a data-report_id={this.state.reportDetail._id} data-report_watchlist={true} onClick={this.watchlistReadOnClick}><img alt="" src={notificationRound} /></a></li>;
        }


        if (this.state.reportDetail.bookmark) {
            bookmark = <li><a data-report_id={this.state.reportDetail._id} data-report_bookmarks={false} data-report_bookmarkNotify={false} onClick={this.bookmarkReadOnClick}><img alt="" src={filterRo} /></a></li>;
        } else {

            bookmark = <li><a data-report_id={this.state.reportDetail._id} data-report_bookmarks={true} data-report_bookmarkNotify={true} onClick={this.bookmarkReadOnClick}><img alt="" src={filterRound} /></a></li>;
        }


        return (

            <div>

                {this.state.bookMarkCountStatus ? <Header
                    bookmarkCount={this.state.countBookmarks}
                /> : <Header
                    bookmarkCount={this.state.countBookmarks}
                />}
                <TopLinks />
                <div className="container miidlePart">
                    <div className="row">
                        <div className="col-12 reportTop">
                            {this.state.reportDetail.publishedDate ? (
                                <div className="date">{moment(this.state.reportDetail.publishedDate).format("dddd, MMMM D , YYYY")}</div>
                            ) : ''}

                            <div className="mainHd">{this.state.reportDetail.reportTitle}</div>

                            <div className="contentBox">{this.state.reportDetail.reportSubTitle}</div>
                        </div>
                        <div className="col-12 col-lg-10 pt-4 pb-0 pb-lg-4">
                            <div className="reportBox">
                                <div className="reportIcons">
                                    <ul>
                                        {watchlist}

                                        {bookmark}


                                        {this.emailLinkHtml()}
                                        {this.printLinkHtml()}
                                    </ul>
                                </div>
                                {this.reportButtonHtml()}
                                {this.reportSummaryHtml()}
                                {this.watchlistUpdatesHtml()}


                                {/* <div className="reportHd">Related Research</div> */}
                                <div className="reportHd">Latest Research</div>
                                <div className="tableBox libTable">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Format</th>
                                                <th>Report Title</th>
                                                <th>Save</th>
                                            </tr>
                                        </thead>

                                        {this.state.status &&
                                            <tbody>

                                                {this.state.reportListData.map((Listvalue, index) =>
                                                    (Listvalue.reportDetails.reportTitle != this.state.reportDetail.reportTitle && index < 5) && (

                                                        <tr>
                                                            <td className="date">{moment(Listvalue.reportDetails.publishedDate).format('M/D/YYYY')}</td>
                                                            <td className="format">{Listvalue.reportDetails.reportFormat}</td>
                                                            <td className="report">
                                                                <span><a href={'/report-page/' + Listvalue.reportDetails._id}>{Listvalue.reportDetails.reportTitle}</a></span>
                                                                {Listvalue.reportDetails.reportSubTitle}
                                                            </td>

                                                            <td className="save">
                                                                <div className="action d-flex">
                                                                    {Listvalue.watchlist ? (


                                                                        <a data-report_id={Listvalue.reportDetails._id} data-report_watchlist={false} onClick={this.watchlistReadOnClick}><img alt="" src={view_over} /></a>
                                                                    ) : <a data-report_id={Listvalue.reportDetails._id} data-report_watchlist={true} onClick={this.watchlistReadOnClick}><img alt="" src={view} /></a>}


                                                                    {Listvalue.bookmark ? (

                                                                        <a data-report_id={Listvalue.reportDetails._id} data-report_bookmarks={false} data-report_bookmarkNotify={false} onClick={this.bookmarkReadOnClick}><img alt="" src={bookmarkRo} /></a>

                                                                    ) : <a data-report_id={Listvalue.reportDetails._id} data-report_bookmarks={true} data-report_bookmarkNotify={true} onClick={this.bookmarkReadOnClick}><img alt="" src={filter} /></a>
                                                                    }

                                                                </div>
                                                            </td>





                                                        </tr>

                                                    ))}


                                            </tbody>
                                        }

                                    </table>
                                </div>

                                {this.state.isLoading &&
                                    <div className="text-center mt-4 loadMore"><Spinner color="primary" /><span className="text">Loading</span> </div>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-lg-2 pt-4 pb-4">
                            <div>
                                {this.showReportImageHtml()}
                            </div>
                        </div>


                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
export default ReportPage;