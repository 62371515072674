import React, { Component } from 'react';
import './EquityEtf.scss';
import combinedServices from "../../shared/services/user-service";
import { TopLinks } from '../../component/TopLinks/TopLinks';

export class EquityEtf extends Component {

    constructor(props) {
        super(props);
            this.state = {
                
                getModelPortfolioDataList : '',
                getModelPortfolioStatus :false,
                colorPosition :'',
                userStatus:''
    
            }
    
    }
    convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
    }

    componentDidMount=async()=> {
       
        


        let getModelPortfolioResponse =  await combinedServices.getmodelPortfolioData();
         if(getModelPortfolioResponse.status){

            this.setState({
                getModelPortfolioDataList :getModelPortfolioResponse.data,
                getModelPortfolioStatus : getModelPortfolioResponse.status
            });

            console.log("getModelPortfolioDataList ", this.state.getModelPortfolioDataList);

            
         }else{

            this.setState({
           
            getModelPortfolioStatus : getModelPortfolioResponse.status
            });



         }


       
    }
    showChartError =() =>{

        var status = this.state.userStatus;
        if(status==="TRIAL USER" || status==="WHITE LABEL" || status==="RESEARCH"){
    
            this.setState({
                chartErrorMassage :"Your subscription level does not provide access to OCIO. Please email support@marketdeskresearch.com"
            });
    
    
        }
    
    }



    render() {
        return (
         
            
            <div className="tableBox etfTable">
                
                <table>
                    <thead>
                        <tr>
                            <th colSpan="2">Equity &amp; ETF Models <span>(as of 4/19/2021)</span></th>
                            <th><span>YTD</span></th>
                            <th><span>1 Year</span></th>
                        </tr>
                    </thead>
                    <tbody>
                       <tr>
                            <td className="date">{this.convertDate(this.state.getModelPortfolioDataList.modDate1)}</td>
                            <td className="title">
                                {this.state.userStatus=="OCIO" ?
                                <a  href={this.state.getModelPortfolioDataList.modURL1} >{this.state.getModelPortfolioDataList.modTitle1}</a>:<a  onClick={this.showChartError}>{this.state.getModelPortfolioDataList.modTitle1}</a>
                                }
                            </td>

                            <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD1 > 0 ? 'up' :'down'} >{this.state.getModelPortfolioDataList.modYTD1}%</span></td>
                            <td className="year"><span className={this.state.getModelPortfolioDataList.modYear1 > 0 ?'up' :'down'}>{this.state.getModelPortfolioDataList.modYear1}%</span></td>
                        </tr>

                         <tr>
                            <td className="date">{this.convertDate(this.state.getModelPortfolioDataList.modDate2)}</td>
                            <td className="title"><a  href={this.state.getModelPortfolioDataList.modURL2} >{this.state.getModelPortfolioDataList.modTitle2}</a></td>

                            <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD2 >0 ? 'up' :'down'} >{this.state.getModelPortfolioDataList.modYTD2}%</span></td>
                            <td className="year"><span className={this.state.getModelPortfolioDataList.modYear2 > 0  ?'up' :'down'}>{this.state.getModelPortfolioDataList.modYear2}%</span></td>
                        </tr>

                        <tr>
                            <td className="date">{this.convertDate(this.state.getModelPortfolioDataList.modDate3)}</td>
                            <td className="title"><a href={this.state.getModelPortfolioDataList.modURL3} >{this.state.getModelPortfolioDataList.modTitle3}</a></td>

                            <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD3 >0? 'up' :'down'} >{this.state.getModelPortfolioDataList.modYTD3}%</span></td>
                            <td className="year"><span className={this.state.getModelPortfolioDataList.modYear3 >0?'up' :'down'}>{this.state.getModelPortfolioDataList.modYear3}%</span></td>
                        </tr>


                         <tr>
                            <td className="date">{this.convertDate(this.state.getModelPortfolioDataList.modDate4)}</td>
                            <td className="title"><a  href={this.state.getModelPortfolioDataList.modURL4} >{this.state.getModelPortfolioDataList.modTitle4}</a></td>

                            <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD4 >0? 'up' :'down'} >{this.state.getModelPortfolioDataList.modYTD4}%</span></td>
                            <td className="year"><span className={this.state.getModelPortfolioDataList.modYear4 >0?'up' :'down'}>{this.state.getModelPortfolioDataList.modYear4}%</span></td>
                        </tr>

                        <tr>
                            <td className="date">{this.convertDate(this.state.getModelPortfolioDataList.modDate5)}</td>
                            <td className="title"><a  href={this.state.getModelPortfolioDataList.modURL5} >{this.state.getModelPortfolioDataList.modTitle5}</a></td>

                            <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD5 >0? 'up' :'down'} >{this.state.getModelPortfolioDataList.modYTD5}%</span></td>
                            <td className="year"><span className={this.state.getModelPortfolioDataList.modYear5 >0?'up' :'down'}>{this.state.getModelPortfolioDataList.modYear5}%</span></td>
                        </tr>




                          <tr>
                            <td className="date">{this.convertDate(this.state.getModelPortfolioDataList.modDate6)}</td>
                            <td className="title"><a  href={this.state.getModelPortfolioDataList.modURL6} >{this.state.getModelPortfolioDataList.modTitle6}</a></td>

                            <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD6 >0? 'up' :'down'} >{this.state.getModelPortfolioDataList.modYTD6}%</span></td>
                            <td className="year"><span className={this.state.getModelPortfolioDataList.modYear6 >0?'up' :'down'}>{this.state.getModelPortfolioDataList.modYear6}%</span></td>
                        </tr>


                        <tr>
                            <td className="date">{this.convertDate(this.state.getModelPortfolioDataList.modDate7)}</td>
                            <td className="title"><a href={this.state.getModelPortfolioDataList.modURL7} >{this.state.getModelPortfolioDataList.modTitle7}</a></td>

                            <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD7 >0? 'up' :'down'} >{this.state.getModelPortfolioDataList.modYTD7}%</span></td>
                            <td className="year"><span className={this.state.getModelPortfolioDataList.modYear7 >0?'up' :'down'}>{this.state.getModelPortfolioDataList.modYear7}%</span></td>
                        </tr>


                          <tr>
                            <td className="date">{this.convertDate(this.state.getModelPortfolioDataList.modDate8)}</td>
                            <td className="title"><a  href={this.state.getModelPortfolioDataList.modURL8} >{this.state.getModelPortfolioDataList.modTitle8}</a></td>

                            <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD8 >0? 'up' :'down'} >{this.state.getModelPortfolioDataList.modYTD8}%</span></td>
                            <td className="year"><span className={this.state.getModelPortfolioDataList.modYear8 >0?'up' :'down'}>{this.state.getModelPortfolioDataList.modYear8}%</span></td>
                        </tr>


                          <tr>
                            <td className="date">{this.convertDate(this.state.getModelPortfolioDataList.modDate9)}</td>
                            <td className="title"><a  href={this.state.getModelPortfolioDataList.modURL9} >{this.state.getModelPortfolioDataList.modTitle9}</a></td>

                            <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD9 >0? 'up' :'down'} >{this.state.getModelPortfolioDataList.modYTD9}%</span></td>
                            <td className="year"><span className={this.state.getModelPortfolioDataList.modYear9 >0?'up' :'down'}>{this.state.getModelPortfolioDataList.modYear9}%</span></td>
                        </tr>

                        <tr>
                            <td className="date">{this.convertDate(this.state.getModelPortfolioDataList.modDate10)}</td>
                            <td className="title"><a  href={this.state.getModelPortfolioDataList.modURL10} >{this.state.getModelPortfolioDataList.modTitle10}</a></td>

                            <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD10 >0? 'up' :'down'} >{this.state.getModelPortfolioDataList.modYTD10}%</span></td>
                            <td className="year"><span className={this.state.getModelPortfolioDataList.modYear10 >0?'up' :'down'}>{this.state.getModelPortfolioDataList.modYear10}%</span></td>
                        </tr>

                        
                       
                       
                    </tbody>
                </table>
            </div>
        )
    }
}
export default EquityEtf;