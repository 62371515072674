import React, { Component } from 'react';
import './LeftMenu.scss';
import { NavLink } from "react-router-dom";

export class LeftMenu extends Component {

    render() {
        return (
            <div>
                <ul className="leftMenu">
                    <li><NavLink to="/bookmarks" activeClassName="active">Bookmarks</NavLink ></li>
                    <li><NavLink to="/following" activeClassName="active">Following</NavLink ></li>
                    <li><NavLink to="/watchlist" activeClassName="active">Watchlist</NavLink ></li>
                </ul>
            </div>
        )
    }
}
export default LeftMenu;