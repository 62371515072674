import React, { Component } from 'react';
import {NavLink, Link} from "react-router-dom";
import './Header.scss';
import logo from '../../assets/images/logo.png';
import user from '../../assets/images/user_icon.png';
import notification from '../../assets/images/notificatio_icon.png';
import filter from '../../assets/images/filter_icon.png';
import { Form, Input } from 'reactstrap';
import getLocalData from "../../shared/localStorage/getData";
import Notification from '../Notification/Notification';
import { Library } from '../../pages/Library/Library';
import { Redirect } from "react-router-dom";
import BookmarkNotification from '../BookmarkNotification/BookmarkNotification';
import combinedServices from "../../shared/services/user-service";
import LoginPopup from '../LoginPopup/LoginPopup';


export class Header extends Component {
  

    constructor(props) {
       
            super(props);
            this.state = {
              user_logged_in: false,
              admin_logged_in: false,
              searchKey:'',
              toLibraryPage:false,
              
              
            };
       
    }

    componentDidMount=async()=> {
      var data = {};
     

        if(getLocalData('user_info','isLogin')){
          let user_info = getLocalData("user_info", "all");
          data.userId = user_info.userData.userId;
          this.setState({
            user_logged_in: true
          })

          let bookMarkNotification = await combinedServices.getBookmarkNotification(data);
          
          if(bookMarkNotification.status){
              this.setState({
                  countBookmarks: bookMarkNotification.data,
                  bookMarkCountStatus:bookMarkNotification.status
                 
              });
  
  
          }

        
        }

       
    
        if(getLocalData('admin_info','isLogin')){
          this.setState({
            admin_logged_in: true
          })
        }
       
       
    }

    handleKeyPress = (e) => {
       
        if (e.key === 'Enter') {
          this.setState({searchKey:e.target.value,toLibraryPage:true})
          window.location.replace("/library?searchKeyword="+e.target.value);
         
        }
      }

    render() {
     
      var bookmarkCount= '' ;
      var headerLogo='';
      if(this.props.bookmarkCount>=0){
        if(this.props.bookmarkCount== 0){
          bookmarkCount = '';
        }

        if(this.props.bookmarkCount> 0){
          bookmarkCount = <span className="bookmark_count">{this.props.bookmarkCount}</span>
        }
        
        
      }else if(this.state.countBookmarks) {
        bookmarkCount = <span className="bookmark_count">{this.state.countBookmarks}</span>
      }
      if(this.state.admin_logged_in){
      headerLogo =  <div className="logo"><NavLink to="/admin/manual-links"><img alt="" src={logo} /></NavLink></div>
      }else{

        headerLogo =  <div className="logo"><NavLink to="/"><img alt="" src={logo} /></NavLink></div>
      }




      
        return (
            <div>
            {!this.state.user_logged_in && !this.state.admin_logged_in && (
              <LoginPopup />
            )}

                <div className="header">
                    <div className="container d-flex flex-wrap align-items-center justify-content-between">
                        {headerLogo}
                       {this.state.user_logged_in && (
                        <div className="headerRgt d-flex align-items-center justify-content-between">

                            <div className="searchBox">
                                
                                    <Input type="text" className="searchInput"  onKeyDown={this.handleKeyPress} placeholder="Quick Search" />
                               
                            </div>
                             
                            
                            <ul className="topLink d-flex">
                                <li><NavLink to="/user-profile"><img alt="" src={user} /></NavLink></li>
                                <li><Notification /></li>
                                
                                <li><NavLink to="/bookmarks"><img alt="" src={filter} />{bookmarkCount}</NavLink></li>

                            </ul>
                            
                            
                        </div>
                        )}
                        
                    </div>
                </div>
            </div>
        )
    }
}
export default Header;