import React, { Component } from 'react';
import {NavLink, Link} from "react-router-dom";

import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import notification from '../../assets/images/notificatio_icon.png';
import combinedServices from "../../shared/services/user-service";
import getLocalData from "../../shared/localStorage/getData";
import filter from '../../assets/images/filter_icon.png';

export class BookmarkNotification extends Component {

  constructor(props) {
          super(props);
          this.state = {
           BookmarkNotification : props.bookmarkCount
          };

  }

  render() {

    return (
     
       <NavLink to="/bookmarks"><img alt="" src={filter} /><span className="count">{this.state.BookmarkNotification}</span></NavLink>
     
    );


  }

 
}

export default BookmarkNotification;