import React, { Component } from 'react';
import './Notification.scss';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import notification from '../../assets/images/notificatio_icon.png';
import combinedServices from "../../shared/services/user-service";
import getLocalData from "../../shared/localStorage/getData";

export class Notification extends Component {

  constructor(props) {
          super(props);
          this.state = {
            dropdownOpen: false,
            followingNotify:0,
            watchlistNotify:0,
            totalNotify:0
          };

  }



   toggle = () => {
    this.setState({
      dropdownOpen:!this.state.dropdownOpen
     
    })
     
    
   }
 

   componentDidMount = async() => {
    var data = {} ;

    if(getLocalData('user_info','isLogin')){

      let user_info = getLocalData("user_info", "all");
      data.userId = user_info.userData.userId;
      var notificationDetails = await combinedServices.getUserNotification(data);
    
    
       if(notificationDetails.status) {

        this.setState({
          followingNotify:notificationDetails.data.followingNotify,
          totalNotify:notificationDetails.data.totalNotify,
          watchlistNotify:notificationDetails.data.watchlistNotify,
        })
       }
    }
    
   
  }
  render() {
    

    return (
      <ButtonDropdown className="notiBox" direction="bottom" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle caret className="notiBtn"><img alt="" src={notification} />
        
        {this.state.totalNotify > 0 &&
        <span className="notificationTotalcount">{this.state.totalNotify}</span>
        }
       
        </DropdownToggle>
        <DropdownMenu>
        <a href="/watchlist">
          <DropdownItem className="d-flex align-items-center justify-content-between">
          <span>Watchlist</span>
            {this.state.watchlistNotify > 0 &&
             <span className="count">{this.state.watchlistNotify}</span>
            }
            
            </DropdownItem></a>
            <a href="/following">
          <DropdownItem className="d-flex align-items-center justify-content-between"><span>Following</span> 
          {this.state.followingNotify > 0 &&
          <span className="count">{this.state.followingNotify}</span>
          }
          </DropdownItem></a>
        </DropdownMenu>
      </ButtonDropdown>
    );


  }

 
}

export default Notification;