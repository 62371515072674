import React, { Component } from 'react';
import './Bookmarks.scss';
import { TopLinks } from '../../component/TopLinks/TopLinks';
import { LeftMenu } from '../../component/LeftMenu/LeftMenu';
import view from '../../assets/images/view.png';
import view_over from '../../assets/images/view_over.png';
import filter from '../../assets/images/filter.png';
import filterRo from '../../assets/images/filter_ro.png';
import { Spinner } from 'reactstrap';
import Header from '../../component/headerWithoutLoginPopup/Header';
import { Footer } from '../../component/Footer/Footer';
import combinedServices from "../../shared/services/user-service";
import getLocalData from "../../shared/localStorage/getData";
import moment from 'moment';
import removeLocalData from "../../shared/localStorage/removeData";

export class Bookmarks extends Component {

    constructor(props) {
        super(props);
        this.state = {
           
            bookmarkData: [],
            activePage: 1,
            userId: '',
            isLoading: true,
            bookMarkCountStatus: true,
         
        }

    }

    logoutUser = () => {

        removeLocalData("user_info");
        removeLocalData("admin_info");
        window.location.href = '/';
    };


   
   
    componentDidMount = async () => {
        let user_info = getLocalData("user_info", "all");

        if (getLocalData('user_info', 'isLogin')) {
            var userData = {};
           
            userData.email = user_info.userData.userEmail;
            userData.userId = user_info.userData.userId;
            let userProfileResponse = await combinedServices.checkIfUserExists(userData);
           
            if (userProfileResponse.status) {
                var userProfileDetailsData = userProfileResponse.data;
                if (userProfileDetailsData.status != user_info.userData.status) {
                    this.logoutUser();
                }
            }
           

        }

       
        this.setState({
            userId: user_info.userData.userId,
        
        });
        let data = {
            userId: user_info.userData.userId,
            page: this.state.activePage
        }
        let bookmarkNotification =  await combinedServices.readBookmarkNotifyByUser(data);
      
        
        let bookmarksList = await combinedServices.getBookmarkReport(data);
       
        
       
        if (bookmarksList.status) {
            this.setState({
                bookmarkData: bookmarksList.data,
                bookmarkStatus: bookmarksList.status,
                totalReports: bookmarksList.totalReport,
                reportMsg:bookmarksList.msg,
                isLoading: false

            });


        } else {


            this.setState({

                bookmarkData: bookmarksList.data,
                bookmarkStatus: bookmarksList.status,
                totalReports: bookmarksList.totalReport,
                reportMsg:bookmarksList.msg,
                isLoading: false

            });
        }

    }

  
    watchlistReadOnClick = async(event) =>{
   
       
         let userIsLogin = getLocalData("user_info", "isLogin");
 
         if(userIsLogin){
             

             let reportId = event.currentTarget.dataset.report_id;
             let report_watchlist =  event.currentTarget.dataset.report_watchlist;
             let user_info = getLocalData("user_info", "all");
             let userId = user_info.userData.userId;
            
             var  watchlistData = {
                 userId:userId,
                 reportId:reportId,
                 watchlist:report_watchlist

             }

            
             let watchlistResponse = await combinedServices.saveUserReporWatchlist(watchlistData);
            
             this.getUpdateWatchlistAndBookmarks();
 
         }
 
 
     }

     bookmarkReadOnClick = async(event) =>{

       
        let userIsLogin = getLocalData("user_info", "isLogin");

        if(userIsLogin){
            
            let reportId = event.currentTarget.dataset.report_id;
            let report_bookmarks = event.currentTarget.dataset.report_bookmarks;
            let report_bookmarknotify = event.currentTarget.dataset.report_bookmarknotify;
            let user_info = getLocalData("user_info", "all");
            let userId = user_info.userData.userId;
            var  bookmarkData = {
                userId:userId,
                reportId:reportId,
                bookmark:report_bookmarks,
                isBookmarkNotify:report_bookmarknotify

            }
           
            let bookmarkResponse = await combinedServices.saveUserReportBookmarked(bookmarkData);

            this.getUpdateWatchlistAndBookmarks();
           

        }


    }

     

     getUpdateWatchlistAndBookmarks = async()  =>{

       var data = {};
       data.page = this.state.activePage;
       data.userId = this.state.userId;

       
       
       let bookmarksList = await combinedServices.getBookmarkReport(data);
        if (bookmarksList.status) {
            this.setState({
                bookmarkData: bookmarksList.data,
                bookmarkStatus: bookmarksList.status,
                totalReports: bookmarksList.totalReport,
                reportMsg:bookmarksList.msg

            });


        } else {


            this.setState({

                bookmarkData: bookmarksList.data,
                bookmarkStatus: bookmarksList.status,
                totalReports: bookmarksList.totalReport,
                reportMsg:bookmarksList.msg
            });
        }


     }

    handlePageChangeOnLoadMore = async () => {

        var pageNumber = this.state.activePage + 1;
        var data = {};
        data.page = pageNumber;
        data.userId = this.state.userId;

        this.setState({ activePage:pageNumber,isLoading:true });

        let bookmarksList = await combinedServices.getBookmarkReport(data);
       
        console.log(bookmarksList)
        if (bookmarksList.status) {
            this.setState({
                bookmarkData: bookmarksList.data,
                bookmarkStatus: bookmarksList.status,
                totalReports: bookmarksList.totalReport,
                reportMsg:bookmarksList.msg,
                isLoading:false

            });


        } else {


            this.setState({

                bookmarkData: bookmarksList.data,
                bookmarkStatus: bookmarksList.status,
                totalReports: bookmarksList.totalReport,
                reportMsg:bookmarksList.msg,
                isLoading:false

            });
        }



    }

    render() {
        
        var loadMoreButton = '';
        var isLodingButton ='';

        if (this.state.totalReports > 20 && this.state.reportMsg == "found") {

            if(this.state.isLoading){

                loadMoreButton = <div className="text-center mt-4 loadMore"><Spinner color="primary"/><span className="text">Loading</span></div>
            }else{

                 loadMoreButton= <div className="text-center mt-4 loadMore"><span onClick={this.handlePageChangeOnLoadMore} className="text">Load More</span></div>
            }
        }else{

            if(this.state.isLoading){

                isLodingButton = <div className="text-center mt-4 loadMore"><Spinner color="primary"/><span className="text">Loading</span></div>
            }


        }
  

        return (

            <div>
               
                <Header/>
                <TopLinks />
                
                <div className="container miidlePart">
                    <div className="row">
                        <div className="col-12 pt-4 reportTop">
                            <div className="mainHd">Bookmarks</div>
                            <div className="contentBox">Research Saved for Further Reading</div>
                        </div>
                        <div className="col-12 col-lg-3 pt-4 pb-0 pb-lg-4">
                            <LeftMenu />
                        </div>
                        <div className="col-12 col-lg-9 pt-4 pb-4">
                            <div className="tableBox libTable">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Report Format</th>
                                            <th>Report Title</th>
                                            <th>Save</th>
                                        </tr>
                                    </thead>
                                    
                                    {this.state.bookmarkStatus &&
                                        <tbody>

                                            {this.state.bookmarkData.map(Listvalue =>
                                                <tr>
                                                    <td className="date">{moment(Listvalue.reportDetails.publishedDate).format('M/D/YYYY')}</td>
                                                    <td className="format">{Listvalue.reportDetails.reportFormat}</td>
                                                    <td className="report">
                                                        <span><a href={'/report-page/' + Listvalue.reportDetails._id}>{Listvalue.reportDetails.reportTitle}</a></span>
                                                        {Listvalue.reportDetails.reportSubTitle}
                                                    </td>

                                                    <td className="save">
                                                        <div className="action d-flex">
                                                            {Listvalue.watchlist ? (


                                                                <a data-report_id={Listvalue.reportDetails._id} data-report_watchlist={false} onClick={this.watchlistReadOnClick}><img alt="" src={view_over} /></a>
                                                            ) : <a data-report_id={Listvalue.reportDetails._id}  data-report_watchlist={true} onClick={this.watchlistReadOnClick}><img alt="" src={view} /></a>}


                                                            {Listvalue.bookmark ? (

                                                                <a data-report_id={Listvalue.reportDetails._id} data-report_bookmarks={false}  data-report_bookmarkNotify={false} onClick={this.bookmarkReadOnClick}><img alt="" src={filterRo} /></a>

                                                            ) : <a data-report_id={Listvalue.reportDetails._id} data-report_bookmarks={true}  data-report_bookmarkNotify={true} onClick={this.bookmarkReadOnClick}><img alt="" src={filter} /></a>
                                                            }

                                                        </div>
                                                    </td>


                                                </tr>

                                            )}


                                        </tbody>
                                    
                                     }
                                  
                                  
                                </table>
                            </div>

                           {isLodingButton}

                           {loadMoreButton}


                          
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
export default Bookmarks;