import authHeader from "../auth-header/auth-header";
import axios from "axios";
import removeLocalData from "../../shared/localStorage/removeData";
import getLocalData from "../../shared/localStorage/getData";

let header = authHeader("USER");
// get base url according to environment
const getBaseURLAccordingToEnvironment = (env) => {
  console.log("9", env)
  switch (env) {
    case "prod":
      return "https://api.marketdeskportal.com/";
    case "dev":
      //return "http://localhost:4000/";

      // return "http://mdr.flexsin.org:9000/"; //commented by chandra
        return "https://api.mdr.flexsin.org/";
    case "local":
      return "http://localhost:9000/";

    default:
      return "http://localhost:9000/";
  }
};

//const baseURL = getBaseURLAccordingToEnvironment("local");
//const baseURL = getBaseURLAccordingToEnvironment("dev");
const baseURL = getBaseURLAccordingToEnvironment("prod");


/*--------------------------------ADMIN SERVICES START------------------------------------*/


async function userSignUp(data) {
  let response = await axios.post(baseURL + "user/signUp", data);

  let response_data = response.data;
  return response_data;
}


async function userLogin(data) {
  let response = await axios.post(baseURL + "user/login", data);

  let response_data = response.data;
  return response;
}



async function forgotPassword(data) {
  let response = await axios.post(baseURL + "user/forgotPassword", data);
  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function resetPassword(data) {
  let response = await axios.post(baseURL + "user/resetPassword", data);
  let response_data = response.data;
  return response;
}


async function manualLink(data) {

  let response = await axios.post(baseURL + "admin/saveManualLinks", data);

  try {
    let response_data = response.data;

    return response_data;
  } catch (err) {
    console.log("74", err)
    return err;
  }
}

async function getClientSupportData(data) {
  let response = await axios.get(baseURL + "admin/getClientSupportData", data);

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

// ====================Added by chandra

async function getLrtReportData() {

  let response = await axios.get(baseURL + "admin/getLrtReportData");
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}



// ======================Commented by chandra
// async function getclientResourceData() {

//   let response = await axios.get(baseURL + "admin/getclientResourceData");


//   try {
//     let response_data = response.data;
//     return response_data;
//   } catch (err) {
//     return err;
//   }

// }


// async function getcurrentRatingsData() {

//   let response = await axios.get(baseURL + "admin/getcurrentRatingsData");
//   try {
//     let response_data = response.data;
//     return response_data;
//   } catch (err) {
//     return err;
//   }

// }

async function getmodelPortfolioData() {

  let response = await axios.get(baseURL + "admin/getmodelPortfolioData");
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}


function logout(key) {
  let logoutresponse = removeLocalData(key);
  return logoutresponse;
}


async function getUserProfile(data) {
  let response = await axios.post(baseURL + "user/getUserProfile", data);

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}


async function gettrialDayLeftData() {

  let response = await axios.get(baseURL + "admin/gettrialDayLeftData");
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}
// ============Commented by chandra

// async function getthematicAndTacticalData() {

//   let response = await axios.get(baseURL + "admin/getthematicAndTacticalData");
//   try {
//     let response_data = response.data;
//     return response_data;
//   } catch (err) {
//     return err;
//   }

// }

async function getUserList(pageNumber) {

  let response = await axios.get(baseURL + "admin/getUserList?page=" + pageNumber);
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}



async function saveNewEditReport(data) {

  let response = await axios.post(baseURL + "admin/saveNewEditReport", data);
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}


async function getReportList(pageNumber) {

  let response = await axios.get(baseURL + "admin/getReportLists?page=" + pageNumber);
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}




async function getReportListById(reportId) {

  let response = await axios.get(baseURL + "admin/getReportListById?reportId=" + reportId);
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}

async function updateNewEditData(data) {

  let response = await axios.post(baseURL + "admin/updateNewEditData", data);
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}

async function getLatestResearch(data) {

  let response = await axios.post(baseURL + "admin/getLatestResearch", data);
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}



async function getMostReadReport(data) {

  let response = await axios.post(baseURL + "admin/getMostReadReport", data);
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}





async function updateUserStatus(data) {

  let response = await axios.post(baseURL + "admin/updateUserStatus", data);
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}




async function filterReportForUser(data) {

  let response = await axios.post(baseURL + "user/filterReportForUser", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    console.log("311", response_data)
    return response_data;
  } catch (err) {
    return err;
  }

}


async function saveUserReportBookmarked(data) {

  let response = await axios.post(baseURL + "user/saveUserReportBookmarked", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}

async function saveUserReporWatchlist(data) {

  let response = await axios.post(baseURL + "user/saveUserReporWatchlist", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}



async function updateUserTrialDate(data) {

  let response = await axios.post(baseURL + "admin/updateUserTrialDate", data);
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}



async function getUserReportListById(data) {

  let response = await axios.post(baseURL + "user/getUserReportListById", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}




async function calculateTrailDate(data) {

  let response = await axios.post(baseURL + "user/calculateTrailDate", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}





async function getwhiteResourcesData() {

  let response = await axios.get(baseURL + "admin/getwhiteResourcesData");
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}

async function getwhiteChartData() {

  let response = await axios.get(baseURL + "admin/getwhiteChartData");
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}



async function getwhiteMonthlyData() {

  let response = await axios.get(baseURL + "admin/getwhiteMonthlyData");
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}




async function getwhiteQuaterlyLetterData() {

  let response = await axios.get(baseURL + "admin/getwhiteQuaterlyLetterData");
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}





async function getwhiteQuaterlyMarketData() {

  let response = await axios.get(baseURL + "admin/getwhiteQuaterlyMarketData");
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}
// ================added by chandra

async function getwhiteMarketTalkingPointData() {

  let response = await axios.get(baseURL + "admin/getwhiteMarketTalkingPointData");
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}


// =======================end



async function getwhiteoffShelfData() {

  let response = await axios.get(baseURL + "admin/getwhiteoffShelfData");
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}


async function getOCIOResourceData() {

  let response = await axios.get(baseURL + "admin/getOCIOResourceData");
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}
// commented by chandra
// async function getOCIODocumentData() {

//   let response = await axios.get(baseURL + "admin/getOCIODocumentData");
//   try {
//     let response_data = response.data;
//     return response_data;
//   } catch (err) {
//     return err;
//   }

// }




async function getOCIOCoreData() {

  let response = await axios.get(baseURL + "admin/getOCIOCoreData");
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}

// ============commented by chandra

// async function getOCIOTacticalsData() {

//   let response = await axios.get(baseURL + "admin/getOCIOTacticalsData");
//   try {
//     let response_data = response.data;
//     return response_data;
//   } catch (err) {
//     return err;
//   }

// }


async function getBookmarkReport(data) {

  let response = await axios.post(baseURL + "user/getBookmarkReport", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}


async function getWatchlistReport(data) {

  let response = await axios.post(baseURL + "user/getWatchlistReport", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}



async function saveFollowedTopics(data) {

  let response = await axios.post(baseURL + "user/saveFollowedTopics", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}




async function getFollowedTopicsByUser(data) {

  let response = await axios.post(baseURL + "user/getFollowedTopicsByUser", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}



async function getFollowingTopics(data) {

  let response = await axios.post(baseURL + "user/getFollowingTopics", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}

async function getSearchDataFromReport(data) {

  let response = await axios.post(baseURL + "user/getSearchDataFromReport", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}

async function getUserNotification(data) {

  let response = await axios.post(baseURL + "user/getUserNotification", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}




async function exportAllUsers() {

  let response = await axios.get(baseURL + "admin/exportAllUsers");
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}


async function exportUserAnalyticsData() {

  let response = await axios.get(baseURL + "admin/exportUserAnalyticsData");
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}



async function getUserAnalyticsData(data) {

  let response = await axios.post(baseURL + "admin/getUserAnalyticsData", data);
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}



async function readUserWatchlistNotification(data) {

  let response = await axios.post(baseURL + "user/readUserWatchlistNotification", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}





async function getBookmarkNotification(data) {

  let response = await axios.post(baseURL + "user/getBookmarkNotification", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}




async function readUserFollowingNotification(data) {

  let response = await axios.post(baseURL + "user/readUserFollowingNotification", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}

async function readBookmarkNotifyByUser(data) {

  let response = await axios.post(baseURL + "user/readBookmarkNotifyByUser", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}



async function userLastActivity(data) {

  let response = await axios.post(baseURL + "user/userLastActivity", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}



async function checkIfUserExists(data) {

  let response = await axios.post(baseURL + "user/checkIfUserExists", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}

async function getTermsAndConditions() {

  let response = await axios.get(baseURL + "admin/getTermsAndConditions");
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}



async function getCategoryReportForUser(data) {

  let response = await axios.post(baseURL + "user/getCategoryReportForUser", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}


async function changeUserPassword(data) {

  let response = await axios.post(baseURL + "admin/changeUserPassword", data);
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }

}


let combinedServices = {

  userSignUp,
  userLogin,
  forgotPassword,
  resetPassword,
  logout,
  manualLink,
  getClientSupportData,
  getLrtReportData,
  // getclientResourceData,
  // getcurrentRatingsData,
  getmodelPortfolioData,
  getUserProfile,
  gettrialDayLeftData,
  // getthematicAndTacticalData,
  getUserList,
  saveNewEditReport,
  getReportList,
  getReportListById,
  updateNewEditData,
  getLatestResearch,
  getMostReadReport,
  updateUserStatus,
  filterReportForUser,
  saveUserReportBookmarked,
  saveUserReporWatchlist,
  updateUserTrialDate,
  getUserReportListById,
  calculateTrailDate,
  getwhiteResourcesData,
  getwhiteChartData,
  getwhiteMonthlyData,
  getwhiteQuaterlyLetterData,
  getwhiteQuaterlyMarketData,
  getwhiteMarketTalkingPointData, //added by chandra
  getwhiteoffShelfData,
  getOCIOResourceData,
  // getOCIODocumentData, 
  getOCIOCoreData,
  // getOCIOTacticalsData,
  getBookmarkReport,
  getWatchlistReport,
  saveFollowedTopics,
  getFollowedTopicsByUser,
  getFollowingTopics,
  getSearchDataFromReport,
  getUserNotification,
  exportAllUsers,
  exportUserAnalyticsData,
  getUserAnalyticsData,
  getBookmarkNotification,
  readUserWatchlistNotification,
  readUserFollowingNotification,
  readBookmarkNotifyByUser,
  userLastActivity,
  checkIfUserExists,
  getTermsAndConditions,
  getCategoryReportForUser,
  changeUserPassword

};

export default combinedServices;
