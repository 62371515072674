import React, { Component } from 'react';
import './WhiteLabel.scss';
import { TopLinks } from '../../component/TopLinks/TopLinks';
import MonthChart from '../../assets/images/ChartOfMonth.JPG';
import MonthlyClientLetters from '../../assets/images/MonthlyLetter.JPG';
import QuarterlyClientLetters from '../../assets/images/QuarterlyLetter.JPG';
import MarketPerspectives from '../../assets/images/QuarterlyMarketPerspectives.JPG';
import ShelfPresentations from '../../assets/images/OffShelfPresentations.JPG';
import Header from '../../component/headerWithoutLoginPopup/Header';
import { Footer } from '../../component/Footer/Footer';
import combinedServices from "../../shared/services/user-service";
import moment from 'moment';
import getLocalData from "../../shared/localStorage/getData";
import removeLocalData from "../../shared/localStorage/removeData";




export class WhiteLabel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            whiteLableResourceStatus: false,
            whiteLabelResourceDataList: {},
            whiteLabelChartStatus: false,
            whiteLabelChartDataList: {},
            whiteLabelMontlyStatus: false,
            whiteLabelMontlytDataList: {},
            whiteLableQuaterlyStatus: false,
            whiteLabelQuaterlyDataList: {},
            whiteLableQuaterlyMarketStatus: false,
            whiteLabelQuaterlyMarketDataList: {},
            whiteoffShelfStatus: false,
            whiteoffShelfDataList: {},
            userStatus: '',
            chartErrorMassage: '',
            clientMonthlyErrorMassage: '',
            clientQuarterlyErrorMassage: '',
            showClientQuarterlyMarketError: '',
            showClientOffShelfError: '',
            whiteLabelMarketTalkingPointErrorMsg: '',
            whiteLabelMarketTalkingPointStatus: false,
            whiteLabelMarketTalkingPointDataList: {},


        }



    }
    logoutUser = () => {

        removeLocalData("user_info");
        removeLocalData("admin_info");
        window.location.href = '/';
    };


    componentDidMount = async () => {

        if (getLocalData('user_info', 'isLogin')) {
            var data = {};
            let user_info = getLocalData("user_info", "all");
            data.email = user_info.userData.userEmail;
            data.userId = user_info.userData.userId;
            let userProfileResponse = await combinedServices.checkIfUserExists(data);

            if (userProfileResponse.status) {
                var userProfileDetailsData = userProfileResponse.data;
                if (userProfileDetailsData.status != user_info.userData.status) {
                    this.logoutUser();
                }
            }
            this.setState({
                userStatus: user_info.userData.status

            });


        }

        let whiteLabelResources = await combinedServices.getwhiteResourcesData();


        if (whiteLabelResources.status) {

            this.setState({
                whiteLabelResourceDataList: whiteLabelResources.data,
                whiteLableResourceStatus: whiteLabelResources.status
            });

        } else {

            this.setState({

                whiteLableResourceStatus: whiteLabelResources.status
            });
        }

        let whiteLabelChartData = await combinedServices.getwhiteChartData();


        if (whiteLabelChartData.status) {

            this.setState({
                whiteLabelChartDataList: whiteLabelChartData.data,
                whiteLabelChartStatus: whiteLabelChartData.status
            });

        } else {

            this.setState({
                whiteLabelChartStatus: whiteLabelChartData.status
            });
        }

        let whiteLabelMontlyData = await combinedServices.getwhiteMonthlyData();


        if (whiteLabelMontlyData.status) {

            this.setState({
                whiteLabelMontlytDataList: whiteLabelMontlyData.data,
                whiteLabelMontlyStatus: whiteLabelMontlyData.status
            });

        } else {

            this.setState({
                whiteLabelMontlyStatus: whiteLabelMontlyData.status
            });
        }

        let getwhiteQuaterlyLetterData = await combinedServices.getwhiteQuaterlyLetterData();


        if (getwhiteQuaterlyLetterData.status) {

            this.setState({
                whiteLabelQuaterlyDataList: getwhiteQuaterlyLetterData.data,
                whiteLableQuaterlyStatus: getwhiteQuaterlyLetterData.status
            });

        } else {

            this.setState({

                whiteLableQuaterlyStatus: getwhiteQuaterlyLetterData.status
            });
        }

        let getwhiteQuaterlyMarketData = await combinedServices.getwhiteQuaterlyMarketData();


        if (getwhiteQuaterlyMarketData.status) {

            this.setState({
                whiteLabelQuaterlyMarketDataList: getwhiteQuaterlyMarketData.data,
                whiteLableQuaterlyMarketStatus: getwhiteQuaterlyMarketData.status
            });

        } else {

            this.setState({

                whiteLableQuaterlyMarketStatus: getwhiteQuaterlyMarketData.status
            });
        }

        // =======Added by chandra 

        let getwhiteMarketTalkingPointData = await combinedServices.getwhiteMarketTalkingPointData();

        console.log("getwhiteMarketTalkingPointData", getwhiteMarketTalkingPointData.data.mtpDate1)
        if (getwhiteMarketTalkingPointData.status) {
            console.log("getwhiteMarketTalkingPointData 177",this.state.whiteLabelMarketTalkingPointStatus, getwhiteMarketTalkingPointData)

            this.setState({
                whiteLabelMarketTalkingPointDataList: getwhiteMarketTalkingPointData.data,
                whiteLabelMarketTalkingPointStatus: getwhiteMarketTalkingPointData.status
            },()=>{
                console.log("1830000",this.state.whiteLabelMarketTalkingPointDataList)
            });

        } else {

            this.setState({

                whiteLabelMarketTalkingPointStatus: getwhiteMarketTalkingPointData.status
            });
        }

        // =========end

        let getwhiteoffShelfData = await combinedServices.getwhiteoffShelfData();


        if (getwhiteoffShelfData.status) {

            this.setState({
                whiteoffShelfDataList: getwhiteoffShelfData.data,
                whiteoffShelfStatus: getwhiteoffShelfData.status
            });

        } else {

            this.setState({

                whiteoffShelfStatus: getwhiteoffShelfData.status
            });
        }






    }


    showChartError = () => {

        var status = this.state.userStatus;
        console.log("225 Status")
        if (status === "TRIAL USER" || status == "QUANT PACK" ||status === "WHITE LABEL" || status === "RESEARCH" || status === "PENDING" || status === "NOT ACTIVE") {

            this.setState({
                chartErrorMassage: "Your subscription level does not provide access to Chart of the Month. Please email support@marketdeskresearch.com"
            });


            setTimeout(() => {
                this.setState({ chartErrorMassage: '' })
            }, 5000)


        }

    }

    showClientMontlyError = () => {

        var status = this.state.userStatus;
        console.log("245 Status",status)
        if (status === "TRIAL USER" || status === "QUANT PACK" || status === "RESEARCH" || status === "PENDING" || status === "NOT ACTIVE") {

            this.setState({
                clientMonthlyErrorMassage: "Your subscription level does not provide access to Client Letters. Please email support@marketdeskresearch.com"
            });
            setTimeout(() => {
                this.setState({ clientMonthlyErrorMassage: '' })
            }, 5000)


        }



    }

    showClientQuarterlyError = () => {


        var status = this.state.userStatus;
        console.log("266 Status",status)
        if (status === "TRIAL USER" || status === "QUANT PACK" || status === "RESEARCH" || status === "PENDING" || status === "NOT ACTIVE") {

            this.setState({
                clientQuarterlyErrorMassage: "Your subscription level does not provide access to Client Letters. Please email support@marketdeskresearch.com"
            });


        }

        setTimeout(() => {
            this.setState({ clientQuarterlyErrorMassage: '' })
        }, 5000)



    }

    showClientQuarterlyMarketError = () => {

        var status = this.state.userStatus;
        console.log(" 287 Status",status)
        if (status === "TRIAL USER" || status === "QUANT PACK" || status === "WHITE LABEL" || status === "RESEARCH" || status === "PENDING" || status === "NOT ACTIVE") {

            this.setState({
                showClientQuarterlyMarketError: "Your subscription level does not provide access to Market Perspectives. Please email support@marketdeskresearch.com"
            });

            setTimeout(() => {
                this.setState({ showClientQuarterlyMarketError: '' })
            }, 5000)


        }


    }

    // =======================added by chandra

    whiteLabelMarketTalkingPointError = () => {

        var status = this.state.userStatus;
        console.log("309 Status",status)
        if (status === "TRIAL USER" || status === "QUANT PACK" || status === "WHITE LABEL" || status === "RESEARCH" || status === "PENDING" || status === "NOT ACTIVE") {

            this.setState({
                whiteLabelMarketTalkingPointErrorMsg: "Your subscription level does not provide access to Market Perspectives. Please email support@marketdeskresearch.com"
            });

            setTimeout(() => {
                this.setState({ whiteLabelMarketTalkingPointErrorMsg: '' })
            }, 5000)


        }


    }



    // ==================================end
    showClientOffShelfError = () => {

        var status = this.state.userStatus;
        console.log("332 Status",status)
        if (status === "TRIAL USER" || status === "QUANT PACK" || status === "RESEARCH" || status === "PENDING" || status === "NOT ACTIVE") {

            this.setState({
                showClientOffShelfError: "Your subscription level does not provide access to Presentations. Please email support@marketdeskresearch.com"
            });

            setTimeout(() => {
                this.setState({ showClientOffShelfError: '' })
            }, 5000)


        }




    }


    render() {
        var resourcesDetails = this.state.whiteLabelResourceDataList;



        return (

            <div>
                <Header />
                <TopLinks />
                <div className="container">
                    <div className="mt-3 pb-3 mt-xl-5 pb-xl-5">
                        <div className="row justify-content-between">
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="mainHd">White Label Insights</div>
                                <div className="contentBox">
                                    {this.state.whiteLabelChartDataList.whiteLabelInsightsParagraph}

                                </div>
                            </div>
                            <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                <div className="blueBgHd"> White Label Resources</div>
                                <div className="infoBox">

                                    {this.state.whiteLableResourceStatus &&
                                        <ul className="ocioRes">

                                            <li><a target="_blank" href={resourcesDetails.hURL1}>{resourcesDetails.hTitle1}</a></li>
                                            <li><a target="_blank" href={resourcesDetails.hURL2}>{resourcesDetails.hTitle2}</a></li>
                                            <li><a target="_blank" href={resourcesDetails.hURL3}>{resourcesDetails.hTitle3}</a></li>

                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="borBot labelList mt-xl-5 pb-xl-10 pt-5 pb-3 pt-md-5 pb-md-5 d-flex flex-wrap">
                            <div className="left">
                                <div className="hd">Chart of the Month</div>
                                {/* <div><img alt="" src={MonthChart} /></div> */}
                                <div><img src='https://cdn2.hubspot.net/hub/3941881/hubfs/COTM7.15.2023.png' alt="month chart" /></div>
                            </div>
                            <div className="right">
                                <div className="text-center blueClr pb-4">{this.state.chartErrorMassage}</div>


                                {this.state.whiteLabelChartStatus &&
                                    <ul className="labelDet">
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteLabelChartDataList.cotmDate1).format('M/D/YYYY')}</div>
                                            <div className="det">{this.state.whiteLabelChartDataList.cotmTitle1} </div>
                                            {this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS" ?
                                                <div className="link"><a target="_blank" href={this.state.whiteLabelChartDataList.cotmDoc1}>Word </a></div> : <div className="link"><a onClick={this.showChartError}>Word </a></div>
                                            }
                                        </li>
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteLabelChartDataList.cotmDate2).format('M/D/YYYY')}</div>
                                            <div className="det">{this.state.whiteLabelChartDataList.cotmTitle2}</div>
                                            {this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS" ?
                                                <div className="link"><a target="_blank" href={this.state.whiteLabelChartDataList.cotmDoc2}>Word </a></div> : <div className="link"><a onClick={this.showChartError}>Word </a></div>
                                            }
                                        </li>
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteLabelChartDataList.cotmDate3).format('M/D/YYYY')}</div>
                                            <div className="det">{this.state.whiteLabelChartDataList.cotmTitle3}</div>
                                            {this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS" ?
                                                <div className="link"><a target="_blank" href={this.state.whiteLabelChartDataList.cotmDoc3}>Word </a></div> : <div className="link"><a onClick={this.showChartError}>Word </a></div>
                                            }
                                        </li>
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteLabelChartDataList.cotmDate4).format('M/D/YYYY')}</div>
                                            <div className="det">{this.state.whiteLabelChartDataList.cotmTitle4}</div>
                                            {this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS" ?
                                                <div className="link"><a target="_blank" href={this.state.whiteLabelChartDataList.cotmDoc4}>Word </a></div> : <div className="link"><a onClick={this.showChartError}>Word </a></div>
                                            }
                                        </li>
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteLabelChartDataList.cotmDate5).format('M/D/YYYY')}</div>
                                            <div className="det">{this.state.whiteLabelChartDataList.cotmTitle5}</div>
                                            {this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS" ?
                                                <div className="link"><a target="_blank" href={this.state.whiteLabelChartDataList.cotmDoc5}>Word </a></div> : <div className="link"><a onClick={this.showChartError}>Word </a></div>
                                            }
                                        </li>
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteLabelChartDataList.cotmDate6).format('M/D/YYYY')}</div>
                                            <div className="det">{this.state.whiteLabelChartDataList.cotmTitle6}</div>
                                            {this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS" ?
                                                <div className="link"><a target="_blank" href={this.state.whiteLabelChartDataList.cotmDoc6}>Word </a></div> : <div className="link"><a onClick={this.showChartError}>Word </a></div>
                                            }
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>

                        <div className="borBot labelList mt-xl-5 pb-xl-10 mt-pt-3 pb-3 pt-md-5 pb-md-5 d-flex flex-wrap">
                            <div className="left">
                                {/* <div className="hd">Monthly Client Letters</div> */}
                                <div className="hd">Monthly Letters</div>
                                <div><img alt="" src={MonthlyClientLetters} /></div>
                            </div>
                            <div className="right">
                                <div className="text-center blueClr pb-4">{this.state.clientMonthlyErrorMassage}</div>
                                {this.state.whiteLabelMontlyStatus &&
                                    <ul className="labelDet">
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteLabelMontlytDataList.mlDate1).format('M/D/YYYY')}</div>
                                            <div className="det large">{this.state.whiteLabelMontlytDataList.mlTitle1}</div>

                                            {this.state.userStatus == "WHITE LABEL" || this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS" ?
                                                <div className="link large">
                                                    <a target="_blank" href={this.state.whiteLabelMontlytDataList.mlDoc1}>Word</a>
                                                    <a target="_blank" href={this.state.whiteLabelMontlytDataList.mlChart1}>Charts</a>
                                                    <a target="_blank" href={this.state.whiteLabelMontlytDataList.mlSupport1}>Support</a>
                                                </div>
                                                : <div className="link large">
                                                    <a onClick={this.showClientMontlyError}>Word</a>
                                                    <a onClick={this.showClientMontlyError}>Charts</a>
                                                    <a onClick={this.showClientMontlyError}>Support</a>
                                                </div>
                                            }
                                        </li>
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteLabelMontlytDataList.mlDate2).format('M/D/YYYY')}</div>
                                            <div className="det large"> {this.state.whiteLabelMontlytDataList.mlTitle2}</div>
                                            {this.state.userStatus == "WHITE LABEL" || this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS" ?
                                                <div className="link large">
                                                    <a target="_blank" href={this.state.whiteLabelMontlytDataList.mlDoc2}>Word</a>
                                                    <a target="_blank" href={this.state.whiteLabelMontlytDataList.mlChart2}>Charts</a>
                                                    <a target="_blank" href={this.state.whiteLabelMontlytDataList.mlSupport2}>Support</a>
                                                </div>
                                                : <div className="link large">
                                                    <a onClick={this.showClientMontlyError}>Word</a>
                                                    <a onClick={this.showClientMontlyError}>Charts</a>
                                                    <a onClick={this.showClientMontlyError}>Support</a>
                                                </div>
                                            }
                                        </li>
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteLabelMontlytDataList.mlDate3).format('M/D/YYYY')}</div>
                                            <div className="det large">{this.state.whiteLabelMontlytDataList.mlTitle3}</div>
                                            {this.state.userStatus == "WHITE LABEL" || this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS"?
                                                <div className="link large">
                                                    <a target="_blank" href={this.state.whiteLabelMontlytDataList.mlDoc3}>Word</a>
                                                    <a target="_blank" href={this.state.whiteLabelMontlytDataList.mlChart3}>Charts</a>
                                                    <a target="_blank" href={this.state.whiteLabelMontlytDataList.mlSupport3}>Support</a>
                                                </div>
                                                : <div className="link large">
                                                    <a onClick={this.showClientMontlyError}>Word</a>
                                                    <a onClick={this.showClientMontlyError}>Charts</a>
                                                    <a onClick={this.showClientMontlyError}>Support</a>
                                                </div>
                                            }
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>

                        <div className="borBot labelList mt-xl-5 pb-xl-10 pt-3 pb-3 pt-md-5 pb-md-5 d-flex flex-wrap">
                            <div className="left">
                                <div className="hd">Quarterly Letters</div>
                                <div><img alt="" src={QuarterlyClientLetters} /></div>
                            </div>
                            <div className="right">
                                <div className="text-center blueClr pb-4">{this.state.clientQuarterlyErrorMassage}</div>
                                {this.state.whiteLableQuaterlyStatus &&
                                    <ul className="labelDet">
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteLabelQuaterlyDataList.qlDate1).format('M/D/YYYY')}</div>
                                            <div className="det large">{this.state.whiteLabelQuaterlyDataList.qlTitle1}</div>
                                            {this.state.userStatus == "WHITE LABEL" || this.state.userStatus == "OCIO" ||  this.state.userStatus == "WL PLUS" ?
                                                <div className="link large">
                                                    <a target="_blank" href={this.state.whiteLabelQuaterlyDataList.qlDoc1}>Word</a>
                                                    <a target="_blank" href={this.state.whiteLabelQuaterlyDataList.qlChart1}>Charts</a>
                                                    <a target="_blank" href={this.state.whiteLabelQuaterlyDataList.qlSupport1}>Support</a>
                                                </div> : <div className="link large">
                                                    <a onClick={this.showClientQuarterlyError}>Word</a>
                                                    <a onClick={this.showClientQuarterlyError}>Charts</a>
                                                    <a onClick={this.showClientQuarterlyError}>Support</a>
                                                </div>
                                            }
                                        </li>
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteLabelQuaterlyDataList.qlDate2).format('M/D/YYYY')}</div>
                                            <div className="det large">{this.state.whiteLabelQuaterlyDataList.qlTitle2}</div>
                                            {this.state.userStatus == "WHITE LABEL" || this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS" ?
                                                <div className="link large">
                                                    <a target="_blank" href={this.state.whiteLabelQuaterlyDataList.qlDoc2}>Word</a>
                                                    <a target="_blank" href={this.state.whiteLabelQuaterlyDataList.qlChart2}>Charts</a>
                                                    <a target="_blank" href={this.state.whiteLabelQuaterlyDataList.qlSupport2}>Support</a>
                                                </div> : <div className="link large">
                                                    <a onClick={this.showClientQuarterlyError}>Word</a>
                                                    <a onClick={this.showClientQuarterlyError}>Charts</a>
                                                    <a onClick={this.showClientQuarterlyError}>Support</a>
                                                </div>
                                            }
                                        </li>
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteLabelQuaterlyDataList.qlDate3).format('M/D/YYYY')}</div>
                                            <div className="det large">{this.state.whiteLabelQuaterlyDataList.qlTitle3}</div>
                                            {this.state.userStatus == "WHITE LABEL" || this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS" ?
                                                <div className="link large">
                                                    <a target="_blank" href={this.state.whiteLabelQuaterlyDataList.qlDoc3}>Word</a>
                                                    <a target="_blank" href={this.state.whiteLabelQuaterlyDataList.qlChart3}>Charts</a>
                                                    <a target="_blank" href={this.state.whiteLabelQuaterlyDataList.qlSupport3}>Support</a>
                                                </div> : <div className="link large">
                                                    <a onClick={this.showClientQuarterlyError}>Word</a>
                                                    <a onClick={this.showClientQuarterlyError}>Charts</a>
                                                    <a onClick={this.showClientQuarterlyError}>Support</a>
                                                </div>
                                            }
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>
                        <div className="borBot labelList mt-xl-5 pb-xl-10 pt-3 pb-3 pt-md-5 pb-md-5 d-flex flex-wrap">
                            <div className="left">
                                <div className="hd">Market Talking Points</div>

                                <div><img src='https://reports.marketdeskresearch.com/hubfs/MDR%20Images/MarketTalkingPoints.png' alt='quarterlymarket'></img></div>
                            </div>
                            <div className="right">
                                <div className="text-center blueClr pb-4">{this.state.whiteLabelMarketTalkingPointErrorMsg}</div>
                                {this.state.whiteLabelMarketTalkingPointStatus &&
                                    <ul className="labelDet">
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteLabelMarketTalkingPointDataList.mtpDate1).format('M/D/YYYY')}</div>
                                            <div className="det">{this.state.whiteLabelMarketTalkingPointDataList.mtpTitle1}</div>
                                            {this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS" ?
                                                <div className="link"><a target="_blank" href={this.state.whiteLabelMarketTalkingPointDataList.mtpUrl1}>Word</a></div> : <div className="link"><a onClick={this.whiteLabelMarketTalkingPointError}>Word</a></div>
                                            }
                                        </li>
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteLabelMarketTalkingPointDataList.mtpDate2).format('M/D/YYYY')}</div>
                                            <div className="det">{this.state.whiteLabelMarketTalkingPointDataList.mtpTitle2}</div>
                                            {this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS"?
                                                <div className="link"><a target="_blank" href={this.state.whiteLabelMarketTalkingPointDataList.mtpUrl2}>Word</a></div> : <div className="link"><a onClick={this.whiteLabelMarketTalkingPointError}>Word</a></div>
                                            }
                                        </li>
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteLabelMarketTalkingPointDataList.mtpDate3).format('M/D/YYYY')}</div>
                                            <div className="det">{this.state.whiteLabelMarketTalkingPointDataList.mtpTitle3}</div>
                                            {this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS"?
                                                <div className="link"><a target="_blank" href={this.state.whiteLabelMarketTalkingPointDataList.mtpUrl3}>Word</a></div> : <div className="link"><a onClick={this.whiteLabelMarketTalkingPointError}>Word</a></div>
                                            }
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>

                        <div className="borBot labelList mt-xl-5 pb-xl-10 pt-3 pb-3 pt-md-5 pb-md-5 d-flex flex-wrap">
                            <div className="left">
                                <div className="hd">Quarterly Webinar</div>
                                {/* <div className="hd">Quarterly Market Perspectives</div> */}

                                {/* <div><img alt="" src={MarketPerspectives} /></div> */}
                                <div><img src='https://cdn2.hubspot.net/hub/3941881/hubfs/MDR%20Images/Webinar%26Script.png' alt='quarterlymarket'></img></div>
                            </div>
                            <div className="right">
                                <div className="text-center blueClr pb-4">{this.state.showClientQuarterlyMarketError}</div>
                                {this.state.whiteLableQuaterlyMarketStatus &&
                                    <ul className="labelDet">
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteLabelQuaterlyMarketDataList.qmpDate1).format('M/D/YYYY')}</div>
                                            <div className="det">{this.state.whiteLabelQuaterlyMarketDataList.qmpTitle1}</div>
                                            {this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS" ?
                                                <div className="link"><a target="_blank" href={this.state.whiteLabelQuaterlyMarketDataList.qmpPpt1}>PPT</a></div> : <div className="link"><a onClick={this.showClientQuarterlyMarketError}>PPT</a></div>
                                            }
                                        </li>
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteLabelQuaterlyMarketDataList.qmpDate2).format('M/D/YYYY')}</div>
                                            <div className="det">{this.state.whiteLabelQuaterlyMarketDataList.qmpTitle2}</div>
                                            {this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS"?
                                                <div className="link"><a target="_blank" href={this.state.whiteLabelQuaterlyMarketDataList.qmpPpt2}>PPT</a></div> : <div className="link"><a onClick={this.showClientQuarterlyMarketError}>PPT</a></div>
                                            }
                                        </li>
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteLabelQuaterlyMarketDataList.qmpDate3).format('M/D/YYYY')}</div>
                                            <div className="det">{this.state.whiteLabelQuaterlyMarketDataList.qmpTitle3}</div>
                                            {this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS"?
                                                <div className="link"><a target="_blank" href={this.state.whiteLabelQuaterlyMarketDataList.qmpPpt3}>PPT</a></div> : <div className="link"><a onClick={this.showClientQuarterlyMarketError}>PPT</a></div>
                                            }
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>
                        <div className="labelList pt-3 mt-xl-5 pt-md-5 d-flex flex-wrap">

                        {/* <div className="labelList pt-3 pb-3 mt-xl-5 pb-xl-10 pt-md-5 pb-md-5 d-flex flex-wrap"> */}
                            <div className="left">
                                {/* <div className="hd">Off the Shelf Presentations</div> */}
                                <div className="hd">Client Presentations</div>

                                <div><img alt="" src={ShelfPresentations} /></div>
                            </div>
                            <div className="right">
                                <div className="text-center blueClr pb-4">{this.state.showClientOffShelfError}</div>
                                {this.state.whiteoffShelfStatus &&
                                    <ul className="labelDet">
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteoffShelfDataList.ospDate1).format('M/D/YYYY')}</div>
                                            <div className="det">{this.state.whiteoffShelfDataList.ospTitle1}</div>

                                            {this.state.userStatus == "WHITE LABEL" || this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS" ?
                                                <div className="link"><a target="_blank" href={this.state.whiteoffShelfDataList.ospDoc1}>PPT</a></div> : <div className="link"><a onClick={this.showClientOffShelfError}>PPT</a></div>
                                            }
                                        </li>
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteoffShelfDataList.ospDate2).format('M/D/YYYY')}</div>
                                            <div className="det">{this.state.whiteoffShelfDataList.ospTitle2}</div>

                                            {this.state.userStatus == "WHITE LABEL" || this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS" ?
                                                <div className="link"><a target="_blank" href={this.state.whiteoffShelfDataList.ospDoc2}>PPT</a></div> : <div className="link"><a onClick={this.showClientOffShelfError}>PPT</a></div>
                                            }
                                        </li>
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteoffShelfDataList.ospDate3).format('M/D/YYYY')}</div>
                                            <div className="det">{this.state.whiteoffShelfDataList.ospTitle3}</div>

                                            {this.state.userStatus == "WHITE LABEL" || this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS" ?
                                                <div className="link"><a target="_blank" href={this.state.whiteoffShelfDataList.ospDoc3}>PPT</a></div> : <div className="link"><a onClick={this.showClientOffShelfError}>PPT</a></div>
                                            }
                                        </li>
                                        <li className="d-flex">
                                            <div className="date">{moment(this.state.whiteoffShelfDataList.ospDate4).format('M/D/YYYY')}</div>
                                            <div className="det">{this.state.whiteoffShelfDataList.ospTitle4}</div>

                                            {this.state.userStatus == "WHITE LABEL" || this.state.userStatus == "OCIO" || this.state.userStatus == "WL PLUS" ?
                                                <div className="link"><a target="_blank" href={this.state.whiteoffShelfDataList.ospDoc4}>PPT</a></div> : <div className="link"><a onClick={this.showClientOffShelfError}>PPT</a></div>
                                            }
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
export default WhiteLabel;