import React, { Component } from 'react';
import './TermsAndConditions.scss';
import { TopLinks } from '../../component/TopLinks/TopLinks';
import Header from '../../component/headerWithoutLoginPopup/Header';
import { Footer } from '../../component/Footer/Footer';
import combinedServices from "../../shared/services/user-service";

import getLocalData from "../../shared/localStorage/getData";
import removeLocalData from "../../shared/localStorage/removeData";

export class TermsAndConditions extends Component {

    constructor(props) {
        super(props);
            this.state = {
    
                status:false,
                tAndC :'',
               
                
    
            }
           
        }

    logoutUser = () => {

        removeLocalData("user_info");
        removeLocalData("admin_info");
        window.location.href = '/';
    };


    componentDidMount=async()=> {


        if (getLocalData('user_info', 'isLogin')) {
            var data = {};
            let user_info = getLocalData("user_info", "all");
            data.email = user_info.userData.userEmail;
            data.userId = user_info.userData.userId;
            let userProfileResponse = await combinedServices.checkIfUserExists(data);

            if (userProfileResponse.status) {
                var userProfileDetailsData = userProfileResponse.data;
                if (userProfileDetailsData.status != user_info.userData.status) {
                    this.logoutUser();
                }
            }
            this.setState({
                userStatus: user_info.userData.status

            });

            let getTermsAndConditionsResponse = await combinedServices.getTermsAndConditions();
       
            if(getTermsAndConditionsResponse.status) {

                var getTermsAndConditionsDetails = getTermsAndConditionsResponse.data;
                this.setState({
                    tAndC: getTermsAndConditionsDetails.termsAndConditions,
                    status :getTermsAndConditionsResponse.status
                })


            }


        }
    }

    render() {

        return (

            <div>
              <Header />
                <TopLinks />
                <div className="container miidlePart termsCon">
                    <div className="row">
                        <div className="col-12 pt-5 pb-4">
                            <div className="mainHd">Terms & Conditions</div>

                             {this.state.status &&  

                              <p dangerouslySetInnerHTML= {{__html:this.state.tAndC}}/>
                              }
                            
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default TermsAndConditions;