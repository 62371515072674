import React, { Component } from 'react';
import './Library.scss';
import { TopLinks } from '../../component/TopLinks/TopLinks';
import { Form, FormGroup, Input, Spinner } from 'reactstrap';
import view from '../../assets/images/view.png';
import view_over from '../../assets/images/view_over.png';
import filter from '../../assets/images/filter.png';
import filterRo from '../../assets/images/filter_ro.png';
import Header from '../../component/headerWithoutLoginPopup/Header';
import { Footer } from '../../component/Footer/Footer';
import combinedServices from "../../shared/services/user-service";
import { LoginPopup } from '../../component/LoginPopupLibrary/LoginPopup';
import getLocalData from "../../shared/localStorage/getData";
import { Modal, ModalHeader, ModalBody, Button, Label } from 'reactstrap';
import history from "../../shared/history";
import moment from 'moment';
import removeLocalData from "../../shared/localStorage/removeData";



export class Library extends Component {



    constructor(props) {

        super(props);

        this.state = {
            keywordSeach: '',
            researchCategory: [],
            assetClass: [],
            reportType: [],
            geography: [],
            frequency: [],
            activePage: 1,
            reportListData: [],
            assetClass: [],
            reportType: [],
            geography: [],
            frequency: [],
            totalReports: 0,
            userId: '',
            userIsLogin: false,
            isLoginPopup: false,
            isLoading: true,
            errorMessage: '',
            fields: {},
            bookMarkCountStatus: true,
            searchCategory: '',


        }



    }

    logoutUser = () => {

        removeLocalData("user_info");
        removeLocalData("admin_info");
        window.location.href = '/';
    };



    componentDidMount = async () => {
        var data = {};
        var reportList = '';
        var url_string = (window.location).href;
        var url = new URL(url_string);
        var categoryName = url.searchParams.get("searchKey");
        var searchKeyword = url.searchParams.get("searchKeyword");

        let user_info = getLocalData("user_info", "all");
        // =====this is condition added by chandra for login
        if (user_info == null) {
           
        } else {
            data.userId = user_info.userData.userId;
            data.email = user_info.userData.userEmail;
            this.setState({
                userId: user_info.userData.userId

            })


        }
        // ================================end====

        //  data.userId = user_info.userData.userId; //Commented by chandra for showing login page background
        //  data.email = user_info.userData.userEmail; //Commented by chandra for showing login page background
        let userProfileResponse = await combinedServices.checkIfUserExists(data);

        if (userProfileResponse.status) {
            var userProfileDetailsData = userProfileResponse.data;
            if (userProfileDetailsData.status != user_info.userData.status) {

                this.logoutUser();
            }
        }
        // this.setState({
        //     userId: user_info.userData.userId  //Commented by chandra for showing login page background

        // });




        if (this.state.researchCategory.length == 0 && this.state.assetClass.length == 0 && this.state.reportType.length == 0 && this.state.geography.length == 0 && this.state.frequency.length == 0) {

            data.page = this.state.activePage;
            data.search = searchKeyword;
            this.setState({
                keywordSeach: searchKeyword

            });
            if (!categoryName) {
                reportList = await combinedServices.filterReportForUser(data);
                if (reportList.status) {
                    this.setState({
                        reportListData: reportList.data,
                        totalReports: reportList.totalReport,
                        reportStatus: reportList.status,
                        reportMsg: reportList.msg,
                        isLoading: false


                    });
                } else {
                    this.setState({
                        reportListData: reportList.data,
                        totalReports: reportList.totalReport,
                        reportStatus: reportList.status,
                        isLoading: false
                    });
                }



            } else {
                data.searchKey = categoryName;
                let fields = this.state.fields;
                fields[categoryName] = true;
                this.setState({
                    fields,
                    searchCategory: categoryName
                });
                reportList = await combinedServices.getSearchDataFromReport(data);

                if (reportList.status) {

                    this.setState({
                        reportListData: reportList.data,
                        totalReports: reportList.totalReport,
                        reportStatus: reportList.status,
                        reportMsg: reportList.msg,
                        isLoading: false


                    });
                } else {

                    this.setState({
                        reportListData: reportList.data,
                        totalReports: reportList.totalReport,
                        reportStatus: reportList.status,
                        isLoading: false


                    });
                }


            }

        }


    }


    handleChangeCategory = e => {

        if (this.state.searchCategory) {

            if (!e.target.checked) {
                this.setState({
                    researchCategory: [],
                    fields: [],
                    searchCategory: ''
                });

                this.handlefilterWithCategory();
            }

        }
        if (e.target.checked) {

            const pushArray = this.state.researchCategory;
            pushArray.push(e.target.value);
            this.setState({
                researchCategory: pushArray
            });

            this.handlefilterWithCategory();
        } else {

            var array = this.state.researchCategory;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.

            array.splice(index, 1);
            this.setState({
                researchCategory: array
            });

            this.handlefilterWithCategory();


        }


    }
    handleChangeAssetClass = (e) => {

        if (this.state.searchCategory) {

            if (!e.target.checked) {
                this.setState({
                    assetClass: [],
                    fields: [],
                    searchCategory: ''
                });

                this.handlefilterWithCategory();
            }

        }

        if (e.target.checked) {

            const pushArray1 = this.state.assetClass;
            pushArray1.push(e.target.value);
            this.setState({
                assetClass: pushArray1
            });

            this.handlefilterWithCategory();
        } else {
            var array = this.state.assetClass;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.
            array.splice(index, 1);
            //delete array[index];
            this.setState({
                assetClass: array
            });

            this.handlefilterWithCategory();


        }


    }
    handleChangeReportType = e => {

        if (this.state.searchCategory) {

            if (!e.target.checked) {
                this.setState({
                    reportType: [],
                    fields: [],
                    searchCategory: ''
                });

                this.handlefilterWithCategory();
            }

        }

        if (e.target.checked) {

            const pushArray2 = this.state.reportType;
            pushArray2.push(e.target.value);
            this.setState({
                reportType: pushArray2
            });
            this.handlefilterWithCategory();
        } else {
            var array = this.state.reportType;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.
            array.splice(index, 1);
            this.setState({
                reportType: array
            });

            this.handlefilterWithCategory();
        }


    }
    handleChangeGeography = e => {


        if (this.state.searchCategory) {

            if (!e.target.checked) {
                this.setState({
                    geography: [],
                    fields: [],
                    searchCategory: ''
                });

                this.handlefilterWithCategory();
            }

        }

        if (e.target.checked) {
            const pushArray3 = this.state.geography;
            pushArray3.push(e.target.value);
            this.setState({
                geography: pushArray3
            });

            this.handlefilterWithCategory();
        } else {
            var array = this.state.geography;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.
            array.splice(index, 1);
            this.setState({
                geography: array
            });

            this.handlefilterWithCategory();
        }


    }


    handleChangeFrequency = e => {

        if (this.state.searchCategory) {

            if (!e.target.checked) {
                this.setState({
                    frequency: [],
                    fields: [],
                    searchCategory: ''
                });

                this.handlefilterWithCategory();
            }

        }

        if (e.target.checked) {
            const pushArray4 = this.state.frequency;
            pushArray4.push(e.target.value);
            this.setState({
                frequency: pushArray4
            });

            this.handlefilterWithCategory();
        } else {
            var array = this.state.frequency;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.
            array.splice(index, 1);
            this.setState({
                frequency: array
            });

            this.handlefilterWithCategory();
        }




    }



    handleLibSearch = async (e) => {
        if (e.key === 'Enter') {

            var data = {};
            data.page = this.state.activePage;
            data.search = e.target.value
            data.userId = this.state.userId;

            this.setState({
                keywordSeach: e.target.value
            });

            let keywordSearhReportList = await combinedServices.filterReportForUser(data);
            if (keywordSearhReportList.status) {

                this.setState({
                    reportListData: keywordSearhReportList.data,
                    totalReports: keywordSearhReportList.totalReport,
                    reportStatus: keywordSearhReportList.status,

                });
            } else {

                this.setState({
                    reportListData: keywordSearhReportList.data,
                    totalReports: keywordSearhReportList.totalReport,
                    reportStatus: keywordSearhReportList.status,


                });
            }

        }
    }


    handlefilterWithCategory = async () => {
        var data = {};
        this.setState({ isLoading: true })

        if (this.state.researchCategory.length != 0) {
            data.researchCat = this.state.researchCategory;
        }
        if (this.state.assetClass.length != 0) {
            data.assetCls = this.state.assetClass;
        }

        if (this.state.reportType.length != 0) {
            data.reportType = this.state.reportType;
        }

        if (this.state.geography.length != 0) {
            data.geography = this.state.geography;
        }

        if (this.state.frequency.length != 0) {
            data.frequency = this.state.frequency;
        }

        data.page = this.state.activePage;
        data.userId = this.state.userId;
        data.search = this.state.keywordSeach;


        let reportList = await combinedServices.filterReportForUser(data);


        if (reportList.status) {

            this.setState({
                reportListData: reportList.data,
                totalReports: reportList.totalReport,
                reportStatus: reportList.status,
                reportMsg: reportList.msg,
                isLoading: false

            });



        } else {

            this.setState({
                reportListData: reportList.data,
                totalReports: reportList.totalReport,
                reportStatus: reportList.status,
                reportMsg: reportList.msg,
                isLoading: false
            });
        }


    }



    handlePageChangeOnLoadMore = async () => {

        var pageNumber = this.state.activePage + 1;
        var data = {};
        data.page = pageNumber;
        data.userId = this.state.userId;
        data.search = this.state.keywordSeach;

        this.setState({ activePage: pageNumber, isLoading: true });


        if (this.state.researchCategory.length == 0 && this.state.assetClass.length == 0 && this.state.reportType.length == 0 && this.state.geography.length == 0 && this.state.frequency.length == 0) {


            let reportList = await combinedServices.filterReportForUser(data);


            if (reportList.status) {

                this.setState({
                    reportListData: reportList.data,
                    totalReports: reportList.totalReport,
                    reportStatus: reportList.status,
                    reportMsg: reportList.msg,
                    isLoading: false

                });
            } else {

                this.setState({

                    reportListData: reportList.data,
                    totalReports: reportList.totalReport,
                    reportStatus: reportList.status,
                    reportMsg: reportList.msg,
                    isLoading: false



                });
            }


        } else {


            this.handlefilterWithCategory();
        }





    }


    bookmarkReadOnClick = async (event) => {


        let userIsLogin = getLocalData("user_info", "isLogin");

        if (userIsLogin) {
            let reportId = event.currentTarget.dataset.report_id;
            let report_bookmarks = event.currentTarget.dataset.report_bookmarks;
            let report_bookmarknotify = event.currentTarget.dataset.report_bookmarknotify;
            let user_info = getLocalData("user_info", "all");
            let userId = user_info.userData.userId;
            var bookmarkData = {
                userId: userId,
                reportId: reportId,
                bookmark: report_bookmarks,
                isBookmarkNotify: report_bookmarknotify

            }
            let bookmarkResponse = await combinedServices.saveUserReportBookmarked(bookmarkData);
            this.setState({ bookMarkCountStatus: !this.state.bookMarkCountStatus })

            this.getUpdateWatchlistAndBookmarks();
        }


    }


    watchlistReadOnClick = async (event) => {


        let userIsLogin = getLocalData("user_info", "isLogin");

        if (userIsLogin) {


            let reportId = event.currentTarget.dataset.report_id;
            let report_watchlist = event.currentTarget.dataset.report_watchlist;
            let user_info = getLocalData("user_info", "all");
            let userId = user_info.userData.userId;

            var watchlistData = {
                userId: userId,
                reportId: reportId,
                watchlist: report_watchlist

            }


            let watchlistResponse = await combinedServices.saveUserReporWatchlist(watchlistData);

            this.getUpdateWatchlistAndBookmarks();

        }


    }

    getUpdateWatchlistAndBookmarks = async () => {

        var data = {};
        data.page = this.state.activePage;
        data.userId = this.state.userId;
        data.search = this.state.keywordSeach;


        let bookMarkNotification = await combinedServices.getBookmarkNotification(data);


        if (bookMarkNotification.status) {
            this.setState({
                countBookmarks: bookMarkNotification.data,
                bookMarkCountStatus: bookMarkNotification.status

            });


        } else {

            this.setState({
                countBookmarks: 0,
                bookMarkCountStatus: bookMarkNotification.status

            });


        }

        if (this.state.researchCategory.length == 0 && this.state.assetClass.length == 0 && this.state.reportType.length == 0 && this.state.geography.length == 0 && this.state.frequency.length == 0) {


            let reportList = await combinedServices.filterReportForUser(data);

            if (reportList.status) {

                this.setState({
                    reportListData: reportList.data,
                    totalReports: reportList.totalReport,
                    reportStatus: reportList.status,
                    reportMsg: reportList.msg,
                });
            }


        } else {

            this.handlefilterWithCategory();
        }




    }

    handleClosePopup = (data) => {
        this.setState({ openDeleteModal: data })
    }
    convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
    }

    loginPopup = () => {
        return (
            <LoginPopup
                isOpen={this.state.openDeleteModal}
                closepopup={this.handleClosePopup}
            />
        );
    }

    render() {


        const reportListArray = this.state.reportListData;
        var loadMoreButton = '';
        var isLodingButton = '';

        if (this.state.totalReports > 20 && this.state.reportMsg == "found") {

            if (this.state.isLoading) {

                loadMoreButton = <div className="text-center mt-4 loadMore"><Spinner color="primary" /><span className="text">Loading</span></div>
            } else {

                loadMoreButton = <div className="text-center mt-4 loadMore"><span onClick={this.handlePageChangeOnLoadMore} className="text">Load More</span></div>
            }
        } else {

            if (this.state.isLoading) {

                isLodingButton = <div className="text-center mt-4 loadMore"><Spinner color="primary" /><span className="text">Loading</span></div>
            }


        }

        return (

            <div>

                {this.state.bookMarkCountStatus ? <Header
                    bookmarkCount={this.state.countBookmarks}
                /> : <Header
                    bookmarkCount={this.state.countBookmarks}
                />}
                <TopLinks />
                {this.state.openDeleteModal && this.loginPopup()}

                <div className="container miidlePart">
                    <div className="mt-3 pb-3 mt-xl-5 pb-xl-5">
                        <div className="row">
                            <div className="col-12">
                                <div className="mainHd">Research Library</div>
                            </div>
                            <div className="col-12 col-lg-3 pt-2 pb-4 pb-lg-0">
                                <div>
                                    <div className="blueBgHd">Keyword Search</div>
                                    <div className="infoBox libBox">

                                        <Input type="text" className="libSearch" onKeyDown={this.handleLibSearch} placeholder="Search for..." />

                                    </div>
                                </div>
                                <div className="mtM">
                                    <div className="blueBgHd">Category</div>
                                    <div className="infoBox">
                                        <ul className="libOptList">
                                            {/* <li>
                                            <FormGroup>
                                                <label>
                                                    <span className="checkWrapper"><Input
                                                        type="checkbox"
                                                        checked={this.state.fields.OCIO}
                                                        value="OCIO"
                                                        onChange={this.handleChangeCategory}
                                                        className="checkInpt" />
                                                        <i className="bg"></i></span>
                                                    <span className="contentBox"> OCIO</span>
                                                </label>
                                            </FormGroup>
                                        </li> */}
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            checked={this.state.fields.MacroStrategy}
                                                            onChange={this.handleChangeCategory}
                                                            value="MacroStrategy" className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Macro Strategy</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            checked={this.state.fields.CoreRating}
                                                            value="CoreRating"
                                                            onChange={this.handleChangeCategory}
                                                            className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Core Ratings</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            checked={this.state.fields.TacticalIdeas}
                                                            value="TacticalIdeas" onChange={this.handleChangeCategory} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Tactical Ideas</span>
                                                    </label>
                                                </FormGroup>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="mtM">
                                    <div className="blueBgHd">Asset Class</div>
                                    <div className="infoBox">
                                        <ul className="libOptList">
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            value="Equity"
                                                            checked={this.state.fields.Equity}
                                                            onChange={this.handleChangeAssetClass} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Equity</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"

                                                            checked={this.state.fields.Credit} value="Credit" onChange={this.handleChangeAssetClass} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Credit</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            value="Economics"
                                                            checked={this.state.fields.Economics}
                                                            onChange={this.handleChangeAssetClass} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Economics</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            name="asset"
                                                            value="Alternatives"
                                                            checked={this.state.fields.Alternatives}
                                                            onChange={this.handleChangeAssetClass}
                                                            className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Alternatives</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="mtM">
                                    <div className="blueBgHd">Report Type</div>
                                    <div className="infoBox">
                                        <ul className="libOptList">
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            checked={this.state.fields.Sector}
                                                            value="Sector"
                                                            onChange={this.handleChangeReportType} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Sector</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            checked={this.state.fields.Industry}
                                                            value="Industry" onChange={this.handleChangeReportType} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Industry</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            checked={this.state.fields.Thematic}
                                                            value="Thematic"
                                                            onChange={this.handleChangeReportType} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Thematic</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            checked={this.state.fields.Factor}
                                                            value="Factor" onChange={this.handleChangeReportType} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Factor</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            checked={this.state.fields.WashingtonPolicy}
                                                            value="WashingtonPolicy" onChange={this.handleChangeReportType} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox">MarketDesk Primer</span>

                                                        {/* <span className="contentBox"> Washington Policy</span> */}
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            checked={this.state.fields.International}
                                                            value="International" onChange={this.handleChangeReportType} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> International</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            checked={this.state.fields.Quantitative}
                                                            value="Quantitative"
                                                            onChange={this.handleChangeReportType} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Quantitative</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            value="ETF"
                                                            checked={this.state.fields.ETF}
                                                            onChange={this.handleChangeReportType} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> ETF</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            checked={this.state.fields.IndividualStocks}
                                                            value="IndividualStocks" onChange={this.handleChangeReportType} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Individual Stocks</span>
                                                    </label>
                                                </FormGroup>
                                            </li>

                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            value="ModelPortfolios"
                                                            checked={this.state.fields.ModelPortfolios}
                                                            onChange={this.handleChangeReportType} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Model Portfolios</span>
                                                    </label>
                                                </FormGroup>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="mtM">
                                    <div className="blueBgHd">Geography</div>
                                    <div className="infoBox">
                                        <ul className="libOptList">
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            value="us"
                                                            checked={this.state.fields.us}
                                                            onChange={this.handleChangeGeography} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox">U.S.</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            checked={this.state.fields.LatinAmerica}
                                                            value="LatinAmerica"
                                                            onChange={this.handleChangeGeography} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Latin America</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            value="Europe"
                                                            checked={this.state.fields.Europe}
                                                            onChange={this.handleChangeGeography} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Europe</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input type="checkbox"
                                                            checked={this.state.fields.AsiaPacific}
                                                            value="AsiaPacific" onChange={this.handleChangeGeography} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Asia-Pacific</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="mtM">
                                    <div className="blueBgHd">Frequency</div>
                                    <div className="infoBox">
                                        <ul className="libOptList">
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox"
                                                            checked={this.state.fields.Weekly}
                                                            value="Weekly" onChange={this.handleChangeFrequency} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Weekly</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input
                                                            type="checkbox" checked={this.state.fields.biWeekly} value="biWeekly" onChange={this.handleChangeFrequency} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Bi-Weekly</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input type="checkbox" checked={this.state.fields.Monthly} value="Monthly" onChange={this.handleChangeFrequency} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Monthly</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper"><Input type="checkbox" checked={this.state.fields.Quarterly} value="Quarterly" onChange={this.handleChangeFrequency} className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Quarterly</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-9 pt-2">
                                <div className="tableBox libTable">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Report Format</th>
                                                <th>Report Title</th>
                                                <th>Save</th>
                                            </tr>
                                        </thead>

                                        {
                                            this.state.userId == '' ?
                                                <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="date">
                                                                {moment().format('M/D/YYYY')}
                                                            </td>

                                                            <td className="format">Weekly Data Notess</td>
                                                            <td className="report">
                                                                <span><a href="#">Stock & Bonds Rally After the Fed Pivots</a></span>
                                                                Also: Inflation Data & Examining 2024 Rate Cuts
                                                            </td>
                                                            <td className="save">
                                                                <div className="action d-flex">
                                                                    <a>
                                                                        <img alt="" src={view} />
                                                                    </a>
                                                                    <a>
                                                                        <img alt="" src={filter} />
                                                                    </a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="date">
                                                                {moment().format('M/D/YYYY')}
                                                            </td>

                                                            <td className="format">Leading Data Indicators</td>
                                                            <td className="report">
                                                                <span><a href="#">Weekly Quant Pack</a></span>
                                                                Indicator Updates & Model Signals
                                                            </td>
                                                            <td className="save">
                                                                <div className="action d-flex">
                                                                    <a>
                                                                        <img alt="" src={view} />
                                                                    </a>
                                                                    <a>
                                                                        <img alt="" src={filter} />
                                                                    </a>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="date">
                                                                {moment().format('M/D/YYYY')}
                                                            </td>

                                                            <td className="format">Idea Data Screens</td>
                                                            <td className="report">
                                                                <span><a href="#">Stock Scores & Idea Screens</a></span>
                                                                Performance & Weekly Updates
                                                            </td>
                                                            <td className="save">
                                                                <div className="action d-flex">

                                                                    <a>
                                                                        <img alt="" src={view} />
                                                                    </a>
                                                                    <a>
                                                                        <img alt="" src={filter} />
                                                                    </a>

                                                                </div>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </>
                                                :

                                                <>
                                                    {this.state.reportStatus &&
                                                        <tbody>

                                                            {this.state.reportListData.map(Listvalue =>
                                                                <tr>
                                                                    <td className="date">{moment(Listvalue.reportDetails.publishedDate).format('M/D/YYYY')}</td>
                                                                    <td className="format">{Listvalue.reportDetails.reportFormat}</td>
                                                                    <td className="report">
                                                                        <span><a href={'/report-page/' + Listvalue.reportDetails._id}>{Listvalue.reportDetails.reportTitle}</a></span>
                                                                        {Listvalue.reportDetails.reportSubTitle}
                                                                    </td>

                                                                    <td className="save">
                                                                        <div className="action d-flex">
                                                                            {Listvalue.watchlist ? (


                                                                                <a data-report_id={Listvalue.reportDetails._id} data-report_watchlist={false} onClick={this.watchlistReadOnClick}><img alt="" src={view_over} /></a>
                                                                            ) : <a data-report_id={Listvalue.reportDetails._id} data-report_watchlist={true} onClick={this.watchlistReadOnClick}><img alt="" src={view} /></a>}


                                                                            {Listvalue.bookmark ? (

                                                                                <a data-report_id={Listvalue.reportDetails._id} data-report_bookmarks={false} data-report_bookmarkNotify={false} onClick={this.bookmarkReadOnClick}><img alt="" src={filterRo} /></a>

                                                                            ) : <a data-report_id={Listvalue.reportDetails._id} data-report_bookmarks={true} data-report_bookmarkNotify={true} onClick={this.bookmarkReadOnClick}><img alt="" src={filter} /></a>
                                                                            }

                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            )}


                                                        </tbody>

                                                    }
                                                </>
                                        }
                                    </table>
                                   

                                </div>

                                {isLodingButton}

                                {loadMoreButton}

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
export default Library;