import React, { Component } from 'react';
import { TopLinks } from '../../component/TopLinks/TopLinks';
import Header from '../../component/headerWithoutLoginPopup/Header';
import { Footer } from '../../component/Footer/Footer';
import "./Resources.scss"
import combinedServices from '../../shared/services/user-service';
import getLocalData from "../../shared/localStorage/getData";
import removeLocalData from "../../shared/localStorage/removeData";

export default class Resources extends Component {

    constructor(props) {
        super(props)
        this.state = {
            clientSupportStatus: false,
            clientSupportDataList: '',
            userId: '',
            email: ''

        }
    }
    componentDidMount = async () => {

        if (getLocalData("user_info", "isLogin")) {
            let user_info = getLocalData("user_info", "all");

            var data = {};
            data.email = user_info.userData.userEmail;
            data.userId = user_info.userData.userId;

            this.setState({
                userId: user_info.userData.userId,
                email: user_info.userData.userEmail,

            });
        }
        let clientSupportData = await combinedServices.getClientSupportData(data);
        console.log("resources data", clientSupportData.data)


        if (clientSupportData.status) {

            this.setState({
                clientSupportDataList: clientSupportData.data,
                clientSupportStatus: clientSupportData.status
            });
        } else {

            this.setState({
                clientSupportStatus: clientSupportData.status
            });

        }




    }

    render() {
        return (
            <>
                <Header />
                <TopLinks />
                <div className="container" style={{ minHeight: '70vh' }}>
                    <div className="mt-3 pb-3 mt-xl-5 pb-xl-5">
                        <div className='resorcesHd'>
                            <h2>Platform Resources</h2>
                            <p>The table below includes helpful resources for across the MarketDesk platform. Click the link to open each resource.
                            </p>
                        </div>

                        {/* <div className='resourceTable  resources tableBox etfTable'> */}
                        <div className='resourceTable'>
                            <div className='platfrmRes'>Platform Resources</div>
                            <table cellPadding="0" cellSpacing="0" border="0">
                                <thead>
                                    <tr>
                                        <th width="300">Type</th>
                                        <th>Name</th>
                                        <th width="180">Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.clientSupportDataList.supportType1 ?
                                            <tr>
                                                <td>{this.state.clientSupportDataList.supportType1}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText1}</td>

                                                <td className="viewLink"><a href={this.state.clientSupportDataList.supportButtonURL1} target='_blank'>Open Link</a>
                                            </td>
                                                {/* <td className="viewLink"><a target="_blank" href={this.state.clientSupportDataList.supportButtonURL1}>Word </a></td> */}

                                            </tr>
                                            :
                                            null

                                    }

                                    {
                                        this.state.clientSupportDataList.supportType2 ?
                                            <tr>
                                                <td>{this.state.clientSupportDataList.supportType2}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText2}</td>

                                                <td className="viewLink"><a href={this.state.clientSupportDataList.supportButtonURL2} target='_blank'>Open Link</a>
                                                </td>

                                            </tr>
                                            :
                                            null

                                    }

                                    {
                                        this.state.clientSupportDataList.supportType3 || this.state.clientSupportDataList.supportButtonText3 || this.state.clientSupportDataList.supportButtonURL3 ?
                                            <tr>
                                                <td>{this.state.clientSupportDataList.supportType3}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText3}</td>

                                                <td className="viewLink"><a href={this.state.clientSupportDataList.supportButtonURL3} target='_blank'>Open Link</a>
                                                </td>

                                            </tr>
                                            :
                                            null

                                    }

                                    {
                                        this.state.clientSupportDataList.supportType4 || this.state.clientSupportDataList.supportButtonText4 || this.state.clientSupportDataList.supportButtonURL4 ?
                                            <tr>
                                                <td>{this.state.clientSupportDataList.supportType4}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText4}</td>

                                                <td className="viewLink"><a href={this.state.clientSupportDataList.supportButtonURL4} target='_blank'>Open Link</a>
                                                </td>

                                            </tr>
                                            :
                                            null
                                    }
                                    {
                                        this.state.clientSupportDataList.supportType5 || this.state.clientSupportDataList.supportButtonText5 || this.state.clientSupportDataList.supportButtonURL5 ?
                                            <tr>
                                                <td>{this.state.clientSupportDataList.supportType5}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText5}</td>

                                                <td className="viewLink"><a href={this.state.clientSupportDataList.supportButtonURL5} target='_blank'>Open Link</a>
                                                </td>

                                            </tr>
                                            :
                                            null
                                    }

                                    {
                                        this.state.clientSupportDataList.supportType6 || this.state.clientSupportDataList.supportButtonText6 || this.state.clientSupportDataList.supportButtonURL6 ?
                                            <tr>
                                                <td>{this.state.clientSupportDataList.supportType6}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText6}</td>

                                                <td className="viewLink"><a href={this.state.clientSupportDataList.supportButtonURL6} target='_blank'>Open Link</a>
                                                </td>

                                            </tr>
                                            :
                                            null
                                    }

                                    {
                                        this.state.clientSupportDataList.supportType7 || this.state.clientSupportDataList.supportButtonText7 || this.state.clientSupportDataList.supportButtonURL7 ?
                                            <tr>
                                                <td>{this.state.clientSupportDataList.supportType7}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText7}</td>
                                                <td className="viewLink"><a href={this.state.clientSupportDataList.supportButtonURL7} target='_blank'>Open Link</a>
                                                </td>

                                            </tr>
                                            :
                                            null

                                    }

                                    {
                                        this.state.clientSupportDataList.supportType8 || this.state.clientSupportDataList.supportButtonText8 || this.state.clientSupportDataList.supportButtonURL8 ?
                                            <tr>
                                                <td>{this.state.clientSupportDataList.supportType8}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText8}</td>

                                                <td className="viewLink"><a href={this.state.clientSupportDataList.supportButtonURL8} target='_blank'>Open Link</a>
                                                </td>

                                            </tr>
                                            :
                                            null

                                    }

                                    {
                                        this.state.clientSupportDataList.supportType9 || this.state.clientSupportDataList.supportButtonText9 || this.state.clientSupportDataList.supportButtonURL9 ?
                                            <tr>
                                                <td>{this.state.clientSupportDataList.supportType9}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText9}</td>

                                                <td className="viewLink"><a href={this.state.clientSupportDataList.supportButtonURL9} target='_blank' >Open Link</a>
                                                </td>

                                            </tr>
                                            :
                                            null

                                    }

                                    {
                                        this.state.clientSupportDataList.supportType10 || this.state.clientSupportDataList.supportButtonText10 || this.state.clientSupportDataList.supportButtonURL10 ?
                                            <tr>
                                                <td>{this.state.clientSupportDataList.supportType10}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText10}</td>

                                                <td className='viewLink'><a href={this.state.clientSupportDataList.supportButtonURL10} target='_blank'>Open Link</a>
                                                </td>

                                            </tr>
                                            :
                                            null

                                    }

                                    {
                                        this.state.clientSupportDataList.supportType11 || this.state.clientSupportDataList.supportButtonText11 || this.state.clientSupportDataList.supportButtonURL11 ?

                                            <tr>
                                                <td>{this.state.clientSupportDataList.supportType11}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText11}</td>
                                                <td className="viewLink"><a href={this.state.clientSupportDataList.supportButtonURL11} target='_blank'>Open Link</a>
                                                </td>

                                            </tr>
                                            : null
                                    }
                                    {
                                        this.state.clientSupportDataList.supportType12 || this.state.clientSupportDataList.supportButtonText12 || this.state.clientSupportDataList.supportButtonURL12 ?
                                            <tr>
                                                <td>{this.state.clientSupportDataList.supportType12}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText12}</td>

                                                <td className="viewLink"><a href={this.state.clientSupportDataList.supportButtonURL12} target='_blank'>Open Link</a>
                                                </td>

                                            </tr>
                                            :
                                            null

                                    }

                                    {
                                        this.state.clientSupportDataList.supportType13 || this.state.clientSupportDataList.supportButtonText13 || this.state.clientSupportDataList.supportButtonURL13 ?
                                            <tr>
                                                <td>{this.state.clientSupportDataList.supportType13}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText13}</td>

                                                <td className="viewLink"><a href={this.state.clientSupportDataList.supportButtonURL13} target='_blank'>Open Link</a>
                                                </td>

                                            </tr>
                                            :
                                            null

                                    }

                                    {
                                        this.state.clientSupportDataList.supportType14 || this.state.clientSupportDataList.supportButtonText14 || this.state.clientSupportDataList.supportButtonURL14 ?
                                            <tr>
                                                <td>{this.state.clientSupportDataList.supportType14}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText14}</td>

                                                <td className="viewLink"><a href={this.state.clientSupportDataList.supportButtonURL14} target='_blank'>Open Link</a>
                                                </td>

                                            </tr>
                                            :
                                            null

                                    }

                                    {
                                        this.state.clientSupportDataList.supportType15 || this.state.clientSupportDataList.supportButtonText15 || this.state.clientSupportDataList.supportButtonURL15 ?
                                            <tr>
                                                <td>{this.state.clientSupportDataList.supportType15}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText15}</td>

                                                <td className="viewLink"><a href={this.state.clientSupportDataList.supportButtonURL15} target='_blank'>Open Link</a>
                                                </td>

                                            </tr>
                                            :
                                            null


                                    }
                                    {
                                        this.state.clientSupportDataList.supportType16 || this.state.clientSupportDataList.supportButtonText16 || this.state.clientSupportDataList.supportButtonURL16 ?
                                            <tr>
                                                <td>{this.state.clientSupportDataList.supportType16}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText16}</td>

                                                <td className="viewLink"><a href={this.state.clientSupportDataList.supportButtonURL16} target='_blank'>Open Link</a>
                                                </td>

                                            </tr>
                                            :
                                            null


                                    }


                                    {
                                        this.state.clientSupportDataList.supportType17 || this.state.clientSupportDataList.supportButtonText17 || this.state.clientSupportDataList.supportButtonURL17 ?
                                            <tr>

                                                <td>{this.state.clientSupportDataList.supportType17 ? this.state.clientSupportDataList.supportType17 : null}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText17 ? this.state.clientSupportDataList.supportButtonText17 : null}</td>

                                                <td className="viewLink"><a href={this.state.clientSupportDataList.supportButtonURL17 ? this.state.clientSupportDataList.supportButtonURL17 : null} target='_blank'>Open Link</a>
                                                </td>

                                            </tr>
                                            :
                                            null

                                    }

                                    {
                                        this.state.clientSupportDataList.supportType18 || this.state.clientSupportDataList.supportButtonText18 || this.state.clientSupportDataList.supportButtonURL18 ?
                                            <tr>
                                                <td>{this.state.clientSupportDataList.supportType18}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText18}</td>

                                                <td className="viewLink"><a href={this.state.clientSupportDataList.supportButtonURL18} target='_blank'>Open Link</a>
                                                </td>
                                            </tr>
                                            :
                                            null
                                    }

                                    {
                                        this.state.clientSupportDataList.supportType19 || this.state.clientSupportDataList.supportButtonText19 || this.state.clientSupportDataList.supportButtonURL19 ?
                                            <tr>
                                                <td>{this.state.clientSupportDataList.supportType19}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText19}</td>

                                                <td className="viewLink"><a href={this.state.clientSupportDataList.supportButtonURL19} target='_blank'>Open Link</a></td>

                                            </tr>
                                            :
                                            null
                                    }
                                    {
                                        this.state.clientSupportDataList.supportType20 || this.state.clientSupportDataList.supportButtonText20 || this.state.clientSupportDataList.supportButtonURL20 ?
                                            <tr>
                                                <td>{this.state.clientSupportDataList.supportType20}</td>
                                                <td>{this.state.clientSupportDataList.supportButtonText20}</td>

                                                <td className="viewLink"><a href={this.state.clientSupportDataList.supportButtonURL20} target='_blank'>Open Link</a>
                                                </td>
                                            </tr>
                                            :
                                            null

                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}