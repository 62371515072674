import React, { Component } from "react";

class AppRoutes extends Component {

  render() {
    return (
      <div>
        
      </div>
    );
  }


}

export default AppRoutes;

