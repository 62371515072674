import React, { Component } from 'react';
import './RegisterForm.scss';
import { Button, FormGroup, Label, Form, Input } from 'reactstrap';
import {Link} from "react-router-dom";
import axios from 'axios';
import combinedServices from "../../shared/services/user-service";
import history from "../../shared/history";
import { Redirect } from "react-router-dom";
import { LoginPopup } from '../LoginPopup/LoginPopup';


export class RegisterForm extends Component {
   constructor(props) {
    super(props);
        this.state = {
            fields :{},
            errors :{},
            successMessage : "",
            errorMessage : "",
            submitButton:false,
            toHome:false,
            termAndCondition :false
           
           
        }
       
    }



  

    

    handleChange = e =>{
        
        this.setState({ [e.target.name]: e.target.value })
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
          fields
        });


    }

   


    handleSubmit = async (e) =>{
        e.preventDefault();
    

        if (this.validateForm() === false) {
        return;
        } else {

            
            let fields = this.state.fields;
            console.log(fields);



            const data = {
            firstName: fields['firstName'],
            lastName: fields['lastName'],
            firmName: fields['firmName'],
            mobileNumber: fields['mobileNumber'],
            email: fields['email'],
            password: fields['password'],
            assestUnderManagement: fields['assetsUnderManagement'],
            investmentTeamPeople: fields['investmentTeam'],
            investmentTeamMeet: fields['investmentTeamMeet'],
            corePortfolio: fields['corePortfolio'],
            satellitePortfolio: fields['satellitePortfolio'],
            macroStategy : fields['macroStategy'] ? fields['macroStategy']:0,
            credit : fields['credit'] ? fields['credit']:0,
            equity : fields['equity'] ? fields['equity']:0,
            economics : fields['economics'] ? fields['economics']:0,
            fundamental : fields['fundamental'] ? fields['fundamental']:0,
            technicalAnalysis : fields['technicalAnalysis'] ? fields['technicalAnalysis']:0,
            quantitative : fields['quantitative'] ? fields['quantitative']:0,
            individualStocks : fields['individualStocks'] ? fields['individualStocks']:0,
            mutualFunds : fields['mutualFunds'] ? fields['mutualFunds']:0,
            eTFs : fields['eTFs'] ? fields['eTFs']:0,
            sMAs : fields['sMAs'] ? fields['sMAs']:0
            
            };
            //console.log("data ",data)

            let response = await combinedServices.userSignUp(data);
            //console.log('api response:',response);
            if(response.responseCode === 200){
                this.setState({
                    errorMessage: false,
                    successMessage: response.responseMessage
                })

               // history.push('/');
             window.location.href= history.location.pathname ;
            }else{
                //console.log(response.responseMessage);

                this.setState({
                    successMessage: false,
                    errorMessage: response.responseMessage
                })

            }
        

        }

        
    }


    termAndConditionChange =(e) =>{

        
        this.setState({
                    submitButton: true
                    
                })



    }


    termAndConditionCheckbox= (e) =>{


        if(e.target.checked){


            this.setState({
             submitButton: false,
             termAndCondition : true
                        
           })
        }else{


            this.setState({
                submitButton: true,
                termAndCondition : false
           })

        }
       
    }

    validateForm=()=>{
        console.log("checkbox value2" ,this.state.termAndCondition);
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
       

        if (!fields["firstName"]) {
            formIsValid = false;
            errors["firstName"] = "Please enter your first name.";
        }

        if (!fields["lastName"]) {
            formIsValid = false;
            errors["lastName"] = "Please enter your last name.";
        }

        if (!fields["firmName"]) {
            formIsValid = false;
            errors["firmName"] = "Please enter your company name.";
        }

        if (!fields["mobileNumber"]) {
            formIsValid = false;
            errors["mobileNumber"] = "Please enter your mobile number.";
        }
         if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Please enter your email.";
        }

        if ( fields["email"] !== "") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
                formIsValid = false;
                errors["email"] = "Please enter valid email.";
            }
        }

        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "Please enter your password.";
        }


        if(!fields["assetsUnderManagement"]){
             formIsValid = false;
            errors["assetsUnderManagement"] = "Please select assets under management option.";

        }


        if(!fields["investmentTeam"]){
            formIsValid = false;
            errors["investmentTeam"] = "Please select invest team option.";
        }

        if(!fields["investmentTeamMeet"]){
            formIsValid = false;
            errors["investmentTeamMeet"] = "Please check only one option.";
        }

         if(!fields["corePortfolio"]){
            formIsValid = false;
            errors["corePortfolio"] = "Please check only one option.";
        }


        if(!fields["satellitePortfolio"]){
            formIsValid = false;
            errors["satellitePortfolio"] = "Please check only one option.";
        }


        



        if(!this.state.termAndCondition){
            formIsValid = false;
            errors["termAndCondition"] = "Please check term and condition policy checkbox.";
        }




        this.setState({
            errors: errors
        });
        return formIsValid
    }

    
    
    openLoginModal = ()  =>{

        this.setState({
            openDeleteModal: true,
            openRegisterModal :true
           
        })
       
    }


    
    loginPopup = () => {
        return (
            <LoginPopup
            isOpenLogin={this.state.openDeleteModal}
            isOpenregister={this.state.openRegisterModal}
            
            />
        );
    }



    render() {
        

        return (

        
            <Form onSubmit={this.handleSubmit}>
            {this.state.successMessage ? <div className="alert alert-success">{this.state.successMessage}</div>: ''}
            {this.state.errorMessage ? <div className="alert alert-danger">{this.state.errorMessage}</div>: ''}
                <div className="inner">
                <div> {this.state.openDeleteModal && this.loginPopup()}</div>
                 
                    <ul className="regForm d-flex flex-wrap">
                        <li>
                            <FormGroup>
                                <Input type="text" name="firstName" value={this.state.fields.firstName} className="regInput" placeholder="First Name" onChange={this.handleChange}/>
                            </FormGroup>
                            {this.state.errors.firstName && (
                            <div  className="errorMsg">{this.state.errors.firstName}</div> 
                            )}
                        </li>
                        <li>
                            <FormGroup>
                                <Input type="text" name="lastName" value={this.state.fields.lastName} className="regInput" placeholder="Last Name" onChange={this.handleChange}/>
                            </FormGroup>
                            {this.state.errors.lastName && (
                             <div className="errorMsg">{this.state.errors.lastName}</div> 
                             )}
                        </li>
                        <li>
                            <FormGroup>
                                <Input type="text" name="firmName" value={this.state.fields.firmName} className="regInput" placeholder="Company Name" onChange={this.handleChange}/>
                            </FormGroup>
                            {this.state.errors.firmName && (
                             <div  className="errorMsg">{this.state.errors.firmName}</div> 
                             )}
                        </li>
                        <li>
                            <FormGroup>
                                <Input type="text" name="mobileNumber" value={this.state.fields.mobileNumber} className="regInput" placeholder="Mobile Phone (text alerts)" onChange={this.handleChange}/>
                            </FormGroup>
                            {this.state.errors.mobileNumber && (
                             <div className="errorMsg">{this.state.errors.mobileNumber}</div> 
                             )}
                        </li>
                        <li>
                            <FormGroup>
                                <Input type="text" name="email" value={this.state.fields.email} className="regInput" placeholder="Work Email" onChange={this.handleChange}/>
                            </FormGroup>
                            {this.state.errors.email && (
                             <div  className="errorMsg">{this.state.errors.email}</div> 
                             )}
                        </li>
                        <li>
                            <FormGroup>
                                <Input type="password" name="password" value={this.state.fields.password} className="regInput" placeholder="Password" onChange={this.handleChange}/>
                            </FormGroup>
                             {this.state.errors.password && (
                             <div  className="errorMsg">{this.state.errors.password}</div> 
                             )}
                        </li>
                    </ul>
                </div>
                <div className="modal-header">
                    <h1 className="modal-title">Customize Your Portal Experience</h1>
                </div>
                <div className="inner">
                    <div className="text-center contentBox pb-4">We want your experience with the MarketDesk  Portal to be perfect. Answer a few questions to help us get to know you better.</div>
                    <FormGroup>
                        <span className="regSubHd">1. Topics you're most focused on (Rank 1st to 4th)</span>
                        <ul className="regForm d-flex flex-wrap">
                            <li>
                                <FormGroup>
                                    <Input type="number" name="macroStategy" value={this.state.fields.macroStategy} placeholder="0" min="0" max="4" className="countInput" onChange={this.handleChange}/>
                                    <span className="optnText contentBox">Macro Stategy</span>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup>
                                    <Input type="number" name="credit" value={this.state.fields.credit} placeholder="0" min="0" max="4" className="countInput" onChange={this.handleChange}/>
                                    <span className="optnText contentBox">Credit</span>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup>
                                    <Input type="number" name="equity" value={this.state.fields.equity} placeholder="0" min="0" max="4" className="countInput" onChange={this.handleChange}/>
                                    <span className="optnText contentBox">Equity</span>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup>
                                    <Input type="number" name="economics" value={this.state.fields.economics} placeholder="0" min="0" max="4" className="countInput" onChange={this.handleChange}/>
                                    <span className="optnText contentBox">Economics</span>
                                </FormGroup>
                            </li>
                        </ul>
                    </FormGroup>
                    <FormGroup>
                        <span className="regSubHd">2. Preferred type of analysis (Rank 1st to 3rd)</span>
                        <ul className="regForm d-flex flex-wrap">
                            <li>
                                <FormGroup>
                                    <Input type="number" name="fundamental" value={this.state.fields.fundamental} placeholder="0" min="0" max="3" className="countInput" onChange={this.handleChange}/>
                                    <span className="optnText contentBox">Fundamental</span>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup>
                                    <Input type="number" name="technicalAnalysis" value={this.state.fields.technicalAnalysis} placeholder="0" min="0" max="3" className="countInput" onChange={this.handleChange}/>
                                    <span className="optnText contentBox">Technical Analysis</span>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup>
                                    <Input type="number" name="quantitative" value={this.state.fields.quantitative} placeholder="0" min="0" max="3" className="countInput" onChange={this.handleChange}/>
                                    <span className="optnText contentBox">Quantitative/Statistics</span>
                                </FormGroup>
                            </li>
                        </ul>
                    </FormGroup>
                    <FormGroup>
                        <span className="regSubHd">3. Preferred investment type (Rank 1st to 4th)</span>
                        <ul className="regForm d-flex flex-wrap">
                            <li>
                                <FormGroup>
                                    <Input type="number" name="individualStocks"  value={this.state.fields.individualStocks} placeholder="0" min="0" max="4" className="countInput" onChange={this.handleChange}/>
                                    <span className="optnText contentBox">Individual Stocks</span>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup>
                                    <Input type="number" name="mutualFunds" value={this.state.fields.mutualFunds} placeholder="0" min="0" max="4" className="countInput" onChange={this.handleChange}/>
                                    <span className="optnText contentBox">Mutual Funds</span>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup>
                                    <Input type="number" name="eTFs" value={this.state.fields.eTFs} placeholder="0" min="0" max="4" className="countInput" onChange={this.handleChange}/>
                                    <span className="optnText contentBox">ETFs</span>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup>
                                    <Input type="number" name="sMAs" value={this.state.fields.sMAs} placeholder="0" min="0" max="4" className="countInput" onChange={this.handleChange}/>
                                    <span className="optnText contentBox">SMAs</span>
                                </FormGroup>
                            </li>
                        </ul>
                    </FormGroup>
                    <FormGroup>
                        <span className="regSubHd">4. Firm Assets Under Management (AUM)</span>
                        <Input type="select" onChange={this.handleChange} name="assetsUnderManagement" id="exampleSelect">
                            <option>Please Select</option>
                             <option value="Less Then $50M">Less Then $50M</option>
                              <option value="$50M-$100M">$50M-$100M</option>
                              <option value="$100M-$250M">$100M-$250M</option>
                              <option value="$250M-$500M">$250M-$500M</option>
                              <option value="$500M-$1B">$500M-$1B</option>
                              <option value="Greater Then $1B">Greater Then $1B</option>
                        </Input>

                        {this.state.errors.assetsUnderManagement && (
                        <div className="errorMsg">{this.state.errors.assetsUnderManagement}</div> 
                        )}
                    </FormGroup>
                    <FormGroup>
                        <span className="regSubHd">5. Number of people on your investment team</span>
                        <Input type="select" onChange={this.handleChange} name="investmentTeam" id="exampleSelect">
                           <option>Please Select</option>
                             <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5 or More</option>

                        </Input>
                        {this.state.errors.investmentTeam && (
                        <div className="errorMsg">{this.state.errors.investmentTeam}</div> 
                        )}
                    </FormGroup>
                    <FormGroup>
                        <span className="regSubHd">6. How often does your investment team meet?</span>
                        <ul className="regForm checkForm d-flex flex-wrap">
                            <li>
                                <FormGroup>
                                    <label className="checkCover">
                                        <span className="checkWrapper"><Input type="radio" onChange={this.handleChange} name="investmentTeamMeet" value="Monthly" className="checkInpt" /><i className="bg"></i></span>
                                        <span className="optnText contentBox"> Monthly</span>
                                    </label>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup>
                                    <label className="checkCover">
                                        <span className="checkWrapper"><Input type="radio" onChange={this.handleChange} name="investmentTeamMeet" value="SemiAnnual" className="checkInpt" /><i className="bg"></i></span>
                                        <span className="optnText contentBox"> Semi-Annual</span>
                                    </label>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup>
                                    <label className="checkCover">
                                        <span className="checkWrapper"><Input type="radio" name="investmentTeamMeet" value="Quarterly" className="checkInpt" onChange={this.handleChange}/><i className="bg"></i></span>
                                        <span className="optnText contentBox"> Quarterly</span>
                                    </label>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup>
                                    <label className="checkCover">
                                        <span className="checkWrapper"><Input type="radio" name="investmentTeamMeet" value="Annual" className="checkInpt" onChange={this.handleChange}/><i className="bg"></i></span>
                                        <span className="optnText contentBox"> Annual</span>
                                    </label>
                                </FormGroup>
                            </li>
                        </ul>

                        {this.state.errors.investmentTeamMeet && (
                        <div  className="errorMsg">{this.state.errors.investmentTeamMeet}</div> 
                        )}
                    </FormGroup>
                    <FormGroup>
                        <span className="regSubHd">7. How often do you make Core portfolio changes?</span>
                        <ul className="regForm checkForm d-flex flex-wrap">
                            <li>
                                <FormGroup>
                                    <label className="checkCover">
                                        <span className="checkWrapper"><Input type="radio" name="corePortfolio" value="Monthly" className="checkInpt" onChange={this.handleChange}/><i className="bg"></i></span>
                                        <span className="optnText contentBox"> Monthly</span>
                                    </label>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup>
                                    <label className="checkCover">
                                        <span className="checkWrapper"><Input type="radio" name="corePortfolio" value="SemiAnnual" className="checkInpt" onChange={this.handleChange}/><i className="bg"></i></span>
                                        <span className="optnText contentBox"> Semi-Annual</span>
                                    </label>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup>
                                    <label className="checkCover">
                                        <span className="checkWrapper"><Input type="radio" name="corePortfolio"  value="Quarterly" className="checkInpt" onChange={this.handleChange}/><i className="bg"></i></span>
                                        <span className="optnText contentBox"> Quarterly</span>
                                    </label>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup>
                                    <label className="checkCover">
                                        <span className="checkWrapper"><Input type="radio" name="corePortfolio" value="Annual" className="checkInpt" onChange={this.handleChange}/><i className="bg"></i></span>
                                        <span className="optnText contentBox"> Annual</span>
                                    </label>
                                </FormGroup>
                            </li>
                        </ul>

                        {this.state.errors.corePortfolio && (

                        <div className="errorMsg">{this.state.errors.corePortfolio}</div> 
                        )}
                    </FormGroup>
                    <FormGroup>
                        <span className="regSubHd">8. How often do you make Satellite / Tactical
portfolio changes?</span>
                        <ul className="regForm checkForm d-flex flex-wrap">
                            <li>
                                <FormGroup>
                                    <label className="checkCover">
                                        <span className="checkWrapper"><Input type="radio" name="satellitePortfolio" value="Monthly" className="checkInpt" onChange={this.handleChange}/><i className="bg"></i></span>
                                        <span className="optnText contentBox"> Monthly</span>
                                    </label>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup>
                                    <label className="checkCover">
                                        <span className="checkWrapper"><Input type="radio" name="satellitePortfolio" value="SemiAnnual" className="checkInpt" onChange={this.handleChange} /><i className="bg"></i></span>
                                        <span className="optnText contentBox"> Semi-Annual</span>
                                    </label>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup>
                                    <label className="checkCover">
                                        <span className="checkWrapper"><Input type="radio" name="satellitePortfolio" value="Quarterly" className="checkInpt" onChange={this.handleChange}sssssss/><i className="bg"></i></span>
                                        <span className="optnText contentBox"> Quarterly</span>
                                    </label>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup>
                                    <label className="checkCover">
                                        <span className="checkWrapper"><Input type="radio" name="satellitePortfolio" value="Annual" className="checkInpt" /><i className="bg"></i></span>
                                        <span className="optnText contentBox"> Annual</span>
                                    </label>
                                </FormGroup>
                            </li>
                        </ul>

                        {this.state.errors.satellitePortfolio && (

                        <div  className="errorMsg">{this.state.errors.satellitePortfolio}</div> 
                        )}
                    </FormGroup>
                </div>
               
                <div className="loginBtn text-center">
                    <div>
                   
                    <div className="tcText"> <span className="checkWrapper"><Input  type="checkbox" onClick={this.termAndConditionCheckbox}  name="termAndCondition" value="this."onChange={this.handleChange} className="checkInpt" onChange={this.handleChange}/><i className="bg"></i></span> I understand and agree to the <Link target="_blank" to="/terms-and-conditions">Terms &amp; Conditons</Link></div>
                    {this.state.errors.termAndCondition && (

                     <div  className="errorMsg">{this.state.errors.termAndCondition}</div> 
                    )}
                        
                    <Button disabled={this.state.submitButton}>Create Account</Button>

                    <div><a href="#" onClick={this.openLoginModal}>Already have an account ? Log in</a></div>
                    </div>
                </div>
            </Form>

            
        )
    }
}
export default RegisterForm;