import './App.scss';

import AppRoutes from "./AppRoutes";

import ScrollToTop from "./scrollToTop";

import Header from './component/headerWithoutLoginPopup/Header';

import ResetPassword from './component/ResetPassword/ResetPassword';

import React, { Component } from "react";

import getLocalData from "./shared/localStorage/getData";

import { Footer } from './component/Footer/Footer';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Home } from "./pages/Home/Home";

import { TermsAndConditions } from "./pages/TermsAndConditions/TermsAndConditions";

import { TrialDaysLeft } from "./pages/TrialDaysLeft/TrialDaysLeft";

import { Library } from "./pages/Library/Library";

import { ReportPage } from "./pages/ReportPage/ReportPage";

import { Bookmarks } from "./pages/Bookmarks/Bookmarks";

import { Following } from "./pages/Following/Following";

import { Watchlist } from "./pages/Watchlist/Watchlist";

import { UserProfile } from "./pages/UserProfile/UserProfile";

import { ManualLinks } from "./pages/ManualLinks/ManualLinks";

import { NewEdit } from "./pages/NewEdit/NewEdit";

import { EditReport } from "./pages/NewEdit/EditReport";

import { ReportList } from "./pages/ReportList/ReportList";

import { UserList } from "./pages/UserList/UserList";

import { Analytics } from "./pages/Analytics/Analytics";

// import { Research } from "./pages/Research/Research";

import { Ocio } from "./pages/Ocio/Ocio";

import { WhiteLabel } from "./pages/WhiteLabel/WhiteLabel";

import history from "./shared/history";

import { Redirect } from "react-router-dom";

import combinedServices from "./shared/services/user-service";
import Resources from './pages/Resources/Resources'
import LatestReport from './pages/LatestReport/LatestReport'

import setLocalData from "./shared/localStorage/setData";

import removeLocalData from "./shared/localStorage/removeData";



class App extends Component {

  constructor(props) {

    super(props);

    this.state = {

      user_logged_in: false,

      admin_logged_in: false,

      showManuLinks: false,

      signOutStatus: true

    };

    //this.setTimeout();

    this.manageLogout();



  }



  manageLogout = async () => {


    const runDateTime = new Date();
    const runTimeInMinutes = new Date(runDateTime.setMinutes(runDateTime.getMinutes()));
    const runTimeStamp = Math.floor(runTimeInMinutes.getTime() / 1000);

    if (getLocalData("user_info", "isLogin")) {
      var data = {};
      let user_info = getLocalData("user_info", "all");

      data.email = user_info.userData.userEmail;
      data.userId = user_info.userData.userId;

      let userProfileResponse = await combinedServices.checkIfUserExists(data);


      if (userProfileResponse.status) {
        var userProfileDetailsData = userProfileResponse.data;


        if (userProfileDetailsData.status != user_info.userData.status) {

          this.logoutUser();
        }
      }


      let userLastActivity = await combinedServices.userLastActivity(data);

      if (runTimeStamp >= user_info.expirationTime) {
        this.logoutUser();
      }
    }

  }








  logoutUser = () => {



    removeLocalData("user_info");

    removeLocalData("admin_info");

    window.location.href = '/';

  };



  componentDidMount = async () => {



    const yourParamName = history.location.pathname;

    const youradminUrl = yourParamName.split('/');



    let is_admin_logged_in = getLocalData("admin_info", "isLogin");

    let is_user_logged_in = getLocalData("user_info", "isLogin");




    if (is_user_logged_in) {

      this.setState({

        showManuLinks: true

      });



    }



    if (is_admin_logged_in && yourParamName === "/") {

      window.location.href = '/admin/manual-links';

    }



    if (youradminUrl[1] == 'admin') {

      if (!is_admin_logged_in) {

        window.location.href = '/';

      } else if (youradminUrl.length == 2 || youradminUrl[2] == '') {



        window.location.href = '/admin/manual-links';

      }



    } else {



      if (!is_user_logged_in && youradminUrl[1] != 'change-Password') {



        if (youradminUrl[1] != '') {



          window.location.href = '/';

        }



      }





    }







  }







  render() {





    return (

      <div>

        <Router>



          <div>





            <ScrollToTop />

            <Switch>



              {/* {this.state.showManuLinks ?

                <Route exact path="/" component={(Home)} />

                : <Route exact path="/" component={(Research)} />} */}

              {this.state.showManuLinks ?

                <Route exact path="/" component={(Home)} />

                : <Route exact path="/" component={(Library)} />}
              {/* <Route exact path="/" component={(Research)} /> */}


              <Route exact path="/home" component={(Home)} />

              {/* <Route exact path="/research" component={(Research)} /> */}

              <Route exact path="/terms-and-conditions" component={(TermsAndConditions)} />

              <Route exact path="/trial-days-left" component={(TrialDaysLeft)} />

              <Route exact path="/library" component={(Library)} />

              <Route exact path="/report-page/:id" component={(ReportPage)} />

              <Route exact path="/bookmarks" component={(Bookmarks)} />

              <Route exact path="/following" component={(Following)} />

              <Route exact path="/watchlist" component={(Watchlist)} />

              <Route exact path="/user-profile" component={(UserProfile)} />

              <Route path="/ocio" component={(Ocio)} />

              <Route path="/white-label" component={(WhiteLabel)} />
              <Route exact path="/resources" component={(Resources)} />
              <Route exact path="/latest-report" component={(LatestReport)} />

              <Route path="/change-Password" component={(ResetPassword)} />

              <Route exact path="/admin/manual-links" component={(ManualLinks)} />

              <Route path="/admin/new-edit" component={(NewEdit)} />

              <Route path="/admin/report-list" component={(ReportList)} />

              <Route path="/admin/user-list" component={(UserList)} />

              <Route path="/admin/analytics" component={(Analytics)} />

              <Route path="/admin/edit-report/" component={EditReport} />





            </Switch>

          </div>





        </Router>

      </div>

    );





  }



}



export default App;

