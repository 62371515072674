import React, { Component } from 'react';
import './Ocio.scss';
import { TopLinks } from '../../component/TopLinks/TopLinks';
import TalkingPoints from '../../assets/images/ClientTalkingPoints.JPG';
import QuantPack from '../../assets/images/QuantPack.JPG';
import { CoreAssetAllocation } from '../../component/CoreAssetAllocation/CoreAssetAllocation';
import { TacticalAssetAllocation } from '../../component/TacticalAssetAllocation/TacticalAssetAllocation';
import { EquityEtf } from '../../component/EquityEtf/EquityEtf';
import Header from '../../component/headerWithoutLoginPopup/Header';
import { Footer } from '../../component/Footer/Footer';
import combinedServices from "../../shared/services/user-service";
import getLocalData from "../../shared/localStorage/getData";
import moment from 'moment';
import removeLocalData from "../../shared/localStorage/removeData";



export class Ocio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ocioResourceStatus: false,
            ocioResourceDataList: {},
            ocioDocumentStatus: false,
            ocioDocumentDataList: {},
            coreModalStatus: false,
            coreModalDataList: {},
            chartErrorMassage: '',
            getModelPortfolioDataList: '',
            getModelPortfolioStatus: false,
            userStatus: '',
            showEtfModalError: ''
        }

    }
    convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
    }

    logoutUser = () => {

        removeLocalData("user_info");
        removeLocalData("admin_info");
        window.location.href = '/';
    };

    componentDidMount = async () => {
        if (getLocalData('user_info', 'isLogin')) {
            var data = {};
            let user_info = getLocalData("user_info", "all");
            data.email = user_info.userData.userEmail;
            data.userId = user_info.userData.userId;
            let userProfileResponse = await combinedServices.checkIfUserExists(data);

            if (userProfileResponse.status) {
                var userProfileDetailsData = userProfileResponse.data;
                if (userProfileDetailsData.status != user_info.userData.status) {
                    this.logoutUser();
                }
            }
            this.setState({
                userStatus: user_info.userData.status

            });


        }

        let ocioResources = await combinedServices.getOCIOResourceData();



        if (ocioResources.status) {

            this.setState({
                ocioResourceDataList: ocioResources.data,
                ocioResourceStatus: ocioResources.status
            });

        } else {

            this.setState({
                ocioResourceStatus: ocioResources.status
            });
        }
        // ==========================commented by chandra
        // let ocioDocument = await combinedServices.getOCIODocumentData();


        // if (ocioDocument.status) {

        //     this.setState({
        //         ocioDocumentDataList: ocioDocument.data,
        //         ocioDocumentStatus: ocioDocument.status
        //     });

        // } else {

        //     this.setState({
        //         ocioDocumentStatus: ocioDocument.status
        //     });
        // }
        // =================End

        let coreModalData = await combinedServices.getOCIOCoreData();

        if (coreModalData.status) {

            this.setState({
                coreModalDataList: coreModalData.data,
                coreModalStatus: coreModalData.status
            });

        } else {

            this.setState({
                coreModalStatus: coreModalData.status
            });
        }

        let getModelPortfolioResponse = await combinedServices.getmodelPortfolioData();
        //console.log(getModelPortfolioResponse)

        if (getModelPortfolioResponse.status) {

            this.setState({
                getModelPortfolioDataList: getModelPortfolioResponse.data,
                getModelPortfolioStatus: getModelPortfolioResponse.status
            });



        } else {

            this.setState({

                getModelPortfolioStatus: getModelPortfolioResponse.status
            });



        }
    }

    showChartError = () => {

        var status = this.state.userStatus;
        if (status === "WHITE LABEL" || status === "RESEARCH" || status === "PENDING" || status === "NOT ACTIVE" || status === "QUANT PACK" || status === "WL PLUS" ) {

            this.setState({
                chartErrorMassage: "Your subscription level does not provide access to Models. Please email support@marketdeskresearch.com"
            });
            setTimeout(() => {
                this.setState({ chartErrorMassage: '' })
            }, 5000)



        }

    }

    showEtfModalError = () => {

        var status = this.state.userStatus;
        if (status === "WHITE LABEL" || status === "RESEARCH" || status === "PENDING" || status === "NOT ACTIVE" || status === "QUANT PACK" || status === "WL PLUS" ) {

            this.setState({
                showEtfModalError: "Your subscription level does not provide access to Models. Please email support@marketdeskresearch.com"
            });
            setTimeout(() => {
                this.setState({ showEtfModalError: '' })
            }, 5000)


        }



    }

    showOcioResourcesError = () => {

        var status = this.state.userStatus;
        if (status === "WHITE LABEL" || status === "RESEARCH" || status === "PENDING" || status === "QUANT PACK"  || status === "WL PLUS" || status === "NOT ACTIVE") {

            this.setState({
                resoucesErrorMassage: "Your subscription level does not provide access to Models. Please email support@marketdeskresearch.com"
            });

            setTimeout(() => {
                this.setState({ resoucesErrorMassage: '' })
            }, 5000)


        }

    }




    render() {

        return (

            <div>
                <Header />
                <TopLinks />
                <div className="container">
                    {/* <div className="mt-3 mb-3 mt-xl-5 mb-xl-5 pb-xl-10"> */}
                    <div className="mt-3 mb-3 mt-xl-5 mb-xl-5">
                        <div className="row justify-content-between">
                            <div className="col-12 col-sm-6 col-lg-4">
                                {/* <div className="mainHd">Outsourced CIO</div> */}
                                <div className="mainHd">Model Portfolios </div>
                                <div className="contentBox">
                                    {this.state.coreModalDataList.OCIOParagraph}

                                </div>
                            </div>
                            <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                {/* <div className="blueBgHd">OCIO Resources</div> */}
                                <div className="blueBgHd">Model Resources</div>
                                <div className="infoBox">
                                    {this.state.ocioResourceStatus && (
                                        <ul className="ocioRes">


                                            <li>
                                                {this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" || this.state.userStatus == "WHITE LABEL" || this.state.userStatus == "WL PLUS" || this.state.userStatus == "QUANT PACK" || this.state.userStatus == "RESEARCH" ?
                                                    <a target="_blank" href={this.state.ocioResourceDataList.hURL1}>{this.state.ocioResourceDataList.hTitle1}</a> : <a onClick={this.showOcioResourcesError}>{this.state.ocioResourceDataList.hTitle1}</a>
                                                }
                                            </li>
                                            <li>
                                                {this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" || this.state.userStatus == "WHITE LABEL" || this.state.userStatus == "WL PLUS" || this.state.userStatus == "QUANT PACK" || this.state.userStatus == "RESEARCH" ?
                                                    <a target="_blank" href={this.state.ocioResourceDataList.hURL2}>{this.state.ocioResourceDataList.hTitle2}</a> : <a onClick={this.showOcioResourcesError}>{this.state.ocioResourceDataList.hTitle2}</a>
                                                }
                                            </li>
                                            <li>
                                                {this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" || this.state.userStatus == "WHITE LABEL" || this.state.userStatus == "WL PLUS" || this.state.userStatus == "QUANT PACK" || this.state.userStatus == "RESEARCH"  ?
                                                    <a target="_blank" href={this.state.ocioResourceDataList.hURL3}>{this.state.ocioResourceDataList.hTitle3}</a> : <a onClick={this.showOcioResourcesError}>{this.state.ocioResourceDataList.hTitle3}</a>
                                                }
                                            </li>


                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 mt-xl-5 pb-xl-10">
                            <div className="text-center contentBox blueClr pb-3 pb-xl-4">{this.state.showEtfModalError}</div>
                            <div className="tableBox etfTable">
                                {this.state.getModelPortfolioStatus &&

                                    <table>
                                        <thead>
                                            <tr>

                                                {/* <th colSpan="2">Quantitative Equity Models <span>(as of {moment(this.state.getModelPortfolioDataList.AsofDate1).format('M/D/YYYY')})</span></th> */}
                                                <th colSpan="2">Quantitative Stock Models </th>

                                                <th><span>YTD</span></th>
                                                <th><span>1 Year</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="date">{moment(this.state.getModelPortfolioDataList.modDate1).format('M/D/YYYY')}</td>
                                                <td className="title">
                                                    {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                        <a href={this.state.getModelPortfolioDataList.modURL1} >{this.state.getModelPortfolioDataList.modTitle1}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle1}</a>
                                                    }
                                                </td>

                                                <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD1 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD1}%</span></td>
                                                <td className="year"><span className={this.state.getModelPortfolioDataList.modYear1 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear1}%</span></td>
                                            </tr>

                                            <tr>
                                                <td className="date">{moment(this.state.getModelPortfolioDataList.modDate2).format('M/D/YYYY')}</td>
                                                <td className="title">
                                                    {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                        <a href={this.state.getModelPortfolioDataList.modURL2} >{this.state.getModelPortfolioDataList.modTitle2}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle2}</a>
                                                    }
                                                </td>

                                                <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD2 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD2}%</span></td>
                                                <td className="year"><span className={this.state.getModelPortfolioDataList.modYear2 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear2}%</span></td>
                                            </tr>

                                            <tr>
                                                <td className="date">{moment(this.state.getModelPortfolioDataList.modDate3).format('M/D/YYYY')}</td>
                                                <td className="title">
                                                    {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                        <a href={this.state.getModelPortfolioDataList.modURL3} >{this.state.getModelPortfolioDataList.modTitle3}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle3}</a>
                                                    }
                                                </td>

                                                <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD3 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD3}%</span></td>
                                                <td className="year"><span className={this.state.getModelPortfolioDataList.modYear3 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear3}%</span></td>
                                            </tr>


                                            <tr>
                                                <td className="date">{moment(this.state.getModelPortfolioDataList.modDate4).format('M/D/YYYY')}</td>
                                                <td className="title">
                                                    {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                        <a href={this.state.getModelPortfolioDataList.modURL4} >{this.state.getModelPortfolioDataList.modTitle4}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle4}</a>
                                                    }
                                                </td>

                                                <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD4 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD4}%</span></td>
                                                <td className="year"><span className={this.state.getModelPortfolioDataList.modYear4 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear4}%</span></td>
                                            </tr>

                                            <tr>
                                                <td className="date">{moment(this.state.getModelPortfolioDataList.modDate5).format('M/D/YYYY')}</td>
                                                <td className="title">
                                                    {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                        <a href={this.state.getModelPortfolioDataList.modURL5} >{this.state.getModelPortfolioDataList.modTitle5}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle5}</a>
                                                    }
                                                </td>
                                                <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD5 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD5}%</span></td>
                                                <td className="year"><span className={this.state.getModelPortfolioDataList.modYear5 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear5}%</span></td>
                                            </tr>


                                            {this.state.getModelPortfolioDataList.modTitle6 &&

                                                <tr>
                                                    <td className="date">{moment(this.state.getModelPortfolioDataList.modDate6).format('M/D/YYYY')}</td>
                                                    <td className="title">
                                                        {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                            <a href={this.state.getModelPortfolioDataList.modURL6} >{this.state.getModelPortfolioDataList.modTitle6}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle6}</a>
                                                        }
                                                    </td>

                                                    <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD6 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD6}%</span></td>
                                                    <td className="year"><span className={this.state.getModelPortfolioDataList.modYear6 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear6}%</span></td>
                                                </tr>
                                            }

                                            {this.state.getModelPortfolioDataList.modTitle7 &&
                                                <tr>
                                                    <td className="date">{moment(this.state.getModelPortfolioDataList.modDate7).format('M/D/YYYY')}</td>
                                                    <td className="title">
                                                        {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                            <a href={this.state.getModelPortfolioDataList.modURL7} >{this.state.getModelPortfolioDataList.modTitle7}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle7}</a>
                                                        }
                                                    </td>

                                                    <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD7 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD7}%</span></td>
                                                    <td className="year"><span className={this.state.getModelPortfolioDataList.modYear7 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear7}%</span></td>
                                                </tr>
                                            }
                                            {this.state.getModelPortfolioDataList.modTitle8 &&

                                                <tr>
                                                    <td className="date">{moment(this.state.getModelPortfolioDataList.modDate8).format('M/D/YYYY')}</td>
                                                    <td className="title">
                                                        {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                            <a href={this.state.getModelPortfolioDataList.modURL8} >{this.state.getModelPortfolioDataList.modTitle8}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle8}</a>
                                                        }
                                                    </td>

                                                    <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD8 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD8}%</span></td>
                                                    <td className="year"><span className={this.state.getModelPortfolioDataList.modYear8 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear8}%</span></td>
                                                </tr>}

                                            {this.state.getModelPortfolioDataList.modTitle9 &&
                                                <tr>
                                                    <td className="date">{moment(this.state.getModelPortfolioDataList.modDate9).format('M/D/YYYY')}</td>
                                                    <td className="title">
                                                        {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                            <a href={this.state.getModelPortfolioDataList.modURL9} >{this.state.getModelPortfolioDataList.modTitle9}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle9}</a>
                                                        }
                                                    </td>

                                                    <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD9 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD9}%</span></td>
                                                    <td className="year"><span className={this.state.getModelPortfolioDataList.modYear9 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear9}%</span></td>
                                                </tr>
                                            }
                                            {this.state.getModelPortfolioDataList.modTitle10 &&
                                                <tr>
                                                    <td className="date">{moment(this.state.getModelPortfolioDataList.modDate10).format('M/D/YYYY')}</td>
                                                    <td className="title">
                                                        {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                            <a href={this.state.getModelPortfolioDataList.modURL10} >{this.state.getModelPortfolioDataList.modTitle10}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle10}</a>
                                                        }
                                                    </td>

                                                    <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD10 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD10}%</span></td>
                                                    <td className="year"><span className={this.state.getModelPortfolioDataList.modYear10 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear10}%</span></td>
                                                </tr>

                                            }


                                        </tbody>
                                    </table>
                                }


                            </div>
                        </div>

                        {/* <div className="mt-4 mt-xl-5 pb-xl-10">
                        

                        <div className="text-center contentBox blueClr pb-4 pb-xl-5">{this.state.resoucesErrorMassage}</div>
                            <div className="text-center contentBox blueClr pb-4 pb-xl-5">{this.state.chartErrorMassage}</div>

                            <ul className="d-flex flex-wrap justify-content-between ocioList">

                                <li className="d-flex">

                                    <div className="img">
                                        {this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                            <a target="_blank" href={this.state.ocioDocumentDataList.clientTalkingPointsURL}>
                                                <img alt="" src={TalkingPoints} />
                                            </a>
                                            : <a onClick={this.showChartError}>
                                                <img alt="" src={TalkingPoints} />
                                            </a>
                                        }
                                    </div>
                                    <div className="det">
                                        {this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                            <a target="_blank" href={this.state.ocioDocumentDataList.clientTalkingPointsURL}>

                                                <span>Market Talking Points</span>
                                        Bulleted commentary with current market views and quick takes for client conversations. These talking points will be updated periodically.
                                        </a>
                                            : <a onClick={this.showChartError}>

                                                <span>Market Talking Points</span>
                                        Bulleted commentary with current market views and quick takes for client conversations. These talking points will be updated periodically.
                                        </a>
                                        }
                                    </div>

                                </li>
                                <li className="d-flex">
                                    <div className="img">
                                        { this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                            <a target="_blank" href={this.state.ocioDocumentDataList.quantPacksURL}>
                                                <img alt="" src={QuantPack} /></a> : <a onClick={this.showChartError}><img alt="" src={QuantPack} />
                                            </a>
                                        }
                                    </div>
                                    <div className="det">
                                        {this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                            <a target="_blank" href={this.state.ocioDocumentDataList.quantPacksURL}>
                                                <span>OCIO QuantPack</span>
                                        Quantitative indicators helping guide asset allocation. The dynamic framework highlights opportunities and risks across markets.
                                        </a> : <a onClick={this.showChartError}><span>OCIO QuantPack</span>
                                        Quantitative indicators helping guide asset allocation. The dynamic framework highlights opportunities and risks across markets.</a>
                                        }
                                    </div>
                                </li>
                            </ul>
                        </div> */}
                        <div className="mt-4 mt-xl-5 pb-xl-10">
                            {this.state.coreModalStatus &&

                                <CoreAssetAllocation />
                            }
                        </div>
                        {/* <div className="mt-4 mt-xl-5 pb-xl-10">

                            <TacticalAssetAllocation />
                        </div> */}
                        {/* <div className="mt-4 mt-xl-5 pb-xl-10">
                            <div className="text-center contentBox blueClr pb-3 pb-xl-4">{this.state.showEtfModalError}</div>
                            <div className="tableBox etfTable">
                                {this.state.getModelPortfolioStatus &&

                                    <table>
                                        <thead>
                                            <tr>

                                                <th colSpan="2">Equity &amp; ETF Models <span>(as of {moment(this.state.getModelPortfolioDataList.AsofDate1).format('M/D/YYYY')})</span></th>
                                                <th><span>YTD</span></th>
                                                <th><span>1 Year</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="date">{moment(this.state.getModelPortfolioDataList.modDate1).format('M/D/YYYY')}</td>
                                                <td className="title">
                                                    {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                        <a  href={this.state.getModelPortfolioDataList.modURL1} >{this.state.getModelPortfolioDataList.modTitle1}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle1}</a>
                                                    }
                                                </td>

                                                <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD1 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD1}%</span></td>
                                                <td className="year"><span className={this.state.getModelPortfolioDataList.modYear1 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear1}%</span></td>
                                            </tr>

                                            <tr>
                                                <td className="date">{moment(this.state.getModelPortfolioDataList.modDate2).format('M/D/YYYY')}</td>
                                                <td className="title">
                                                    {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                        <a  href={this.state.getModelPortfolioDataList.modURL2} >{this.state.getModelPortfolioDataList.modTitle2}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle2}</a>
                                                    }
                                                </td>

                                                <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD2 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD2}%</span></td>
                                                <td className="year"><span className={this.state.getModelPortfolioDataList.modYear2 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear2}%</span></td>
                                            </tr>

                                            <tr>
                                                <td className="date">{moment(this.state.getModelPortfolioDataList.modDate3).format('M/D/YYYY')}</td>
                                                <td className="title">
                                                    {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                        <a  href={this.state.getModelPortfolioDataList.modURL3} >{this.state.getModelPortfolioDataList.modTitle3}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle3}</a>
                                                    }
                                                </td>

                                                <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD3 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD3}%</span></td>
                                                <td className="year"><span className={this.state.getModelPortfolioDataList.modYear3 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear3}%</span></td>
                                            </tr>


                                            <tr>
                                                <td className="date">{moment(this.state.getModelPortfolioDataList.modDate4).format('M/D/YYYY')}</td>
                                                <td className="title">
                                                    {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                        <a  href={this.state.getModelPortfolioDataList.modURL4} >{this.state.getModelPortfolioDataList.modTitle4}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle4}</a>
                                                    }
                                                </td>

                                                <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD4 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD4}%</span></td>
                                                <td className="year"><span className={this.state.getModelPortfolioDataList.modYear4 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear4}%</span></td>
                                            </tr>

                                            <tr>
                                                <td className="date">{moment(this.state.getModelPortfolioDataList.modDate5).format('M/D/YYYY')}</td>
                                                <td className="title">
                                                    {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                        <a  href={this.state.getModelPortfolioDataList.modURL5} >{this.state.getModelPortfolioDataList.modTitle5}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle5}</a>
                                                    }
                                                </td>
                                                <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD5 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD5}%</span></td>
                                                <td className="year"><span className={this.state.getModelPortfolioDataList.modYear5 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear5}%</span></td>
                                            </tr>


                                            {this.state.getModelPortfolioDataList.modTitle6 &&

                                                <tr>
                                                    <td className="date">{moment(this.state.getModelPortfolioDataList.modDate6).format('M/D/YYYY')}</td>
                                                    <td className="title">
                                                        {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                            <a  href={this.state.getModelPortfolioDataList.modURL6} >{this.state.getModelPortfolioDataList.modTitle6}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle6}</a>
                                                        }
                                                    </td>

                                                    <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD6 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD6}%</span></td>
                                                    <td className="year"><span className={this.state.getModelPortfolioDataList.modYear6 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear6}%</span></td>
                                                </tr>
                                            }

                                            {this.state.getModelPortfolioDataList.modTitle7 &&
                                                <tr>
                                                    <td className="date">{moment(this.state.getModelPortfolioDataList.modDate7).format('M/D/YYYY')}</td>
                                                    <td className="title">
                                                        {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                            <a  href={this.state.getModelPortfolioDataList.modURL7} >{this.state.getModelPortfolioDataList.modTitle7}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle7}</a>
                                                        }
                                                    </td>

                                                    <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD7 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD7}%</span></td>
                                                    <td className="year"><span className={this.state.getModelPortfolioDataList.modYear7 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear7}%</span></td>
                                                </tr>
                                            }
                                            {this.state.getModelPortfolioDataList.modTitle8 &&

                                                <tr>
                                                    <td className="date">{moment(this.state.getModelPortfolioDataList.modDate8).format('M/D/YYYY')}</td>
                                                    <td className="title">
                                                        {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                            <a  href={this.state.getModelPortfolioDataList.modURL8} >{this.state.getModelPortfolioDataList.modTitle8}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle8}</a>
                                                        }
                                                    </td>

                                                    <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD8 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD8}%</span></td>
                                                    <td className="year"><span className={this.state.getModelPortfolioDataList.modYear8 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear8}%</span></td>
                                                </tr>}

                                            {this.state.getModelPortfolioDataList.modTitle9 &&
                                                <tr>
                                                    <td className="date">{moment(this.state.getModelPortfolioDataList.modDate9).format('M/D/YYYY')}</td>
                                                    <td className="title">
                                                        {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                            <a  href={this.state.getModelPortfolioDataList.modURL9} >{this.state.getModelPortfolioDataList.modTitle9}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle9}</a>
                                                        }
                                                    </td>

                                                    <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD9 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD9}%</span></td>
                                                    <td className="year"><span className={this.state.getModelPortfolioDataList.modYear9 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear9}%</span></td>
                                                </tr>
                                            }
                                            {this.state.getModelPortfolioDataList.modTitle10 &&
                                                <tr>
                                                    <td className="date">{moment(this.state.getModelPortfolioDataList.modDate10).format('M/D/YYYY')}</td>
                                                    <td className="title">
                                                        {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                            <a  href={this.state.getModelPortfolioDataList.modURL10} >{this.state.getModelPortfolioDataList.modTitle10}</a> : <a onClick={this.showEtfModalError}>{this.state.getModelPortfolioDataList.modTitle10}</a>
                                                        }
                                                    </td>

                                                    <td className="ytd"><span className={this.state.getModelPortfolioDataList.modYTD10 > 0 ? 'up' : 'down'} >{this.state.getModelPortfolioDataList.modYTD10}%</span></td>
                                                    <td className="year"><span className={this.state.getModelPortfolioDataList.modYear10 > 0 ? 'up' : 'down'}>{this.state.getModelPortfolioDataList.modYear10}%</span></td>
                                                </tr>

                                            }


                                        </tbody>
                                    </table>
                                }


                            </div>
                        </div> */}
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
export default Ocio;