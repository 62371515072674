import React, { Component } from 'react';
import './Analytics.scss';
import { AdminTopLinks } from '../../component/TopLinks/AdminTopLinks';
import { Spinner, FormGroup, Button, Input } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from '../../component/headerWithoutLoginPopup/Header';
import { Footer } from '../../component/Footer/Footer';
import { CSVLink, CSVDownload } from "react-csv";
import combinedServices from "../../shared/services/user-service";
import Pagination from "react-js-pagination";
import PaginationCustom from "../../component/pagination";
import moment from 'moment';



export class Analytics extends Component {

    constructor(props) {
        super(props);
        this.csvLink = React.createRef()
        this.state = {
            csvAnalyticsData: '',
            activePage: 1,
            analyticsListData: [],
            isLoading: true

        }

    }


    componentDidMount = async () => {
        var data = {};
        data.page = this.state.activePage;

        let analyticDataResponse = await combinedServices.getUserAnalyticsData(data);
       

        if (analyticDataResponse.status) {
            this.setState({
                analyticsListData: analyticDataResponse.data,
                totalUsers: analyticDataResponse.totalUsers,
                analyticStatus: analyticDataResponse.status,
                isLoading: false

            });


        } else {

            this.setState({
                analyticsListData: analyticDataResponse.data,
                totalUsers: analyticDataResponse.totalUsers,
                analyticStatus: analyticDataResponse.status,
                isLoading: false


            });
        }
     
    }
    exportAnalyticData = async () => {
        let analyticsDetailsResponse = await combinedServices.exportUserAnalyticsData();
        this.setState({ csvAnalyticsData: analyticsDetailsResponse })
        this.csvLink.current.link.click()
    }
   
    handlePageChange = async (pageNumber) => {
        this.setState({ activePage: pageNumber });
        var data = {};
        data.page = pageNumber;
        let analyticDataResponse = await combinedServices.getUserAnalyticsData(data);
        

        if (analyticDataResponse.status) {
            this.setState({
                analyticsListData: analyticDataResponse.data,
                totalUsers: analyticDataResponse.totalUsers,
                analyticStatus: analyticDataResponse.status

            });
        } else {
            this.setState({
                analyticsListData: analyticDataResponse.data,
                totalUsers: analyticDataResponse.totalUsers,
                analyticStatus: analyticDataResponse.status

            });
        }
    }
    render() {

        return (

            <div>
                <Header />
                <AdminTopLinks />

                {this.state.csvAnalyticsData != '' ?
                    <CSVLink
                        data={this.state.csvAnalyticsData}
                        filename="userAnalyticList.csv"
                        className="hidden"
                        ref={this.csvLink}
                        target="_blank"
                    />

                    : ''}
                <div className="container miidlePart">
                    <div className="row">
                        <div className="col-12 pt-4 pb-4">
                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                <div className="d-flex">
                                    <div className="mainHd">User Analytics</div>
                                    <div className="ml-3 mb-4"><Button className="formbtn" onClick={this.exportAnalyticData}>Export</Button></div>
                                </div>

                            </div>
                            <div className="tableBox analyticsTable">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>User ID</th>
                                            <th>Last Active</th>
                                            <th>Bookmarks</th>
                                            <th>Watchlist</th>
                                            <th>Visited</th>
                                            <th>History</th>
                                            <th>Following</th>
                                        </tr>
                                    </thead>
                                    {this.state.analyticStatus && 
                                        <tbody>

                                            {this.state.analyticsListData.map((Listvalue, index) => {
                                                var userBookmarkReport = Listvalue.userBookmarkReport.join(',');
                                                var userWatchlistReport = Listvalue.userWatchlistReport.join(',');
                                                var userVisitedReport = Listvalue.userVisitedReport.join(',');
                                                var userSearchReport = Listvalue.userSearchKey.join(',');
                                                
                                               
                                                var followingTopics ='';
                                                {Listvalue.userFollowingTopics.map((value, index) => {
                                                    var researchCategory = value.researchCategory.join(',');
                                                    var assestClass = value.assestClass.join(',');
                                                    var geography = value.geography.join(',');
                                                    var modelPortfolios = value.modelPortfolios.join(',');
                                                    var reportType = value.reportType.join(',');
                                                    followingTopics = researchCategory + '\n' + assestClass + '\n' + geography + '\n' + modelPortfolios + '\n' + reportType;
                                                })}

                                                return (<tr>

                                                    <td className="id">{Listvalue.userLoggedIn._id}</td>
                                                    <td className="date">{moment(Listvalue.userLoggedIn.last_login).format('M/D/YYYY')}</td>

                                                              {userBookmarkReport.length > 60 ? (
                                                    <td className="bookmark">{userBookmarkReport.substr(1,60)}...</td>
                                                              ):<td className="bookmark">{userBookmarkReport}</td>}
                                                     {userWatchlistReport.length > 60 ? (
                                                    <td className="watchlist">{userWatchlistReport.substr(1,60)}...</td>
                                                     ): <td className="watchlist">{userWatchlistReport}</td>}
                                                     {userVisitedReport.length > 60 ? (
                                                    <td className="visited">{userVisitedReport.substr(1,60)}...</td>
                                                     ):<td className="visited">{userVisitedReport}</td>}
                                                     {userSearchReport.length > 60 ? (
                                                    <td className="history">{userSearchReport.substr(1,60)}...</td>
                                                     ): <td className="history">{userSearchReport}</td>}
                                                   {followingTopics.length > 60 ? (
                                                     <td className="following">{followingTopics.substr(1,60)}..</td>
                                                   ):<td className="following">{followingTopics}</td>}
                                                </tr>)
                                            })}

                                        </tbody>
     } 
     
                                </table>
                            </div>

                            {this.state.isLoading &&
                                <div className="text-center mt-4 loadMore"><Spinner color="primary" /><span className="text">Loading</span> </div>
                            }
                            {this.state.totalUsers > 10 &&
                                <div>

                                    <Pagination
                                        activePage={this.state.activePage}

                                        totalItemsCount={this.state.totalUsers}

                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}



export default Analytics;