import React, { Component } from 'react';
import './TopLinks.scss';
import {NavLink } from "react-router-dom";
import getLocalData from "../../shared/localStorage/getData";
import combinedServices from "../../shared/services/user-service";

export class TopLinks extends Component {


    constructor(props) {
    super(props);
        this.state = {
            userTrialStatus:false,
            trialDays:0
            

        }
       
    }

    componentDidMount=async()=> { 

        if(getLocalData("user_info", "isLogin")){
            let user_info = getLocalData("user_info", "all");

            const data = {
                userId:user_info.userData.userId
            };

            if(user_info.userData.status == "TRIAL USER"){

                this.setState({
                    userTrialStatus : true
                });
    
    
            }


            let trialDateResponse =  await combinedServices.calculateTrailDate(data);
       
            if(trialDateResponse.status){
                if(trialDateResponse.data > 0){
                    this.setState({
                        trialDays : trialDateResponse.data
                    });
                }
    
    
            }
        }



       

    
    }



    render() {
        
        let trial_link_page ='';

        if(this.state.userTrialStatus){
            trial_link_page = <div className="trialEnd"><NavLink  to="/trial-days-left">Trial Ends in {this.state.trialDays} Days</NavLink ></div>

        }

        return (
            <div className="topLinks">
                <div className="container d-flex align-items-center justify-content-between">
                    <ul className="d-flex menu">
                       
                        <li><NavLink exact to="/" activeClassName="active">Home</NavLink ></li>
                        <li><NavLink  to="/latest-report" activeClassName="active">Latest Reports</NavLink ></li>
                        <li><NavLink  to="/white-label" activeClassName="active">White Label</NavLink ></li>
                        {/* <li><NavLink  to="/research" activeClassName="active">Research</NavLink ></li> */}
                        <li><NavLink  to="/ocio" activeClassName="active">Model Portfolios</NavLink ></li>
                        <li><NavLink  to="/library" activeClassName="active"> Research Library</NavLink ></li>
                        <li><NavLink  to="/resources" activeClassName="active">Resources</NavLink ></li>

                    </ul>
                  


                      {trial_link_page}
                    
                </div>
            </div>
        )
    }
}
export default TopLinks;