import React, { Component } from 'react';
import './UserProfile.scss';
import { TopLinks } from '../../component/TopLinks/TopLinks';
import { Form, FormGroup, Input, Button } from 'reactstrap';
import Header from '../../component/headerWithoutLoginPopup/Header';
import history from "../../shared/history";
import removeLocalData from "../../shared/localStorage/removeData";
import { Redirect } from "react-router-dom";
import { Footer } from '../../component/Footer/Footer';
import combinedServices from "../../shared/services/user-service";
import getLocalData from "../../shared/localStorage/getData";
import authHeader from "../../shared/auth-header/auth-header";
export class UserProfile extends Component {

    constructor(props) {
    super(props);
        this.state = {

            toHome: false,
            getUserStatus:false,
            clientSupportStatus:false,
            clientSupportDataList :'',
            category:[],
            assetClass:[],
            reportType:[],
            geography:[],
            frequency:[],
            modelPortfolio :[],
            userId:'',
            successMessage: "",
            errorMessage: "",
            fields: {},
            

        }

       
       
    }

    componentDidMount=async()=> { 

       
       if(getLocalData("user_info", "isLogin")){
        let user_info = getLocalData("user_info", "all");
        
        var data ={};
        data.email= user_info.userData.userEmail ;
        data.userId =user_info.userData.userId ;
        
        this.setState({
            userId :user_info.userData.userId,
            email: user_info.userData.userEmail,
            
        });
        let userProfileResponse =  await combinedServices.getUserProfile(data);
        
        if(userProfileResponse.responseCode = 200 ){
            var userProfileDetailsData = userProfileResponse.data ;
             
            if(userProfileDetailsData.user.status != user_info.userData.status) {

                this.logoutUser();
            }
 
                this.setState({
                     userProfileResponseData :userProfileDetailsData.user,
                     getUserStatus : true
                 });
 
                 
        }else{
 
              this.setState({getUserStatus : false});

        }
    }
           

        let  followedTopicsByUser=  await combinedServices.getFollowedTopicsByUser(data);
        
        if(followedTopicsByUser.status){
            var category = followedTopicsByUser.data.researchCategory;
            var assestClass = followedTopicsByUser.data.assestClass;
            var modelPortfolio = followedTopicsByUser.data.modelPortfolios;
            var geography = followedTopicsByUser.data.geography;
            var reportType = followedTopicsByUser.data.reportType;
            this.setState({
                category:category,
                assetClass:assestClass,
                modelPortfolio:modelPortfolio,
                geography:geography,
                reportType:reportType
    
                    
            });

            category.map((value) => {
                let fields = this.state.fields;
                fields[value] = true;
                this.setState({
                    fields
                });
    
    
    
            });

            assestClass.map((value) => {


                let fields = this.state.fields;
                fields[value] = true;
                this.setState({
                    fields
                });
    
    
    
            });

            modelPortfolio.map((value) => {


                let fields = this.state.fields;
                fields[value] = true;
                this.setState({
                    fields
                });
    
    
    
            });

            geography.map((value) => {


                let fields = this.state.fields;
                fields[value] = true;
                this.setState({
                    fields
                });
    
    
    
            });

            reportType.map((value) => {


                let fields = this.state.fields;
                fields[value] = true;
                this.setState({
                    fields
                });
    
    
    
            });


        }
      

        let clientSupportData =  await combinedServices.getClientSupportData(data);
        
    
        if(clientSupportData.status ){

                this.setState({
                    clientSupportDataList :clientSupportData.data,
                    clientSupportStatus : clientSupportData.status
                });
        }else{

            this.setState({
               clientSupportStatus : clientSupportData.status
            });

        }
        



    }

   handleChangeCategory = e => {
    
        let fields = this.state.fields;
        fields[e.target.value] = e.target.checked;
        this.setState({
            fields
        });


        if (e.target.checked) {

            const pushArray = this.state.category;
            pushArray.push(e.target.value);

            this.setState({
                category: pushArray,


            });

        } else {
            var array = this.state.category;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.
            array.splice(index, 1);
            this.setState({
                category: array,



            });


        }


    }

    handleChangeModelPortfolio = e =>{


        let fields = this.state.fields;
        fields[e.target.value] = e.target.checked;
        this.setState({
            fields
        });


        if (e.target.checked) {

            const pushArray = this.state.modelPortfolio;
            pushArray.push(e.target.value);

            this.setState({
                modelPortfolio: pushArray,


            });

        } else {
            var array = this.state.modelPortfolio;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.
            array.splice(index, 1);
            this.setState({
                modelPortfolio: array,
            });


        }




    }

    handleChangeGeography = e =>{


        let fields = this.state.fields;
        fields[e.target.value] = e.target.checked;
        this.setState({
            fields
        });


        if (e.target.checked) {

            const pushArray = this.state.geography;
            pushArray.push(e.target.value);

            this.setState({
                geography: pushArray,


            });

        } else {
            var array = this.state.geography;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.
            array.splice(index, 1);
            this.setState({
                geography: array,
            });


        }




    }

    handleChangeAssetClass = e =>{

        let fields = this.state.fields;
        fields[e.target.value] = e.target.checked;
        this.setState({
            fields
        });


        if (e.target.checked) {

            const pushArray = this.state.assetClass;
            pushArray.push(e.target.value);

            this.setState({
                assetClass: pushArray,


            });

        } else {
            var array = this.state.assetClass;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.
            array.splice(index, 1);
            this.setState({
                assetClass: array,
            });


        }



    }

    handleChangeReportType = e =>{

        let fields = this.state.fields;
        fields[e.target.value] = e.target.checked;
        this.setState({
            fields
        });


        if (e.target.checked) {

            const pushArray = this.state.reportType;
            pushArray.push(e.target.value);

            this.setState({
                reportType: pushArray,


            });

        } else {
            var array = this.state.reportType;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.
            array.splice(index, 1);
            this.setState({
                reportType: array,
            });


        }



    }

    handleSubmit = async (e) => {
        e.preventDefault();
        var data ={};


        data.researchCategory = this.state.category;
        data.assestClass = this.state.assetClass;
        data.reportType = this.state.reportType;
        data.geography = this.state.geography;
        data.modelPortfolios = this.state.modelPortfolio;
        data.userId = this.state.userId;
       
        let userFollowingTopics =  await combinedServices.saveFollowedTopics(data);
       
       
        if (userFollowingTopics.status) {

            this.setState({
                errorMessage: false,
                successMessage: "Preference Update Successful."
                		

            })
            setTimeout(() => {
                this.setState({ successMessage: '' })
            }, 3000)


        } else {

           

            this.setState({
                successMessage: false,
                errorMessage: userFollowingTopics.msg
            })
            setTimeout(() => {
                this.setState({ errorMessage: '' })
            }, 3000)

        }

        

    }

    logoutUser = () => {
            
            removeLocalData("user_info");
            removeLocalData("admin_info");
            window.location.href='/';
    };

    render() {



        return (

            <div>
            <Header/>
                <TopLinks />
                <Form onSubmit={this.handleSubmit}>
                
                <div className="container miidlePart">
                    <div className="row">
                    <div className="col-12 col-lg-3 pt-5 pb-0 pb-lg-5">
                            <div className="profileTop">
                                <div className="mainHd">My Profile</div>
                                <a onClick={this.logoutUser} className="logoutBtn">Logout</a>
                            </div>

                             {this.state.getUserStatus &&(
                            
                            <div className='mt-5'>
                           
                                <div className="blueBgHd">Contact Information</div>

                                
                                <div className="contactInfo">
                                    <span>Email Address / Username</span>
                                    <a>{this.state.userProfileResponseData.email}</a>
                                </div>
                                <div className="contactInfo">
                                    <span>First &amp; Last Name</span>
                                    {this.state.userProfileResponseData.firstName} {this.state.userProfileResponseData.lastName}
                                </div>
                                <div className="contactInfo">
                                    <span>Mobile Number</span>
                                    {this.state.userProfileResponseData.mobileNumber}
                                </div>
                                <div className="contactInfo">
                                    <span>Firm Name</span>
                                   {this.state.userProfileResponseData.firmName}
                                </div>
                            
                                <div className="mt-4 contentBox profileInfo"><a target="_blank" href="mailto:support@marketdeskresearch.com?subject=MarketDesk - Update My Contact Information&body=Enter your new contact information below">Update Your Information</a></div>
                            </div>
                            )}
                           
                        </div>
                        
                        
                        <div className="col-12 col-lg-9 pt-5 pb-5">
                        {this.state.successMessage ? <div className="alert alert-success">{this.state.successMessage}</div>: ''}
                                    {this.state.errorMessage ? <div className="alert alert-danger">{this.state.errorMessage}</div>: ''}
                            <div className="profileHd">
                                <div className="mainHd">Preferences</div>
                                <div className="text">Select the Research Topics You'd Like to Follow</div>
                            </div>
                            <div className="profileDetCon mt-4">
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <div>
                                            <div className="blueBgHd">Category</div>
                                            <div>
                                                <ul className="libOptList">
                                                {/* <li>
                                                        <FormGroup>
                                                            <label>
                                                                <span className="checkWrapper">
                                                                    <Input 
                                                                    type="checkbox" 
                                                                    checked={this.state.fields.OCIO} 
                                                                    onChange={this.handleChangeCategory} 
                                                                    name="rearchCategory"
                                                                    value="OCIO" 
                                                                    className="checkInpt" />
                                                                    <i className="bg"></i>
                                                                    </span>
                                                                <span className="contentBox">OCIO</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li> */}
                                                    <li>
                                                        <FormGroup>
                                                            <label>
                                                                <span className="checkWrapper">
                                                                    <Input 
                                                                    type="checkbox" 
                                                                    checked={this.state.fields.MacroStrategy} 
                                                                    onChange={this.handleChangeCategory} 
                                                                    name="rearchCategory"
                                                                    value="MacroStrategy" 
                                                                    className="checkInpt" />
                                                                    <i className="bg"></i>
                                                                    </span>
                                                                <span className="contentBox"> Macro Strategy</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <label>
                                                                <span className="checkWrapper">
                                                                    <Input 
                                                                    type="checkbox" 
                                                                    checked={this.state.fields.CoreRating}
                                                                     onChange={this.handleChangeCategory} 
                                                                     name="rearchCategory"
                                                                     value="CoreRating" 
                                                                    className="checkInpt" />
                                                                    <i className="bg"></i></span>
                                                                <span className="contentBox"> Core Ratings</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <label>
                                                                <span className="checkWrapper"><Input 
                                                                type="checkbox" 
                                                                checked={this.state.fields.TacticalIdeas}
                                                                onChange={this.handleChangeCategory} 
                                                                name="rearchCategory"
                                                                value="TacticalIdeas" 
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                                <span className="contentBox"> Tactical Ideas</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                   
                                                </ul>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="blueBgHd">Model Portfolios</div>
                                            <div>
                                                <ul className="libOptList">
                                                    <li>
                                                        <FormGroup>
                                                            <label>
                                                                <span className="checkWrapper"><Input 
                                                                type="checkbox" 
                                                                checked={this.state.fields.LargeCap}
                                                                onChange={this.handleChangeModelPortfolio} 
                                                                name="modelPortfolio"
                                                                value="LargeCap" 
                                                                className="checkInpt" />
                                                                <i className="bg"></i></span>
                                                                <span className="contentBox"> Large Cap</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <label>
                                                                <span className="checkWrapper"><Input 
                                                                type="checkbox"
                                                                checked={this.state.fields.SmallCap}
                                                                onChange={this.handleChangeModelPortfolio} 
                                                                name="modelPortfolio"
                                                                value="SmallCap" 
                                                                className="checkInpt" />
                                                                <i className="bg"></i></span>
                                                                <span className="contentBox"> Small Cap</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <label>
                                                                <span className="checkWrapper"><Input 
                                                                type="checkbox" 
                                                                checked={this.state.fields.DividendIncome}
                                                                onChange={this.handleChangeModelPortfolio} 
                                                                name="modelPortfolio"
                                                                value="DividendIncome" 
                                                                className="checkInpt" />
                                                                <i className="bg"></i></span>
                                                                <span className="contentBox"> Dividend/Income</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <label>
                                                                <span className="checkWrapper"><Input 
                                                                type="checkbox" 
                                                                checked={this.state.fields.Momentum}
                                                                onChange={this.handleChangeModelPortfolio} 
                                                                name="modelPortfolio"
                                                                value="Momentum"
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                                <span className="contentBox"> Momentum</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <label>
                                                                <span className="checkWrapper"><Input 
                                                                type="checkbox"
                                                                checked={this.state.fields.ETFs} 
                                                                onChange={this.handleChangeModelPortfolio} 
                                                                name="modelPortfolio"
                                                                value="ETFs"
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                                <span className="contentBox"> ETFs</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div>
                                            <div className="blueBgHd">Geography</div>
                                            <div>
                                                <ul className="libOptList">
                                                    <li>
                                                        <FormGroup>
                                                            <label>
                                                                <span className="checkWrapper"><Input 
                                                                type="checkbox"
                                                                checked={this.state.fields.us} 
                                                                onChange={this.handleChangeGeography} 
                                                                name="geography"
                                                                value="us"
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                                <span className="contentBox"> U.S.</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <label>
                                                                <span className="checkWrapper"><Input 
                                                                type="checkbox"
                                                                checked={this.state.fields.LatinAmerica} 
                                                                onChange={this.handleChangeGeography} 
                                                                name="geography"
                                                                value="LatinAmerica" 
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                                <span className="contentBox"> Latin America</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <label>
                                                                <span className="checkWrapper"><Input 
                                                                type="checkbox" 
                                                                checked={this.state.fields.Europe}
                                                                onChange={this.handleChangeGeography} 
                                                                name="geography"
                                                                value="Europe" 
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                                <span className="contentBox"> Europe</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <label>
                                                                <span className="checkWrapper"><Input 
                                                                type="checkbox"
                                                                checked={this.state.fields.AsiaPacific}
                                                                onChange={this.handleChangeGeography} 
                                                                name="geography"
                                                                value="AsiaPacific" 
                                                                
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                                <span className="contentBox"> Asia-Pacific</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="blueBgHd">Asset Class</div>
                                            <div>

                                            <ul className="libOptList">
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox" 
                                                            checked={this.state.fields.Equity}
                                                            onChange={this.handleChangeAssetClass} 
                                                            name="asset" value="Equity" 
                                                            className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Equity</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox" 
                                                            checked={this.state.fields.Credit}
                                                            onChange={this.handleChangeAssetClass} 
                                                            name="asset" 
                                                            value="Credit" 
                                                            className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Credit</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input 
                                                            type="checkbox" 
                                                            checked={this.state.fields.Economics}
                                                            onChange={this.handleChangeAssetClass} 
                                                            name="asset" 
                                                            value="Economics" 
                                                            className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Economics</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input 
                                                            type="checkbox" 
                                                            checked={this.state.fields.Alternatives}
                                                            onChange={this.handleChangeAssetClass} 
                                                            name="asset"  
                                                            value="Alternatives" 
                                                            className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Alternatives</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                        </ul>
                                               
                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div>
                                            <div className="blueBgHd">Report Type</div>
                                            <div>
                                                <ul className="libOptList">
                                                <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input 
                                                            type="checkbox" 
                                                            checked={this.state.fields.Sector}
                                                            onChange={this.handleChangeReportType} 
                                                             name="reporttype" 
                                                             value="Sector" 
                                                             className="checkInpt" />
                                                             <i className="bg"></i></span>
                                                        <span className="contentBox"> Sector</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input 
                                                            type="checkbox" 
                                                            checked={this.state.fields.Industry}
                                                            onChange={this.handleChangeReportType} 
                                                             name="reporttype" 
                                                             value="Industry"
                                                              className="checkInpt" />
                                                              <i className="bg"></i></span>
                                                        <span className="contentBox"> Industry</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input 
                                                            type="checkbox" 
                                                            checked={this.state.fields.Thematic}
                                                            onChange={this.handleChangeReportType}
                                                            name="reporttype" 
                                                            value="Thematic" 
                                                            className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Thematic</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                        <Input 
                                                        type="checkbox" 
                                                        checked={this.state.fields.Factor}
                                                        onChange={this.handleChangeReportType} 
                                                        name="reporttype" 
                                                        value="Factor" 
                                                        className="checkInpt" />
                                                        <i className="bg"></i></span>
                                                        <span className="contentBox"> Factor</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                             checked={this.state.fields.WashingtonPolicy} 
                                                            onChange={this.handleChangeReportType} 
                                                            name="reporttype" 
                                                            value="WashingtonPolicy" 
                                                            className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox">MarketDesk Primers</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox" 
                                                             checked={this.state.fields.International} 
                                                            onChange={this.handleChangeReportType} 
                                                            name="reporttype" 
                                                            value="International" 
                                                            className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> International</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox" 
                                                             checked={this.state.fields.Quantitative} 
                                                            onChange={this.handleChangeReportType} 
                                                            name="reporttype" 
                                                            value="Quantitative" 
                                                            className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Quantitative</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                             checked={this.state.fields.ETF} 
                                                            onChange={this.handleChangeReportType} 
                                                            name="reporttype" 
                                                            value="ETF" 
                                                            className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> ETF</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox" 
                                                             checked={this.state.fields.IndividualStocks} 
                                                            onChange={this.handleChangeReportType} 
                                                            name="reporttype" 
                                                            value="IndividualStocks" 
                                                            className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Individual Stocks</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div><Button className="formbtn">Submit</Button></div>
                            </div>
                        </div>
                    
                    </div>
                    {/* {this.state.clientSupportStatus && (
                    <div className="row">
                        <div className="col-12">
                            <div className="profileBot">
                                <div className="profileHd">
                                    <div className="mainHd">Client Support</div>
                                    <div className="text"> Click Below to Access Resources</div>
                                    <ul className="profileBtns d-flex flex-wrap">
                                        <li><a target="_blank" href={this.state.clientSupportDataList.supportButtonURL1}>{this.state.clientSupportDataList.supportButtonText1}</a></li>
                                        <li><a target="_blank" href={this.state.clientSupportDataList.supportButtonURL2}>{this.state.clientSupportDataList.supportButtonText2}</a></li>
                                        <li><a target="_blank" href={this.state.clientSupportDataList.supportButtonURL3}>{this.state.clientSupportDataList.supportButtonText3}</a></li>
                                        <li><a target="_blank" href={this.state.clientSupportDataList.supportButtonURL4}>{this.state.clientSupportDataList.supportButtonText4}</a></li>
                                        <li><a target="_blank" href={this.state.clientSupportDataList.supportButtonURL5}>{this.state.clientSupportDataList.supportButtonText5}</a></li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    )} */}
                </div>
               
                </Form>
                <Footer/>
            </div>

            
        );
    }
}
export default UserProfile;