import React, { Component } from 'react';
import './ForgotPassword.scss';
import { Button, FormGroup, Label, Form, Input } from 'reactstrap';
import combinedServices from "../../shared/services/user-service";
import { Redirect } from "react-router-dom";

export class ForgotPassword extends Component {


    constructor(props) {
    super(props);
        this.state = {
            email : '',
            errors :{},
            successMessage : "",
            errorMessage : "",
            toHome:false
           
        }
       
    }



    handleChange = e =>{
        this.setState({ email : e.target.value })

    }

     handleSubmit = async (e) =>{
        e.preventDefault();
        const form = e.target;

        if (this.validateForm() === false) {
         return;
        } else {
            
            const data = {
            email: this.state.email,
            
            };
            

            let reponsedata = await combinedServices.forgotPassword(data);
            let response = reponsedata.data;

        

            if(response.responseCode === 200){
               this.setState({
                    errorMessage: false,
                    successMessage: response.responseMessage
                    
                })

            }else{
                

                this.setState({
                    successMessage: false,
                    errorMessage: response.responseMessage
                })

            }

        }

        
    }


     validateForm=()=>{
        let email = this.state.email;
        let errors = {};
        let formIsValid = true;
       

        if (!email) {
            formIsValid = false;
            errors["email"] = "Please enter your email.";
        }
        this.setState({
            errors: errors
        });
        return formIsValid
    }






    render() {

        return (
            <Form onSubmit={this.handleSubmit}>
            {this.state.successMessage ? <div className="alert alert-success">{this.state.successMessage}</div>: ''}
            {this.state.errorMessage ? <div className="alert alert-danger">{this.state.errorMessage}</div>: ''}
            
                <div className="inner">
                    <FormGroup>
                        <Label>Email</Label>
                        <Input type="text" name="email" value={this.state.email} onChange={this.handleChange} className="loginInput" />
                        {this.state.errors.email && (
                        <div className="errorMsg">{this.state.errors.email}</div> 
                        )}
                    </FormGroup>
                </div>
                <div className="loginBtn"><Button>Reset My Password</Button></div>
            </Form>
        )
    }
}
export default ForgotPassword;