import React, { Component, PropTypes } from 'react';
import './NewEdit.scss';
import { AdminTopLinks } from '../../component/TopLinks/AdminTopLinks';
import { Form, Label, FormGroup, Input, Button } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from '../../component/headerWithoutLoginPopup/Header';
import { Footer } from '../../component/Footer/Footer';
import dateFormat from 'dateformat';
import combinedServices from "../../shared/services/user-service";
import moment from 'moment';
import Swal from "sweetalert2";
import history from "../../shared/history";
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
export class NewEdit extends Component {


    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            successMessage: "",
            errorMessage: "",
            selectDate: new Date(),
            researchCategory: [],
            assetClass: [],
            reportType: [],
            geography: [],
            frequency: [],
            reportPera: '',
            reportWatchlistPera: '',
        }

    }

    handleChangeReportPeragraph = (value) => {
        let fields = this.state.fields;
        fields['reportParagraph'] = value;
        this.setState({
            fields,
            reportPera: value
        });
    }
    handleChangeReportWatchlistPeragraph = (value) => {

        let fields = this.state.fields;
        fields['watchListUpdates'] = value;
        this.setState({
            fields,
            reportWatchlistPera: value
        });


    }


    handleChange = e => {

        this.setState({ [e.target.name]: e.target.value })
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });

    }


    handleChangeCategory = e => {

        if (e.target.checked) {

            const pushArray = this.state.researchCategory;
            pushArray.push(e.target.value);


            this.setState({
                researchCategory: pushArray
            });


        } else {
            var array = this.state.researchCategory;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.
            //delete array[index];
            array.splice(index, 1);
            this.setState({
                researchCategory: array
            });


        }


    }



    handleChangeAssetClass = (e) => {

        if (e.target.checked) {

            const pushArray1 = this.state.assetClass;
            pushArray1.push(e.target.value);


            this.setState({
                assetClass: pushArray1
            });


        } else {
            var array = this.state.assetClass;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.
            // delete array[index];
            array.splice(index, 1);
            this.setState({
                assetClass: array
            });


        }


    }

    handleChangeReportType = e => {

        if (e.target.checked) {

            const pushArray2 = this.state.reportType;
            pushArray2.push(e.target.value);


            this.setState({
                reportType: pushArray2
            });


        } else {
            var array = this.state.reportType;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.
            //delete array[index];
            array.splice(index, 1);
            this.setState({
                reportType: array
            });


        }


    }


    handleChangeGeography = e => {

        if (e.target.checked) {

            const pushArray3 = this.state.geography;
            pushArray3.push(e.target.value);


            this.setState({
                geography: pushArray3
            });


        } else {
            var array = this.state.geography;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.
            //delete array[index];
            array.splice(index, 1);
            this.setState({
                geography: array
            });


        }


    }


    handleChangeFrequency = e => {

        if (e.target.checked) {

            const pushArray4 = this.state.frequency;
            pushArray4.push(e.target.value);


            this.setState({
                frequency: pushArray4
            });


        } else {
            var array = this.state.frequency;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.
            //delete array[index];
            array.splice(index, 1);
            this.setState({
                frequency: array
            });


        }




    }



    handleDateChange = (date) => {

        this.setState({
            selectDate: date

        })
    }

    convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
    }




    handleSubmit = async (e) => {
        //alert();
        e.preventDefault();

        if (this.validateForm() === false) {
            return;
        } else {

            const fields = this.state.fields;
            const researchCategory = this.state.researchCategory;
            const assestClass = this.state.assetClass;
            const reportType = this.state.reportType;
            const geography = this.state.geography;
            const frequency = this.state.frequency;

            const data = {

                researchCategory,
                assestClass,
                reportType,
                geography,
                frequency,
                publishedDate: this.state.selectDate,
                reportFormat: fields['reportFormate'],
                reportTitle: fields['reportTitle'],
                reportSubTitle: fields['reportSubTitle'],
                buttonText1: fields['buttonText1'],
                buttonURL1: fields['buttonURL1'],
                buttonText2: fields['buttonText2'] ? fields['buttonText2'] : '',
                buttonURL2: fields['buttonURL2'] ? fields['buttonURL2'] : '',
                buttonText3: fields['buttonText3'] ? fields['buttonText3'] : '',
                buttonURL3: fields['buttonURL3'] ? fields['buttonURL3'] : '',

                reportPDFImgURL: fields['reportpdfImageUrl'],
                reportPDFURL: fields['reportpdfurl'],
                reportParagraph: fields['reportParagraph'],
                watchlistUpdates: fields['watchListUpdates'],

            }



            let responseData = await combinedServices.saveNewEditReport(data);

            if (responseData.status) {

                this.setState({
                    errorMessage: false,
                    successMessage: responseData.message
                })
                this.sucessMsgAlert();

            } else {



                this.setState({
                    successMessage: false,
                    errorMessage: responseData.message
                })

                this.errorMessage();


            }


        }

    }


    validateForm = () => {
        let fields = this.state.fields;

        let errors = {};
        let formIsValid = true;


        if (!fields["reportFormate"]) {
            formIsValid = false;
            errors["reportFormate"] = "Please enter the report format.";
        }

        if (!fields["reportTitle"]) {
            formIsValid = false;
            errors["reportTitle"] = "Please enter the report title.";
        }

        if (!fields["reportSubTitle"]) {
            formIsValid = false;
            errors["reportSubTitle"] = "Please enter the report sub title.";
        }

        if (!fields["buttonText1"]) {
            formIsValid = false;
            errors["buttonText1"] = "Please enter button text1.";
        }
        if (!fields["buttonURL1"]) {
            formIsValid = false;
            errors["buttonURL1"] = "Please enter button URL1.";
        }



        if (!fields["reportpdfImageUrl"]) {
            formIsValid = false;
            errors["reportpdfImageUrl"] = "Please enter report pdf image url.";
        }
        if (!fields["reportpdfurl"]) {
            formIsValid = false;
            errors["reportpdfurl"] = "Please enter report pdf url";
        }
        if (!fields["reportParagraph"]) {
            formIsValid = false;
            errors["reportParagraph"] = "Please enter report paragraph .";
        }

        if (!fields["watchListUpdates"]) {
            formIsValid = false;
            errors["watchListUpdates"] = "Please enter watchlist updates .";
        }

        this.setState({
            errors: errors
        });
        return formIsValid
    }



    sucessMsgAlert = () => {

        return (
            Swal.fire({
                title: 'Success',
                type: 'Success',
                text: this.state.successMessage,
            }).then(function () {
                window.location.reload();

            })


        )
    }

    errorMsgAlert = () => {

        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: this.state.errorMessage,
        });



    }


    render() {
        var submit_button = <div className="pt-2 pt-lg-4"><Button className="formbtn">Submit</Button></div>;


        return (

            <div>
                <Header />
                <AdminTopLinks />
                <Form onSubmit={this.handleSubmit}>

                    <div className="container miidlePart">
                        <div className="row">
                            <div className="col-12">
                                <div className="mainHd pt-4">New/Edit Report</div>
                            </div>
                            <div className="col-12 col-lg-3 pt-2 pb-0 pb-lg-4">

                                <div className="mtM">
                                    <div className="blueBgHd"> Category</div>
                                    <div className="infoBox">
                                        <ul className="libOptList">


                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeCategory}
                                                                name="rearchCategory"
                                                                value="OCIO"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> OCIO</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                name="rearchCategory"
                                                                onChange={this.handleChangeCategory}
                                                                value="MacroStrategy"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Macro Strategy</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                name="rearchCategory"
                                                                onChange={this.handleChangeCategory}
                                                                value="CoreRating" className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Core Ratings</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                name="rearchCategory"
                                                                onChange={this.handleChangeCategory}
                                                                value="TacticalIdeas"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Tactical Ideas</span>
                                                    </label>
                                                </FormGroup>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="mtM">
                                    <div className="blueBgHd">Asset Class</div>
                                    <div className="infoBox">
                                        <ul className="libOptList">
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeAssetClass}
                                                                name="asset" value="Equity"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Equity</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeAssetClass}
                                                                name="asset"
                                                                value="Credit"
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> Credit</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeAssetClass}
                                                                name="asset"
                                                                value="Economics"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Economics</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeAssetClass}
                                                                name="asset"
                                                                value="Alternatives"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Alternatives</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="mtM">
                                    <div className="blueBgHd">Report Type</div>
                                    <div className="infoBox">
                                        <ul className="libOptList">
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeReportType}
                                                                name="reporttype"
                                                                value="Sector"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Sector</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeReportType}
                                                                name="reporttype"
                                                                value="Industry"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Industry</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeReportType}
                                                                name="reporttype"
                                                                value="Thematic"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Thematic</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeReportType}
                                                                name="reporttype"
                                                                value="Factor"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Factor</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeReportType}
                                                                name="reporttype"
                                                                value="MarketDeskPrimer"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox">MarketDesk Primer</span>
                                                       
                                                        {/* <span className="contentBox"> Washington Policy</span> */}
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeReportType}
                                                                name="reporttype"
                                                                value="International"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> International</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeReportType}
                                                                name="reporttype"
                                                                value="Quantitative"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Quantitative</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeReportType}
                                                                name="reporttype"
                                                                value="ETF"
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                        <span className="contentBox"> ETF</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeReportType}
                                                                name="reporttype"
                                                                value="IndividualStocks"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Individual Stocks</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                        <span className="checkWrapper"><Input type="checkbox"
                                                            name="reporttype"
                                                            onChange={this.handleChangeReportType}
                                                            value="ModelPortfolios"
                                                            className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Model Portfolios</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="mtM">
                                    <div className="blueBgHd">Geography</div>
                                    <div className="infoBox">
                                        <ul className="libOptList">
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeGeography}
                                                                name="geography"
                                                                value="us"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> U.S</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeGeography}
                                                                name="geography"
                                                                value="LatinAmerica"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Latin America</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeGeography}
                                                                name="geography"
                                                                value="Europe"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Europe</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeGeography}
                                                                name="geography"
                                                                value="AsiaPacific"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Asia-Pacific</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="mtM">
                                    <div className="blueBgHd">Frequency</div>
                                    <div className="infoBox">
                                        <ul className="libOptList">
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeFrequency}
                                                                name="frequency"
                                                                value="Weekly"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Weekly</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeFrequency}
                                                                name="frequency"
                                                                value="biWeekly"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Bi-Weekly</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeFrequency}
                                                                name="frequency"
                                                                value="Monthly"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Monthly</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                onChange={this.handleChangeFrequency}
                                                                name="frequency"
                                                                value="Quarterly"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Quarterly</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-9 pt-4 pt-lg-2 pb-4">
                                <ul className="editForm d-flex flex-wrap">
                                    <li className="fullWidth">
                                        <FormGroup>
                                            <Label>Date Published</Label>
                                            <DatePicker placeholderText="Select Date"
                                                selected={this.state.selectDate}

                                                name="date"
                                                onChange={this.handleDateChange}
                                                className="formInput form-control" />
                                        </FormGroup>
                                    </li>
                                    <li className="fullWidth">
                                        <FormGroup>
                                            <Label>Report Format</Label>
                                            <Input type="text" name="reportFormate" onChange={this.handleChange} className="formInput" />
                                        </FormGroup>

                                        {this.state.errors.reportFormate && (
                                            <div className="errorMsg">{this.state.errors.reportFormate}</div>
                                        )}


                                    </li>
                                    <li className="fullWidth">
                                        <FormGroup>
                                            <Label>Report Title</Label>
                                            <Input type="text" name="reportTitle" onChange={this.handleChange} className="formInput" />
                                        </FormGroup>
                                        {this.state.errors.reportTitle && (
                                            <div className="errorMsg">{this.state.errors.reportTitle}</div>
                                        )}

                                    </li>
                                    <li className="fullWidth">
                                        <FormGroup>
                                            <Label>Report Sub Title</Label>
                                            <Input type="text" name="reportSubTitle" onChange={this.handleChange} className="formInput" />
                                        </FormGroup>
                                        {this.state.errors.reportSubTitle && (
                                            <div className="errorMsg">{this.state.errors.reportSubTitle}</div>
                                        )}
                                    </li>
                                    <li>
                                        <FormGroup>
                                            <Label>Button #1 - Text</Label>
                                            <Input type="text" name="buttonText1" onChange={this.handleChange} className="formInput" />
                                        </FormGroup>

                                        {this.state.errors.buttonText1 && (
                                            <div className="errorMsg">{this.state.errors.buttonText1}</div>
                                        )}
                                    </li>
                                    <li>
                                        <FormGroup>
                                            <Label>Button #1 - URL</Label>
                                            <Input type="text" name="buttonURL1" onChange={this.handleChange} className="formInput" />
                                        </FormGroup>
                                        {this.state.errors.buttonURL1 && (
                                            <div className="errorMsg">{this.state.errors.buttonURL1}</div>
                                        )}
                                    </li>
                                    <li>
                                        <FormGroup>
                                            <Label>Button #2 - Text</Label>
                                            <Input type="text" name="buttonText2" onChange={this.handleChange} className="formInput" />
                                        </FormGroup>

                                    </li>
                                    <li>
                                        <FormGroup>
                                            <Label>Button #2 - URL</Label>
                                            <Input type="text" name="buttonURL2" onChange={this.handleChange} className="formInput" />
                                        </FormGroup>

                                    </li>
                                    <li>
                                        <FormGroup>
                                            <Label>Button #3 - Text</Label>
                                            <Input type="text" name="buttonText3" onChange={this.handleChange} className="formInput" />
                                        </FormGroup>

                                    </li>
                                    <li>
                                        <FormGroup>
                                            <Label>Button #3 - URL</Label>
                                            <Input type="text" name="buttonURL3" onChange={this.handleChange} className="formInput" />
                                        </FormGroup>


                                    </li>
                                    <li>
                                        <FormGroup>
                                            <Label>Report PDF Image URL</Label>
                                            <Input type="text" name="reportpdfImageUrl" onChange={this.handleChange} className="formInput" />
                                        </FormGroup>

                                        {this.state.errors.reportpdfImageUrl && (
                                            <div className="errorMsg">{this.state.errors.reportpdfImageUrl}</div>
                                        )}
                                    </li>
                                    <li>
                                        <FormGroup>
                                            <Label>Report PDF URL</Label>
                                            <Input type="text" name="reportpdfurl" onChange={this.handleChange} className="formInput" />
                                        </FormGroup>
                                        {this.state.errors.reportpdfurl && (
                                            <div className="errorMsg">{this.state.errors.reportpdfurl}</div>
                                        )}
                                    </li>
                                    <li className="fullWidth">
                                        <FormGroup>
                                            <Label>Report Paragraph</Label>


                                            <ReactQuill value={this.state.reportPera}
                                                onChange={this.handleChangeReportPeragraph}
                                                className="formInput"
                                            />
                                        </FormGroup>

                                        {this.state.errors.reportParagraph && (

                                            <div className="errorMsg">{this.state.errors.reportParagraph}</div>
                                        )}
                                    </li>
                                    <li className="fullWidth">
                                        <FormGroup>
                                            <Label>Watchlist Updates</Label>

                                            <ReactQuill value={this.state.reportWatchlistPera}
                                                onChange={this.handleChangeReportWatchlistPeragraph} className="formInput" />

                                        </FormGroup>

                                        {this.state.errors.watchListUpdates && (
                                            <div className="errorMsg">{this.state.errors.watchListUpdates}</div>
                                        )}
                                    </li>
                                </ul>



                                {submit_button}
                            </div>
                        </div>
                    </div>
                </Form>


                <Footer />
            </div>
        );
    }
}



export default NewEdit;