import React, { Component } from 'react';
import {NavLink } from "react-router-dom";

export class AdminTopLinks extends Component {

    render() {
        return (
            <div className="topLinks">
                <div className="container d-flex align-items-center justify-content-between">
                    <ul className="d-flex flex-wrap menu afterLogin">
                        <li><NavLink exact  to="/admin/manual-links" activeClassName="active">Manual Links</NavLink ></li>
                        <li><NavLink  to="/admin/new-edit" activeClassName="active">New/Edit</NavLink ></li>
                        <li><NavLink  to="/admin/report-list" activeClassName="active">Report List</NavLink ></li>
                        <li><NavLink  to="/admin/user-list" activeClassName="active">User List</NavLink ></li>
                        <li><NavLink  to="/admin/analytics" activeClassName="active">Analytics</NavLink ></li>
                    </ul>
                </div>
            </div>
        )
    }
}
export default AdminTopLinks;