import React, { Component } from 'react';
import './CoreAssetAllocation.scss';
import combinedServices from "../../shared/services/user-service";
import moment from 'moment';
import getLocalData from "../../shared/localStorage/getData";

export class CoreAssetAllocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coreModalStatus: false,
            coreModalDataList: {},
            chartErrorMassage: '',
            userStatus: ''
        }

    }
    convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
    }

    componentDidMount = async () => {

        if (getLocalData('user_info', 'isLogin')) {
            var data = {};
            let user_info = getLocalData("user_info", "all");
            data.email = user_info.userData.userEmail;
            data.userId = user_info.userData.userId;

            let userProfileResponse = await combinedServices.getUserProfile(data);

            var userProfileDetailsData = userProfileResponse.data;
            var userDetails = userProfileDetailsData.user;

            if (userDetails) {

                this.setState({
                    userStatus: userDetails.status

                });

            }


        }

        let coreModalData = await combinedServices.getOCIOCoreData();

        if (coreModalData.status) {

            this.setState({
                coreModalDataList: coreModalData.data,
                coreModalStatus: coreModalData.status
            });

        } else {

            this.setState({
                coreModalStatus: coreModalData.status
            });
        }

    }

    showTacticModelError = () => {
        console.log(this.state.userStatus)

        var status = this.state.userStatus;
        if (status === "WHITE LABEL" || status === "RESEARCH" || status === "PENDING"  || status === "WL PLUS" || status === "QUANT PACK"|| status === "NOT ACTIVE") {

            this.setState({
                showClientQuarterlyMarketError: "Your subscription level does not provide access to Models. Please email support@marketdeskresearch.com"
            });

            setTimeout(() => {
                this.setState({ showClientQuarterlyMarketError: '' })
            }, 5000)


        }
    }

    render() {

        return (
            <div>
                <div className="text-center contentBox blueClr pb-3 pb-xl-4">{this.state.showClientQuarterlyMarketError}</div>

                <div className="tableBox caaTable">

                    <table>
                        <thead>
                            <tr>
                                <th colSpan="2">ETF Asset Allocation Models </th>


                                {/* <th colSpan="2">Core Asset Allocation Models <span>(as of {moment(this.state.coreModalDataList.AsofDate3).format('M/D/YYYY')})</span></th> */}
                                <th><span>YTD</span></th>
                                <th><span>1 Year</span></th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td className="date">{moment(this.state.coreModalDataList.aaDate1).format('M/D/YYYY')}</td>
                                <td className="title">

                                    {this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                        <a href={this.state.coreModalDataList.aaURL1}>{this.state.coreModalDataList.aaTitle1}</a> : <span onClick={this.showTacticModelError}>{this.state.coreModalDataList.aaTitle1}</span>
                                    }

                                </td>
                                <td className="ytd">
                                    {this.state.coreModalDataList.aaYTD1 > 0 ?
                                        <span className="up">{this.state.coreModalDataList.aaYTD1}%</span> : <span className="down">{this.state.coreModalDataList.aaYTD1}%</span>
                                    }

                                </td>
                                <td className="year">
                                    {this.state.coreModalDataList.aaYear1 > 0 ?
                                        <span className="up">{this.state.coreModalDataList.aaYear1}%</span> : <span className="down">{this.state.coreModalDataList.aaYear1}%</span>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="date">{moment(this.state.coreModalDataList.aaDate2).format('M/D/YYYY')}</td>
                                <td className="title">

                                    {this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                        <a href={this.state.coreModalDataList.aaURL2}>{this.state.coreModalDataList.aaTitle2}</a> : <a onClick={this.showTacticModelError}>{this.state.coreModalDataList.aaTitle2}</a>
                                    }

                                </td>
                                <td className="ytd">
                                    {this.state.coreModalDataList.aaYTD2 > 0 ?
                                        <span className="up">{this.state.coreModalDataList.aaYTD2}%</span> : <span className="down">{this.state.coreModalDataList.aaYTD2}%</span>
                                    }

                                </td>
                                <td className="year">
                                    {this.state.coreModalDataList.aaYear2 > 0 ?
                                        <span className="up">{this.state.coreModalDataList.aaYear2}%</span> : <span className="down">{this.state.coreModalDataList.aaYear2}%</span>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="date">{moment(this.state.coreModalDataList.aaDate3).format('M/D/YYYY')}</td>
                                <td className="title">

                                    {this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                        <a href={this.state.coreModalDataList.aaURL3}>{this.state.coreModalDataList.aaTitle3}</a> : <a onClick={this.showTacticModelError}>{this.state.coreModalDataList.aaTitle3}</a>
                                    }

                                </td>
                                <td className="ytd">
                                    {this.state.coreModalDataList.aaYTD3 > 0 ?
                                        <span className="up">{this.state.coreModalDataList.aaYTD3}%</span> : <span className="down">{this.state.coreModalDataList.aaYTD3}%</span>
                                    }

                                </td>
                                <td className="year">
                                    {this.state.coreModalDataList.aaYear3 > 0 ?
                                        <span className="up">{this.state.coreModalDataList.aaYear3}%</span> : <span className="down">{this.state.coreModalDataList.aaYear3}%</span>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="date">{moment(this.state.coreModalDataList.aaDate4).format('M/D/YYYY')}</td>
                                <td className="title">

                                    {this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                        <a href={this.state.coreModalDataList.aaURL4}>{this.state.coreModalDataList.aaTitle4}</a> : <a onClick={this.showTacticModelError}>{this.state.coreModalDataList.aaTitle4}</a>
                                    }

                                </td>
                                <td className="ytd">
                                    {this.state.coreModalDataList.aaYTD4 > 0 ?
                                        <span className="up">{this.state.coreModalDataList.aaYTD4}%</span> : <span className="down">{this.state.coreModalDataList.aaYTD4}%</span>
                                    }

                                </td>
                                <td className="year">
                                    {this.state.coreModalDataList.aaYear4 > 0 ?
                                        <span className="up">{this.state.coreModalDataList.aaYear4}%</span> : <span className="down">{this.state.coreModalDataList.aaYear4}%</span>
                                    }
                                </td>
                            </tr>

                            <tr>
                                {this.state.coreModalDataList.aaDate5 === null ||
                                    this.state.coreModalDataList.aaTitle5 === "" ||
                                    this.state.coreModalDataList.aaURL5 === "" ||
                                    this.state.coreModalDataList.aaYTD5 === null ||
                                    this.state.coreModalDataList.aaYear5 === "" ? (
                                    null
                                ) : (
                                    <>
                                        <td className="date">{moment(this.state.coreModalDataList.aaDate5).format('M/D/YYYY')}</td>
                                        <td className="title">
                                            {this.state.userStatus === "TRIAL USER" || this.state.userStatus === "OCIO" ? (
                                                <a href={this.state.coreModalDataList.aaURL5}>{this.state.coreModalDataList.aaTitle5}</a>
                                            ) : (
                                                <a onClick={this.showTacticModelError}>{this.state.coreModalDataList.aaTitle5}</a>
                                            )}
                                        </td>
                                        <td className="ytd">
                                            {this.state.coreModalDataList.aaYTD5 > 0 ? (
                                                <span className="up">{this.state.coreModalDataList.aaYTD5}%</span>
                                            ) : (
                                                <span className="down">{this.state.coreModalDataList.aaYTD5}%</span>
                                            )}
                                        </td>
                                        <td className="year">
                                            {this.state.coreModalDataList.aaYear5 > 0 ? (
                                                <span className="up">{this.state.coreModalDataList.aaYear5}%</span>
                                            ) : (
                                                <span className="down">{this.state.coreModalDataList.aaYear5}%</span>
                                            )}
                                        </td>
                                    </>
                                )}
                            </tr>
                            {/* 
                            <tr>



                                <td className="date">{moment(this.state.coreModalDataList.aaDate6).format('M/D/YYYY')}</td>
                                <td className="title">

                                    {this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                        <a href={this.state.coreModalDataList.aaURL6}>{this.state.coreModalDataList.aaTitle6}</a> : <a onClick={this.showTacticModelError}>{this.state.coreModalDataList.aaTitle6}</a>
                                    }

                                </td>
                                <td className="ytd">
                                    {this.state.coreModalDataList.aaYTD6 > 0 ?
                                        <span className="up">{this.state.coreModalDataList.aaYTD6}%</span> : <span className="down">{this.state.coreModalDataList.aaYTD6}%</span>
                                    }

                                </td>
                                <td className="year">
                                    {this.state.coreModalDataList.aaYear6 > 0 ?
                                        <span className="up">{this.state.coreModalDataList.aaYear6}%</span> : <span className="down">{this.state.coreModalDataList.aaYear6}%</span>
                                    }
                                </td>

                            </tr> */}
                            <tr>
                                {this.state.coreModalDataList.aaDate6 == null ||
                                    this.state.coreModalDataList.aaURL6 == "" ||
                                    this.state.coreModalDataList.aaYTD6 == null ||
                                    this.state.coreModalDataList.aaYear6 == "" ? (
                                    <>

                                    </>
                                ) : (
                                    <>
                                        <td className="date">{moment(this.state.coreModalDataList.aaDate6).format('M/D/YYYY')}</td>
                                        <td className="title">
                                            {this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" ? (
                                                <a href={this.state.coreModalDataList.aaURL6}>{this.state.coreModalDataList.aaTitle6}</a>
                                            ) : (
                                                <a onClick={this.showTacticModelError}>{this.state.coreModalDataList.aaTitle6}</a>
                                            )}
                                        </td>
                                        <td className="ytd">
                                            {this.state.coreModalDataList.aaYTD6 > 0 ? (
                                                <span className="up">{this.state.coreModalDataList.aaYTD6}%</span>
                                            ) : (
                                                <span className="down">{this.state.coreModalDataList.aaYTD6}%</span>
                                            )}
                                        </td>
                                        <td className="year">
                                            {this.state.coreModalDataList.aaYear6 > 0 ? (
                                                <span className="up">{this.state.coreModalDataList.aaYear6}%</span>
                                            ) : (
                                                <span className="down">{this.state.coreModalDataList.aaYear6}%</span>
                                            )}
                                        </td>
                                    </>
                                )}
                            </tr>

                            {/* <tr>

                                <td className="date">{moment(this.state.coreModalDataList.aaDate7).format('M/D/YYYY')}</td>
                                <td className="title">

                                    {this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                        <a href={this.state.coreModalDataList.aaURL7}>{this.state.coreModalDataList.aaTitle7}</a> : <a onClick={this.showTacticModelError}>{this.state.coreModalDataList.aaTitle7}</a>
                                    }

                                </td>
                                <td className="ytd">
                                    {this.state.coreModalDataList.aaYTD7 > 0 ?
                                        <span className="up">{this.state.coreModalDataList.aaYTD7}%</span> : <span className="down">{this.state.coreModalDataList.aaYTD7}%</span>
                                    }

                                </td>
                                <td className="year">
                                    {this.state.coreModalDataList.aaYear7 > 0 ?
                                        <span className="up">{this.state.coreModalDataList.aaYear7}%</span> : <span className="down">{this.state.coreModalDataList.aaYear7}%</span>
                                    }
                                </td>


                            </tr> */}
                            <tr>
                                {this.state.coreModalDataList.aaDate7 == null ||
                                    this.state.coreModalDataList.aaURL7 == "" ||
                                    this.state.coreModalDataList.aaYTD7 == null ||
                                    this.state.coreModalDataList.aaYear7 == "" ? (
                                    null // Render nothing or show a placeholder if any condition is true
                                ) : (
                                    <>
                                        <td className="date">
                                            {moment(this.state.coreModalDataList.aaDate7).format('M/D/YYYY')}
                                        </td>
                                        <td className="title">
                                            {this.state.userStatus === "TRIAL USER" || this.state.userStatus === "OCIO" ? (
                                                <a href={this.state.coreModalDataList.aaURL7}>{this.state.coreModalDataList.aaTitle7}</a>
                                            ) : (
                                                <a onClick={this.showTacticModelError}>{this.state.coreModalDataList.aaTitle7}</a>
                                            )}
                                        </td>
                                        <td className="ytd">
                                            {this.state.coreModalDataList.aaYTD7 > 0 ? (
                                                <span className="up">{this.state.coreModalDataList.aaYTD7}%</span>
                                            ) : (
                                                <span className="down">{this.state.coreModalDataList.aaYTD7}%</span>
                                            )}
                                        </td>
                                        <td className="year">
                                            {this.state.coreModalDataList.aaYear7 > 0 ? (
                                                <span className="up">{this.state.coreModalDataList.aaYear7}%</span>
                                            ) : (
                                                <span className="down">{this.state.coreModalDataList.aaYear7}%</span>
                                            )}
                                        </td>
                                    </>
                                )}
                            </tr>

                            {/* <tr>
                                
                                    
                                            <td className="date">{moment(this.state.coreModalDataList.aaDate8).format('M/D/YYYY')}</td>
                                            <td className="title">

                                                {this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                                    <a href={this.state.coreModalDataList.aaURL8}>{this.state.coreModalDataList.aaTitle8}</a> : <a onClick={this.showTacticModelError}>{this.state.coreModalDataList.aaTitle8}</a>
                                                }

                                            </td>
                                            <td className="ytd">
                                                {this.state.coreModalDataList.aaYTD8 > 0 ?
                                                    <span className="up">{this.state.coreModalDataList.aaYTD8}%</span> : <span className="down">{this.state.coreModalDataList.aaYTD8}%</span>
                                                }

                                            </td>
                                            <td className="year">
                                                {this.state.coreModalDataList.aaYear8 > 0 ?
                                                    <span className="up">{this.state.coreModalDataList.aaYear8}%</span> : <span className="down">{this.state.coreModalDataList.aaYear8}%</span>
                                                }
                                            </td>
                                       
                                
                            </tr> */}
                            <tr>
                                {this.state.coreModalDataList.aaDate8 === null ||
                                    this.state.coreModalDataList.aaURL8 === "" ||
                                    this.state.coreModalDataList.aaYTD8 === null ||
                                    this.state.coreModalDataList.aaYear8 === "" ? (
                                    null // Render nothing or show a placeholder if any condition is true
                                ) : (
                                    <>
                                        <td className="date">
                                            {moment(this.state.coreModalDataList.aaDate8).format('M/D/YYYY')}
                                        </td>
                                        <td className="title">
                                            {this.state.userStatus === "TRIAL USER" || this.state.userStatus === "OCIO" ? (
                                                <a href={this.state.coreModalDataList.aaURL8}>{this.state.coreModalDataList.aaTitle8}</a>
                                            ) : (
                                                <a onClick={this.showTacticModelError}>{this.state.coreModalDataList.aaTitle8}</a>
                                            )}
                                        </td>
                                        <td className="ytd">
                                            {this.state.coreModalDataList.aaYTD8 > 0 ? (
                                                <span className="up">{this.state.coreModalDataList.aaYTD8}%</span>
                                            ) : (
                                                <span className="down">{this.state.coreModalDataList.aaYTD8}%</span>
                                            )}
                                        </td>
                                        <td className="year">
                                            {this.state.coreModalDataList.aaYear8 > 0 ? (
                                                <span className="up">{this.state.coreModalDataList.aaYear8}%</span>
                                            ) : (
                                                <span className="down">{this.state.coreModalDataList.aaYear8}%</span>
                                            )}
                                        </td>
                                    </>
                                )}
                            </tr>

                            {/* <tr> */}



                            {/* <td className="date">{moment(this.state.coreModalDataList.aaDate9).format('M/D/YYYY')}</td>
                                <td className="title">

                                    {this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                        <a href={this.state.coreModalDataList.aaURL9}>{this.state.coreModalDataList.aaTitle9}</a> : <a onClick={this.showTacticModelError}>{this.state.coreModalDataList.aaTitle9}</a>
                                    }

                                </td>
                                <td className="ytd">
                                    {this.state.coreModalDataList.aaYTD9 > 0 ?
                                        <span className="up">{this.state.coreModalDataList.aaYTD9}%</span> : <span className="down">{this.state.coreModalDataList.aaYTD9}%</span>
                                    }

                                </td>
                                <td className="year">
                                    {this.state.coreModalDataList.aaYear9 > 0 ?
                                        <span className="up">{this.state.coreModalDataList.aaYear9}%</span> : <span className="down">{this.state.coreModalDataList.aaYear9}%</span>
                                    }
                                </td> */}
                            {/* </tr> */}
                            <tr>
                                {this.state.coreModalDataList.aaDate9 === null ||
                                    this.state.coreModalDataList.aaURL9 === "" ||
                                    this.state.coreModalDataList.aaYTD9 === null ||
                                    this.state.coreModalDataList.aaYear9 === "" ? (
                                    null // Render nothing or show a placeholder if any condition is true
                                ) : (
                                    <>
                                        <td className="date">
                                            {moment(this.state.coreModalDataList.aaDate9).format('M/D/YYYY')}
                                        </td>
                                        <td className="title">
                                            {this.state.userStatus === "TRIAL USER" || this.state.userStatus === "OCIO" ? (
                                                <a href={this.state.coreModalDataList.aaURL9}>{this.state.coreModalDataList.aaTitle9}</a>
                                            ) : (
                                                <a onClick={this.showTacticModelError}>{this.state.coreModalDataList.aaTitle9}</a>
                                            )}
                                        </td>
                                        <td className="ytd">
                                            {this.state.coreModalDataList.aaYTD9 > 0 ? (
                                                <span className="up">{this.state.coreModalDataList.aaYTD9}%</span>
                                            ) : (
                                                <span className="down">{this.state.coreModalDataList.aaYTD9}%</span>
                                            )}
                                        </td>
                                        <td className="year">
                                            {this.state.coreModalDataList.aaYear9 > 0 ? (
                                                <span className="up">{this.state.coreModalDataList.aaYear9}%</span>
                                            ) : (
                                                <span className="down">{this.state.coreModalDataList.aaYear9}%</span>
                                            )}
                                        </td>
                                    </>
                                )}
                            </tr>



                            {/* <tr>



                                <td className="date">{moment(this.state.coreModalDataList.aaDate10).format('M/D/YYYY')}</td>
                                <td className="title">

                                    {this.state.userStatus == "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                        <a href={this.state.coreModalDataList.aaURL10}>{this.state.coreModalDataList.aaTitle10}</a> : <a onClick={this.showTacticModelError}>{this.state.coreModalDataList.aaTitle10}</a>
                                    }

                                </td>
                                <td className="ytd">
                                    {this.state.coreModalDataList.aaYTD10 > 0 ?
                                        <span className="up">{this.state.coreModalDataList.aaYTD10}%</span> : <span className="down">{this.state.coreModalDataList.aaYTD10}%</span>
                                    }

                                </td>
                                <td className="year">
                                    {this.state.coreModalDataList.aaYear10 > 0 ?
                                        <span className="up">{this.state.coreModalDataList.aaYear10}%</span> : <span className="down">{this.state.coreModalDataList.aaYear10}%</span>
                                    }
                                </td>


                            </tr> */}
                            <tr>
                                {this.state.coreModalDataList.aaDate10 === null ||
                                    this.state.coreModalDataList.aaURL10 === "" ||
                                    this.state.coreModalDataList.aaYTD10 === null ||
                                    this.state.coreModalDataList.aaYear10 === "" ? (
                                    null 
                                ) : (
                                    <>
                                        <td className="date">
                                            {moment(this.state.coreModalDataList.aaDate10).format('M/D/YYYY')}
                                        </td>
                                        <td className="title">
                                            {this.state.userStatus === "TRIAL USER" || this.state.userStatus === "OCIO" ? (
                                                <a href={this.state.coreModalDataList.aaURL10}>{this.state.coreModalDataList.aaTitle10}</a>
                                            ) : (
                                                <a onClick={this.showTacticModelError}>{this.state.coreModalDataList.aaTitle10}</a>
                                            )}
                                        </td>
                                        <td className="ytd">
                                            {this.state.coreModalDataList.aaYTD10 > 0 ? (
                                                <span className="up">{this.state.coreModalDataList.aaYTD10}%</span>
                                            ) : (
                                                <span className="down">{this.state.coreModalDataList.aaYTD10}%</span>
                                            )}
                                        </td>
                                        <td className="year">
                                            {this.state.coreModalDataList.aaYear10 > 0 ? (
                                                <span className="up">{this.state.coreModalDataList.aaYear10}%</span>
                                            ) : (
                                                <span className="down">{this.state.coreModalDataList.aaYear10}%</span>
                                            )}
                                        </td>
                                    </>
                                )}
                            </tr>





                        </tbody>
                    </table>
                </div>
            </div>

        )
    }
}
export default CoreAssetAllocation;