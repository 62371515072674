import React, { Component } from 'react';
import './ReportList.scss';
import { AdminTopLinks } from '../../component/TopLinks/AdminTopLinks';
import { Spinner } from 'reactstrap';
import Header from '../../component/headerWithoutLoginPopup/Header';
import { Footer } from '../../component/Footer/Footer';
import Pagination from "react-js-pagination";
import PaginationCustom from "../../component/pagination";
import combinedServices from "../../shared/services/user-service";
import RichTextEditor from 'react-rte';
import moment from 'moment';
import history from "../../shared/history";

export class ReportList extends Component {

constructor(props) {
        super(props);
        this.state = {
            activePage: 1 ,
            reportListData :[],
            totalReports : 0,
            isLoading: true,
           
            
        }
        
}
componentDidMount = async() =>{
       
        var currentLocation = window.location;
        this.setState({
            currentUrl:currentLocation.origin
        })


        let reportList = await combinedServices.getReportList(this.state.activePage);
 
        if(reportList.status){

            this.setState({
                reportListData:reportList.data,
                totalReports:reportList.totalReport,
                reportStatus:reportList.status,
                isLoading: false,
     
             });


        } else{

            this.setState({
                reportListData:reportList.data,
                totalReports:reportList.totalReport,
                reportStatus:reportList.status,
                isLoading: false,
     
             });
        }
       
}

handlePageChange=async(pageNumber)=> {
        this.setState({activePage: pageNumber});
        let reportList = await combinedServices.getReportList(pageNumber);
        if(reportList.status){

            this.setState({
                reportListData:reportList.data,
                totalReports:reportList.totalReport,
                reportStatus:reportList.status,
     
             });


        }else{

            this.setState({
                reportListData:reportList.data,
                totalReports:reportList.totalReport,
                reportStatus:reportList.status,
     
             });


        }
       
}
   
    render() {

    
    
        return (

            <div>
            <Header/>
                <AdminTopLinks />
                <div className="container miidlePart">
                    <div className="row">
                        <div className="col-12 pt-4 pb-4">
                            <div className="mainHd">Report List</div>
                            <div className="tableBox reportListTable">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Report Format</th>
                                            <th>Report Title</th>
                                            <th>Page URL</th>
                                            <th>Edit</th>
                                        </tr>
                                    </thead>

                                    {this.state.reportStatus &&
                                    <tbody>

                                    {this.state.reportListData.map(Listvalue => {
                                     

                                        return ( <tr>
                                            <td className="date">{moment(Listvalue.publishedDate).format('M/D/YYYY') }</td>
                                            <td className="format">{Listvalue.reportFormat.substring(0,25)}</td>
                                            <td className="title">
                                                <span><a href={'/admin/edit-report/' + Listvalue._id}>{Listvalue.reportTitle.substring(0,40)}</a></span>
                                            </td>
                                            <td className="page">{this.state.currentUrl+'/report-page/'+Listvalue._id}</td>
                                
                                            <td className="edit"><a href={'/admin/edit-report/' + Listvalue._id}>Edit Report</a></td>
                                        </tr>)
                                        
                                    })}
                                    
                                    </tbody>
                                    }
                                  
                                </table>
                            </div>

                            {this.state.isLoading &&
                            <div className="text-center mt-4 loadMore"><Spinner color="primary" /><span className="text">Loading</span> </div>
                            }
                            
                            {this.state.totalReports > 10 && 
                            <div>

                                <Pagination
                                activePage={this.state.activePage}
                                
                                totalItemsCount={this.state.totalReports}
                               
                                onChange={this.handlePageChange}
                                />
                            </div>
                           }
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default ReportList;