import React, { Component } from 'react';
import {NavLink, Link} from "react-router-dom";
import './Header.scss';
import logo from '../../assets/images/logo.png';
import user from '../../assets/images/user_icon.png';
import notification from '../../assets/images/notificatio_icon.png';
import filter from '../../assets/images/filter_icon.png';
import { Form, Input } from 'reactstrap';
import LoginPopup from '../LoginPopup/LoginPopup';
import getLocalData from "../../shared/localStorage/getData";
import Notification from '../Notification/Notification';

export class Header extends Component {

constructor() {
    super();
    this.state = {
      user_logged_in: false,
      admin_logged_in: false
    };
  }

 componentDidMount() {

    

    if(getLocalData('user_info','isLogin')){
      this.setState({
        user_logged_in: true
      })
    }

    if(getLocalData('admin_info','isLogin')){
      this.setState({
        admin_logged_in: true
      })
    }
   
  }

    render() {


        return (
            <div>
           
               
                <div className="header">
                    <div className="container d-flex flex-wrap align-items-center justify-content-between">
                        <div className="logo"><NavLink to="/"><img alt="" src={logo} /></NavLink></div>
                       
                        
                    </div>
                </div>
            </div>
        )
    }
}
export default Header;