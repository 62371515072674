import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Label, Form, Input } from 'reactstrap';
import './CreateAccountForm.scss';
import combinedServices from "../../shared/services/user-service";

class EditUserDetails extends Component {

    constructor(props) {
        console.log(props)
        super(props);
        this.state = {
            fields: {},
            errors: {},
            successMessage: "",
            errorMessage: "",
            modal:props.isOpenUserdetailsPopup,
            email: props.email,
            userId: props.userId
        }


    }
    toggle = () => {
        this.props.closepopup(this.setState({modal:!this.state.modal}));
    }
    handleChange = e => {
        this.setState({[e.target.name]:e.target.value })
    }

   
    handleSubmit = async (e) =>{
        e.preventDefault();
        const form = e.target;

        if (this.validateForm() === false) {
        return;
        } else {
            
            const data = {
                userId: this.state.userId,
                newPassword: this.state.password,
                confirmPassword: this.state.confirmPassword
                
            }
            let reponsedata = await combinedServices.changeUserPassword(data);
           
            if(reponsedata.status){
               this.setState({
                    errorMessage: false,
                    successMessage:reponsedata.msg,
               })

            setTimeout(function() { 
                window.location.href="/admin/user-list"
            }, 5000);

             
            }else{
                

                this.setState({
                    successMessage: false,
                    errorMessage: reponsedata.msg
                })

            }

        }

        
    }

     

    validateForm=()=>{
        
        let password = this.state.password;
        let confirmPassword = this.state.confirmPassword;
        let errors = {};
        let formIsValid = true;
       
        if (!password) {
            formIsValid = false;
            errors["password"] = "Please enter your password.";
        }
        if (!confirmPassword) {
            formIsValid = false;
            errors["confirmPassword"] = "Please enter your confirm password.";
        }
        if(password || confirmPassword){
            if(password != confirmPassword){
                formIsValid = false;
                errors["confirmPassword"] = "Password and confirm password did not match";
            }
        }
        this.setState({
            errors: errors
        });
        return formIsValid
    }


   
    render() {
        
        return (
            <div>

                <Modal isOpen={this.state.modal} className="modalOuter accountForm">
                    <div className="loginInside">
                        <ModalHeader toggle={this.toggle}>Change Password</ModalHeader>
                        
                        <ModalBody>
                            <Form onSubmit={this.handleSubmit}>


                                <div className="inner">
                                    <FormGroup>
                                        <Label>Password<span className="asterick">*</span></Label>
                                        <Input type="password" name="password" onChange={this.handleChange} className="loginInput passwordIn" />
                                        {this.state.errors.password && (
                                            <div className="errorMsg">{this.state.errors.password}</div>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Confirm Password<span className="asterick">*</span></Label>
                                        <Input type="password" name="confirmPassword" onChange={this.handleChange} className="loginInput passwordIn" />
                                        {this.state.errors.confirmPassword && (
                                            <div className="errorMsg">{this.state.errors.confirmPassword}</div>
                                        )}
                                    </FormGroup>
                                </div>
                             

                                <div className="loginBtn">

                                    <div className="mesggtext">
                                        {this.state.successMessage ? <div className="successMsg">{this.state.successMessage}</div> : ''}
                                        {this.state.errorMessage ? <div className="errorMsg">{this.state.errorMessage}</div> : ''}

                                    </div>

                                    <Button>Change Password</Button>
                                    
                                </div>

                            </Form>
                        </ModalBody>
                    </div>


                </Modal>
            </div>
        );
    }
}

export default EditUserDetails;