import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Label, Form, Input  } from 'reactstrap';
import './LoginPopup.scss';
import { RegisterForm } from '../RegisterForm/RegisterForm';
import { ForgotPassword } from '../ForgotPassword/ForgotPassword';
import combinedServices from "../../shared/services/user-service";
import history from "../../shared/history";
import setLocalData from "../../shared/localStorage/setData";
import { Redirect } from "react-router-dom";

export class LoginPopup extends Component {

    constructor(props) {
    super(props);
        this.state = {
            fields :{},
            errors :{},
            successMessage : "",
            errorMessage : "",
            modal :true,
            showTextReg:false,
            showTextForgetPsw:false,
            toggle:true,
            toUserProfile:false,
            toManualLink:false,
          
            isOpen: props.isOpen
        }
   
       
    }



    toggle = () =>{
         this.setState({
          toggle :!this.state.toggle
        });
        this. props.closepopup(!this.state.toggle);

    } 
   
     regmodal = () =>{

        this.setState({
            modal :false,
            showTextReg :true,
            showTextForgetPsw :false
        });
      
    }

     forgetPswmodal =()=>{
          this.setState({
            modal :false,
            showTextReg :false,
            showTextForgetPsw :true
        });
    }



     handleChange = e =>{
        this.setState({ [e.target.name]: e.target.value })
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
          fields
        });


    }


     handleSubmit = async (e) =>{
        e.preventDefault();
        const form = e.target;

        if (this.validateForm() === false) {

        return;
        } else {
            let fields = this.state.fields;

            const data = {
            email: fields['email'],
            password: fields['password']
            
            };
            

            let reponsedata = await combinedServices.userLogin(data);
            let response = reponsedata.data;
            let result = response.result1;
           // console.log(result);

            if(response.responseCode === 200){

                let success = response.success ;

                let login_response = {

                  roleType :result.roleType, 
                  userEmail :result.email,
                  userId:result._id


                }
                if(result.roleType === "ADMIN" ){

                     setLocalData("admin_info", login_response);

                }else{

                     setLocalData("user_info", login_response);

                }
    

               
            
               this.setState({
                    errorMessage: false,
                    successMessage: response.responseMessage
                })


                 if(result._id && result.roleType =="ADMIN") {

                     this.setState({
                          toManualLink :true
                        });


                 }else{

                    window.location.href= history.location.pathname ;

            
                 }



            }else{
                

                this.setState({
                    successMessage: false,
                    errorMessage: response.responseMessage
                })

            }

        }

        
    }




    validateForm=()=>{
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
       

        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Please enter your email.";
        }

        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "Please enter your password.";
        }

        this.setState({
            errors: errors
        });
        return formIsValid
    }




    render() {

        if (this.state.toUserProfile) {
           return <Redirect to='/user-profile' />
        }

         if (this.state.toManualLink) {
           return <Redirect to='/admin/manual-links' />
        }



       
        return (
            <div>
            {this.state.toggle && (
            
            <Modal isOpen={true} className="modalOuter">

            {this.state.modal && (
                <div>

                    <ModalHeader toggle={this.toggle}>Log In</ModalHeader>
                    <ModalBody>
                     
                         <Form onSubmit={this.handleSubmit} >
                         {this.state.successMessage ? <div className="alert alert-success">{this.state.successMessage}</div>: ''}
                          {this.state.errorMessage ? <div className="alert alert-danger">{this.state.errorMessage}</div>: ''}
                            <div className="inner">
                                <FormGroup>
                                    <Label>Email</Label>
                                    <Input type="text" value={this.state.fields.email} name="email" className="loginInput" onChange={this.handleChange}/>
                                    {this.state.errors.email && (
                                    <div  className="errorMsg">{this.state.errors.email}</div> 
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label>Password</Label>
                                    <Input type="password" name="password" value={this.state.fields.password} className="loginInput" onChange={this.handleChange}/>
                                    {this.state.errors.password && (
                                    <div  className="errorMsg">{this.state.errors.password}</div> 
                                    )}
                                </FormGroup>
                                <FormGroup className="mb0 d-flex justify-content-between loginLink">
                                    <div><a href="#" onClick={this.regmodal}>Request for an Account</a></div>
                                    <div><a href="#" onClick={this.forgetPswmodal}>Forgot your password?</a></div>
                                </FormGroup>
                            </div>
                            <div className="loginBtn"><Button>Log In</Button></div>
                        </Form>
                    </ModalBody>
                </div>
                )}

                {this.state.showTextReg && (


                <div >
                    <ModalHeader toggle={this.toggle}>Portal Registration</ModalHeader>
                    <ModalBody>
                        <RegisterForm />
                    </ModalBody>
                </div>
                )}

                {this.state.showTextForgetPsw && (

                <div>
                    <ModalHeader toggle={this.toggle}>Forgot Password</ModalHeader>
                    <ModalBody>
                        <ForgotPassword />
                    </ModalBody>
                </div>
                )}
            </Modal>
            )}
        </div>
        


            )
    }



}


export default LoginPopup;
