import React, { Component,useState,useEffect } from 'react';
import './UserList.scss';
import { AdminTopLinks } from '../../component/TopLinks/AdminTopLinks';
import { Spinner, FormGroup, Button, Input } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from '../../component/headerWithoutLoginPopup/Header';
import { Footer } from '../../component/Footer/Footer';
import Pagination from "react-js-pagination";
import PaginationCustom from "../../component/pagination";
import combinedServices from "../../shared/services/user-service";
import dateFormat from 'dateformat';
import CreateAccountPopup from '../../component/CreateAccountForm/CreateAccountForm';
import { CSVLink, CSVDownload } from "react-csv";
import Swal from "sweetalert2";
import moment from 'moment';
import EditUserDetailsPopup from '../../component/EditUserDetails/EditUserDetails';

export class UserList extends Component {

    constructor(props) {
        super(props);
        this.csvLink = React.createRef()
        this.state = {
            userlist: false,
            allDates: {},
            activePage: 1,
            page_number: 1,
            dataCount: 0,
            perPage: "",
            userListData: [],
            errorMessage: false,
            successMessage: false,
            fields: {},
            openDeleteModal: false,
            openUserDetailsEditModal:false,
            isLoading: true,
            startDate: new Date()
        }
    }

    handleChangeDate(date, name) {
    
        this.setState({ [name]: date })
        let fields = this.state.fields;
        fields[name] = date;
        this.setState({
            fields
        });

        
    }
   
    handlePageChange = async (pageNumber) => {
        this.setState({activePage: pageNumber});
        let userListResponse = await combinedServices.getUserList(pageNumber);
        if(userListResponse.status){
            this.setState({
                userListData: userListResponse.data,
                totalUser: userListResponse.totalUser,
                userStatus:userListResponse.status,
            });
           

        }else{

            this.setState({
                userListData: userListResponse.data,
                totalUser: userListResponse.totalUser,
                userStatus:userListResponse.status,
            });


        }

      
    }

    allUserList = async() =>{
        let userListResponse = await combinedServices.getUserList(this.state.activePage);
       
       
        if(userListResponse.status){

            this.setState({
                userListData: userListResponse.data,
                totalUser: userListResponse.totalUser,
                userStatus:userListResponse.status,
                isLoading: false
            });

        }else{

            this.setState({
                userListData: userListResponse.data,
                totalUser: userListResponse.totalUser,
                userStatus:userListResponse.status,
                isLoading: false
               
            });



        }
       
    

    }

    componentDidMount = async () => {
        this.allUserList();
    }

    handleUpdateUserStatus = async (event) => {
        var email = event.target[event.target.selectedIndex].getAttribute('data-email');
        var id = event.target[event.target.selectedIndex].getAttribute('data-id');
        var status = event.target.value;

        var data = {

            email: email,
            userId: id,
            status: status


        }


        let userUpdateStatus = await combinedServices.updateUserStatus(data);
        if (userUpdateStatus.status) {

            this.setState({
                errorMessage: false,
                successMessage: userUpdateStatus.data
            })
           
            this.sucessMsgAlert();
            this.allUserList();
            

        } else {

            this.setState({
                successMessage: false,
                errorMessage: userUpdateStatus.data
            })
            this.errorMsgAlert();
        }


    }

    openModalWithItem = (userId,email) => {
        this.setState({
            openDeleteModal: true,
            userId:userId,
            email:email

        })
    }


    openUserEditPopup = (userId,email) => {
        this.setState({
            openUserDetailsEditModal: true,
            userId:userId,
            email:email

        })
    }



   

    handleClosePopup = (data) => {
        this.setState({ openDeleteModal: data })
    }

    handleClosePopupUserDetails = (data) =>{
        this.setState({ openUserDetailsEditModal: data })
    }

    createAccountPopup = () => {
        return (
            <CreateAccountPopup
                isOpen={this.state.openDeleteModal}
                userId={this.state.userId}
                email={this.state.email}
                closepopup={this.handleClosePopup}
            />
        );
    }

    createUserDetaiilEditPopup = () => {
        return (
            <EditUserDetailsPopup
                isOpenUserdetailsPopup={this.state.openUserDetailsEditModal}
                userId={this.state.userId}
                email={this.state.email}
                closepopup={this.handleClosePopupUserDetails}
            />
        );
    }


    exportUserDetails = async() =>{

        let userDetailResponse = await combinedServices.exportAllUsers();
       
        this.setState({ csvUserListData: userDetailResponse })

        this.csvLink.current.link.click()


    }


    sucessMsgAlert = () => {

        return (
            Swal.fire({
                title: 'Success',
                type: 'Success',
                text: this.state.successMessage,
            })


        )
    }

    errorMsgAlert = () => {

        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: this.state.errorMessage,
        });
    }
    render() {
        const userListArray = this.state.userListData;

        return (

            <div>
                
                <Header />
                <AdminTopLinks />
                {this.state.csvUserListData != null ?
                <CSVLink
                data={this.state.csvUserListData}
                filename="userList.csv"
                className="hidden"
                ref={this.csvLink}
                target="_blank" 
                />

                : null }
                {this.state.openDeleteModal && this.createAccountPopup()}
                {this.state.openUserDetailsEditModal && this.createUserDetaiilEditPopup()}
                

                <div className="container miidlePart">
                    <div className="row">
                   
                        <div className="col-12 pt-4 pb-4">
                            <div className="d-flex">
                                <div className="mainHd">User List</div>
                                <div className="ml-3 mb-4"><Button className="formbtn" onClick={this.exportUserDetails}>Export</Button></div>
                            </div>

                            <div className="tableBox userListTable">
                                
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date Created</th>
                                            <th>User ID</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th>Firm</th>
                                            <th>User Status</th>
                                            <th>Last Trial Date</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>

                                    {this.state.userStatus &&
                                    <tbody>
                                        {this.state.userListData.map((Listvalue, index) => {

                                            return (<tr>
                                                <td className="date">{moment(Listvalue.createdAt).format('M/D/YYYY')}</td>
                                                <td className="id">  {Listvalue._id}</td>
                                                <td className="firstName">{Listvalue.firstName}</td>
                                                <td className="LastName">{Listvalue.lastName}</td>
                                                <td className="email">{Listvalue.email}</td>
                                                <td className="mobile">{Listvalue.mobileNumber}</td>
                                                <td className="firm">{Listvalue.firmName}</td>
                                                <td className="status">
                                                    <FormGroup>
                                                        <Input type="select" onChange={this.handleUpdateUserStatus}>
                                                            <option value="">Please Select</option>
                                                            <option data-email={Listvalue.email} data-id={Listvalue._id} selected={Listvalue.status === "PENDING" ? 'selected' : ''} value="PENDING">PENDING</option>
                                                            <option data-email={Listvalue.email} data-id={Listvalue._id} selected={Listvalue.status === "TRIAL USER" ? 'selected' : ''} value="TRIAL USER"> TRIAL USER</option>
                                                            <option data-email={Listvalue.email} data-id={Listvalue._id} selected={Listvalue.status === "WHITE LABEL" ? 'selected' : ''} value="WHITE LABEL">WHITE LABEL</option>
                                                            <option data-email={Listvalue.email} data-id={Listvalue._id} selected={Listvalue.status === "RESEARCH" ? 'selected' : ''} value="RESEARCH">RESEARCH</option>
                                                            <option data-email={Listvalue.email} data-id={Listvalue._id} selected={Listvalue.status === "OCIO" ? 'selected' : ''} value="OCIO">OCIO</option>
                                                            <option data-email={Listvalue.email} data-id={Listvalue._id} selected={Listvalue.status === "WL PLUS" ? 'selected' : ''} value="WL PLUS">WL PLUS</option>
                                                            <option data-email={Listvalue.email} data-id={Listvalue._id} selected={Listvalue.status === "QUANT PACK" ? 'selected' : ''} value="QUANT PACK">QUANT PACK</option>

                                                            <option data-email={Listvalue.email} data-id={Listvalue._id} selected={Listvalue.status === "NOT ACTIVE" ? 'selected' : ''} value="NOT ACTIVE">NOT ACTIVE</option>
                                                        </Input>
                                                    </FormGroup>
                                                </td>
                                                {Listvalue.status === "TRIAL USER" ? (
                                                <Example  email={Listvalue.email} userId={Listvalue._id} selectedDate={Listvalue.trialDate} userStatus={Listvalue.status}/>
                                                ):
                                                <Example1  email={Listvalue.email} userId={Listvalue._id} selectedDate={Listvalue.trialDate} />
                                                }
                                                <td className="Action">
                                                    <Button onClick={() => this.openModalWithItem(Listvalue._id,Listvalue.email)} className="formbtn"> About </Button>
                                                    <Button onClick={() => this.openUserEditPopup(Listvalue._id,Listvalue.email)} className="formbtn"> Edit</Button>
                                                </td>
                                            </tr>)
                                        }

                                        )}

                                    </tbody>
                                }
                               
                                </table>
                            </div>

                            {this.state.isLoading  &&
                            <div className="text-center mt-4 loadMore"><Spinner color="primary" /><span className="text">Loading</span> </div>
                            }
                            
                            {this.state.totalUser > 10 && 
                            <div>
                        
                                <Pagination
                                    activePage={this.state.activePage}

                                    totalItemsCount={this.state.totalUser}

                                    onChange={this.handlePageChange}
                                />
                            
                            </div>
                            }

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}



const Example = (props) => {

    var update_date = new Date();  
    if(props.selectedDate){

         update_date = new Date(props.selectedDate); 
    }
    
    const [startDate, setStartDate] = useState(update_date);
    const [userStatus, setUserStatus] = useState(props.userStatus);
    const handleDateSelect = async(date) =>{
        
        let data = {
            email:props.email,
            userId :props.userId,
            trialDate:date
        }
       
        let titleDateResponse = await combinedServices.updateUserTrialDate(data);
    }

    

    return (

       
        <td className="trialDate">
            <FormGroup>

            <DatePicker placeholderText="Select Date"
                    className="formInput dateInput form-control" 
                    selected={startDate}
                    onSelect={handleDateSelect}
                   
                    onChange={(date) => setStartDate(date)} />
                
            </FormGroup>
        </td>
    );

    

    
  };

  const Example1 = (props) => {

    var update_date ;
    if(props.selectedDate){

         update_date = new Date(props.selectedDate); 
    }
    
    const [startDate, setStartDate] = useState(update_date);
  
    return (

       
        <td className="trialDate">
            <FormGroup>

            <DatePicker placeholderText="Select Date"
                    className="formInput dateInput form-control" 
                    selected={startDate}
                    />
                
            </FormGroup>
        </td>
    );

    

    
  };


export default UserList;