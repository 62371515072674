import React, { Component } from 'react';
import './TacticalAssetAllocation.scss';
import combinedServices from "../../shared/services/user-service";
import moment from 'moment';
import getLocalData from "../../shared/localStorage/getData";

export class TacticalAssetAllocation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            coreModalStatus: false,
            coreModalDataList: {},
            coreTacticalModalStatus: false,
            coreTacticalModalDataList: {},
            chartErrorMassage: '',
            userStatus: '',
            showClientQuarterlyMarketError:''
        }

    }
    convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
    }


    componentDidMount = async () => {
        if(getLocalData('user_info','isLogin')){
            var data = {};
            let user_info = getLocalData("user_info", "all");
            data.email = user_info.userData.userEmail;
            data.userId = user_info.userData.userId ;

            let userProfileResponse =  await combinedServices.getUserProfile(data);
            var userProfileDetailsData = userProfileResponse.data ;
            var userDetails = userProfileDetailsData.user ;

            if(userDetails){

                this.setState({
                    userStatus:userDetails.status
                    
                });

            }
           
           
        }

        let coreTacticalModalData = await combinedServices.getOCIOTacticalsData();

        if (coreTacticalModalData.status) {

            this.setState({
                coreTacticalModalDataList: coreTacticalModalData.data,
                coreTacticalModalStatus: coreTacticalModalData.status
            });

        } else {

            this.setState({
                coreTacticalModalStatus: coreTacticalModalData.status
            });
        }

    }

    showTacticModelError = () => {

        var status = this.state.userStatus;
        if (  status === "WHITE LABEL" || status === "RESEARCH" || status === "PENDING" || status === "NOT ACTIVE") {

            this.setState({
                showClientQuarterlyMarketError: "Your subscription level does not provide access to OCIO. Please email support@marketdeskresearch.com"
            });

            setTimeout(() => {
                this.setState({ showClientQuarterlyMarketError: '' })
            }, 5000)


        }
    }




    render() {


        return (
            <div>
            <div className="text-center contentBox blueClr pb-3 pb-xl-4">{this.state.showClientQuarterlyMarketError}</div>
            <div className="tableBox taaTable">
                 
                {this.state.coreTacticalModalStatus &&
                    <table>
                        <thead>
                            <tr>
                                <th colSpan="2">Tactical Asset Allocation Models <span>(as of {moment(this.state.coreTacticalModalDataList.AsofDate3).format('M/D/YYYY')})</span></th>
                                <th><span>YTD</span></th>
                                <th><span>1 Year</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="date">{moment(this.state.coreTacticalModalDataList.aaDate1).format('M/D/YYYY')}</td>
                                <td className="title">

                                    {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                        <a  href={this.state.coreTacticalModalDataList.aaURL1}>{this.state.coreTacticalModalDataList.aaTitle1}</a> : 
                                        <a onClick={this.showTacticModelError}>{this.state.coreTacticalModalDataList.aaTitle1}</a>
                                    }


                                </td>
                                <td className="ytd">
                                    {this.state.coreTacticalModalDataList.aaYTD1 > 0 ?

                                        <span className="up">{this.state.coreTacticalModalDataList.aaYTD1}%</span> : <span className="down">{this.state.coreTacticalModalDataList.aaYTD1}</span>
                                    }
                                </td>
                                <td className="year">
                                    {this.state.coreTacticalModalDataList.aaYear1 > 0 ?

                                        <span className="up">{this.state.coreTacticalModalDataList.aaYear1}%</span> : <span className="down">{this.state.coreTacticalModalDataList.aaYear1}%</span>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="date">{moment(this.state.coreTacticalModalDataList.aaDate2).format('M/D/YYYY')}</td>
                                <td className="title">

                                    {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                        <a  href={this.state.coreTacticalModalDataList.aaURL2}>{this.state.coreTacticalModalDataList.aaTitle2}</a> : <a onClick={this.showTacticModelError}>{this.state.coreTacticalModalDataList.aaTitle2}</a>
                                    }</td>
                                <td className="ytd">
                                    {this.state.coreTacticalModalDataList.aaYTD2 > 0 ?

                                        <span className="up">{this.state.coreTacticalModalDataList.aaYTD2}%</span> : <span className="down">{this.state.coreTacticalModalDataList.aaYTD2}</span>
                                    }
                                </td>
                                <td className="year">
                                    {this.state.coreTacticalModalDataList.aaYear2 > 0 ?

                                        <span className="up">{this.state.coreTacticalModalDataList.aaYear2}%</span> : <span className="down">{this.state.coreTacticalModalDataList.aaYear2}%</span>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="date">{moment(this.state.coreTacticalModalDataList.aaDate3).format('M/D/YYYY')}</td>
                                <td className="title">
                                    {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                        <a  href={this.state.coreTacticalModalDataList.aaURL3}>{this.state.coreTacticalModalDataList.aaTitle3}</a> : <a onClick={this.showTacticModelError}>{this.state.coreTacticalModalDataList.aaTitle3}</a>
                                    }</td>
                                <td className="ytd">
                                    {this.state.coreTacticalModalDataList.aaYTD3 > 0 ?

                                        <span className="up">{this.state.coreTacticalModalDataList.aaYTD3}%</span> : <span className="down">{this.state.coreTacticalModalDataList.aaYTD3}</span>
                                    }
                                </td>
                                <td className="year">
                                    {this.state.coreTacticalModalDataList.aaYear3 > 0 ?

                                        <span className="up">{this.state.coreTacticalModalDataList.aaYear3}%</span> : <span className="down">{this.state.coreTacticalModalDataList.aaYear3}%</span>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="date">{moment(this.state.coreTacticalModalDataList.aaDate4).format('M/D/YYYY')}</td>
                                <td className="title">

                                    {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                        <a  href={this.state.coreTacticalModalDataList.aaURL4}>{this.state.coreTacticalModalDataList.aaTitle4}</a> : <a onClick={this.showTacticModelError}>{this.state.coreTacticalModalDataList.aaTitle4}</a>
                                    }</td>
                                <td className="ytd">
                                    {this.state.coreTacticalModalDataList.aaYTD4 > 0 ?

                                        <span className="up">{this.state.coreTacticalModalDataList.aaYTD4}%</span> : <span className="down">{this.state.coreTacticalModalDataList.aaYTD4}</span>
                                    }
                                </td>
                                <td className="year">
                                    {this.state.coreTacticalModalDataList.aaYear4 > 0 ?

                                        <span className="up">{this.state.coreTacticalModalDataList.aaYear4}%</span> : <span className="down">{this.state.coreTacticalModalDataList.aaYear4}%</span>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="date">{moment(this.state.coreTacticalModalDataList.aaDate5).format('M/D/YYYY')}</td>
                                <td className="title">
                                    {this.state.userStatus === "TRIAL USER" || this.state.userStatus == "OCIO" ?
                                        <a  href={this.state.coreTacticalModalDataList.aaURL5}>{this.state.coreTacticalModalDataList.aaTitle5}</a> : <a onClick={this.showTacticModelError}>{this.state.coreTacticalModalDataList.aaTitle5}</a>
                                    }</td>
                                <td className="ytd">
                                    {this.state.coreTacticalModalDataList.aaYTD5 > 0 ?

                                        <span className="up">{this.state.coreTacticalModalDataList.aaYTD5}%</span> : <span className="down">{this.state.coreTacticalModalDataList.aaYTD5}</span>
                                    }
                                </td>
                                <td className="year">
                                    {this.state.coreTacticalModalDataList.aaYear5 > 0 ?

                                        <span className="up">{this.state.coreTacticalModalDataList.aaYear5}%</span> : <span className="down">{this.state.coreTacticalModalDataList.aaYear5}%</span>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>

                }

            </div>
            </div>
        )
    }
}
export default TacticalAssetAllocation;