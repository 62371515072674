import React, { Component } from 'react';
import './Home.scss';
import { TopLinks } from '../../component/TopLinks/TopLinks';
import Header from '../../component/headerWithoutLoginPopup/Header';
import { Footer } from '../../component/Footer/Footer';
import combinedServices from "../../shared/services/user-service";
import getLocalData from "../../shared/localStorage/getData";
import removeLocalData from "../../shared/localStorage/removeData";

export class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            getclientResourceDataList: {},
            clientResourcesDataSatus: false

        }
    }


    logoutUser = () => {

        removeLocalData("user_info");
        removeLocalData("admin_info");
        window.location.href = '/';
    };




    componentDidMount = async () => {

        if (getLocalData('user_info', 'isLogin')) {
            var data = {};
            let user_info = getLocalData("user_info", "all");
            data.email = user_info.userData.userEmail;
            data.userId = user_info.userData.userId;
            let userProfileResponse = await combinedServices.checkIfUserExists(data);

            if (userProfileResponse.status) {
                var userProfileDetailsData = userProfileResponse.data;
                if (userProfileDetailsData.status != user_info.userData.status) {
                    this.logoutUser();
                }
            }
            this.setState({
                userStatus: user_info.userData.status

            });


        }

        // ======================Commented by chandra
        // let clientResourceResponse =  await combinedServices.getclientResourceData();
        // if(clientResourceResponse.status){

        //    this.setState({
        //    getclientResourceDataList :clientResourceResponse.data[0],
        //    clientResourcesDataSatus : clientResourceResponse.status
        //    });

        // }else{

        //    this.setState({

        //    clientResourcesDataSatus : clientResourceResponse.status
        //    });



        // }

    }





    render() {

        return (

            <div>
                <Header />
                <TopLinks />
                <div className="container">
                    <div className="row" style={{marginBottom:'10%'}}>
                        <div className="col-12 mt-3 mb-3 mt-xl-5 mb-xl-5">
                            {/* <div className="mb-3 mb-md-5 pb-xl-5"> */}
                            <div>

                                <div className="mainHd">MarketDesk Portal</div>
                                <div className="contentBox mb-5">Welcome to the MarketDesk Portal. Navigate the platform using <br/> the quick links below or select a page to explore the resources.</div>

                                <div className="contentBox mb-5"></div>
                                {/* <div className="mainHd">MarketDesk Portal</div> */}
                                {/* <div className="contentBox mb-5">Welcome to the MarketDesk Portal. Browse the pages below<br /> or click any of the categories to explore a specific topic.</div> */}
                                <ul className="d-flex flex-wrap portalList">
                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox" style={{ borderColor: "#19936d" }}>
                                            {/* <div className="hd mb-2 mb-xl-3"><img src='https://reports.marketdeskresearch.com/hubfs/MDR%20Images/1-ModelPortfolios.png' alt='portfolios' style={{ width: '75px' }}></img></div> */}
                                            <div className="hd mb-2 mb-xl-3">Model Portfolios</div>
                                            <div className="mb-3 textHeight">Monthly commentary and performance updates for the ETF Asset Allocation and Quantitative Equity Models.</div>
                                            <div><a href='/ocio' className="viewLink">View</a></div>
                                        </div>
                                    </li>
                                    {/* <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox" style={{ borderColor: "#19936d" }}>
                                            <div className="hd mb-2 mb-xl-3">Outsourced CIO</div>
                                            <div className="mb-3">Asset allocation models, client talking points, and quantitative indicators</div>
                                            <div><a href='/ocio' className="viewLink">View</a></div>
                                        </div>
                                    </li> */}
                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox" style={{ borderColor: "#91bce3" }}>
                                            {/* <div className="hd mb-2 mb-xl-3"><img src='https://reports.marketdeskresearch.com/hubfs/MDR%20Images/2-LatestReports.png' alt='latest report' style={{ width: '75px' }}></img></div> */}

                                            <div className="hd mb-2 mb-xl-3">Latest Reports</div>
                                            <div className="mb-3 textHeight">Find and download the latest resources across the entire MarketDesk platform here.</div>
                                            <div><a href='/latest-report' className="viewLink">View</a></div>
                                        </div>
                                    </li>
                                    {/* <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox" style={{ borderColor: "#91bce3" }}>
                                            <div className="hd mb-2 mb-xl-3">Investment Research</div>
                                            <div className="mb-3">Macro strategy, core ratings, market analysis, and tactical ideas</div>
                                            <div><a href='/research' className="viewLink">View</a></div>
                                        </div>
                                    </li> */}
                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox" style={{ borderColor: "#2f75b5" }}>
                                            {/* <div className="hd mb-2 mb-xl-3"><img src='https://reports.marketdeskresearch.com/hubfs/MDR%20Images/3-WhiteLabel.png' alt='portfolios' style={{ width: '75px' }}></img></div> */}

                                            <div className="hd mb-2 mb-xl-3">White Label Insights</div>
                                            <div className="mb-3 textHeight">Client-friendly newsletters, presentations, and webinars for your existing and prospective clients.</div>
                                            <div><a href='/white-label' className="viewLink">View</a></div>
                                        </div>
                                    </li>
                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox" style={{ borderColor: "#d8b47e" }}>
                                            {/* <div className="hd mb-2 mb-xl-3"><img src=' https://reports.marketdeskresearch.com/hubfs/MDR%20Images/4-ReportLibrary.png' alt='report' style={{ width: '75px' }}></img></div> */}

                                            <div className="hd mb-2 mb-xl-3">Research Library</div>
                                            <div className="mb-3 textHeight">Access the full research library. Sort by asset class, report type, publishing frequency, and key market topics.</div>
                                            <div><a href='/library' className="viewLink">View</a></div>
                                        </div>
                                    </li>
                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox" style={{ borderColor: "#1f4e78" }}>
                                            {/* <div className="hd mb-2 mb-xl-3"><img src='https://reports.marketdeskresearch.com/hubfs/MDR%20Images/5-TopicsFollowing.png' alt='topics' style={{ width: '75px' }}></img></div> */}

                                            <div className="hd mb-2 mb-xl-3">Topics You're Following</div>
                                            <div className="mb-3 textHeight">A shortlist of the latest reports for the research categories and asset classes you've selected to follow.</div>
                                            <div><a href='/following' className="viewLink">View</a></div>
                                        </div>
                                    </li>
                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox" style={{ borderColor: "#afeaff" }}>
                                            {/* <div className="hd mb-2 mb-xl-3"><img src='https://reports.marketdeskresearch.com/hubfs/MDR%20Images/6-ProfilePreferences.png' alt='profiles' style={{ width: '75px' }}></img></div> */}

                                            <div className="hd mb-2 mb-xl-3">Profile &amp; Preferences</div>
                                            <div className="mb-3 textHeight">Select your top research preferences and update your contact information from this page.</div>
                                            <div><a href='/user-profile' className="viewLink">View</a></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {/* <div className="mb-3 mb-md-5">
                                <div className="mainHd pb-5">Explore by Category</div>
                                <ul className="d-flex flex-wrap categoryList">
                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox">
                                            <div className="hd mb-2"><a href="/library?searchKey=ModelPortfolios">Model Portfolios</a></div>
                                            <div>Asset allocation and individual stock portfolios</div>
                                        </div>
                                    </li>
                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox">
                                            <div className="hd mb-2"><a href="/research">Latest Research</a></div>
                                            <div>The latest reports and analysis published by the MarketDesk Team</div>
                                        </div>
                                    </li>
                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox">
                                            <div className="hd mb-2"><a href="/research">Most Read Reports</a></div>
                                            <div>Top read reports across all clients</div>
                                        </div>
                                    </li>
                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox">
                                            <div className="hd mb-2"><a href="/library?searchKey=MacroStrategy">Macro Strategy</a></div>
                                            <div>Timely reports looking across asset classes and macro conditions</div>
                                        </div>
                                    </li>
                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox">
                                            <div className="hd mb-2"><a href="/library?searchKey=TacticalIdeas">Tactical Ideas</a></div>
                                            <div>Industries, factors, regions, and thematic ETF opportunities</div>
                                        </div>
                                    </li>
                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox">
                                            <div className="hd mb-2"><a href="/library?searchKey=Sector">Sector Insights</a></div>
                                            <div>Ratings and analysis across U.S. sectors and industries</div>
                                        </div>
                                    </li>
                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox">
                                            <div className="hd mb-2"><a href="/library?searchKey=Credit">Credit Markets</a></div>
                                            <div>Interest rates, credit classes, and monetary policy</div>
                                        </div>
                                    </li>
                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox">
                                            <div className="hd mb-2"><a href="/library?searchKey=International">International Markets</a></div>
                                            <div>Global market analysis and positioning</div>
                                        </div>
                                    </li>
                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox">
                                            <div className="hd mb-2"><a href="/library?searchKey=Economics">Global Economics</a></div>
                                            <div>Key indicators and macro trends impacting global markets</div>
                                        </div>
                                    </li>
                                </ul>
                            </div> */}



                            {/* {this.state.clientResourcesDataSatus &&
                            <div className="mb-3 mb-md-5_1 pt-xl-5">
                                <div className="mainHd pb-3 pb-md-5">Client Resources</div>
                                
                                <ul className="d-flex flex-wrap resList">
                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox">
                                            <div className="hd mb-2 mb-xl-3">{this.state.getclientResourceDataList.resource1.clientTitle1}</div>
                                            <div><a target="_blank" href={this.state.getclientResourceDataList.resource1.clientURL1} className="viewLink">View</a></div>
                                        </div>
                                    </li>

                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox">
                                            <div className="hd mb-2 mb-xl-3">{this.state.getclientResourceDataList.resource2.clientTitle2}</div>
                                            <div><a target="_blank" href={this.state.getclientResourceDataList.resource2.clientURL2} className="viewLink">View</a></div>
                                        </div>
                                    </li>

                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox">
                                            <div className="hd mb-2 mb-xl-3">{this.state.getclientResourceDataList.resource3.clientTitle3}</div>
                                            <div><a target="_blank" href={this.state.getclientResourceDataList.resource3.clientURL3} className="viewLink">View</a></div>
                                        </div>
                                    </li>
                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox">
                                            <div className="hd mb-2 mb-xl-3">{this.state.getclientResourceDataList.resource4.clientTitle4}</div>
                                            <div><a target="_blank" href={this.state.getclientResourceDataList.resource4.clientURL4} className="viewLink">View</a></div>
                                        </div>
                                    </li>
                                    <li className="col-12 col-sm-6 col-md-4">
                                        <div className="inner contentBox">
                                            <div className="hd mb-2 mb-xl-3">{this.state.getclientResourceDataList.resource5.clientTitle5}</div>
                                            <div><a target="_blank" href={this.state.getclientResourceDataList.resource5.clientURL5} className="viewLink">View</a></div>
                                        </div>
                                    </li>

                                 
                                   
                                </ul>
                            </div>
                        } */}





                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
export default Home;