import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Label, Form, Input } from 'reactstrap';
import './LoginPopup.scss';
import { RegisterForm } from '../RegisterForm/RegisterForm';
import { ForgotPassword } from '../ForgotPassword/ForgotPassword';
import combinedServices from "../../shared/services/user-service";
import history from "../../shared/history";
import setLocalData from "../../shared/localStorage/setData";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";

export class LoginPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loginFields: {},
            loginErrors: {},
            loginsuccessMessage: "",
            loginerrorMessage: "",
            successForgotMessage: '',
            errorForgotMessage: '',
            isLoginModal: true,
            isRegisterModal: false,
            isForgotPassword: false,
            toggle: true,
            toUserProfile: false,
            toManualLink: false,
            isOpen: false,
            fields: {},
            errors: {},
            successMessage: "",
            errorMessage: "",
            ForgotEmail: ''

        }


    }


    openRegisterModal = () => {

        this.setState({
            isLoginModal: false,
            isRegisterModal: !this.state.isRegisterModal,
            isForgotPassword: false
        });

    }


    openLoginModal = () => {

        this.setState({
            isLoginModal: true,
            isRegisterModal: false,
            isForgotPassword: false
        });



    }

    forgetPswmodal = () => {
        this.setState({
            isLoginModal: false,
            isRegisterModal: false,
            isForgotPassword: true
        });
    }



    handleChangeLogin = e => {
        this.setState({ [e.target.name]: e.target.value })
        let loginFields = this.state.loginFields;
        loginFields[e.target.name] = e.target.value;
        this.setState({
            loginFields
        });


    }


    handleChange = e => {

        this.setState({ [e.target.name]: e.target.value })
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields

        });


    }

    handleClickRadioButtonData = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields

        });

    }

    handleChangeTopic = e => {
        let topics = this.state.topicsArray;
        topics.push(e.target.value)

        this.setState({ [e.target.name]: e.target.value })
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
            topicsArray: topics
        });



    }

    hasDuplicates = (arr) => {
        var counts = [];

        for (var i = 0; i <= arr.length; i++) {
            if (counts[arr[i]] === undefined) {
                counts[arr[i]] = 1;
            } else {
                return true;
            }
        }
        return false;
    }

    handleChangeForgotPsw = e => {
        this.setState({ ForgotEmail: e.target.value })

    }



    loginHandleSubmit = async (e) => {
        e.preventDefault();


        if (this.validateFormLogin() === false) {
            return;
        } else {
            let fields = this.state.loginFields;

            const data = {
                email: fields['email'],
                password: fields['password']

            };


            let reponsedata = await combinedServices.userLogin(data);
            let response = reponsedata.data;
            let result = response.result1;


            if (response.responseCode === 200) {
                let success = response.success;

                let login_response = {

                    roleType: result.roleType,
                    userEmail: result.email,
                    userId: result._id,
                    status: result.status,
                    token: result.token


                }
                if (result.roleType === "ADMIN") {

                    setLocalData("admin_info", login_response);

                } else {

                    setLocalData("user_info", login_response);

                }




                this.setState({
                    loginErrorMessage: false,
                    loginSuccessMessage: response.responseMessage
                })


                if (result._id && result.roleType == "ADMIN") {

                    this.setState({
                        toManualLink: true
                    });


                } else {

                    window.location.href = '/';



                }



            } else {


                this.setState({
                    loginSuccessMessage: false,
                    loginErrorMessage: response.responseMessage
                })



            }

        }


    }


    termAndConditionCheckbox = () => {
        this.setState({ termAndCondition: !this.state.termAndCondition })
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        if (this.validateForm() === false) {
            return;
        } else {
            let fields = this.state.fields;
            let errors = this.state.errors;
            var emailData = {
                email: fields['email']
            }
            let exitEmailReponseData = await combinedServices.checkIfUserExists(emailData);

            if (exitEmailReponseData.status) {
                errors["email"] = "This user already exits.";
                this.setState({
                errors
                })
            } else if (!this.state.termAndCondition) {
                errors["termAndCondition"] = "Please check term and condition policy checkbox.";
                this.setState({
                    errors
                })

            } else {


                let fields = this.state.fields;

                const data = {
                    firstName: fields['firstName'],
                    lastName: fields['lastName'],
                    firmName: fields['firmName'],
                    mobileNumber: fields['mobileNumber'],
                    email: fields['email'],
                    password: fields['password'],
                    assestUnderManagement: fields['assetsUnderManagement'],
                    investmentTeamPeople: fields['investmentTeam'],
                    investmentTeamMeet: fields['investmentTeamMeet'],
                    corePortfolio: fields['corePortfolio'],
                    satellitePortfolio: fields['satellitePortfolio'],
                    macroStategy: fields['macroStategy'] ? fields['macroStategy'] : 0,
                    credit: fields['credit'] ? fields['credit'] : 0,
                    equity: fields['equity'] ? fields['equity'] : 0,
                    economics: fields['economics'] ? fields['economics'] : 0,
                    fundamental: fields['fundamental'] ? fields['fundamental'] : 0,
                    technicalAnalysis: fields['technicalAnalysis'] ? fields['technicalAnalysis'] : 0,
                    quantitative: fields['quantitative'] ? fields['quantitative'] : 0,
                    individualStocks: fields['individualStocks'] ? fields['individualStocks'] : 0,
                    mutualFunds: fields['mutualFunds'] ? fields['mutualFunds'] : 0,
                    eTFs: fields['eTFs'] ? fields['eTFs'] : 0,
                    sMAs: fields['sMAs'] ? fields['sMAs'] : 0

                };

                let response = await combinedServices.userSignUp(data);
                if (response.responseCode === 200) {

                    this.setState({
                        errorMessage: false,
                        successMessage: response.responseMessage
                    })


                } else {

                    this.setState({
                        successMessage: false,
                        errorMessage: response.responseMessage
                    })

                }
            }



        }


    }


    handleForgotPasswordSubmit = async (e) => {
        e.preventDefault();
        const form = e.target;

        if (this.validateFormForgotPassword() === false) {
            return;
        } else {

            const data = {
                email: this.state.ForgotEmail,

            };
            let reponsedata = await combinedServices.forgotPassword(data);
            let response = reponsedata.data;
            if (response.responseCode === 200) {
                this.setState({
                    errorForgotMessage: false,
                    successForgotMessage: response.responseMessage

                })

                setTimeout(function () {
                    window.location.href = "/"
                }, 5000);



            } else {


                this.setState({
                    successForgotMessage: false,
                    errorForgotMessage: response.responseMessage
                })



            }

        }


    }

    validateFormLogin = () => {
        let loginFields = this.state.loginFields;
        let loginErrors = {};
        let formIsValid = true;


        if (!loginFields["email"]) {
            formIsValid = false;
            loginErrors["email"] = "Please enter your email.";
        }
        if (loginFields["email"] !== "") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(loginFields["email"])) {
                formIsValid = false;
                loginErrors["email"] = "Please enter valid email.";
            }
        }

        if (!loginFields["password"]) {
            formIsValid = false;
            loginErrors["password"] = "Please enter your password.";
        }

        this.setState({
            loginErrors: loginErrors
        });
        return formIsValid
    }




    validateForm = () => {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        var topicsArray = [];
        var analysisArray = [];
        var investArray = [];
        topicsArray.push(fields['macroStategy']);
        topicsArray.push(fields['credit']);
        topicsArray.push(fields['equity']);
        topicsArray.push(fields['economics']);

        analysisArray.push(fields['fundamental']);
        analysisArray.push(fields['technicalAnalysis']);
        analysisArray.push(fields['quantitative']);

        investArray.push(fields['individualStocks']);
        investArray.push(fields['mutualFunds']);
        investArray.push(fields['eTFs']);
        investArray.push(fields['sMAs']);




        if (!fields["firstName"]) {
            formIsValid = false;
            errors["firstName"] = "Please enter your first name.";
        }
        if (fields["firstName"]) {

            var regex = /[^a-zA-Z]/g;
            if (regex.test(fields["firstName"])) {
                formIsValid = false;
                errors["firstName"] = "Numbers and special characters not allow in first name.";
            }

        }
        if (!fields["lastName"]) {
            formIsValid = false;
            errors["lastName"] = "Please enter your last name.";
        }

        if (fields["lastName"]) {
            var regex = /[^a-zA-Z]/g;
            if (regex.test(fields["lastName"])) {
                formIsValid = false;
                errors["lastName"] = "Numbers and special characters not allow in first name.";
            }
        }

        if (!fields["firmName"]) {
            formIsValid = false;
            errors["firmName"] = "Please enter your firm name.";
        }

        if (!fields["mobileNumber"]) {
            formIsValid = false;
            errors["mobileNumber"] = "Please enter your mobile number.";
        }

        if (fields["mobileNumber"]) {

            var mob = /^[1-9]{1}[0-9]{9}$/;
            var txtMobile = fields["mobileNumber"];
            if (mob.test(txtMobile) == false) {
                formIsValid = false;
                errors["mobileNumber"] = "Please enter valid mobile number.";

            }

        }


        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Please enter your email.";
        }

        if (fields["email"] !== "") {
            var data = {
                email: fields["email"]
            }


            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
                formIsValid = false;
                errors["email"] = "Please enter valid email.";
            }


        }
        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "Please enter your password.";
        }

        if (this.hasDuplicates(topicsArray) || topicsArray.find(element => element == 0)) {
            formIsValid = false;
            errors["topics"] = "Please fix overlapping number. Rank each with a different number.";
        }
        if (this.hasDuplicates(analysisArray) || analysisArray.find(element => element == 0)) {
            formIsValid = false;
            errors["analysis"] = "Please fix overlapping number. Rank each with a different number.";
        }
        if (this.hasDuplicates(investArray) || investArray.find(element => element == 0)) {
            formIsValid = false;
            errors["invest"] = "Please fix overlapping number. Rank each with a different number.";
        }

        if (!fields["assetsUnderManagement"]) {
            formIsValid = false;
            errors["assetsUnderManagement"] = "Please select one option.";

        }


        if (!fields["investmentTeam"]) {
            formIsValid = false;
            errors["investmentTeam"] = "Please select one option.";
        }

        if (!fields["investmentTeamMeet"]) {
            formIsValid = false;
            errors["investmentTeamMeet"] = "Please check only one option.";
        }

        if (!fields["corePortfolio"]) {
            formIsValid = false;
            errors["corePortfolio"] = "Please check only one option.";
        }


        if (!fields["satellitePortfolio"]) {
            formIsValid = false;
            errors["satellitePortfolio"] = "Please check only one option.";
        }
        if (!this.state.termAndCondition) {
            formIsValid = false;
            errors["termAndCondition"] = "Please check term and condition policy checkbox.";
        }
        this.setState({
            errors: errors
        });
        return formIsValid
    }


    validateFormForgotPassword = () => {
        let email = this.state.ForgotEmail;
        let errors = {};
        let formIsValid = true;


        if (!email) {
            formIsValid = false;
            errors["forgotEmail"] = "Please enter your email.";
        }
        if (email !== "") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(email)) {
                formIsValid = false;
                errors["forgotEmail"] = "Please enter valid email.";
            }
        }
        this.setState({
            errors: errors
        });
        return formIsValid
    }



    render() {

        if (this.state.toManualLink) {
            return <Redirect to='/admin/manual-links' />
        }


        return (
            <div>
                {this.state.toggle && (

                    <Modal isOpen={true} className="modalOuter">

                        {this.state.isLoginModal && (
                            <div className="loginInside">

                                <ModalHeader>Log In</ModalHeader>
                                <ModalBody>

                                    <Form onSubmit={this.loginHandleSubmit} autocomplete="off">

                                        <div className="inner">
                                            <FormGroup>
                                                <Label>Email<span className="asterick">*</span></Label>
                                                <Input type="text" autoComplete="off" name="email" className="loginInput" onChange={this.handleChangeLogin} />
                                                {this.state.loginErrors.email && (
                                                    <span className="errorMsg">{this.state.loginErrors.email}</span>
                                                )}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Password<span className="asterick">*</span></Label>
                                                <Input type="password" autoComplete="off" name="password" className="loginInput passwordIn" onChange={this.handleChangeLogin} />
                                                {this.state.loginErrors.password && (
                                                    <span className="errorMsg">{this.state.loginErrors.password}</span>
                                                )}
                                            </FormGroup>
                                            <FormGroup className="mb0 d-flex justify-content-between loginLink">
                                                <div><a href="#" onClick={this.openRegisterModal}>Create Your Account</a></div>
                                                <div><a href="#" onClick={this.forgetPswmodal}>Forgot your password?</a></div>
                                            </FormGroup>
                                        </div>
                                        <div className="loginBtn">
                                            <div className="mesggtext">

                                                {this.state.loginSuccessMessage ? <div className="successMsg">{this.state.loginSuccessMessage}</div> : ''}
                                                {this.state.loginErrorMessage ? <span className="errorMsg">{this.state.loginErrorMessage}</span> : ''}

                                            </div>
                                            <Button>Log In</Button>
                                        </div>
                                    </Form>
                                </ModalBody>
                            </div>
                        )}

                        {this.state.isRegisterModal && (


                            <div >

                                <ModalHeader>Portal Registration</ModalHeader>
                                <ModalBody>
                                    <Form onSubmit={this.handleSubmit}>


                                        <div className="inner">
                                            <div> {this.state.openDeleteModal && this.loginPopup()}</div>

                                            <ul className="regForm d-flex flex-wrap">
                                                <li>
                                                    <FormGroup>
                                                        <Input type="text" name="firstName" className="regInput" placeholder="First Name" onChange={this.handleChange} />
                                                    </FormGroup>
                                                    {this.state.errors.firstName && (
                                                        <div className="errorMsg">{this.state.errors.firstName}</div>
                                                    )}
                                                </li>
                                                <li>
                                                    <FormGroup>
                                                        <Input type="text" name="lastName" className="regInput" placeholder="Last Name" onChange={this.handleChange} />
                                                    </FormGroup>
                                                    {this.state.errors.lastName && (
                                                        <div className="errorMsg">{this.state.errors.lastName}</div>
                                                    )}
                                                </li>
                                                <li>
                                                    <FormGroup>
                                                        <Input type="text" name="firmName" className="regInput" placeholder="Company Name" onChange={this.handleChange} />
                                                    </FormGroup>
                                                    {this.state.errors.firmName && (
                                                        <div className="errorMsg">{this.state.errors.firmName}</div>
                                                    )}
                                                </li>
                                                <li>
                                                    <FormGroup>
                                                        <Input type="text" name="mobileNumber" className="regInput" placeholder="Mobile Number (text alerts)" onChange={this.handleChange} />
                                                    </FormGroup>
                                                    {this.state.errors.mobileNumber && (
                                                        <div className="errorMsg">{this.state.errors.mobileNumber}</div>
                                                    )}
                                                </li>
                                                <li>
                                                    <FormGroup>
                                                        <Input type="text" name="email" className="regInput" placeholder="Work Email" onChange={this.handleChange} />
                                                    </FormGroup>
                                                    {this.state.errors.email && (
                                                        <div className="errorMsg">{this.state.errors.email}</div>
                                                    )}
                                                </li>
                                                <li>
                                                    <FormGroup>
                                                        <Input type="password" name="password" className="regInput passwordIn" placeholder="Password" onChange={this.handleChange} />
                                                    </FormGroup>
                                                    {this.state.errors.password && (
                                                        <div className="errorMsg">{this.state.errors.password}</div>
                                                    )}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="modal-header">
                                            <h1 className="modal-title">Customize Your Portal Experience</h1>
                                        </div>
                                        <div className="inner">
                                            <div className="text-center contentBox pb-4">
                                                <p>We want your experience with the MarketDesk  Portal to be perfect.<br />
                                            Answer a few questions to help us get to know you better.</p>
                                            </div>
                                            <FormGroup className="pb-4 pl-md-5 pl-3">
                                                <span className="regSubHd">1. Topics you're most focused on (Rank 1st to 4th)</span>
                                                <ul className="regForm d-flex flex-wrap">
                                                    <li>
                                                        <FormGroup>
                                                            <Input type="number" name="macroStategy" value={this.state.fields.macroStategy} placeholder="0" min="0" max="4" className="countInput" onChange={this.handleChange} />
                                                            <span className="optnText contentBox">Macro Stategy</span>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <Input type="number" name="credit" value={this.state.fields.credit} placeholder="0" min="0" max="4" className="countInput" onChange={this.handleChange} />
                                                            <span className="optnText contentBox">Credit</span>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <Input type="number" name="equity" value={this.state.fields.equity} placeholder="0" min="0" max="4" className="countInput" onChange={this.handleChange} />
                                                            <span className="optnText contentBox">Equity</span>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <Input type="number" name="economics" value={this.state.fields.economics} placeholder="0" min="0" max="4" className="countInput" onChange={this.handleChange} />
                                                            <span className="optnText contentBox">Economics</span>
                                                        </FormGroup>
                                                    </li>
                                                </ul>

                                                {this.state.errors.topics && (
                                                    <div className="errorMsg">{this.state.errors.topics}</div>
                                                )}

                                            </FormGroup>
                                            <FormGroup className="pb-4 pl-md-5 pl-3">
                                                <span className="regSubHd">2. Preferred type of analysis (Rank 1st to 3rd)</span>
                                                <ul className="regForm d-flex flex-wrap">
                                                    <li>
                                                        <FormGroup>
                                                            <Input type="number" name="fundamental" value={this.state.fields.fundamental} placeholder="0" min="0" max="3" className="countInput" onChange={this.handleChange} />
                                                            <span className="optnText contentBox">Fundamental</span>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <Input type="number" name="technicalAnalysis" value={this.state.fields.technicalAnalysis} placeholder="0" min="0" max="3" className="countInput" onChange={this.handleChange} />
                                                            <span className="optnText contentBox">Technical Analysis</span>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <Input type="number" name="quantitative" value={this.state.fields.quantitative} placeholder="0" min="0" max="3" className="countInput" onChange={this.handleChange} />
                                                            <span className="optnText contentBox">Quantitative/Statistics</span>
                                                        </FormGroup>
                                                    </li>
                                                </ul>

                                                {this.state.errors.analysis && (
                                                    <div className="errorMsg">{this.state.errors.analysis}</div>
                                                )}
                                            </FormGroup>
                                            <FormGroup className="pb-4 pl-md-5 pl-3">
                                                <span className="regSubHd">3. Preferred investment type (Rank 1st to 4th)</span>
                                                <ul className="regForm d-flex flex-wrap">
                                                    <li>
                                                        <FormGroup>
                                                            <Input type="number" name="individualStocks" value={this.state.fields.individualStocks} placeholder="0" min="0" max="4" className="countInput" onChange={this.handleChange} />
                                                            <span className="optnText contentBox">Individual Stocks</span>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <Input type="number" name="mutualFunds" value={this.state.fields.mutualFunds} placeholder="0" min="0" max="4" className="countInput" onChange={this.handleChange} />
                                                            <span className="optnText contentBox">Mutual Funds</span>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <Input type="number" name="eTFs" value={this.state.fields.eTFs} placeholder="0" min="0" max="4" className="countInput" onChange={this.handleChange} />
                                                            <span className="optnText contentBox">ETFs</span>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <Input type="number" name="sMAs" value={this.state.fields.sMAs} placeholder="0" min="0" max="4" className="countInput" onChange={this.handleChange} />
                                                            <span className="optnText contentBox">SMAs</span>
                                                        </FormGroup>
                                                    </li>
                                                </ul>

                                                {this.state.errors.invest && (
                                                    <div className="errorMsg">{this.state.errors.invest}</div>
                                                )}
                                            </FormGroup>
                                            <FormGroup className="pb-4 pl-md-5 pl-3 pr-md-5 pr-3">
                                                <span className="regSubHd">4. Firm Assets Under Management (AUM)</span>
                                                <Input type="select" onChange={this.handleChange} name="assetsUnderManagement" id="exampleSelect">
                                                    <option>Please select</option>
                                                    <option value="Less than $50M">Less than $50M</option>
                                                    <option value="$50M-100M">$50M-100M</option>
                                                    <option value="$100M-250M">$100M-250M</option>
                                                    <option value="$250M-500M">$250M-500M</option>
                                                    <option value="$500M- $1B">$500M-$1B</option>
                                                    <option value="Greater than $1B">Greater than $1B</option>
                                                </Input>

                                                {this.state.errors.assetsUnderManagement && (
                                                    <div className="errorMsg">{this.state.errors.assetsUnderManagement}</div>
                                                )}
                                            </FormGroup>
                                            <FormGroup className="pb-4 pl-md-5 pl-3 pr-md-5 pr-3">
                                                <span className="regSubHd">5. Number of people on your investment team</span>
                                                <Input type="select" onChange={this.handleChange} name="investmentTeam" id="exampleSelect">
                                                    <option>Please select</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5 or More</option>

                                                </Input>
                                                {this.state.errors.investmentTeam && (
                                                    <div className="errorMsg">{this.state.errors.investmentTeam}</div>
                                                )}
                                            </FormGroup>

                                            <FormGroup className="pb-4 pl-md-5 pl-3">
                                                <span className="regSubHd">6. How often does your investment team meet?</span>
                                                <ul className="regForm checkForm d-flex flex-wrap">
                                                    <li>
                                                        <FormGroup>
                                                            <label className="checkCover">
                                                                <span className="checkWrapper"><Input type="radio" onClick={this.handleClickRadioButtonData} name="investmentTeamMeet" value="Monthly" className="checkInpt" /><i className="bg"></i></span>
                                                                <span className="optnText contentBox"> Monthly</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <label className="checkCover">
                                                                <span className="checkWrapper"><Input type="radio" onClick={this.handleClickRadioButtonData} name="investmentTeamMeet" value="SemiAnnual" className="checkInpt" /><i className="bg"></i></span>
                                                                <span className="optnText contentBox"> Semi-Annual</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <label className="checkCover">
                                                                <span className="checkWrapper"><Input type="radio" name="investmentTeamMeet" value="Quarterly" className="checkInpt" onClick={this.handleClickRadioButtonData} /><i className="bg"></i></span>
                                                                <span className="optnText contentBox"> Quarterly</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <label className="checkCover">
                                                                <span className="checkWrapper"><Input type="radio" name="investmentTeamMeet" value="Weekly" className="checkInpt" onClick={this.handleClickRadioButtonData} /><i className="bg"></i></span>
                                                                <span className="optnText contentBox">Weekly</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                </ul>

                                                {this.state.errors.investmentTeamMeet && (
                                                    <div className="errorMsg">{this.state.errors.investmentTeamMeet}</div>
                                                )}
                                            </FormGroup>
                                            <FormGroup className="pb-4 pl-md-5 pl-3">
                                                <span className="regSubHd">7. How often do you make Core portfolio changes?</span>
                                                <ul className="regForm checkForm d-flex flex-wrap">
                                                    <li>
                                                        <FormGroup>
                                                            <label className="checkCover">
                                                                <span className="checkWrapper"><Input type="radio" name="corePortfolio" value="Monthly" className="checkInpt" onClick={this.handleClickRadioButtonData} /><i className="bg"></i></span>
                                                                <span className="optnText contentBox"> Monthly</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <label className="checkCover">
                                                                <span className="checkWrapper"><Input type="radio" name="corePortfolio" value="SemiAnnual" className="checkInpt" onClick={this.handleClickRadioButtonData} /><i className="bg"></i></span>
                                                                <span className="optnText contentBox"> Semi-Annual</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <label className="checkCover">
                                                                <span className="checkWrapper"><Input type="radio" name="corePortfolio" value="Quarterly" className="checkInpt" onClick={this.handleClickRadioButtonData} /><i className="bg"></i></span>
                                                                <span className="optnText contentBox"> Quarterly</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <label className="checkCover">
                                                                <span className="checkWrapper"><Input type="radio" name="corePortfolio" value="Annual" className="checkInpt" onClick={this.handleClickRadioButtonData} /><i className="bg"></i></span>
                                                                <span className="optnText contentBox"> Annual</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                </ul>

                                                {this.state.errors.corePortfolio && (

                                                    <div className="errorMsg">{this.state.errors.corePortfolio}</div>
                                                )}
                                            </FormGroup>
                                            <FormGroup className="pl-md-5 pl-3">

                                                <span className="regSubHd">8. How often do you make Satellite / Tactical
portfolio changes?</span>
                                                <ul className="regForm checkForm d-flex flex-wrap">
                                                    <li>
                                                        <FormGroup>
                                                            <label className="checkCover">
                                                                <span className="checkWrapper"><Input type="radio" name="satellitePortfolio" value="Monthly" className="checkInpt" onClick={this.handleClickRadioButtonData} /><i className="bg"></i></span>
                                                                <span className="optnText contentBox"> Monthly</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <label className="checkCover">
                                                                <span className="checkWrapper"><Input type="radio" name="satellitePortfolio" value="SemiAnnual" className="checkInpt" onClick={this.handleClickRadioButtonData} /><i className="bg"></i></span>
                                                                <span className="optnText contentBox"> Semi-Annual</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <label className="checkCover">
                                                                <span className="checkWrapper"><Input type="radio" name="satellitePortfolio" value="Quarterly" className="checkInpt" onClick={this.handleClickRadioButtonData} /><i className="bg"></i></span>
                                                                <span className="optnText contentBox"> Quarterly</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                    <li>
                                                        <FormGroup>
                                                            <label className="checkCover">
                                                                <span className="checkWrapper"><Input type="radio" name="satellitePortfolio" value="Annual" className="checkInpt" onClick={this.handleClickRadioButtonData} /><i className="bg"></i></span>
                                                                <span className="optnText contentBox"> Annual</span>
                                                            </label>
                                                        </FormGroup>
                                                    </li>
                                                </ul>

                                                {this.state.errors.satellitePortfolio && (

                                                    <div className="errorMsg">{this.state.errors.satellitePortfolio}</div>
                                                )}


                                            </FormGroup>
                                        </div>
                                        {this.state.successMessage ? <div className="alert alert-success">{this.state.successMessage}</div> : ''}
                                        {this.state.errorMessage ? <div className="alert alert-danger">{this.state.errorMessage}</div> : ''}

                                        <div className="loginBtn text-center">
                                            <div className="fullWidth">

                                                <div className="tcText"> <span className="checkWrapper"><Input type="checkbox" onChange={this.termAndConditionCheckbox} className="checkInpt" /><i className="bg"></i></span> I understand and agree to the <a target="_blank" href="https://www.marketdeskresearch.com/terms">Terms &amp; Conditions</a>
                                                    {this.state.errors.termAndCondition && (

                                                        <div className="errorMsg">{this.state.errors.termAndCondition}</div>
                                                    )}

                                                </div>

                                                <div><Button>Create Account</Button></div>

                                                <div className="mt-3"><a href="#" onClick={this.openLoginModal}>Already have an account ? Log in</a></div>
                                                <div>

                                                </div>
                                            </div>
                                        </div>



                                    </Form>


                                </ModalBody>
                            </div>
                        )}

                        {this.state.isForgotPassword && (

                            <div className="loginInside">
                                <ModalHeader>Forgot Password</ModalHeader>
                                <ModalBody>
                                    <Form onSubmit={this.handleForgotPasswordSubmit}>


                                        <div className="inner">
                                            <FormGroup>
                                                <Label>Email<span className="asterick">*</span></Label>
                                                <Input type="text" name="email" onChange={this.handleChangeForgotPsw} className="loginInput" />
                                                {this.state.errors.forgotEmail && (
                                                    <div className="errorMsg">{this.state.errors.forgotEmail}</div>
                                                )}
                                            </FormGroup>


                                            <FormGroup className="mb0 d-flex justify-content-between loginLink">
                                                <div><a href="#" onClick={this.openRegisterModal}>Create Your Account</a></div>
                                                <div><a href="#" onClick={this.openLoginModal}>Log In</a></div>
                                            </FormGroup>
                                        </div>




                                        <div className="loginBtn">

                                            <div className="mesggtext">
                                                {this.state.successForgotMessage ? <div className="successMsg">{this.state.successForgotMessage}</div> : ''}
                                                {this.state.errorForgotMessage ? <div className="errorMsg">{this.state.errorForgotMessage}</div> : ''}

                                            </div>

                                            <Button>Reset Password</Button>

                                        </div>

                                    </Form>
                                </ModalBody>
                            </div>
                        )}
                    </Modal>
                )}
            </div>



        )
    }



}


export default LoginPopup;
