import React, { useState,useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Label, Form, Input  } from 'reactstrap';
import './CreateAccountForm.scss';
import combinedServices from "../../shared/services/user-service";

const CreateAccountForm = (props) => {

    const [modal, setModal] = useState(props.isOpen);
    const [userProfileData, userData] = useState([]);
    const [userTopicsData, userTopics] = useState([]);
    const [userInvestData, userInvest] = useState([]);
    const [userAnalysisData, userAnalysis] = useState([]);
    
    const toggle = () => {
        props.closepopup(setModal(!modal));
    }

    useEffect(async() => {
        var data ={
            userId :props.userId,
            email:props.email
        }
       

        let userProfileResponse = await combinedServices.getUserProfile(data);
        var userprofileData = userProfileResponse.data ;
        var investData = userprofileData.userInvestment;
        var topicsData = userprofileData.userTopic;
        var analysisData = userprofileData.userAnalysis;

        userData(userprofileData.user )
        userTopics(topicsData )
        userInvest(investData)
        userAnalysis(analysisData)

       
        
        }, [])

    return (
        <div>
            
            <Modal isOpen={modal} className="modalOuter accountForm">
                <div>
                    <ModalHeader toggle={toggle}>Create Account Form</ModalHeader>
                    <ModalBody>
                    <div className="tableBox accountTables">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Answers</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="id">1</td>
                                            <td className="answer">{userTopicsData.macroStategy}-Macro Strategy,{userTopicsData.credit}-Credit,{userTopicsData.equity}-Equity, {userTopicsData.economics}-Economics</td>
                                        </tr>
                                        <tr>
                                            <td className="id">2</td>
                                            <td className="answer">{userAnalysisData.technicalAnalysis}-Technical, {userAnalysisData.fundamental}-Fundamental, {userAnalysisData.quantitative}-Quantitative/Statistics</td>
                                        </tr>
                                        <tr>
                                            <td className="id">3</td>
                                            <td className="answer">{userInvestData.mutualFunds}-Mutual Funds, {userInvestData.individualStocks}-Individual Stocks, {userInvestData.eTFs}-ETFs, {userInvestData.sMAs}-SMAs</td>
                                        </tr>
                                        <tr>
                                            <td className="id">4</td>
                                            <td className="answer">{userProfileData.assestUnderManagement}</td>
                                        </tr>
                                        <tr>
                                            <td className="id">5</td>
                                            <td className="answer">{userProfileData.investmentTeamPeople}</td>
                                        </tr>
                                        <tr>
                                            <td className="id">6</td>
                                            <td className="answer">{userProfileData.investmentTeamMeet}</td>
                                        </tr>
                                        <tr>
                                            <td className="id">7</td>
                                            <td className="answer">{userProfileData.corePortfolio}</td>
                                        </tr>
                                        
                                        <tr>
                                            <td className="id">8</td>
                                            <td className="answer">{userProfileData.satellitePortfolio}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                    </ModalBody>
                </div>
            </Modal>
        </div>
    );
}

export default CreateAccountForm;