import React, { Component } from 'react';
import { TopLinks } from '../../component/TopLinks/TopLinks';
import Header from '../../component/headerWithoutLoginPopup/Header';
import { Footer } from '../../component/Footer/Footer';
import combinedServices from '../../shared/services/user-service';
import getLocalData from "../../shared/localStorage/getData";
import removeLocalData from "../../shared/localStorage/removeData";
import './LatestReport.scss'
import moment from 'moment'

export default class LatestReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            latestReportStatus: false,
            latestReportDataList: '',
            userId: '',
            email: ''

        }
    }

    componentDidMount = async () => {

        if (getLocalData("user_info", "isLogin")) {
            let user_info = getLocalData("user_info", "all");

            var data = {};
            data.email = user_info.userData.userEmail;
            data.userId = user_info.userData.userId;

            this.setState({
                userId: user_info.userData.userId,
                email: user_info.userData.userEmail,

            });
        }
        let latestReportData = await combinedServices.getLrtReportData(data);
        console.log("latest report data", latestReportData.data)


        if (latestReportData.status) {

            this.setState({
                latestReportDataList: latestReportData.data,
                latestReportStatus: latestReportData.status
            });
        } else {

            this.setState({
                latestReportStatus: latestReportData.status
            });

        }




    }
    render() {
        return (
            <>
                <Header />
                <TopLinks />
                <div className='mt-3 pb-3 mt-xl-5 pb-xl-5'>
                <div className="container" style={{ minHeight: '70vh' }}>
                    <div className='resorcesHd'>
                        <h2>Latest Reports</h2>
                        {/* <p>The page organizes the most recent reports from MarketDesk by type. Click
                            any reports below to open the report page and download the material.
                        </p> */}
                        <p>The page organizes the most recent reports from MarketDesk by type. Click
                            any reports below to download the material.
                        </p>
                    </div>
                    {
                        this.state.latestReportStatus == true ?
                            <>
                                {
                                    this.state.latestReportDataList.lrtTitle1 ?

                                        <div className='lrtListBx'>
                                            <h2>{this.state.latestReportDataList.lrtTitle1}</h2>
                                            <ul>

                                                {
                                                 this.state.latestReportDataList.lrtUrl1 == "" ||  this.state.latestReportDataList.lrtImage1 == "" || this.state.latestReportDataList.lrtName1 == "" || this.state.latestReportDataList.lrtDate1 == null ?
                                                        <>


                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl1} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage1} alt="LRT Image"  />
                                                                <strong>{this.state.latestReportDataList.lrtName1}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate1).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }



                                                {
                                                  this.state.latestReportDataList.lrtUrl2 == "" ||  this.state.latestReportDataList.lrtImage2 == "" || this.state.latestReportDataList.lrtName2 == "" || this.state.latestReportDataList.lrtDate2 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl2} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage2} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName2}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate2).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }



                                                {
                                                this.state.latestReportDataList.lrtUrl3 == "" ||    this.state.latestReportDataList.lrtImage3 == "" || this.state.latestReportDataList.lrtName3 == "" || this.state.latestReportDataList.lrtDate3 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl3} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage3} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName3}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate3).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }



                                                {
                                                 this.state.latestReportDataList.lrtUrl4 == "" ||   this.state.latestReportDataList.lrtImage4 == "" || this.state.latestReportDataList.lrtName4 == "" || this.state.latestReportDataList.lrtDate4 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl4} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage4} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName4}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate4).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }



                                                {
                                                 this.state.latestReportDataList.lrtUrl5 == "" ||   this.state.latestReportDataList.lrtImage5 == "" || this.state.latestReportDataList.lrtName5 == "" || this.state.latestReportDataList.lrtDate5 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl5} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage5} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName5}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate5).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }



                                            </ul>
                                        </div>
                                        :
                                        null}
                                {
                                    this.state.latestReportDataList.lrtTitle2 ?
                                        <div className='lrtListBx'>
                                            <h2>{this.state.latestReportDataList.lrtTitle2}</h2>
                                            <ul>



                                                {
                                                 this.state.latestReportDataList.lrtUrl6 == "" ||   this.state.latestReportDataList.lrtImage6 == "" || this.state.latestReportDataList.lrtName6 == "" || this.state.latestReportDataList.lrtDate6 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl6} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage6} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName6}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate6).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>

                                                }




                                                {
                                                this.state.latestReportDataList.lrtUrl7 == "" ||    this.state.latestReportDataList.lrtImage7 == "" || this.state.latestReportDataList.lrtName7 == "" || this.state.latestReportDataList.lrtDate7 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl7} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage7} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName7}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate7).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }



                                                {
                                                this.state.latestReportDataList.lrtUrl8 == "" ||    this.state.latestReportDataList.lrtImage8 == "" || this.state.latestReportDataList.lrtName8 == "" || this.state.latestReportDataList.lrtDate8 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl8} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage8} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName8}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate8).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }


                                                {
                                                this.state.latestReportDataList.lrtUrl9 == "" ||    this.state.latestReportDataList.lrtImage9 == "" || this.state.latestReportDataList.lrtName9 == "" || this.state.latestReportDataList.lrtDate9 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl9} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage9} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName9}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate9).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }



                                                {
                                                this.state.latestReportDataList.lrtUrl10 == "" ||    this.state.latestReportDataList.lrtImage10 == "" || this.state.latestReportDataList.lrtName10 == "" || this.state.latestReportDataList.lrtDate10 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl10} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage10} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName10}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate10).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }



                                            </ul>
                                        </div>
                                        :
                                        null}
                                {
                                    this.state.latestReportDataList.lrtTitle3 ?

                                        <div className='lrtListBx'>
                                            <h2>{this.state.latestReportDataList.lrtTitle3}</h2>
                                            <ul>

                                                {
                                                this.state.latestReportDataList.lrtUrl11 == "" ||    this.state.latestReportDataList.lrtImage11 == "" || this.state.latestReportDataList.lrtName11 == "" || this.state.latestReportDataList.lrtDate11 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl11} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage11} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName11}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate11).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }



                                                {
                                                this.state.latestReportDataList.lrtUrl12 == "" ||    this.state.latestReportDataList.lrtImage12 == "" || this.state.latestReportDataList.lrtName12 == "" || this.state.latestReportDataList.lrtDate12 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl12} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage12} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName12}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate12).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }



                                                {
                                                this.state.latestReportDataList.lrtUrl13 == "" ||    this.state.latestReportDataList.lrtImage13 == "" || this.state.latestReportDataList.lrtName13 == "" || this.state.latestReportDataList.lrtDate13 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl13} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage13} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName13}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate13).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }



                                                {
                                                 this.state.latestReportDataList.lrtUrl14 == "" ||   this.state.latestReportDataList.lrtImage14 == "" || this.state.latestReportDataList.lrtName14 == "" || this.state.latestReportDataList.lrtDate14 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl14} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage14} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName14}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate14).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }



                                                {
                                                this.state.latestReportDataList.lrtUrl15 == "" ||    this.state.latestReportDataList.lrtImage15 == "" || this.state.latestReportDataList.lrtName15 == "" || this.state.latestReportDataList.lrtDate15 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl15} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage15} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName15}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate15).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }



                                            </ul>
                                        </div>
                                        :
                                        null}
                                {
                                    this.state.latestReportDataList.lrtTitle4 ?

                                        <div className='lrtListBx'>
                                            <h2>{this.state.latestReportDataList.lrtTitle4}</h2>
                                            <ul>

                                                {
                                                 this.state.latestReportDataList.lrtUrl16 == "" ||   this.state.latestReportDataList.lrtImage16 == "" || this.state.latestReportDataList.lrtName16 == "" || this.state.latestReportDataList.lrtDate16 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl16} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage16} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName16}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate16).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }


                                                {
                                                 this.state.latestReportDataList.lrtUrl17 == "" ||   this.state.latestReportDataList.lrtImage17 == "" || this.state.latestReportDataList.lrtName17 == "" || this.state.latestReportDataList.lrtDate17 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl17} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage17} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName17}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate17).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }


                                                {
                                                this.state.latestReportDataList.lrtUrl18 == "" ||    this.state.latestReportDataList.lrtImage18 == "" || this.state.latestReportDataList.lrtName18 == "" || this.state.latestReportDataList.lrtDate18 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl18} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage18} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName18}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate18).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }


                                                {
                                                this.state.latestReportDataList.lrtUrl19 == "" ||    this.state.latestReportDataList.lrtImage19 == "" || this.state.latestReportDataList.lrtName19 == "" || this.state.latestReportDataList.lrtDate19 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                        <a href={this.state.latestReportDataList.lrtUrl19} target="_blank">
                                                            <img src={this.state.latestReportDataList.lrtImage19} alt="LRT Image" />
                                                            <strong>{this.state.latestReportDataList.lrtName19}</strong>
                                                            <span className="date">{moment(this.state.latestReportDataList.lrtDate19).format('M/D/YYYY')}</span>
                                                        </a>
                                                        </li>
                                                }


                                                {
                                                this.state.latestReportDataList.lrtUrl20 == "" ||    this.state.latestReportDataList.lrtImage20 == "" || this.state.latestReportDataList.lrtName20 == "" || this.state.latestReportDataList.lrtDate20 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl20} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage20} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName20}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate20).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }




                                            </ul>


                                        </div>
                                        :
                                        null}
                                {
                                    this.state.latestReportDataList.lrtTitle5 ?


                                        <div className='lrtListBx mb-0'>
                                            <h2>{this.state.latestReportDataList.lrtTitle5}</h2>
                                            <ul>

                                                {
                                                this.state.latestReportDataList.lrtUrl21 == "" ||    this.state.latestReportDataList.lrtImage21 == "" || this.state.latestReportDataList.lrtName21 == "" || this.state.latestReportDataList.lrtDate21 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl21} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage21} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName21}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate21).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }



                                                {
                                                this.state.latestReportDataList.lrtUrl22 == "" ||    this.state.latestReportDataList.lrtImage22 == "" || this.state.latestReportDataList.lrtName22 == "" || this.state.latestReportDataList.lrtDate22 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl22} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage22} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName22}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate22).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }


                                                {
                                                this.state.latestReportDataList.lrtUrl23 == "" ||    this.state.latestReportDataList.lrtImage23 == "" || this.state.latestReportDataList.lrtName23 == "" || this.state.latestReportDataList.lrtDate23 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl23} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage23} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName23}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate23).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>

                                                }



                                                {
                                                 this.state.latestReportDataList.lrtUrl24 == "" ||   this.state.latestReportDataList.lrtImage24 == "" || this.state.latestReportDataList.lrtName24 == "" || this.state.latestReportDataList.lrtDate24 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl24} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage24} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName24}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate24).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }


                                                {
                                                 this.state.latestReportDataList.lrtUrl25 == "" ||   this.state.latestReportDataList.lrtImage25 == "" || this.state.latestReportDataList.lrtName25 == "" || this.state.latestReportDataList.lrtDate25 == null ?
                                                        <>

                                                        </>
                                                        :
                                                        <li>
                                                            <a href={this.state.latestReportDataList.lrtUrl25} target="_blank">
                                                                <img src={this.state.latestReportDataList.lrtImage25} alt="LRT Image" />
                                                                <strong>{this.state.latestReportDataList.lrtName25}</strong>
                                                                <span className="date">{moment(this.state.latestReportDataList.lrtDate25).format('M/D/YYYY')}</span>
                                                            </a>
                                                        </li>
                                                }



                                            </ul>
                                        </div>
                                        :
                                        null
                                }
                            </>
                            :
                            null}

                </div>
                </div>
                <Footer />
            </>
        )
    }
}