import React, { Component } from 'react';
import './NewEdit.scss';
import { AdminTopLinks } from '../../component/TopLinks/AdminTopLinks';
import { Form, Label, FormGroup, Input, Button } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from '../../component/headerWithoutLoginPopup/Header';
import { Footer } from '../../component/Footer/Footer';
import dateFormat from 'dateformat';
import combinedServices from "../../shared/services/user-service";
import { useParams } from "react-router-dom";
import history from "../../shared/history";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6



export class EditReport extends Component {

    
    

    constructor(props) {

        super(props);
        this.state = {
            fields: {},
            errors: {},
            successMessage:'',
            errorMessage:'',
            reportStatus:false,
            selectDate: new Date(),
            reportDataById: [],
            category:[],
            assetClass:[],
            reportType:[],
            geography:[],
            frequency:[],
            reportFormat: '',
            reportTitle: '',
            reportSubTitle: '',
            buttonText1: '',
            buttonURL1: '',
            buttonText2: '',
            buttonURL2: '',
            buttonText3: '',
            buttonURL3: '',
            reportPDFImgURL: '',
            reportPDFURL: '',
            reportParagraph: '',
            watchListUpdates: '',
            isReportIcioChecked: false,
            fields: {},
            toReportList :false,
            isWatchlistUpdated:false


        }
        

    }

    handleReportChecked = () => {
        this.setState({ isReportChecked: !this.state.isReportChecked });
    }



    handleChangeCategory = e => {
    
        let fields = this.state.fields;
        fields[e.target.value] = e.target.checked;
        this.setState({
            fields
        });


        if (e.target.checked) {

            const pushArray = this.state.category;
            pushArray.push(e.target.value);

            this.setState({
                category: pushArray,


            });

        } else {
            var array = this.state.category;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.
            array.splice(index, 1);
            this.setState({
                category: array,



            });


        }


    }


    
    handleChangeAssetClass = (e) =>{

        
        let fields = this.state.fields;
        fields[e.target.value] = e.target.checked;
        this.setState({
            fields
        });


        if(e.target.checked){
           
            const pushArray1 = this.state.assetClass ;
            pushArray1.push(e.target.value);
            

            this.setState({
                assetClass:pushArray1
            });


        }else{
            var array = this.state.assetClass;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.
          
            array.splice(index,1);
            this.setState({
                assetClass:array
            });


        }


    }


    handleChangeReportType = e =>{
        let fields = this.state.fields;
        fields[e.target.value] = e.target.checked;
        this.setState({
            fields
        });

        if(e.target.checked){
           
            const pushArray2 = this.state.reportType ;
            pushArray2.push(e.target.value);
            

            this.setState({
                reportType:pushArray2
            });


        }else{
            var array = this.state.reportType;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.
            //delete array[index];
            array.splice(index,1);
            this.setState({
                reportType:array
            });


        }


    }


    handleChangeGeography = e =>{

        let fields = this.state.fields;
        fields[e.target.value] = e.target.checked;
        this.setState({
            fields
        });

        if(e.target.checked){
           
            const pushArray3 = this.state.geography ;
             pushArray3.push(e.target.value);
            

            this.setState({
                geography:pushArray3
            });


        }else{
            var array = this.state.geography;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.
            //delete array[index];
            array.splice(index,1);
            this.setState({
                geography:array
            });


        }


    }


    handleChangeFrequency = e =>{

        let fields = this.state.fields;
        fields[e.target.value] = e.target.checked;
        this.setState({
            fields
        });

        if(e.target.checked){
           
            const pushArray4 = this.state.frequency ;
             pushArray4.push(e.target.value);
            

            this.setState({
                frequency:pushArray4
            });


        }else{
            var array = this.state.frequency;
            var index = array.indexOf(e.target.value); // Let's say it's Bob.
            //delete array[index];
            array.splice(index,1);
            this.setState({
                frequency:array
            });


        }




    }

   



    convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')



        
    }

    handleChangeReportPeragraph = (value) => {
        
        this.setState({
            reportParagraph: value
  
        });
    }
    handleChangeReportWatchlistPeragraph = (value) =>{
        this.setState({
            watchlistUpdates: value ,
            isWatchlistUpdated:true
           
        });
    }

    componentDidMount = async () => {

        const yourParamName = history.location.pathname;
        const youradminUrl = yourParamName.split('/');

        if (youradminUrl[3] != '') {

            const reportId = youradminUrl[3];
            let reportDataById = await combinedServices.getReportListById(reportId);          
            let reportFormData = reportDataById.data;

           
           
            this.setState({
                reportDataById: reportDataById.data,
                reportFormat: reportFormData.reportFormat,
                reportTitle: reportFormData.reportTitle,
                reportSubTitle: reportFormData.reportSubTitle,
                buttonText1: reportFormData.buttonText1,
                buttonURL1: reportFormData.buttonURL1,
                buttonText2: reportFormData.buttonText2,
                buttonURL2: reportFormData.buttonURL2,
                buttonText3: reportFormData.buttonText3,
                buttonURL3: reportFormData.buttonURL3,
                reportParagraph: reportFormData.reportParagraph,
                watchlistUpdates: reportFormData.watchlistUpdates,
                reportPDFImgURL: reportFormData.reportPDFImgURL,
                reportPDFURL: reportFormData.reportPDFURL,
                reportId: reportFormData._id
            });

            var category = reportDataById.data.researchCategory;
            var assestClass = reportDataById.data.assestClass;
            var frequency = reportDataById.data.frequency;
            var geography = reportDataById.data.geography;
            var reportType = reportDataById.data.reportType;

            this.setState({
            category:category,
            assetClass:assestClass,
            frequency:frequency,
            geography:geography,
            reportType:reportType

                
            });

            category.map((value) => {
                let fields = this.state.fields;
                fields[value] = true;
                this.setState({
                    fields
                });



            });

            assestClass.map((value) => {


                let fields = this.state.fields;
                fields[value] = true;
                this.setState({
                    fields
                });



            });
            frequency.map((value) => {


                let fields = this.state.fields;
                fields[value] = true;
                this.setState({
                    fields
                });



            });




            geography.map((value) => {


                let fields = this.state.fields;
                fields[value] = true;
                this.setState({
                    fields
                });



            });




            reportType.map((value) => {


                let fields = this.state.fields;
                fields[value] = true;
                this.setState({
                    fields
                });



            });
            let publishDate = new Date(this.state.reportDataById.publishedDate);
            this.setState({
                selectDate: publishDate
            })
            //  this.setState({selectDate: new Date(Date.now() + ( 3600 * 1000 * 72))});
        }
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })

    }
    handleChangeWatchlistUpdate = e =>{
        this.setState({ [e.target.name]: e.target.value,isWatchlistUpdated:true })
       
    }

    handleDateChange = (date) => {
        this.setState({
            selectDate: date
        })
    }


    handleSubmit = async (e) => {
        e.preventDefault();

        const researchCategory = this.state.category;
        const assestClass = this.state.assetClass;
        const reportType = this.state.reportType;
        const geography = this.state.geography;
        const frequency = this.state.frequency;
        const data = {

            researchCategory,
            assestClass,
            reportType,
            geography,
            frequency,
            publishedDate: this.state.selectDate,
            reportFormat: this.state.reportFormat,
            reportTitle: this.state.reportTitle,
            reportSubTitle: this.state.reportSubTitle,
            buttonText1: this.state.buttonText1,
            buttonURL1: this.state.buttonURL1,
            buttonText2: this.state.buttonText2,
            buttonURL2: this.state.buttonURL2,
            buttonText3: this.state.buttonText3,
            buttonURL3: this.state.buttonURL3,
            reportPDFImgURL: this.state.reportPDFImgURL,
            reportPDFURL: this.state.reportPDFURL,
            reportParagraph: this.state.reportParagraph,
            watchlistUpdates: this.state.watchlistUpdates,
            reportId: this.state.reportId,
            isWatchlistUpdated:this.state.isWatchlistUpdated

        }
        let responseData = await combinedServices.updateNewEditData(data);
        
        if (responseData.status) {

            this.setState({
                errorMessage: false,
                successMessage: responseData.message,
                reportStatus :responseData.status
                
            })
            this.sucessMsgAlert();


        } else {

            var errorMsg = responseData.message ;

            this.setState({
                successMessage: false,
                errorMessage: errorMsg.message,
                reportStatus :responseData.status
            })

            this.errorMsgAlert();

        }

    }

    sucessMsgAlert = () =>{

        return(

            
            Swal.fire({  
                title:'Success',  
                type: 'Success',  
                text: this.state.successMessage,  
            })



        )
    }
    errorMsgAlert = () =>{

        Swal.fire({  
            icon: 'error',  
            title: 'Oops...',  
            text: this.state.errorMessage,  
          });  



    }




    render() {

     
        return (

            <div>
                <Header />
                <AdminTopLinks />
               
                
               
                <Form onSubmit={this.handleSubmit}>


                    <div className="container miidlePart">
                        <div className="row">
                            <div className="col-12">
                                <div className="mainHd pt-4">New/Edit Report</div>
                               
                            </div>
                            <div className="col-12 col-lg-3 pt-2 pb-0 pb-lg-4">

                                <div className="mtM">
                                    <div className="blueBgHd">Research Category</div>
                                    <div className="infoBox">
                                        <ul className="libOptList">


                                            <li>
                                                <FormGroup>
                                                    <label>


                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                checked={this.state.fields.OCIO}
                                                                onChange={this.handleChangeCategory}
                                                                name="rearchCategory"
                                                                value="OCIO"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>

                                                        <span className="contentBox"> OCIO</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>


                                                        <span className="checkWrapper">
                                                            <Input
                                                                type="checkbox"
                                                                checked={this.state.fields.MacroStrategy}
                                                                onChange={this.handleChangeCategory}
                                                                name="rearchCategory"
                                                                value="MacroStrategy"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Macro Strategy</span>


                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                name="rearchCategory"
                                                                checked={this.state.fields.CoreRating}
                                                                onChange={this.handleChangeCategory}
                                                                value="CoreRating"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>



                                                        <span className="contentBox"> Core Ratings</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                name="rearchCategor"
                                                                checked={this.state.fields.TacticalIdeas}
                                                                onChange={this.handleChangeCategory}

                                                                value="TacticalIdeas"
                                                               
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>

                                                        <span className="contentBox"> Tactical Ideas</span>
                                                    </label>
                                                </FormGroup>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="mtM">
                                    <div className="blueBgHd">Asset Class</div>
                                    <div className="infoBox">
                                        <ul className="libOptList">
                                            <li>
                                                <FormGroup>
                                                    <label>
                                                            <span className="checkWrapper">
                                                                <Input type="checkbox" 
                                                                 checked={this.state.fields.Equity}
                                                                
                                                                 onChange={this.handleChangeAssetClass} 
                                                                 name="asset" 
                                                                 value="Equity" 
                                                                 className="checkInpt" />
                                                                 <i className="bg"></i></span>
                                                    
                                                        <span className="contentBox"> Equity</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                        
                                                            <span className="checkWrapper">
                                                                <Input type="checkbox" 
                                                                checked={this.state.fields.Credit}
                                                                
                                                                onChange={this.handleChangeAssetClass} 
                                                                name="asset" 
                                                                value="Credit" 
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                       

                                                        <span className="contentBox"> Credit</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                        
                                                            <span className="checkWrapper">
                                                                <Input type="checkbox" 
                                                                checked={this.state.fields.Economics}
                                                                onChange={this.handleChangeAssetClass} 
                                                                name="asset" 
                                                                value="Economics" 
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                    
                                                        <span className="contentBox"> Economics</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                        
                                                            <span className="checkWrapper">
                                                                <Input type="checkbox"
                                                            
                                                                 checked={this.state.fields.Alternatives}
                                                                 onChange={this.handleChangeAssetClass}  
                                                                 name="asset" 
                                                                 value="Alternatives" className="checkInpt" /><i className="bg"></i></span>
                                                        

                                                        <span className="contentBox"> Alternatives</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="mtM">
                                    <div className="blueBgHd">Report Type</div>
                                    <div className="infoBox">
                                        <ul className="libOptList">
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                        
                                                        <span className="checkWrapper">
                                                        <Input  type="checkbox" 
                                                        onChange={this.handleChangeReportType} 
                                                        checked={this.state.fields.Sector}
                                                     
                                                        name="reporttype" 
                                                        value="Sector" 
                                                        className="checkInpt" /><i className="bg"></i></span>
                                                        

                                                        <span className="contentBox"> Sector</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                        
                                                            <span className="checkWrapper">
                                                                <Input  type="checkbox" 
                                                                 onChange={this.handleChangeReportType} 
                                                                 checked={this.state.fields.Industry}
                                                                name="reporttype" 
                                                                value="Industry" className="checkInpt" /><i className="bg"></i></span>
                                                       

                                                        <span className="contentBox"> Industry</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                        
                                                            <span className="checkWrapper">
                                                                <Input  type="checkbox" 
                                                                 onChange={this.handleChangeReportType} 
                                                                 checked={this.state.fields.Thematic}
                                                                name="reporttype" 
                                                                value="Thematic" 
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                       

                                                        <span className="contentBox"> Thematic</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                        
                                                    <span className="checkWrapper">
                                                        <Input  type="checkbox" 
                                                        onChange={this.handleChangeReportType} 
                                                        checked={this.state.fields.Factor}
                                                        name="reporttype" 
                                                        value="Factor" 
                                                        className="checkInpt" /><i className="bg"></i></span>
                                                       

                                                        <span className="contentBox"> Factor</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                      
                                                            <span className="checkWrapper">
                                                                <Input  type="checkbox" 
                                                                 onChange={this.handleChangeReportType} 
                                                                 checked={this.state.fields.WashingtonPolicy} 
                                                                name="reporttype" 
                                                                value="WashingtonPolicy" 
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                       

                                                        <span className="contentBox"> Washington Policy</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                       
                                                            <span className="checkWrapper">
                                                                <Input  type="checkbox" 
                                                                onChange={this.handleChangeReportType} 
                                                                checked={this.state.fields.International} 
                                                                name="reporttype" 
                                                                value="International" 
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                        

                                                        <span className="contentBox"> International</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                        
                                                            <span className="checkWrapper">
                                                                <Input  type="checkbox" 
                                                                 onChange={this.handleChangeReportType} 
                                                                 checked={this.state.fields.Quantitative} 
                                                                name="reporttype" 
                                                                value="Quantitative" 
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                    

                                                        <span className="contentBox"> Quantitative</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>


                                                       
                                                            <span className="checkWrapper">
                                                                <Input  type="checkbox" 
                                                                onChange={this.handleChangeReportType} 
                                                                checked={this.state.fields.ETF} 
                                                                name="reporttype" 
                                                                value="ETF" 
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                       
                                                        <span className="contentBox"> ETF</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                        
                                                            <span className="checkWrapper">
                                                                <Input  type="checkbox" 
                                                                 onChange={this.handleChangeReportType} 
                                                                 checked={this.state.fields.IndividualStocks} 
                                                                name="reporttype" 
                                                                value="IndividualStocks" 
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                        

                                                        <span className="contentBox"> Individual Stocks</span>
                                                    </label>
                                                </FormGroup>
                                            </li>


                                            <li>
                                                <FormGroup>
                                                    <label>

                                                        <span className="checkWrapper">
                                                            <Input type="checkbox"
                                                                name="reporttype"
                                                                onChange={this.handleChangeReportType} 
                                                                 checked={this.state.fields.ModelPortfolios} 
                                                                value="ModelPortfolios"
                                                                className="checkInpt" />
                                                            <i className="bg"></i></span>
                                                        <span className="contentBox"> Model Portfolios</span>
                                                    </label>
                                                </FormGroup>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="mtM">
                                    <div className="blueBgHd">Geography</div>
                                    <div className="infoBox">
                                        <ul className="libOptList">
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                        
                                                            <span className="checkWrapper">
                                                                <Input  type="checkbox" 
                                                                onChange={this.handleChangeGeography} 
                                                                checked={this.state.fields.us}  
                                                                name="geography" 
                                                                value="us" 
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                       

                                                        <span className="contentBox"> U.S</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                        
                                                            <span className="checkWrapper">
                                                                <Input type="checkbox" 
                                                                onChange={this.handleChangeGeography} 
                                                                checked={this.state.fields.LatinAmerica}  
                                                                name="geography" 
                                                                value="LatinAmerica" 
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                       


                                                        <span className="contentBox"> Latin America</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                       
                                                            <span className="checkWrapper">
                                                                <Input  type="checkbox" 
                                                                onChange={this.handleChangeGeography} 
                                                                checked={this.state.fields.Europe}   
                                                                name="geography" 
                                                                value="Europe" 
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                       

                                                        <span className="contentBox"> Europe</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                        
                                                            <span className="checkWrapper">
                                                            <Input  type="checkbox" 
                                                             onChange={this.handleChangeGeography} 
                                                             checked={this.state.fields.AsiaPacific}  
                                                            name="geography" 
                                                            value="AsiaPacific" 
                                                            className="checkInpt" /><i className="bg"></i></span>
                                                        

                                                        <span className="contentBox"> Asia-Pacific</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="mtM">
                                    <div className="blueBgHd">Frequency</div>
                                    <div className="infoBox">
                                        <ul className="libOptList">
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                       
                                                            <span className="checkWrapper">
                                                                <Input  type="checkbox" 
                                                                  onChange={this.handleChangeFrequency} 
                                                                  checked={this.state.fields.Weekly}  
                                                                name="frequency" 
                                                                value="Weekly" 
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                        


                                                        <span className="contentBox"> Weekly</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                        
                                                            <span className="checkWrapper">
                                                            <Input  type="checkbox" 
                                                            onChange={this.handleChangeFrequency} 
                                                            checked={this.state.fields.biWeekly} 
                                                            name="frequency" value="biWeekly" className="checkInpt" /><i className="bg"></i></span>
                                                       

                                                        <span className="contentBox"> Bi-Weekly</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>



                                                       
                                                            <span className="checkWrapper">
                                                                <Input type="checkbox" 
                                                                  onChange={this.handleChangeFrequency} 
                                                                  checked={this.state.fields.Monthly}  
                                                                 name="frequency" 
                                                                 value="Monthly" 
                                                                 className="checkInpt" /><i className="bg"></i></span>
                                                        


                                                        <span className="contentBox"> Monthly</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                            <li>
                                                <FormGroup>
                                                    <label>

                                                            <span className="checkWrapper">
                                                                <Input  type="checkbox" 
                                                                 onChange={this.handleChangeFrequency} 
                                                                 checked={this.state.fields.Quarterly}  
                                                                name="frequency" 
                                                                value="Quarterly" 
                                                                className="checkInpt" /><i className="bg"></i></span>
                                                        

                                                        <span className="contentBox"> Quarterly</span>
                                                    </label>
                                                </FormGroup>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-9 pt-4 pt-lg-2 pb-4">
                                <ul className="editForm d-flex flex-wrap">
                                    <li className="fullWidth">
                                        <FormGroup>
                                            <Label>Date Published</Label>
                                            <DatePicker
                                                placeholderText="Select Date"
                                                selected={this.state.selectDate}
                                                name="date"
                                                onChange={this.handleDateChange}
                                                className="formInput form-control"
                                            />
                                        </FormGroup>
                                    </li>
                                    <li className="fullWidth">
                                        <FormGroup>
                                            <Label>Report Format</Label>
                                            <Input type="text" name="reportFormat" value={this.state.reportFormat} onChange={this.handleChange} className="formInput" />

                                        </FormGroup>



                                    </li>
                                    <li className="fullWidth">
                                        <FormGroup>
                                            <Label>Report Title</Label>
                                            <Input type="text" name="reportTitle" value={this.state.reportTitle} onChange={this.handleChange} className="formInput" />
                                        </FormGroup>


                                    </li>
                                    <li className="fullWidth">
                                        <FormGroup>
                                            <Label>Report Sub Title</Label>
                                            <Input type="text" name="reportSubTitle" value={this.state.reportSubTitle} onChange={this.handleChange} className="formInput" />
                                        </FormGroup>

                                    </li>
                                    <li>
                                        <FormGroup>
                                            <Label>Button #1 - Text</Label>
                                            <Input type="text" name="buttonText1" value={this.state.buttonText1} onChange={this.handleChange} className="formInput" />
                                        </FormGroup>


                                    </li>
                                    <li>
                                        <FormGroup>
                                            <Label>Button #1 - URL</Label>
                                            <Input type="text" name="buttonURL1" value={this.state.buttonURL1} onChange={this.handleChange} className="formInput" />
                                        </FormGroup>

                                    </li>
                                    <li>
                                        <FormGroup>
                                            <Label>Button #2 - Text</Label>
                                            <Input type="text" name="buttonText2" value={this.state.buttonText2} onChange={this.handleChange} className="formInput" />
                                        </FormGroup>

                                    </li>
                                    <li>
                                        <FormGroup>
                                            <Label>Button #2 - URL</Label>
                                            <Input type="text" name="buttonURL2" value={this.state.buttonURL2} onChange={this.handleChange} className="formInput" />
                                        </FormGroup>

                                    </li>
                                    <li>
                                        <FormGroup>
                                            <Label>Button #3 - Text</Label>
                                            <Input type="text" name="buttonText3" value={this.state.buttonText3} onChange={this.handleChange} className="formInput" />
                                        </FormGroup>

                                    </li>
                                    <li>
                                        <FormGroup>
                                            <Label>Button #3 - URL</Label>
                                            <Input type="text" name="buttonURL3" value={this.state.buttonURL3} onChange={this.handleChange} className="formInput" />
                                        </FormGroup>

                                    </li>
                                    <li>
                                        <FormGroup>
                                            <Label>Report PDF Image URL</Label>
                                            <Input type="text" name="reportPDFImgURL" value={this.state.reportPDFImgURL} onChange={this.handleChange} className="formInput" />
                                        </FormGroup>


                                    </li>
                                    <li>
                                        <FormGroup>
                                            <Label>Report PDF URL</Label>
                                            <Input type="text" name="reportPDFURL" value={this.state.reportPDFURL} onChange={this.handleChange} className="formInput" />
                                        </FormGroup>

                                    </li>
                                    <li className="fullWidth">
                                        <FormGroup>
                                            <Label>Report Paragraph</Label>
                                            <ReactQuill value={this.state.reportParagraph}
                  onChange={this.handleChangeReportPeragraph} 
                  className="formInput "
                  />
                                           
                                        </FormGroup>

                                       
                                       
                                    </li>
                                    <li className="fullWidth">
                                        <FormGroup>
                                            <Label>Watchlist Updates</Label>
                                            <ReactQuill value={this.state.watchlistUpdates}
                  onChange={this.handleChangeReportWatchlistPeragraph} 
                  className="formInput "
                  />
                  </FormGroup>
                                           
                                       


                                    </li>
                                </ul>

                               

                              
                                <div className="pt-2 pt-lg-4"><Button className="formbtn">Submit</Button></div>
                            </div>
                        </div>
                    </div>
                </Form>


                <Footer />
            </div>
        );
    }
}
export default EditReport;