

export default function setLocalData(key, value) 
{   
    var responseStatus = false;
    let initialState = {}

    switch (key) 
    {
        
        case 'user_info':
            const currentDateTime = new Date();
            const updateDateTime = new Date();
//            const expireDateTime = new Date(updateDateTime.setMinutes(updateDateTime.getMinutes()+1440));
		const expireDateTime = new Date(updateDateTime.getTime() + (4 * 30 * 24 * 60 * 60 * 1000));
            const currentTimestamp = Math.floor(currentDateTime.getTime() / 1000);
            const expireTimeStamp = Math.floor(expireDateTime.getTime() / 1000);
        
            initialState = {
                isLogin: true,
                isRegistered: true,
                userData: value,
                loginTime: currentTimestamp,
                expirationTime: expireTimeStamp
                
            };
            localStorage.setItem(key, btoa(JSON.stringify(initialState)));
            
            responseStatus = true;
            
            break;

        case 'admin_info':
            
            initialState = {
                isLogin: true,
                userData: value,
                
              
            };
            console.log(initialState)
            localStorage.setItem(key, btoa(JSON.stringify(initialState)));
            responseStatus = true;
            
            break;
        default:
            responseStatus = false;

            break;
    }

    return responseStatus;
}
